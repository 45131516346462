import React from 'react';

const FormFieldWrapper = ({
  children,
  noWrapper = false,
  // colSpan=6
  // or colSpan={col:4, md:5}
  colSpan = { col: 9, md: 12 },
  className = '',
}) => {
  if (noWrapper) {
    return <>{children}</>;
  }

  const COL = 9;
  const MD = 12;
  let newSpan = { col: COL, md: MD };
  if (typeof colSpan === 'object') {
    newSpan = { col: COL, md: MD, ...colSpan };
  } else if (!Number.isNaN(colSpan)) {
    newSpan = { col: COL, md: colSpan };
  }

  return (
    <div
      className={`p-field p-col-${newSpan.col || COL} p-md-${newSpan.md ||
        MD} p-mb-0 form-field-wrapper ${className}`}
    >
      {children}
    </div>
  );
};

export default FormFieldWrapper;
