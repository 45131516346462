import React from 'react';
import { Button } from 'olaqin-design-system';
import customStyle from './Reports.module.scss';

const ReportsDownload = ({ isDisabled, linkToFile, fileNameForDownload }) => (
  <>
    {isDisabled ? (
      <Button
        icon="pi pi-download"
        type="secondary"
        btnType="secondary"
        disabled={isDisabled}
        className={customStyle.customButtonDownload}
        label="Télécharger"
      />
    ) : (
      <a href={linkToFile} download={fileNameForDownload}>
        <Button
          icon="pi pi-download"
          type="secondary"
          btnType="secondary"
          disabled={isDisabled}
          className={customStyle.customButtonDownload}
          label="Télécharger"
        />
      </a>
    )}
  </>
);
export default ReportsDownload;
