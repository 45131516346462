import React from 'react';
import { billingSystemTypeServer } from 'app/constants/client.constants';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldInputMask from 'components/_shared/form/FormFieldInputMask';
import './clientCreateForm.scss';

const BusinessIdentifiers = ({ billingSystemValue }) => (
  <FormFieldGroup title="Identifiants entreprises">
    <FormFieldInputMask
      name="siret"
      label="N° de SIRET"
      mask="999 999 999 99999"
      autoClear={false}
    />
    <FormFieldInputText name="ape" label="Code APE" />
    {billingSystemValue === billingSystemTypeServer.OLAQIN_HARDWARE && (
      <FormFieldInputMask name="finess" label="N° FINESS" mask="99 999999 9" />
    )}
    {billingSystemValue === billingSystemTypeServer.AFFID && (
      <FormFieldInputMask name="adeli" label="N° ADELI" mask="99 99 9999 9" />
    )}
    {billingSystemValue === billingSystemTypeServer.AFFID && (
      <FormFieldInputText name="rpps" label="N° RPPS" />
    )}
  </FormFieldGroup>
);

export default BusinessIdentifiers;
