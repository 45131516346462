import env from '@beam-australia/react-env';

let authorityEnv;
let logout;

// eslint-disable-next-line prefer-const
authorityEnv = env('AUTHORITY');
// eslint-disable-next-line prefer-const
logout = env('POST_LOGOUT_REDIRECT_URI');
// Cookies.set('connect', 'olaqin');

export const msalConfig = {
  auth: {
    clientId: env('CLIENT_ID'),
    authority: authorityEnv,
    knownAuthorities: [env('KNOWN_AUTHORITIES')],
    redirectUri: env('REDIRECT_URI'),
    postLogoutRedirectUri: logout,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: [env('SCOPES'), 'openid', 'profile'],
};
