import React from 'react';
import customStyle from './WarningLabel.module.scss';

export default function WarningLabel({ icon = '' }) {
  return (
    <li className={customStyle.rowWarning}>
      {icon && <i className={`pi ${icon}`} />}
      <span>Information manquante</span>
    </li>
  );
}
