/* eslint-disable import/prefer-default-export */
import { Validators } from 'olaqin-design-system';
import { maxLengthValidator } from 'app/utils/formFieldUtils';

const stellairUserCreationValidationSchema = {
  firstName: [
    Validators.required('Veuillez renseigner le prénom de l’utilisateur.'),
    maxLengthValidator(100, 'Le prénom de l’utilisateur de peut excéder 100 caractères.'),
  ],
  lastName: [
    Validators.required('Veuillez renseigner le nom de l’utilisateur.'),
    maxLengthValidator(100, 'Le nom de l’utilisateur de peut excéder 100 caractères.'),
  ],
  email: [
    Validators.required('Veuillez renseigner l’adresse e-mail du compte.'),
    value => Validators.email(value) && 'L’adresse e-mail est invalide.',
  ],
};

export const StellairAccountFormValidations = values =>
  Validators.createValidator(stellairUserCreationValidationSchema)(values);

const stellairActivationValidationSchema = {
  debutDate: [
    Validators.required('Veuillez sélectionner la date de début d’activation du service.'),
    value => Validators.validateDate(value) && '',
  ],
};

export const StellairActivationFormValidations = values =>
  Validators.createValidator(stellairActivationValidationSchema)(values);
