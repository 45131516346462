import React from 'react';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';

const MonthlyIndexFields = () => (
  <div style={{ marginBottom: '8px' }}>
    <FormFieldInputText
      name="name"
      label="Nom de l’indice"
      labelClassName="boldLabel"
      inputType="textarea"
      autoResize
      rows={1}
    />
    <FormFieldInputText name="value" label="Valeur de l’indice" labelClassName="boldLabel" />
    <FormFieldInputText name="date" label="Mois d’application" labelClassName="boldLabel" />
  </div>
);
export default MonthlyIndexFields;
