import React from 'react';
import { Field } from 'react-final-form';
import { CardDisplay } from 'olaqin-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { findOfferByName, selectIsOfferNameSearchLoading } from 'app/redux/slices/offres';
import SearchInput from 'components/_shared/SearchInput/SearchInput';

const CardGeneral = ({ isEdit }) => {
  const dispatch = useDispatch();
  const isSearching = useSelector(selectIsOfferNameSearchLoading);

  const isOfferExist = async offerName => {
    try {
      const res = await dispatch(findOfferByName(offerName)).unwrap();
      return res;
    } catch {
      return {};
    }
  };

  const handleCustomValidation = async value => {
    if (!value) {
      return 'Veuillez renseigner une désignation';
    }
    const trimmedValue = value.trim();
    if (trimmedValue.length > 100) {
      return 'La désignation ne peut excéder 100 caractères';
    }
    const { content } = await isOfferExist(trimmedValue);
    if (content) {
      const findSameName = content.find(offer => offer.nom === trimmedValue);
      if (findSameName && findSameName?.identifiant !== isEdit) {
        return `L’offre "${trimmedValue}" existe déjà`;
      }
    }
    return '';
  };

  return (
    <CardDisplay title="Informations générales" style={{ width: '100%' }}>
      <div className="p-field p-fluid p-col-12 p-lg-5 p-p-0 p-mt-3">
        <p style={{ fontWeight: 700 }} className="p-mb-1">
          Désignation de l’offre
        </p>
        <Field
          name="generalInfo.nom"
          validate={handleCustomValidation}
          render={({ input, meta: { valid, error, touched } }) => (
            <span className="p-input-icon-right">
              <SearchInput
                isLoading={isSearching}
                className={!valid && touched && 'p-invalid p-d-block'}
                onSearch={input.onChange}
                onBlur={input.onBlur}
                defaultValue={input.value}
                style={{}}
                hideSearchIcon
              />
              {!!error && touched && (
                <small style={{ height: 0 }} className="p-error p-d-block">
                  {error}
                </small>
              )}
              {valid && touched && <i className="pi pi-check" style={{ color: '#2F7D7C' }} />}
            </span>
          )}
        />
      </div>
    </CardDisplay>
  );
};

export default CardGeneral;
