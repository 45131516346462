import React, { useRef, useState } from 'react';
import SubscriptionsNewBody from 'components/subscriptionsNew/SubscriptionsNewBody';
import { Button } from 'olaqin-design-system';
import { reset } from 'app/redux/slices/souscriptions';
import { routes } from 'app/routes/appRoutes';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import HeaderPage from 'components/_shared/HeaderPage/HeaderPage';
import { Menu } from 'primereact/menu';
import SubImportDialogForm from './dialogs/SubImportDialogForm';

const SubscriptionsNew = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useDocumentTitle(`${TITLE_PREFIX} Souscriptions`);

  const [isImportDialogVisible, handleImportDialog] = useState(false);
  const menu = useRef(null);

  const handleClickCreerSouscription = () => {
    dispatch(reset());
    history.push(routes.SUBSCRIPTION_CREATE());
  };

  const subActionsModel = [
    {
      label: 'Créer des souscriptions à partir d’un CSV',
      icon: 'pi pi-file',
      command: () => handleImportDialog(true),
    },
  ];

  return (
    <>
      <HeaderPage title="Souscriptions">
        {isImportDialogVisible && (
          <SubImportDialogForm
            visible={isImportDialogVisible}
            onHide={() => handleImportDialog(false)}
          />
        )}

        <div>
          <Menu
            style={{ width: '22.5rem' }}
            model={subActionsModel}
            popup
            ref={menu}
            id="popup_menu_client"
            appendTo={document.body}
          />
          <Button
            className="p-mr-3"
            label="Importer"
            icon="pi pi-chevron-down"
            iconPos="right"
            btnType="primary"
            onClick={event => menu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
            style={{
              height: '48px',
              padding: '9px 12px',
              background: '#FFFFFF',
              border: '1px solid rgba(106, 106, 106, 0.24)',
              color: 'black',
              fontSize: '14px',
              fontFamily: 'Arial',
            }}
          />
          <Button
            btnType="primary"
            label="Nouvelle souscription"
            icon="pi pi-plus"
            style={{ backgroundColor: '#9c67cd', borderColor: '#9c67cd' }}
            onClick={handleClickCreerSouscription}
          />
        </div>
      </HeaderPage>
      <SubscriptionsNewBody />
    </>
  );
};

export default SubscriptionsNew;
