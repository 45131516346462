import {
  chargerSouscription,
  createNewSubscription,
  modifierSouscription,
  reset,
  selectSouscription,
} from 'app/redux/slices/souscriptions';
import { CardDisplay } from 'olaqin-design-system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import { ProgressSpinner } from 'primereact/progressspinner';
import { listerOffresCommerciales, selectOffresLoading } from 'app/redux/slices/offres';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import { routes } from 'app/routes/appRoutes';
import { newTypeQuantities } from 'app/constants/quantityConstants';
import { checkForEnabledAndRequired } from 'components/souscriptions/createSubscription/formUtils';
import { thirdPartyPayerOptions } from 'app/constants/client.constants';
import customStyle from './createSubscription.module.scss';
import CardClient from './Cards/CardClient/CardClient';
import CardOffer from './Cards/CardOffer/CardOffer';
import CardParameters from './Cards/CardParameters/CardParameters';
import CreateSubscriptionFormHeader from './CreateSubscriptionFormHeader';
import createSubscriptionValidation from './createSubscriptionValidation';
import SubscriptionThirdPartyPayerFields from './Cards/CardParameters/ThirdPartPayerFields';

const CreateSubscriptionForm = ({ match: { params } }) => {
  const subscriptionOnEdit = params?.subscriptionId;
  const history = useHistory();
  const isOffersListLoading = useSelector(selectOffresLoading);
  const subscription = useSelector(selectSouscription);
  const dispatch = useDispatch();

  useDocumentTitle(
    !subscriptionOnEdit
      ? `${TITLE_PREFIX} Nouvelle souscription`
      : `${TITLE_PREFIX} Modification de la souscription`,
  );

  useEffect(() => {
    if (subscriptionOnEdit) {
      dispatch(chargerSouscription({ souscriptionId: subscriptionOnEdit }));
    }
    dispatch(listerOffresCommerciales());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const actionOnSuccessEdit = () => {
    if (subscriptionOnEdit) {
      dispatch(chargerSouscription({ souscriptionId: subscriptionOnEdit }));
    }
  };

  const handleSubmit = async values => {
    const subPrestaionsValuesCurrent = values.options.reduce((acc, curr) => {
      acc[curr.prestation.identifiant] = curr.valueQuantity;
      return acc;
    }, {});

    const subPrestaionsFromOffer = values.offer.prestations.reduce((acc, curr) => {
      acc[curr.prestation.identifiant] = curr.identifiant;
      return acc;
    }, {});

    const formattedOptions = values.options
      // send only options which is required or enabled by user
      .filter(checkForEnabledAndRequired)
      .map(opt => {
        const quantityType = opt.quantite.type;
        // check if value should be from depend
        const actualValue =
          quantityType === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION
            ? subPrestaionsValuesCurrent[opt.quantite.dependPrestation.identifiant]
            : subPrestaionsValuesCurrent[opt.prestation.identifiant];
        return {
          prestationOffreCommerciale: {
            identifiant: subPrestaionsFromOffer[opt.prestation.identifiant],
          },
          // remove value form QUANTITY_DEPENDING_ON_VOLUME
          ...(quantityType === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME
            ? {}
            : { valueQuantity: actualValue }),
        };
      });

    const deliveryAddressId = values.deliveryAddress?.identifiant;

    const formattedFormData = {
      souscripteur: {
        identifiant: values.client.identifiant,
      },
      ...(values.collaborator?.identifiant
        ? {
            collaborator: { identifiant: values.collaborator?.identifiant },
          }
        : {}),
      thirdPartyPayer: {
        identifiant: values.thirdPartyPayer?.numeroClient,
      },
      offre: {
        identifiant: values.offer.identifiant,
      },
      ...(deliveryAddressId
        ? {
            deliveryAddress: {
              identifiant: deliveryAddressId,
            },
          }
        : {}),
      options: formattedOptions,
    };

    await dispatch(
      subscriptionOnEdit
        ? modifierSouscription({ subscriptionOnEdit, data: formattedFormData })
        : createNewSubscription(formattedFormData),
    )
      .unwrap()
      .then(response => {
        const subId = subscriptionOnEdit || response.data.id;
        history.push(routes.SUBSCRIPTION_DETAILS(subId));
      });
  };

  const handleClose = () => {
    const nextPage = subscriptionOnEdit
      ? routes.SUBSCRIPTION_DETAILS(subscriptionOnEdit)
      : routes.SUBSCRIPTIONS();
    history.push(nextPage);
  };

  const isSubmitDisabled = ({ values, submitting }) =>
    isOffersListLoading || !values.offer || !values.client || submitting;

  const generateInitialValues = () => {
    if (subscriptionOnEdit) {
      // if on edit option is presented and in related prestation is "optional: true"
      // then set flag options.${index}.enabledOption to true
      const generateOptionsWithEnabled =
        subscription.offreCommerciale?.prestations.map(prestation => {
          const { options } = subscription;

          const relatedOption = options.find(
            el => el.prestation.identifiant === prestation.prestation.identifiant,
          );
          // option could be not presented when it was disabled
          if (!relatedOption) {
            // valueQuantity ?
            return {
              ...prestation,
              valueQuantity: 1, // fix needed
              ...(prestation.optional ? { enabledOption: false } : {}),
            };
          }

          return {
            ...relatedOption,
            ...prestation,
            ...(prestation.optional ? { enabledOption: true } : {}),
          };
        }) || [];

      return {
        client: subscription.souscripteur,
        offer: subscription.offreCommerciale,
        options: generateOptionsWithEnabled,
        deliveryAddress: {
          identifiant: subscription.deliveryAddress?.identifiant || '',
        },
        ...(subscription.collaborator?.identifiant
          ? {
              collaborator: { identifiant: subscription.collaborator?.identifiant },
            }
          : {}),
        thirdPartyPayer: subscription.thirdPartyPayerClient || '',
        thirdPartyPayerType:
          thirdPartyPayerOptions[subscription.thirdPartyPayer?.identifiant ? 1 : 0].value,
      };
    }

    return {
      client: null,
      offer: null,
      options: [],
      deliveryAddress: {
        identifiant: '',
      },
      thirdPartyPayer: '',
      thirdPartyPayerType: thirdPartyPayerOptions[0].value,
    };
  };

  return (
    <Portal>
      <div className={customStyle.pageContainerForm}>
        <Form
          initialValues={generateInitialValues}
          validate={createSubscriptionValidation}
          onSubmit={handleSubmit}
          render={form => (
            <form id="create-sub-form" onSubmit={form.handleSubmit}>
              <CreateSubscriptionFormHeader
                isEdit={!!subscriptionOnEdit}
                isFormDirty={form.dirty}
                isSubmitDisabled={isSubmitDisabled(form)}
                handleClose={handleClose}
              />
              <div className={customStyle.formBody}>
                <div className="p-col-12 p-p-0">
                  {isOffersListLoading ? (
                    <div className="p-d-flex p-justify-center p-align-center">
                      <ProgressSpinner />
                    </div>
                  ) : (
                    <div className="p-lg-8 p-md-12 p-lg-offset-2">
                      <CardDisplay title="Client" style={{ width: '100%' }}>
                        <CardClient actionOnSuccessEdit={actionOnSuccessEdit} />
                      </CardDisplay>
                      <CardDisplay title="Offre" style={{ width: '100%' }}>
                        <CardOffer formBatch={form.form.batch} />
                      </CardDisplay>
                      <CardDisplay title="Paiement" style={{ width: '100%' }}>
                        <SubscriptionThirdPartyPayerFields isEdit={!!subscriptionOnEdit} />
                      </CardDisplay>
                      <CardDisplay title="Paramètres" style={{ width: '100%' }}>
                        <CardParameters isEdit={!!subscriptionOnEdit} />
                      </CardDisplay>
                    </div>
                  )}
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </Portal>
  );
};

export default CreateSubscriptionForm;
