import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

const DeleteCompositionConfirmation = ({ visible, onHide, actionOnSuccessDelete }) => (
  <DialogBox
    id="deleteCompositionConfirmation"
    visible={visible}
    onHide={onHide}
    width={500}
    title="Confirmation"
    onCancel={onHide}
    cancelLabel="Annuler"
    onConfirm={actionOnSuccessDelete}
    confirmLabel="Confirmer la suppression"
    isConfirmButtonDangerColor
    noSubmitTypeOnButton
    noTopSeparator
    dismissableMask
  >
    <div style={{ marginBottom: 8 }}>Souhaitez-vous réellement supprimer la prestation ?</div>
  </DialogBox>
);

export default DeleteCompositionConfirmation;
