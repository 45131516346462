/**
 * Combine address info into label
 * @param adresse
 * @param complement
 * @param postalCode
 * @param regionalCode
 * @param ville
 * @param pays
 * @return {string}
 */
// eslint-disable-next-line import/prefer-default-export
export const combineAddressLabel = ({
  name = '',
  adresse = '',
  complement = '',
  codePostal = '',
  codeRegion = '',
  ville = '',
  pays = '',
}) => {
  const addressName = name || '';
  const mainAddress = adresse || '';
  const optionalLabel = complement || '';
  return `${addressName} ${mainAddress} ${optionalLabel} ${codePostal} ${codeRegion} ${ville} ${pays}`;
};

/**
 * convertStringToArray
 * @param string {string}
 * @return {label: string, value: string}[]
 */
export const convertStringToArray = string => {
  const values = string.split(',').map(item => item.trim());
  const resultArray = values.map(item => ({
    label: item,
    value: item,
  }));
  return resultArray;
};

export const trimAll = str => str.replace(/ /g, '');
