// eslint-disable-next-line import/prefer-default-export
export const routes = {
  DASHBOARD: () => '/',
  CLIENTS: () => '/LesClients',
  CLIENTS_DETAILS: id => `/LesClients/${id || ':clientId'}`,

  SUBSCRIPTIONS: () => '/Souscriptions',
  SUBSCRIPTION_CREATE: () => '/Souscriptions/NouvelleSouscription',
  SUBSCRIPTION_EDIT: id => `/Souscriptions/${id || ':subscriptionId'}/ModifierSouscription`,
  SUBSCRIPTION_DETAILS: id => `/Souscriptions/${id || ':subscriptionId'}`,

  INDEXES: () => '/Indexes',

  BILLING_ORDER_DETAILS: id => `/BonDeFacturations/${id || ':billingId'}`,

  REPORTS_PRESTATIONS: () => '/LesRapports/PrestationsSouscrites',
  REPORTS_PRESTATIONS_FSE: () => '/LesRapports/ConsommationFSE',
  REPORTS_EXPORT: () => '/LesRapports/Export',
  REPORTS_STELLAIR_USAGE: () => '/LesRapports/UsageStellair',

  PRESTATIONS: () => '/LesPrestations',

  OFFERS: () => '/Offres',
  OFFER_CREATE: () => '/Offres/NouvelleOffre',
  OFFER_EDIT: id => `/Offres/EditOffer/${id || ':offerId'}`,
  OFFER_EDIT_PARAMETERS: id => `/Offres/EditParameters/${id || ':offerId'}`,
  OFFER_DETAILS: id => `/Offres/details/${id || ':offerId'}`,
};
