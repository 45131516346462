import { useRef, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useDocumentTitle = (title = 'Niqola', prevailOnUnmount = false) => {
  const defaultTitle = useRef('Niqola');

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
};
