import React, { useEffect } from 'react';
import { Portal } from 'react-portal';
import { useDocumentTitle } from 'app/hooks';
import {
  selectIsOfferDetailsLoading,
  getOfferDetails,
  selectOfferDetailsData,
  getOfferUsageTypes,
} from 'app/redux/slices/offres';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressSpinner } from 'olaqin-design-system';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import customStyle from '../../souscriptions/createSubscription/createSubscription.module.scss';
import OfferParametersFields from './OfferParametersFields';
import OfferParametersEditHeader from './OfferParametersEditHeader';

const OfferParametersEdit = ({ match: { params } }) => {
  const { offerId } = params;
  const isOfferDataLoading = useSelector(selectIsOfferDetailsLoading);
  const offerData = useSelector(selectOfferDetailsData);
  const dispatch = useDispatch();
  useDocumentTitle(`Paramètres de facturation de l’offre ${offerData.nom}`);

  useEffect(() => {
    dispatch(getOfferUsageTypes());
  }, []);

  useEffect(() => {
    dispatch(getOfferDetails({ offreCommercialeId: offerId }));
  }, [offerId]);

  if (isOfferDataLoading) {
    return <ProgressSpinner />;
  }

  return (
    <Portal>
      <div className={customStyle.pageContainerForm}>
        <Form
          initialValues={offerData}
          mutators={{ ...arrayMutators }}
          onSubmit={() => {}}
          render={() => (
            <form id="edit-offer-params-form">
              <OfferParametersEditHeader offerId={offerId} offerName={offerData.nom} />
              <OfferParametersFields />
            </form>
          )}
        />
      </div>
    </Portal>
  );
};

export default OfferParametersEdit;
