import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  getSubscriptionNewListPaginated,
  selectIsSouscriptionLoading,
  selectNewListSubscriptions,
} from 'app/redux/slices/souscriptions';
import { useHistory } from 'react-router-dom';
import { routes } from 'app/routes/appRoutes';
import usePagination from 'app/hooks/usePagination';
import { TabPanel, TabView } from 'primereact/tabview';
import {
  TabsData,
  DataTableColumnsPerTab,
} from 'components/subscriptionsNew/TabsAndColumns.costants';
import RegisterGridFiltering, {
  GridFilterTypes,
} from 'components/_shared/GridDynamicFilters/RegisterGridFiltering';
import moment from 'moment';
import SubscriptionsHeaderTable from './SubscriptionsHeaderTable';
import SubscriptionGridSorting, { getTabDefaultSortingModel } from './SubscriptionGridSorting';

import './SubscriptionsNew.scss';

const valueGetter = (terms, field) =>
  field.includes('-')
    ? [terms[field.split('-')[0]], terms[field.split('-')[1]]]
    : terms[field] ?? '';

const SubscriptionsNewBody = () => {
  const history = useHistory();
  const subscriptionsNewData = useSelector(selectNewListSubscriptions);
  const isLoading = useSelector(selectIsSouscriptionLoading);
  const [isFilteringPanelDisplayed, setFilteringPanelState] = useState(true);
  const { onTablePageChange, onTermsChange, terms } = usePagination({
    dataFetchBy: getSubscriptionNewListPaginated,
    debounced: true,
  });
  const [currentActiveTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (terms.status) {
      const activeTabOnLoadIndex = TabsData.findIndex(tab => tab.status === terms.status);
      setActiveTab(activeTabOnLoadIndex);
    }
  }, []);

  const handleClickRow = ({ data }) => {
    history.push(routes.SUBSCRIPTION_DETAILS(data.identifiant));
  };

  const handleTabChange = ({ index }) => {
    setActiveTab(index);
    const currentTabDefaultSortingModel = getTabDefaultSortingModel(
      TabsData[index].status ?? 'Toutes',
    );
    if (currentTabDefaultSortingModel) {
      onTermsChange(
        'status-sortField',
        [TabsData[index].status, currentTabDefaultSortingModel],
        true,
        true,
      );
    } else {
      onTermsChange('status', TabsData[index].status, true, true);
    }
  };

  const onFilterChange = (evt, colName, colType) => {
    const value = evt ? evt.target?.value ?? evt : '';
    if (colType === GridFilterTypes.periodDate) {
      const startDate = value && value[0] ? moment(value[0]).format('YYYY-MM-DD') : null;
      const endDate = value && value[1] ? moment(value[1]).format('YYYY-MM-DD') : null;
      onTermsChange(colName, [startDate, endDate]);
      return;
    }
    onTermsChange(colName, value);
  };

  const onSortChange = fieldName => {
    onTermsChange('sortField', fieldName, false);
  };

  const CurrentTabServerLabel = TabsData[currentActiveTab].status ?? 'Toutes';

  const SortingEl = useCallback(
    () => (
      <SubscriptionGridSorting
        currentTab={CurrentTabServerLabel}
        onSortChange={onSortChange}
        currentValue={terms.sortField}
      />
    ),
    [CurrentTabServerLabel, onSortChange, terms.sortField],
  );

  const isClearButtonVisible = useMemo(() => {
    const { page, size, status, sortField, ...restTerms } = terms;
    return Object.keys(restTerms).length > 0;
  }, [terms]);

  const onClearFilters = () => {
    onTermsChange('status', terms.status, false, true);
  };

  return (
    <>
      <TabView
        id="subscriptionBilling"
        activeIndex={currentActiveTab}
        onTabChange={handleTabChange}
      >
        {TabsData.map(tab => (
          <TabPanel key={tab.label} header={tab.label} />
        ))}
      </TabView>
      <DataTable
        id="subscriptions-table"
        header={
          <SubscriptionsHeaderTable
            SortingComponent={SortingEl}
            isFilteringPanelDisplayed={isFilteringPanelDisplayed}
            setFilteringPanelState={setFilteringPanelState}
            isClearButtonVisible={isClearButtonVisible}
            onClearFilters={onClearFilters}
          />
        }
        value={subscriptionsNewData.content || []}
        loading={isLoading}
        paginator
        onPage={onTablePageChange}
        first={subscriptionsNewData.pageable?.offset || 0}
        numberOfElements={subscriptionsNewData.numberOfElements || 0}
        totalRecords={subscriptionsNewData.totalElements || 0}
        rows={subscriptionsNewData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="No souscriptions"
        responsive={true}
        selectionMode="single"
        onRowSelect={handleClickRow}
        rowHover
        autoLayout
      >
        {DataTableColumnsPerTab[currentActiveTab].columns.map(columnData => (
          <Column
            key={columnData.key}
            field={columnData.field}
            header={columnData.header}
            body={columnData.body}
            sortable={false}
            headerStyle={columnData.headerStyle}
            bodyStyle={columnData.bodyStyle}
            {...RegisterGridFiltering(isFilteringPanelDisplayed, columnData.filterType, {
              value: valueGetter(terms, columnData.field),
              onChange: evt => onFilterChange(evt, columnData.field, columnData.filterType),
              placeholder: columnData.filterPlaceHolder,
              style: columnData.filterHeaderStyle ?? {},
            })}
          />
        ))}
      </DataTable>
    </>
  );
};

export default SubscriptionsNewBody;
