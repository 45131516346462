import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import DeliveryAddressDialogWrapper from './Dialog/DeliveryAddressDialogWrapper';
import DeliveryAddressDataTable from './DeliveryAddressDataTable/DeliveryAddressDataTable';

const DeliveryAddressTab = ({ clientId, sageBase }) => (
  <div className="p-grid">
    <div className="p-col-12">
      <CardDisplay title="Adresses de livraison" style={{ width: 'auto' }}>
        <DeliveryAddressDataTable clientId={clientId} sageBase={sageBase} />
        <DeliveryAddressDialogWrapper clientId={clientId} sageBase={sageBase} />
      </CardDisplay>
    </div>
  </div>
);

export default DeliveryAddressTab;
