import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { toast } from 'react-toastify';
import { throwError } from 'app/utils/functions';

const managerName = 'utilisateurs';

const initialState = {
  listeClients: [],
  listeCategoriesComptables: [],
  listeCategoriesComptablesHardware: [],
  listeCategoriesComptablesBillingSystem: [],
  listeComptesCollectifs: [],
  listeComptesCollectifsHardware: [],
  listeComptesCollectifsBillingSystem: [],
  paymentTermsList: [],
  loading: false,
  error: null,
  success: null,
  souches: {
    isLoading: false,
    data: [],
  },
};

/**
 * ASYNC TREATEMENT
 * */

export const listerCategoriesComptables = createAsyncThunk(
  `${managerName}/listerCategoriesComptables`,
  async ({ fromSage, sageBase = 'SERVICE' }) =>
    API.CategoriesComptables.listerCategorieComptables(fromSage, sageBase),
);

export const listerComptesCollectifs = createAsyncThunk(
  `${managerName}/listerComptesCollectifs`,
  async ({ fromSage, sageBase = 'SERVICE' }) =>
    API.ComptesCollectifs.listerComptesCollectifs(fromSage, sageBase),
);

export const listerCategoriesComptablesHardware = createAsyncThunk(
  `${managerName}/listerCategoriesComptablesHardware`,
  async ({ fromSage, sageBase = 'HARDWARE' }) =>
    API.CategoriesComptables.listerCategorieComptables(fromSage, sageBase),
);

export const listerComptesCollectifsHardware = createAsyncThunk(
  `${managerName}/listerComptesCollectifsHardware`,
  async ({ fromSage, sageBase = 'HARDWARE' }) =>
    API.ComptesCollectifs.listerComptesCollectifs(fromSage, sageBase),
);

export const listerCategoriesComptablesBillingSystem = createAsyncThunk(
  `${managerName}/listerCategoriesComptablesBillingSystem`,
  async ({ fromSage, sageBase = 'SERVICE' }) =>
    API.CategoriesComptables.listerCategorieComptables(fromSage, sageBase),
);

export const listerComptesCollectifsBillingSystem = createAsyncThunk(
  `${managerName}/listerComptesCollectifsBillingSystem`,
  async ({ fromSage, sageBase = 'SERVICE' }) => {
    const { data } = await API.ComptesCollectifs.listerComptesCollectifs(fromSage, sageBase);
    return data;
  },
);

export const getPaymentTerms = createAsyncThunk(`${managerName}/getPaymentTerms`, async () => {
  try {
    const { data } = await API.PaymentTerm.paymentTermTemplates();

    return data;
  } catch (error) {
    return throwError(error);
  }
});

export const getSouchesList = createAsyncThunk(`${managerName}/getSouchesList`, async sageBase => {
  try {
    const { data } = await API.Souches.listerSouches(sageBase);

    return data;
  } catch (error) {
    return throwError(error);
  }
});

/**
 * SLICE
 * */
const clientsSlice = createSlice({
  name: managerName,
  initialState,
  reducers: {
    setCategoriesComptables: (state, action) => {
      state.listeCategoriesComptables = action.payload;
    },
    setComptesCollectifs: (state, action) => {
      state.listeComptesCollectifs = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(listerCategoriesComptables.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerCategoriesComptables.fulfilled, (state, action) => {
      state.listeCategoriesComptables = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerCategoriesComptables.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
    });
    builder.addCase(listerComptesCollectifs.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerComptesCollectifs.fulfilled, (state, action) => {
      state.listeComptesCollectifs = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerComptesCollectifs.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
    });

    builder.addCase(listerCategoriesComptablesHardware.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerCategoriesComptablesHardware.fulfilled, (state, action) => {
      state.listeCategoriesComptablesHardware = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerCategoriesComptablesHardware.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
    });
    builder.addCase(listerComptesCollectifsHardware.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerComptesCollectifsHardware.fulfilled, (state, action) => {
      state.listeComptesCollectifsHardware = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerComptesCollectifsHardware.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
    });

    builder.addCase(listerCategoriesComptablesBillingSystem.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerCategoriesComptablesBillingSystem.fulfilled, (state, action) => {
      state.listeCategoriesComptablesBillingSystem = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerCategoriesComptablesBillingSystem.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
    });
    builder.addCase(listerComptesCollectifsBillingSystem.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerComptesCollectifsBillingSystem.fulfilled, (state, action) => {
      state.listeComptesCollectifsBillingSystem = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerComptesCollectifsBillingSystem.rejected, (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
    });

    builder.addCase(getPaymentTerms.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentTerms.fulfilled, (state, action) => {
      state.paymentTermsList = action.payload;
      state.loading = false;
    });
    builder.addCase(getPaymentTerms.rejected, state => {
      state.loading = false;
    });

    // souches

    builder.addCase(getSouchesList.pending, state => {
      state.souches.isLoading = true;
    });
    builder.addCase(getSouchesList.fulfilled, (state, action) => {
      state.souches.isLoading = false;
      state.souches.data = action.payload;
    });
    builder.addCase(getSouchesList.rejected, state => {
      state.souches.isLoading = false;
    });
  },
});

/**
 * SELECTORS
 * */
const managerStateSelector = state => state[managerName];

export const selectListeCategoriesComptables = createSelector(
  managerStateSelector,
  clients => clients.listeCategoriesComptables,
);
export const selectListeComptesCollectifs = createSelector(
  managerStateSelector,
  clients => clients.listeComptesCollectifs,
);

export const selectListeCategoriesComptablesHardware = createSelector(
  managerStateSelector,
  clients => clients.listeCategoriesComptablesHardware,
);
export const selectListeComptesCollectifsHardware = createSelector(
  managerStateSelector,
  clients => clients.listeComptesCollectifsHardware,
);

export const selectListeCategoriesComptablesBillingSystem = createSelector(
  managerStateSelector,
  clients => clients.listeCategoriesComptablesBillingSystem,
);
export const selectListeComptesCollectifsBillingSystem = createSelector(
  managerStateSelector,
  clients => clients.listeComptesCollectifsBillingSystem,
);

export const selectLoading = createSelector(managerStateSelector, clients => clients.loading);

export const selectPaymentTermsList = createSelector(
  managerStateSelector,
  clients => clients.paymentTermsList,
);

export const selectIsSouchesListLoading = createSelector(
  managerStateSelector,
  clients => clients.souches.isLoading,
);

export const selectSouchesList = createSelector(
  managerStateSelector,
  clients => clients.souches.data,
);

/**
 * EXPORTS
 * */
export default clientsSlice.reducer;

export const { actions } = clientsSlice;
export const { setCategoriesComptables, setComptesCollectifs } = clientsSlice.actions;
