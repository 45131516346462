import React from 'react';
import { InputText as PrimeInputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import StatusSubscriptionDropDown from 'components/subscriptionsNew/statusSubscription/StatusSubscriptionDropDown';
import OffersFilterDrop from 'components/offers/OffersFilterDrop';
import moment from 'moment';

export const GridFilterTypes = {
  plain: 'plain',
  date: 'date',
  dateTime: 'dateTime',
  periodDate: 'periodDate',
  offersList: 'offersList',
  subscriptionStatus: 'subscriptionStatus',
};

const getPeriodCalendarValue = p =>
  p.value?.[0]
    ? [
        p.value[0] ? moment(p.value[0]).toDate() : null,
        p.value[1] ? moment(p.value[1]).toDate() : null,
      ]
    : undefined;

const GridFilterElements = {
  [GridFilterTypes.plain || undefined]: p => <PrimeInputText type="text" {...p} />,
  [GridFilterTypes.offersList]: p => <OffersFilterDrop {...p} />,
  [GridFilterTypes.date]: p => <Calendar {...p} appendTo={document.body} />,
  [GridFilterTypes.periodDate]: p => (
    <Calendar
      {...p}
      monthNavigator
      yearNavigator
      yearRange="2010:2040"
      selectionMode="range"
      appendTo={document.body}
      value={getPeriodCalendarValue(p)}
    />
  ),
  [GridFilterTypes.dateTime]: p => <Calendar {...p} showTime={true} appendTo={document.body} />,
  [GridFilterTypes.subscriptionStatus]: p => <StatusSubscriptionDropDown {...p} />,
};

const RegisterGridFiltering = (filtering, colType, elementProps) => ({
  filter: filtering,
  filterElement: GridFilterElements[colType](elementProps),
});

export default RegisterGridFiltering;
