import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateAnOffer, selectIsOfferValidating } from 'app/redux/slices/offres';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';

const ValidateOfferDialog = ({ visible, onHide, offreCommercialeId, actionOnSuccessValidate }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsOfferValidating);

  const handleValidateOffer = async () => {
    await dispatch(validateAnOffer({ offreCommercialeId }))
      .unwrap()
      .then(() => {
        onHide();
        toast('L’offre a bien été validée.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });

        actionOnSuccessValidate();
      })
      .catch(() => {});
  };

  return (
    <DialogBox
      id="validateOfferDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title="Validation de l’offre"
      isLoading={isLoading}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={handleValidateOffer}
      confirmLabel="Confirmer la validation"
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 16 }}>
        Après validation, l’offre pourra être publiée et disponible à la souscription.
        Confirmez-vous la validation de l’offre ?
      </div>
    </DialogBox>
  );
};

export default ValidateOfferDialog;
