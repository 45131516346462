import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryAddressesAll, selectClientDetailsDataClient } from 'app/redux/slices/teams';
import CreateNewButton from 'components/_shared/CreateNewButton/CreateNewButton';
import DeliveryAddressDialogForm from './DeliveryAddressDialogForm';

const DeliveryAddressDialogWrapper = ({ clientId, sageBase }) => {
  const dispatch = useDispatch();
  const clientData = useSelector(selectClientDetailsDataClient);

  const [isDeliveryAddressDialogVisible, handleShowDialog] = useState(false);

  const actionOnSuccessCreate = () => {
    dispatch(getDeliveryAddressesAll({ id: clientId }));
  };

  return (
    <>
      {isDeliveryAddressDialogVisible && (
        <DeliveryAddressDialogForm
          visible={isDeliveryAddressDialogVisible}
          onHide={() => handleShowDialog(false)}
          actionOnSuccessCreate={actionOnSuccessCreate}
          clientId={clientId}
          clientData={clientData}
          sageBase={sageBase}
        />
      )}
      <CreateNewButton
        label="Ajouter une adresse de livraison"
        onClick={() => handleShowDialog(true)}
      />
    </>
  );
};

export default DeliveryAddressDialogWrapper;
