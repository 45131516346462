import { Validators } from 'olaqin-design-system';

export const matchExactlyPattern = (value, pattern) => {
  const found = value.match(pattern);
  return found && found[0] === value;
};

const IBAN_MATCH_FR = /^FR\d{2}\d{5}\d{5}[A-Z0-9]{11}\d{2}$/;
const BIC_MATCH = /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/;

// eslint-disable-next-line consistent-return
export const validateIban = isOptional => (value = '') => {
  if (!value && isOptional) {
    return '';
  }
  if (!value) {
    return 'Veuillez renseigner un IBAN';
  }
  if (!matchExactlyPattern(value, IBAN_MATCH_FR)) {
    return 'L’IBAN est invalide';
  }
  // checksum for FR MOD 97-10
  const rearrangedIBAN = value.substring(4) + value.substring(0, 4);
  const numericIBAN = rearrangedIBAN.replace(/[A-Z]/g, char => {
    const charCode = char.charCodeAt(0);
    return charCode >= 65 && charCode <= 90 ? (charCode - 55).toString() : char;
  });
  let checksum = parseInt(numericIBAN.substring(0, 2), 10);
  // eslint-disable-next-line no-plusplus
  for (let i = 2; i < numericIBAN.length; i++) {
    checksum = (checksum * 10 + parseInt(numericIBAN[i], 10)) % 97;
  }
  if (checksum !== 1) {
    return 'L’IBAN est invalide';
  }
};

// eslint-disable-next-line consistent-return
export const validateBic = isOptional => (value = '') => {
  if (!value && isOptional) {
    return '';
  }
  if (!value) {
    return 'Veuillez renseigner un code BIC.';
  }
  if (!matchExactlyPattern(value, BIC_MATCH)) {
    return 'Le code BIC est invalide';
  }
};

export const validateMultipleEmailsString = value => {
  if (value?.includes(';')) {
    const emailsList = value.split(';');
    const isThereAnyInvalidEmails = emailsList.some(item => Validators.email(item));
    return (
      isThereAnyInvalidEmails && 'L’e-mail de partage des factures dématérialisées est invalide.'
    );
  }
  return (
    Validators.email(value) && 'L’e-mail de partage des factures dématérialisées est invalide.'
  );
};

export const checkIfEmailIsInvalidInTheList = (value, target) => {
  const emailList = value.split(';');
  return emailList.filter(i => Validators.email(i)).includes(target);
};
