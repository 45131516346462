import React, { useState } from 'react';
import { CardDisplay, Column } from 'olaqin-design-system';
import { DataTable } from 'primereact/datatable';
import { formatDate } from 'app/utils/dateUtils';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import AddStellairAccountDialog from 'components/souscriptions/subscriptionDetails/dialogs/AddStellairAccountDialog';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { useDispatch } from 'react-redux';
import { getClientDetails } from 'app/redux/slices/teams';
import ConnectionsTableCard from './ConnectionsTableCard';

const ConnectionsTabs = ({ clientDetails }) => {
  const dispatch = useDispatch();
  const [isStellairModalVisible, toggleStellairModal] = useState(false);

  const bodyDate = (row, dateField) => dashOnEmpty(formatDate(row[dateField], 'DD/MM/YYYY'));

  const refetchClientData = () => dispatch(getClientDetails({ id: clientDetails.identifiant }));

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <ConnectionsTableCard clientDetails={clientDetails} refetchData={refetchClientData} />
        <CardDisplay
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src="/icons/StellairLogo-32.svg"
                alt="Stellair"
                style={{ marginRight: 4, width: 24, height: 24 }}
              />
              Stellair
            </div>
          }
          style={{ width: 'auto' }}
        >
          <DataTable
            id="client_stellair_connection"
            className="StellairClientGrid p-mt-2"
            value={clientDetails.stellairAccount ? [clientDetails.stellairAccount] : []}
            emptyMessage="Aucune organisation Stellair n’est associée au client"
            responsive
          >
            <Column field="email" header="E-mail" headerStyle={{ width: '20%', fontSize: 14 }} />
            <Column
              field="firstName"
              header="Prénom"
              headerStyle={{ width: '20%', fontSize: 14 }}
            />
            <Column field="lastName" header="Nom" headerStyle={{ width: '20%', fontSize: 14 }} />
            <Column
              field="id"
              header="Identifiant organisation Stellair"
              headerStyle={{ width: '20%', fontSize: 14 }}
              style={{ color: '#6A6A6A' }}
            />
            <Column
              field="creationDate"
              header="Créé le"
              headerStyle={{ width: '20%', fontSize: 14 }}
              body={data => bodyDate(data, 'creationDate')}
            />
          </DataTable>
          <AddStellairAccountDialog
            isVisible={isStellairModalVisible}
            hideDialog={() => toggleStellairModal(false)}
            onSuccess={refetchClientData}
            clientData={clientDetails}
          />
          {!clientDetails.stellairAccount && (
            <SubmitButton
              label="Créer le compte utilisateur du client"
              btnType="secondary"
              type="button"
              outlined
              onClick={() => toggleStellairModal(true)}
              small
              className="p-mt-2"
              style={{
                background: '#FFFFFF',
                color: 'black',
                width: 'max-content',
              }}
            />
          )}
        </CardDisplay>
      </div>
    </div>
  );
};

export default ConnectionsTabs;
