import React, { useRef } from 'react';
import { Field } from 'react-final-form';
import { Chips as PrimeChipsInput } from 'primereact/chips';

import { trimAll } from 'app/utils/formatUtils';
import FormFieldWrapper from './FormFieldWrapper';

const FormFieldChipsInput = ({
  name,
  label,
  noWrapper = false,
  showValidationIcon = false,
  colSpan = { col: 9, md: 12 },
  validate,
  defaultSeparator = ';',
  customInputTemplate,
  ...otherProps
}) => {
  const inputRef = useRef(null);

  const castStringToArray = stringInput => stringInput.split(defaultSeparator);

  const valueFormatter = array => {
    let tempArray = array.join(defaultSeparator);
    tempArray = castStringToArray(trimAll(tempArray));
    return tempArray
      .filter((item, i, ar) => ar.indexOf(item) === i && item !== defaultSeparator && item !== '')
      .join(defaultSeparator);
  };

  return (
    <FormFieldWrapper noWrapper={noWrapper} colSpan={colSpan} className="autoHeight">
      <Field
        name={name}
        validate={validate}
        render={({ input, meta: { valid, error, touched } }) => {
          const itemTemplate = customInputTemplate
            ? { itemTemplate: item => customInputTemplate(input.value, item) }
            : undefined;
          const onBlur = e => {
            input.onBlur(e);
            // filling the input if the user forgot to press the enter button
            if (e.target.value.length > 0) {
              const currentValues = castStringToArray(input.value);
              currentValues.push(e.target.value);
              input.onChange(valueFormatter(currentValues));
            }
            if (inputRef?.current) inputRef.current.inputElement.value = '';
          };
          return (
            <span className="p-input-icon-right">
              {label && (
                <label htmlFor={`${name}-id`} className="label">
                  {label}
                </label>
              )}
              <PrimeChipsInput
                ref={inputRef}
                id={`${name}-id`}
                name={name}
                className={!valid && touched ? 'p-invalid p-d-block' : ''}
                value={input.value ? castStringToArray(input.value) : []}
                onChange={e => input.onChange(valueFormatter(e.target.value))}
                onBlur={onBlur}
                allowDuplicate={false}
                separator={defaultSeparator}
                {...itemTemplate}
                {...otherProps}
              />
              {!!error && touched && <small className="p-error p-d-block">{error}</small>}
              {showValidationIcon && valid && touched && (
                <i className={`pi pi-check ${label ? 'p-mt-1' : ''}`} />
              )}
            </span>
          );
        }}
      />
    </FormFieldWrapper>
  );
};

export default FormFieldChipsInput;
