import React from 'react';
import { useDispatch } from 'react-redux';
import { InputText } from 'olaqin-design-system';
import { Field, Form } from 'react-final-form';
import {
  createPrestation,
  getListPrestations,
  getPrestationsNewListPaginated,
  updatePrestation,
} from 'app/redux/slices/prestations';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { formPrestationCreateValidation } from './formPrestationCreateValidation';

const PrestationCreateDialog = ({
  visible,
  onHide,
  prestationsNewList,
  initialValues = null,
  prestationId,
}) => {
  const dispatch = useDispatch();

  const handleClickConfirm = async formData => {
    await dispatch(
      !prestationId ? createPrestation(formData) : updatePrestation({ prestationId, formData }),
    )
      .unwrap()
      .then(() => {
        dispatch(getPrestationsNewListPaginated({ page: 0, size: 15 }));
        dispatch(getListPrestations());
        onHide();
      });
  };

  const handleCustomValidation = values => {
    const findSameName = prestationsNewList.find(
      prestation => prestation.designation.toLowerCase() === values.designation?.toLowerCase(),
    );
    if (findSameName) {
      return { designation: `La prestation ${findSameName.designation} existe déjà` };
    }

    return formPrestationCreateValidation(values);
  };

  const initialValuesForm = initialValues || { designation: '' };

  return (
    <Form
      initialValues={initialValuesForm}
      validate={handleCustomValidation}
      onSubmit={handleClickConfirm}
      render={({ handleSubmit, submitting, valid }) => (
        <DialogBox
          id="createNewPrestation"
          spacingField="14px"
          spacingLabel="0"
          visible={visible}
          onHide={onHide}
          className="p-col-12 p-sm-12 p-md-12 p-lg-8"
          style={{ maxWidth: '600px' }}
          noSeparator
          title={!prestationId ? 'Création d’une nouvelle prestation' : 'Modifer la prestation'}
          confirmLabel={!prestationId ? 'Créer la prestation' : 'Modifer la prestation'}
          onConfirm={handleSubmit}
          cancelLabel="Annuler"
          onCancel={onHide}
          isConfirmActive={!submitting}
        >
          <div className="p-formgrid p-grid p-align-stretch">
            <div className="p-col-12 p-md-12 p-p-0">
              <div className="p-field p-col-9 p-md-12">
                <span className="p-input-icon-right">
                  {valid && (
                    <i className="pi pi-check" style={{ color: '#2F7D7C', marginTop: '0' }} />
                  )}
                  <Field
                    autoComplete="off"
                    id="designation"
                    label="Désignation de la prestation"
                    name="designation"
                    component={InputText.Field}
                    width="100%"
                  />
                </span>
              </div>
            </div>
          </div>
        </DialogBox>
      )}
    />
  );
};

export default PrestationCreateDialog;
