import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CardDisplay } from 'olaqin-design-system';
import { dashOnEmpty } from 'app/utils/formFieldUtils';

const Identification = ({ clientDetails }) => {
  const { nom, numeroClient, identifiant, email, accountingRef } = clientDetails;

  return (
    <CardDisplay title="Identification" style={{ width: 'auto' }}>
      <div className="item">
        <span>Intitulé de la société</span>
        <span>{dashOnEmpty(nom)}</span>
      </div>
      <div className="item">
        <span>Numéro client</span>
        <span>{dashOnEmpty(numeroClient)}</span>
        {numeroClient && (
          <span>
            <CopyToClipboard
              text={numeroClient}
              style={{ margin: '0 0 0 8px', color: '#101010', cursor: 'pointer' }}
            >
              <i className="pi pi-copy" />
            </CopyToClipboard>
          </span>
        )}
      </div>
      <div className="item">
        <span>Identifiant technique</span>
        <span>{dashOnEmpty(identifiant)}</span>
        {identifiant && (
          <span>
            <CopyToClipboard
              text={identifiant}
              style={{ margin: '0 0 0 8px', color: '#101010', cursor: 'pointer' }}
            >
              <i className="pi pi-copy" />
            </CopyToClipboard>
          </span>
        )}
      </div>
      <div className="item">
        <span>E-mail principal</span>
        <span>{dashOnEmpty(email)}</span>
      </div>
      <div className="item">
        <span>Compte collectif</span>
        <span>
          {dashOnEmpty(accountingRef?.compteCollectifLabel || accountingRef?.compteCollectif)}
        </span>
      </div>
    </CardDisplay>
  );
};

export default Identification;
