import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardDisplay } from 'olaqin-design-system';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import { selectPaymentTermsList, getPaymentTerms } from 'app/redux/slices/utilisateurs';
import { getClientByNumero, selectClientByNumero } from 'app/redux/slices/teams';

const PaymentInfo = ({ clientDetails }) => {
  const { paymentTermTemplateId, bankDetails, thirdPartyPayer } = clientDetails;
  const dispatch = useDispatch();
  const paymentTermsList = useSelector(selectPaymentTermsList);
  const { client: tppInfo } = useSelector(selectClientByNumero);

  const findPaymentTermsList = paymentTermsList?.find(
    ({ identifiant }) => identifiant === paymentTermTemplateId,
  );

  useEffect(() => {
    dispatch(getPaymentTerms());
  }, []);

  useEffect(() => {
    dispatch(getClientByNumero(thirdPartyPayer));
  }, [thirdPartyPayer]);

  return (
    <CardDisplay title="Informations de paiement" style={{ width: 'auto' }}>
      <div className="item">
        <span>Tiers payeur</span>
        {/* related to third Party Payer thicket which is not ready yet */}
        {thirdPartyPayer ? (
          <span>
            {tppInfo?.numeroClient} - {tppInfo?.nom}
          </span>
        ) : (
          <span>Ce client est le tiers payeur</span>
        )}
      </div>
      <div className="item">
        <span>Conditions de paiement</span>
        <span>{dashOnEmpty(findPaymentTermsList?.label || paymentTermTemplateId)}</span>
      </div>
      <div className="item">
        <span>Coordonnés bancaires</span>
        <span>
          <div className="detail">{dashOnEmpty(bankDetails?.bankName)}</div>
          <div className="detail">{dashOnEmpty(bankDetails?.iban)}</div>
          <div className="detail">{dashOnEmpty(bankDetails?.bic)}</div>
        </span>
      </div>
    </CardDisplay>
  );
};

export default PaymentInfo;
