import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import {
  selectDeliveryAddressIsLoading,
  selectUpdateDeliveryAddressIsLoading,
  selectCreateDeliveryAddressIsLoading,
  createNewDeliveryAddress,
  updateDeliveryAddress,
  selectDeliveryAddress,
} from 'app/redux/slices/teams';
import DeliveryAddressFormField from './DeliveryAddressFormField';
import { formDeliveryAddressValidation } from './formDeliveryAddressValidation';

const DeliveryAddressDialogForm = ({
  clientId,
  clientData = {},
  sageBase,
  actionOnSuccessCreate,
  actionOnSuccessEdit,
  visible,
  onHide,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const currentFormData = useSelector(selectDeliveryAddress);
  const isLoading = useSelector(selectDeliveryAddressIsLoading);
  const createIsLoading = useSelector(selectCreateDeliveryAddressIsLoading);
  const updateIsLoading = useSelector(selectUpdateDeliveryAddressIsLoading);
  const { nom, adresseFacturation } = clientData;

  const formInitialData = useMemo(
    () => ({
      name: nom ?? '',
      adresse: adresseFacturation?.adresse ?? '',
      complement: adresseFacturation?.complement ?? '',
      code_postal: adresseFacturation?.codePostal ?? '',
      ville: adresseFacturation?.ville ?? '',
      pays: adresseFacturation?.pays ?? '',
      expedition: '',
      condition: '',
    }),
    [],
  );

  const handleClickConfirm = async formData => {
    if (isEdit) {
      await dispatch(
        updateDeliveryAddress({ formData, clientId, deliveryAddressId: formData.identifiant }),
      )
        .unwrap()
        .then(() => {
          onHide();
          actionOnSuccessEdit();
        });
    } else {
      await dispatch(createNewDeliveryAddress({ formData, id: clientId }))
        .unwrap()
        .then(() => {
          onHide();
          actionOnSuccessCreate();
        });
    }
  };

  return (
    <Portal>
      <Form
        initialValues={isEdit ? currentFormData || {} : formInitialData}
        validate={formDeliveryAddressValidation}
        onSubmit={handleClickConfirm}
        render={({ handleSubmit }) => (
          <form
            className="p-fluid p-formgrid p-grid"
            id={'client-create-dialog'}
            onSubmit={handleSubmit}
          >
            <DialogBox
              id="dialogDeliveryAddress"
              spacingField="8px"
              spacingLabel="0"
              visible={visible}
              onHide={onHide}
              isLoading={isLoading || updateIsLoading || createIsLoading}
              title={
                isEdit
                  ? 'Modification de l’adresse de livraison'
                  : 'Ajout d’une nouvelle adresse de livraison'
              }
              style={{
                maxWidth: '600px',
                overflow: 'auto',
              }}
              className="p-col-12 p-sm-12 p-md-12 p-lg-8"
              confirmLabel={isEdit ? 'Enregistrer les modifications' : 'Ajouter l’adresse'}
              onConfirm={handleSubmit}
              cancelLabel="Annuler"
              onCancel={onHide}
            >
              <DeliveryAddressFormField sageBase={sageBase} />
            </DialogBox>
          </form>
        )}
      />
    </Portal>
  );
};

export default DeliveryAddressDialogForm;
