import React from 'react';
import AutoComplete from 'components/_shared/AutoComplete/AutoComplete';
import { getOffersCommercial } from 'app/redux/slices/offres';
import useFormFieldDropdown from 'components/_shared/form/useFormFieldDropdown';

const OffersFilterDrop = ({ value, onChange, placeholder }) => {
  const optionValue = 'nom';

  const { loadPaginatedOptions } = useFormFieldDropdown({
    action: (page, size, search) => getOffersCommercial({ page, size, search }),
    paginated: true,
    optionValue,
    optionLabel: optionValue,
  });

  const currentValue = value && { nom: value };

  return (
    <AutoComplete
      noWrapper
      name="offer"
      value={currentValue}
      onChange={event => {
        onChange(event?.[optionValue ?? 'value']);
      }}
      getOptionLabel={e => e.nom}
      getOptionValue={e => e.nom}
      valuePropName={optionValue}
      filter
      placeholder={placeholder ?? 'Sélectionner une offre'}
      noOptionsMessage={() => 'Aucune offre trouvée'}
      isClearable
      fieldProps={{
        autoResize: true,
        allowNull: true,
      }}
      withPagination={true}
      {...loadPaginatedOptions()}
      menuPortalTarget={document.body}
      debounceTimeout={1000}
      cacheUniqs={[]}
    />
  );
};

export default OffersFilterDrop;
