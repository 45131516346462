import { routes } from 'app/routes/appRoutes';

export default [
  {
    // pathname for check and highlight
    pathname: routes.PRESTATIONS(),
    // levels in navigation bar
    levels: ['Solutions', 'Prestations'],
  },
  {
    pathname: routes.OFFERS(),
    levels: ['Solutions', 'Offres'],
  },
  {
    pathname: routes.INDEXES(),
    levels: ['Solutions', 'Révisions tarifaires'],
  },
  {
    pathname: routes.DASHBOARD(),
    levels: ['Tableau de bord'],
  },
  {
    pathname: routes.SUBSCRIPTIONS(),
    levels: ['Souscription'],
  },
  {
    pathname: routes.CLIENTS(),
    levels: ['Clients'],
  },
  {
    pathname: routes.REPORTS_PRESTATIONS(),
    levels: ['Rapports', 'Prestations souscrites'],
  },
  {
    pathname: routes.REPORTS_PRESTATIONS_FSE(),
    levels: ['Rapports', 'Consommation FSE'],
  },
  {
    pathname: routes.REPORTS_EXPORT(),
    levels: ['Rapports', 'Export des données'],
  },
  {
    pathname: routes.REPORTS_STELLAIR_USAGE(),
    levels: ['Rapports', 'Usage Stellair'],
  },
];
