import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TwoLineData } from 'olaqin-design-system';
import {
  getClientsNewListPaginated,
  selectClientsNewData,
  selectIsTeamsNewListLoading,
} from 'app/redux/slices/teams';
import { capitalize } from 'app/utils/functions';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import { routes } from 'app/routes/appRoutes';
import usePagination from 'app/hooks/usePagination';
import { formatDate } from 'app/utils/dateUtils';
import ClientCreateDialogForm from './dialogs/ClientCreateDialogForm';
import ClientsHeaderTable from './ClientsHeaderTable';

import './ClientNew.scss';

const ClientsNewBody = () => {
  const history = useHistory();
  const { onTablePageChange, resetTable } = usePagination({
    dataFetchBy: getClientsNewListPaginated,
  });

  const clientsNewData = useSelector(selectClientsNewData);
  const isLoading = useSelector(selectIsTeamsNewListLoading);

  const [editDialogData, handleEditDialog] = useState({ isVisible: false, data: {} });

  const handleClickRow = ({ data }) => {
    history.push(routes.CLIENTS_DETAILS(data.identifiant));
  };

  const actionOnSuccessCreate = () => {
    resetTable();
  };

  const nameBody = row => {
    if (row.hasAnomaly) {
      return (
        <div className="p-d-flex p-align-center">
          <StatusSubscription label="Anomalie" />
          <div className="p-pl-3">
            <TwoLineData topText={`${capitalize(row.nom)}`} />
          </div>
        </div>
      );
    }

    return <TwoLineData topText={`${capitalize(row.nom)}`} />;
  };

  const dateBody = row => {
    if (!row.dateCreation) {
      return '';
    }
    const dateCreation = formatDate(row.dateCreation, 'DD/MM/YYYY');

    return `${dateCreation}`;
  };

  return (
    <>
      <DataTable
        id="clients-table"
        style={{ marginTop: '16px' }}
        header={<ClientsHeaderTable />}
        value={clientsNewData.content || []}
        loading={isLoading}
        paginator
        onPage={onTablePageChange}
        first={clientsNewData.pageable?.offset || 0}
        numberOfElements={clientsNewData.numberOfElements || 0}
        totalRecords={clientsNewData.totalElements || 0}
        rows={clientsNewData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun client ne correspond à votre recherche"
        responsive={true}
        selectionMode="single"
        onRowSelect={handleClickRow}
        rowHover
        autoLayout
      >
        <Column
          header="Intitulé"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          bodyStyle={{
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            maxWidth: '0',
            textOverflow: 'ellipsis',
            padding: '0.5rem 1rem',
          }}
          body={nameBody}
        />
        <Column
          key="numeroClient"
          field="numeroClient"
          header="Numéro"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
        />
        <Column
          key="email"
          field="email"
          header="E-mail principal"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
        />
        <Column
          key="ville"
          field="adresseFacturation.ville"
          header="Ville"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
        />
        <Column
          key="dateCreation"
          field="dateCreation"
          header="Date de création"
          body={dateBody}
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
        />
      </DataTable>
      {editDialogData.isVisible && (
        <ClientCreateDialogForm
          isEdit
          visible={editDialogData.isVisible}
          onHide={() => handleEditDialog({ isVisible: false, data: {} })}
          actionOnSuccessCreate={actionOnSuccessCreate}
        />
      )}
    </>
  );
};

export default ClientsNewBody;
