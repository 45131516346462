import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import {
  getDeliveryAddressesAll,
  selectDeliveryAddressDataTable,
  selectCollaboratorsBillingSystem,
  getCollaboratorsBillingSystem,
} from 'app/redux/slices/teams';
import { useDispatch, useSelector } from 'react-redux';
import { combineAddressLabel } from 'app/utils/formatUtils';
import { sageBaseTypes } from 'app/constants/client.constants';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import CreateNewButton from 'components/_shared/CreateNewButton/CreateNewButton';
import DeliveryAddressDialogForm from 'components/clientsDetail/Tabs/DeliveryAddressTab/Dialog/DeliveryAddressDialogForm';

const CardParameters = ({ isEdit }) => {
  const dispatch = useDispatch();
  const [isDeliveryAddressDialogVisible, handleShowDialog] = useState(false);

  const deliveryAddressData = useSelector(selectDeliveryAddressDataTable);
  const { input: formClientInput } = useField('client', { allowNull: true });
  const { input: formDeliveryAddressInput } = useField('deliveryAddress.identifiant');
  const { input: collaborator } = useField('collaborator.identifiant');
  const [isAddrFirstView, setIsAddrFirstView] = useState(true);
  const collaborators = useSelector(selectCollaboratorsBillingSystem);

  const collaboratorOptions = collaborators.map(el => ({
    label: `${el.nom ?? ''} ${el.prenom ?? ''}`,
    value: el.id,
  }));

  useEffect(() => {
    if (formClientInput.value?.sageBase) {
      dispatch(
        getCollaboratorsBillingSystem({
          sageBase: sageBaseTypes[formClientInput.value?.sageBase],
        }),
      );
    }
    if (!isEdit) {
      collaborator.onChange(formClientInput.value?.collaborator ?? '');
    } else {
      collaborator.onChange('');
    }
  }, [formClientInput.value]);

  const fetchDeliveryAddrs = () => {
    const clientId = formClientInput.value?.identifiant;
    if (clientId) {
      dispatch(getDeliveryAddressesAll({ id: clientId }))
        .unwrap()
        .then(list => {
          // preset first after load
          // if it's on edit sub we should not preselect
          if (!isEdit) {
            const targetSelectedAddr = list.length ? list[0].identifiant : '';
            formDeliveryAddressInput.onChange(targetSelectedAddr);
          } else if (!isAddrFirstView) {
            // this line will not be fired on the modification first view, since we don't want to clear the address field at the init step
            formDeliveryAddressInput.onChange('');
          }
        })
        .catch(() => {});
      setIsAddrFirstView(false);
    }
  };
  useEffect(() => {
    fetchDeliveryAddrs();
  }, [formClientInput.value?.identifiant]);

  const generateAddressesOptions = () =>
    deliveryAddressData.map(address => {
      const label = combineAddressLabel(address);
      return { label, value: address.identifiant };
    });
  const isShowTooltip = !formClientInput.value;

  return (
    <div className="p-fluid p-col-12 p-lg-5 p-p-0">
      {isDeliveryAddressDialogVisible && (
        <DeliveryAddressDialogForm
          visible={isDeliveryAddressDialogVisible}
          onHide={() => handleShowDialog(false)}
          actionOnSuccessCreate={fetchDeliveryAddrs}
          clientId={formClientInput.value?.identifiant}
          clientData={formClientInput.value}
          sageBase={formClientInput.value?.sageBase}
        />
      )}
      <p style={{ fontWeight: 700 }}>Adresse de livraison</p>
      <FormFieldAutoComplete
        name="deliveryAddress.identifiant"
        placeholder="Sélectionner une adresse"
        options={generateAddressesOptions()}
        noWrapper
        tooltip={{
          label:
            'Il est nécessaire de choisir un client avant de sélectionner l’adresse de livraison.',
          display: isShowTooltip,
          disableInput: isShowTooltip,
        }}
        loadOptions={{
          optionValue: 'value',
        }}
        storeSingleValueOnForm
        footerPanel={
          <CreateNewButton
            disabled={isShowTooltip}
            style={{ textAlign: 'left' }}
            label="Ajouter une adresse"
            type="button"
            onClick={() => {
              handleShowDialog(true);
            }}
          />
        }
      />

      <p style={{ fontWeight: 700 }}>Revendeur</p>
      <FormFieldDropdown
        name="collaborator.identifiant"
        placeholder="Sélectionner un revendeur"
        options={collaboratorOptions}
        noWrapper
        tooltip={{
          label: 'Il est nécessaire de choisir un client avant de sélectionner le revendeur',
          display: isShowTooltip,
          disableInput: isShowTooltip,
        }}
      />
    </div>
  );
};

export default CardParameters;
