import { Validators } from 'olaqin-design-system';

export const maxLengthValidator = (max, message) => {
  if (message) {
    return value => Validators.maxLength(max)(value) && message;
  }
  return value => Validators.maxLength(max)(value);
};

export const dashOnEmpty = value => value || '-';

export const alphaNumeriqueWithSpace = (value, message) =>
  !/^[A-Za-z0-9 ]+$/i.test(value) && message;

export const isValidNumber = value => {
  if (Number.isNaN(value)) {
    return false;
  }

  const floatPattern = /^\d{1,5}(,\d+)?$/;
  return floatPattern.test(value);
};
