import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentTerms, selectPaymentTermsList } from 'app/redux/slices/utilisateurs';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import './clientCreateForm.scss';

const PaymentInfo = () => {
  const dispatch = useDispatch();
  const paymentTermsList = useSelector(selectPaymentTermsList);

  useEffect(() => {
    dispatch(getPaymentTerms());
  }, []);

  const paymentTermsListOptions = paymentTermsList?.map(el => ({
    label: el.label,
    value: el.identifiant,
  }));

  return (
    <FormFieldGroup title="Informations de paiement">
      <FormFieldDropdown
        name="paymentTermTemplateId"
        label="Conditions de paiement"
        placeholder="Sélectionner"
        options={paymentTermsListOptions}
        showClearButton
      />
      <FormFieldInputText
        name="bankDetails.bankName"
        label="Coordonnées bancaires"
        placeholder="Nom de la banque"
      />
      <FormFieldInputText name="bankDetails.iban" placeholder="IBAN" />
      <FormFieldInputText name="bankDetails.bic" placeholder="BIC" />
    </FormFieldGroup>
  );
};

export default PaymentInfo;
