import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'olaqin-design-system';
import { Column } from 'primereact/column';
import { formatDate } from 'app/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectStellairUsageData,
  selectIsStellairUsageLoading,
  fetchStellairUsageGridData,
  downloadStellairReport,
  selectRequestedStellairReportID,
} from 'app/redux/slices/reports';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import customStyle from 'components/reports/Reports.module.scss';
import usePagination from 'app/hooks/usePagination';
import {
  subscriptionStatusesServer,
  StellairStatusFormat,
} from 'app/constants/subscription.constants';
import downloadObjectAsJson from 'app/utils/fileUtils';
import ReloadConfirmationModal from './ReloadConfirmationModal';

const ReportsStellairDataTable = () => {
  const dispatch = useDispatch();
  const { onTablePageChange, reloadPage } = usePagination({
    dataFetchBy: fetchStellairUsageGridData,
  });
  const [selectedIdForReload, setIdForReload] = useState(null);

  const isLoadingReportsData = useSelector(selectIsStellairUsageLoading);
  const reportsData = useSelector(selectStellairUsageData);
  const requstedDownloadIds = useSelector(selectRequestedStellairReportID);

  const bodyPeriod = row => {
    const fromDate = formatDate(row.fromDate, 'DD/MM/YYYY');
    const toDate = formatDate(row.toDate, 'DD/MM/YYYY');

    return `${fromDate} – ${toDate}`;
  };

  const bodyStatus = row => <StatusSubscription label={StellairStatusFormat(row.status)} />;

  const downloadReport = async (id, fileName) => {
    await dispatch(downloadStellairReport(id))
      .unwrap()
      .then(data => {
        downloadObjectAsJson(data, fileName);
      })
      .catch(() => {});
  };

  const regenerateReport = id => setIdForReload(id);

  const bodyActionButton = ({ identifiant, status, ...rest }) => {
    const fileName = `stellaiUsage-${bodyPeriod(rest)}`;
    if (status === subscriptionStatusesServer.PENDING) return '';

    if (status === subscriptionStatusesServer.ANOMALIE) {
      return (
        <Button
          icon={`pi pi-refresh`}
          type="button"
          btnType="secondary"
          className={`${customStyle.customButtonResolve} p-m-0`}
          label="Réessayer"
          onClick={() => regenerateReport(identifiant)}
        />
      );
    }

    return (
      <Button
        icon="pi pi-download"
        type="secondary"
        btnType="secondary"
        className={`${customStyle.customButtonDownload} p-m-0`}
        onClick={() => downloadReport(identifiant, fileName)}
        disabled={requstedDownloadIds.includes(identifiant)}
      />
    );
  };

  return (
    <>
      <ReloadConfirmationModal
        targetId={selectedIdForReload}
        onHide={() => setIdForReload(null)}
        onSucess={() => reloadPage()}
      />
      <DataTable
        id="reportsUsageStellair-table"
        value={reportsData.content || []}
        loading={isLoadingReportsData}
        paginator
        onPage={onTablePageChange}
        first={reportsData.pageable?.offset || 0}
        numberOfElements={reportsData.numberOfElements || 0}
        totalRecords={reportsData.totalElements || 0}
        rows={reportsData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun rapport de consommation disponible"
        responsive
        rowHover
        autoLayout
      >
        <Column
          header="Période"
          headerStyle={{ border: '0', width: '18%' }}
          bodyStyle={{
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            maxWidth: '0',
            textOverflow: 'ellipsis',
          }}
          body={bodyPeriod}
        />
        <Column
          field="environment"
          header="Environnement"
          headerStyle={{ border: '0', width: '20%' }}
        />
        <Column
          field="status"
          header="État"
          headerStyle={{ border: '0', width: '15%' }}
          body={bodyStatus}
        />

        <Column
          key="supp"
          header=""
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
        />
      </DataTable>
    </>
  );
};

export default ReportsStellairDataTable;
