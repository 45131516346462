import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { throwError } from 'app/utils/functions';

const reducerName = 'syntecIndexes';

const initialState = {
  isLoading: false,
  isDeleting: false,
  isModalOpen: false,
  monthly: { isLoading: false, data: {} },
  yearly: { isLoading: false, data: {} },
};

export const fetchYearlyIndexGridData = createAsyncThunk(
  `${reducerName}/fetchYearlyIndexGridData`,
  async ({ page, size }, { rejectWithValue }) => {
    try {
      const { data } = await API.SyntecIndex.getAllSyntecIndexesByType('YEAR', page, size);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const fetchMonthlyIndexGridData = createAsyncThunk(
  `${reducerName}/fetchMonthlyIndexGridData`,
  async ({ page, size }, { rejectWithValue }) => {
    try {
      const { data } = await API.SyntecIndex.getAllSyntecIndexesByType('MONTH', page, size);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createNewSyntecIndex = createAsyncThunk(
  `${reducerName}/createNewSyntecIndex`,
  async (syntecIndexForm, { rejectWithValue }) => {
    try {
      const { data } = await API.SyntecIndex.addIndex(syntecIndexForm);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateSyntecIndex = createAsyncThunk(
  `${reducerName}/updateSyntecIndex`,
  async ({ indexId, syntecIndexForm }, { rejectWithValue }) => {
    try {
      const { data } = await API.SyntecIndex.updateIndex(indexId, syntecIndexForm);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const deleteSyntecIndex = createAsyncThunk(
  `${reducerName}/deleteSyntecIndex`,
  async (indexId, { rejectWithValue }) => {
    try {
      const { data } = await API.SyntecIndex.deleteIndex(indexId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

const syntecIndexSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    toggleIndexDialog(state, action) {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: builder => {
    // Yearly index grid data
    builder.addCase(fetchYearlyIndexGridData.pending, state => {
      state.yearly.isLoading = true;
    });
    builder.addCase(fetchYearlyIndexGridData.fulfilled, (state, action) => {
      state.yearly.data = action.payload ?? {};
      state.yearly.isLoading = false;
    });
    builder.addCase(fetchYearlyIndexGridData.rejected, state => {
      state.yearly.isLoading = false;
    });

    // Montly index grid data
    builder.addCase(fetchMonthlyIndexGridData.pending, state => {
      state.monthly.isLoading = true;
    });
    builder.addCase(fetchMonthlyIndexGridData.fulfilled, (state, action) => {
      state.monthly.data = action.payload ?? {};
      state.monthly.isLoading = false;
    });
    builder.addCase(fetchMonthlyIndexGridData.rejected, state => {
      state.monthly.isLoading = false;
    });

    // creating index
    builder.addCase(createNewSyntecIndex.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createNewSyntecIndex.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(createNewSyntecIndex.rejected, state => {
      state.isLoading = false;
    });

    // update index
    builder.addCase(updateSyntecIndex.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateSyntecIndex.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(updateSyntecIndex.rejected, state => {
      state.isLoading = false;
    });

    // deleting index
    builder.addCase(deleteSyntecIndex.pending, state => {
      state.isDeleting = true;
    });
    builder.addCase(deleteSyntecIndex.fulfilled, state => {
      state.isDeleting = false;
    });
    builder.addCase(deleteSyntecIndex.rejected, state => {
      state.isDeleting = false;
    });
  },
});

const managerStateSelector = state => state[reducerName];

export const selectYearlyIndexesIsLoading = createSelector(
  managerStateSelector,
  state => state.yearly.isLoading,
);
export const selectYearlyIndexesData = createSelector(
  managerStateSelector,
  state => state.yearly.data,
);
export const selectMontlyIndexesIsLoading = createSelector(
  managerStateSelector,
  state => state.monthly.isLoading,
);
export const selectMontlyIndexesData = createSelector(
  managerStateSelector,
  state => state.monthly.data,
);

export const selectIsSyntecIndexLoading = createSelector(
  managerStateSelector,
  state => state.isLoading,
);
export const selectIsSyntecIndexModalOpen = createSelector(
  managerStateSelector,
  state => state.isModalOpen,
);
export const selectIsSyntecIndexDeleting = createSelector(
  managerStateSelector,
  state => state.isDeleting,
);

export const { toggleIndexDialog } = syntecIndexSlice.actions;
export default syntecIndexSlice.reducer;
