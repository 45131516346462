import React from 'react';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import PrestationCreateDialogWrap from 'components/prestationsNew/dialogs/PrestationCreateDialogWrap';
import HeaderPage from 'components/_shared/HeaderPage/HeaderPage';
import PrestationsNewBody from './PrestationsNewBody';

const PrestationsNew = () => {
  useDocumentTitle(`${TITLE_PREFIX} Prestations`);

  return (
    <>
      <HeaderPage title="Prestations">
        <PrestationCreateDialogWrap />
      </HeaderPage>
      <PrestationsNewBody />
    </>
  );
};

export default PrestationsNew;
