import React from 'react';
import { Dialog } from 'olaqin-design-system';
import SubmitButton from '../SubmitButton/SubmitButton';
import './DialogBox.scss';

const DialogBox = ({
  id,
  isLoading,
  children,
  title,
  header,
  footer,
  className = '',
  style = {},
  width = 500,
  hideTitleLoading = false,
  onCancel,
  cancelLabel,
  onConfirm,
  confirmLabel,
  isConfirmActive = true,
  isConfirmButtonDangerColor = false,
  noSubmitTypeOnButton = false,
  noTopSeparator = false,
  noBottomSeparator = false,
  noSeparator = false,
  ...otherProps
}) => {
  const Title = () => (
    <div className="title-wrapper">
      <span>{title}</span>
      {isLoading && !hideTitleLoading && (
        <span className="loading-title pi pi-ellipsis-h p-c p-button-icon-right p-ml-2 p-text-bold" />
      )}
    </div>
  );
  return (
    <Dialog
      id={id || 'DialogBox'}
      className={`DialogBox p-fluid p-formgrid p-col-12 p-sm-12 p-md-5 p-lg-5 ${
        noSeparator || noTopSeparator ? 'no-top-separator' : ''
      } ${noSeparator || noBottomSeparator ? 'no-bottom-separator' : ''} ${className}`}
      style={{ maxWidth: `${width}px`, ...style }}
      header={header || <Title />}
      footer={
        <>
          <hr className="separator" />
          <div className="p-d-flex p-justify-end">
            {footer || (
              <>
                {cancelLabel && (
                  <SubmitButton
                    className="p-mx-2"
                    type="button"
                    btnType="secondary"
                    label={cancelLabel}
                    onClick={onCancel}
                    disabled={isLoading}
                    outlined
                  />
                )}
                {confirmLabel && (
                  <SubmitButton
                    icon={isLoading ? 'pi pi-spin pi-spinner' : ''}
                    className={`confirm-btn p-mx-0 ${
                      isConfirmButtonDangerColor ? 'danger-color' : ''
                    }`}
                    type={!noSubmitTypeOnButton ? 'submit' : 'button'}
                    btnType="primary"
                    disabled={!isConfirmActive || isLoading}
                    label={isLoading ? `${confirmLabel}...` : confirmLabel}
                    onClick={onConfirm}
                  />
                )}
              </>
            )}
          </div>
        </>
      }
      {...otherProps}
    >
      {children}
    </Dialog>
  );
};

export default DialogBox;
