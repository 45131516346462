import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getClientDetails } from 'app/redux/slices/teams';
import ClientCreateDialogForm from 'components/clientsNew/dialogs/ClientCreateDialogForm';
import { routes } from 'app/routes/appRoutes';
import { useHistory } from 'react-router-dom';
import ActionMenu from './ActionMenu';
import DuplicateClientDialog from './DuplicateClientDialog';

const UpdateClientDialogWrapper = ({ clientId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, showClientDialog] = useState(false);
  const [isOpenDuplicate, showDuplicateDialog] = useState(false);

  const actionOnSuccessEdit = () => {
    dispatch(getClientDetails({ id: clientId }));
  };

  const actionOnSuccessDuplicate = id => {
    history.push(routes.CLIENTS_DETAILS(id));
    dispatch(getClientDetails({ id }));
  };

  return (
    <div className="p-d-flex">
      <ActionMenu
        onClick={() => showClientDialog(true)}
        onClickDuplicate={() => showDuplicateDialog(true)}
      />

      {isOpenDuplicate && (
        <DuplicateClientDialog
          clientId={clientId}
          visible={isOpenDuplicate}
          onHide={() => showDuplicateDialog(false)}
          actionOnSuccessDuplicate={actionOnSuccessDuplicate}
        />
      )}

      {isOpen && (
        <ClientCreateDialogForm
          visible={isOpen}
          onHide={() => showClientDialog(false)}
          actionOnSuccessEdit={actionOnSuccessEdit}
          isEdit={true}
        />
      )}
    </div>
  );
};

export default UpdateClientDialogWrapper;
