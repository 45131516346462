import React from 'react';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import HeaderPage from 'components/_shared/HeaderPage/HeaderPage';
import IndecesBody from './IndexesBody';
import './indexes.scss';

const IndexesPage = () => {
  useDocumentTitle(`${TITLE_PREFIX} Révisions tarifaires`);
  return (
    <>
      <HeaderPage title="Révisions tarifaires" />
      <IndecesBody />
    </>
  );
};

export default IndexesPage;
