import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { archiveAnOffer, selectArchiveOfferIsLoading } from 'app/redux/slices/offres';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { OfferStatusList } from 'app/constants/offerConstants';

const ArchiveOfferDialog = ({
  visible,
  onHide,
  offreCommercialeId,
  status,
  actionOnSuccessArchive,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectArchiveOfferIsLoading);

  const handlePublishOffer = async () => {
    await dispatch(archiveAnOffer({ offreCommercialeId }))
      .unwrap()
      .then(() => {
        onHide();
        actionOnSuccessArchive();
      })
      .catch(() => {});
  };

  const confirmationDesc =
    status === OfferStatusList.PUBLIEE
      ? 'Confirmez-vous l’archivage de l’offre ?'
      : 'En archivant cette offre, elle ne sera plus disponible à la souscription. Confirmez-vous l’archivage de l’offre ?';

  return (
    <DialogBox
      id="archiveOfferDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title="Archivage de l’offre"
      isLoading={isLoading}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={handlePublishOffer}
      confirmLabel="Confirmer l’archivage"
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 16 }}>{confirmationDesc}</div>
    </DialogBox>
  );
};

export default ArchiveOfferDialog;
