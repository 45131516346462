import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSouchesList, selectSouchesList } from 'app/redux/slices/utilisateurs';

import { CardDisplay } from 'olaqin-design-system';
import WarningLabel from 'components/_shared/WarningLabel/WarningLabel';

import {
  BillOneTimeFeesList,
  TransmissZeroInvoicesList,
  DateFacturationList,
} from 'app/constants/offerConstants';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';

const OfferPararmeters = ({ offerData, actionOnReqParametersEdit, displayConfigureBtn }) => {
  const {
    codeSouche,
    sageBase,
    billOneTimeFeesSeparately,
    transmissZeroInvoices,
    dateFacturation,
  } = offerData;
  const dispatch = useDispatch();

  const soucheList = useSelector(selectSouchesList);
  const soucheLabel = soucheList.find(i => i.code === codeSouche)?.designation;

  useEffect(() => {
    dispatch(getSouchesList(sageBase));
  }, [sageBase]);

  const showLabelOrWarning = (field, list) =>
    list.find(item => item.value === field)?.label ?? <WarningLabel />;

  return (
    <CardDisplay style={{ width: 'auto' }} title="Paramètres de facturation">
      <div className="p-grid item p-mt-2 p-ai-center">
        <span className="p-md-3">Système de facturation :</span>
        <span className="p-md-9 p-d-flex p-align-center" style={{ height: '42px' }}>
          <img src="/icons/SageLogo-28.svg" alt="Sage" />
          <div className="p-pl-2">Sage</div>
        </span>
      </div>
      <div className="p-grid item">
        <span className="p-md-3">Base Sage :</span>
        <span className="p-md-9">{sageBase ?? <WarningLabel />}</span>
      </div>
      <div className="p-grid item">
        <span className="p-md-3">Souche :</span>
        <span className="p-md-9">{soucheLabel ?? <WarningLabel />}</span>
      </div>
      <div className="p-grid item noWrap">
        <span className="p-md-3">Facturation des prestations ponctuelles :</span>
        <span className="p-md-9">
          {showLabelOrWarning(billOneTimeFeesSeparately, BillOneTimeFeesList)}
        </span>
      </div>
      <div className="p-grid item">
        <span className="p-md-3">Bons de facturation à 0 € :</span>
        <span className="p-md-9">
          {showLabelOrWarning(transmissZeroInvoices, TransmissZeroInvoicesList)}{' '}
        </span>
      </div>
      <div className="p-grid item">
        <span className="p-md-3">Jour de transmission :</span>
        <span className="p-md-9">{showLabelOrWarning(dateFacturation, DateFacturationList)}</span>
      </div>
      <div>
        {displayConfigureBtn && (
          <SubmitButton
            className="p-mt-2"
            type="button"
            btnType="secondary"
            label="Configurer les paramètres de facturation"
            outlined
            small
            style={{ background: 'white' }}
            icon="pi pi-cog"
            onClick={actionOnReqParametersEdit}
          />
        )}
      </div>
    </CardDisplay>
  );
};

export default OfferPararmeters;
