import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeleteClientContactIsLoading, deleteClientContact } from 'app/redux/slices/teams';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

const DeleteContactDialog = ({ visible, onHide, actionOnSuccessDelete, clientId, contactId }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectDeleteClientContactIsLoading);

  const handleDeleteContact = async () => {
    await dispatch(deleteClientContact({ clientId, contactId }))
      .unwrap()
      .then(() => {
        onHide();
        actionOnSuccessDelete();
      });
  };

  return (
    <DialogBox
      id="deleteClientContactDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title="Confirmation"
      isLoading={isLoading}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={handleDeleteContact}
      confirmLabel="Supprimer le contact"
      isConfirmButtonDangerColor
      noTopSeparator
      dismissableMask
    >
      <div>Souhaitez-vous réellement supprimer le contact ? Cette action est irréversible.</div>
    </DialogBox>
  );
};

export default DeleteContactDialog;
