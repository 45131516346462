import React from 'react';
import { Field } from 'react-final-form';
import { Tooltip } from 'primereact/tooltip';
import AutoComplete from '../AutoComplete/AutoComplete';
import FormFieldWrapper from './FormFieldWrapper';
import useFormFieldDropdown from './useFormFieldDropdown';

const TooltipWrapper = ({ label, display, children }) =>
  display && label ? (
    <>
      <Tooltip target=".disabled-button" position="right" />
      <span className="disabled-button" data-pr-tooltip={label} data-pr-position="right">
        {children}
      </span>
    </>
  ) : (
    children
  );

const FormFieldAutoComplete = ({
  name,
  label,
  showClearButton = false,
  noWrapper = false,
  showValidationIcon = false,
  colSpan = { col: 9, md: 12 },
  loadOptions = {},
  options = [],
  onChange,
  tooltip,
  storeSingleValueOnForm,
  footerPanel,
  renderers,
  isDisabled,
  hideDropDownButton,
  fieldProps,
  isLoading,
  wrapperInlineStyle,
  preventInputChange,
  ...otherProps
}) => {
  const {
    action,
    optionValue = 'identifiant',
    optionLabel,
    reload,
    disabledOption,
    paginated,
    paginationConfig,
    flatOptions,
  } = loadOptions;
  const { list: optionFormAction, dataLoading, loadPaginatedOptions } = useFormFieldDropdown({
    action,
    optionValue,
    optionLabel,
    reload,
    disabledOption,
    paginated,
    paginationConfig,
    flatOptions,
  });

  const selectIsDisabled = isDisabled || (action ? isLoading : tooltip?.disableInput) || false;

  const getLoading = () => {
    if (paginated) return {};

    return {
      isLoading: isLoading || dataLoading,
    };
  };

  const getOptions = () => (paginated ? {} : { options: action ? optionFormAction : options });

  return (
    <FormFieldWrapper noWrapper={noWrapper} colSpan={colSpan}>
      <TooltipWrapper {...tooltip}>
        <Field
          name={name}
          render={({ input, meta: { valid, error, touched } }) => (
            <span className={`p-input-icon-right`} style={wrapperInlineStyle}>
              {label && (
                <label htmlFor={`${name}-id`} className="label">
                  {label}
                </label>
              )}
              <AutoComplete
                id={`${name}-id`}
                className={!valid && touched ? 'r-invalid' : ''}
                value={tooltip?.hideInputValue ? '' : input.value}
                onChange={event => {
                  const formVal = storeSingleValueOnForm ? event?.[optionValue] : event;
                  if (onChange) {
                    onChange(event, input);
                  }
                  if (!preventInputChange) {
                    input.onChange(formVal);
                  }
                }}
                onBlur={input.onBlur}
                isClearable={showClearButton && !!input.value}
                isDisabled={selectIsDisabled}
                footerPanel={footerPanel}
                valuePropName={optionValue}
                hideDropDownButton={hideDropDownButton}
                withPagination={paginated}
                {...getOptions()}
                {...getLoading()}
                {...loadPaginatedOptions()}
                {...renderers}
                {...otherProps}
              />
              {!!error && touched && <small className="p-error p-d-block">{error}</small>}
              {showValidationIcon && valid && touched && (
                <i className={`pi pi-check ${label ? 'p-mt-1' : ''}`} />
              )}
            </span>
          )}
          {...fieldProps}
        />
      </TooltipWrapper>
    </FormFieldWrapper>
  );
};

export default FormFieldAutoComplete;
