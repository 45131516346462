import React, { useEffect, useState } from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSubscriptionEventsPaginated,
  selectSubscriptionEventGridData,
  selectIsSubscriptionEventGridLoading,
} from 'app/redux/slices/souscriptions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from 'app/utils/dateUtils';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import SubscriptionEventsDetail from './SubscriptionEventsDetail';

const EventsTab = ({ subscriptionId }) => {
  const dispatch = useDispatch();
  const [pageMeta, setPageMeta] = useState({ page: 0, size: 30 });
  const [actionDetailsData, setActionDetailsData] = useState(null);
  const eventsGridData = useSelector(selectSubscriptionEventGridData);
  const isLoading = useSelector(selectIsSubscriptionEventGridLoading);
  const fetchGridData = (page, size) => {
    dispatch(getSubscriptionEventsPaginated({ souscriptionId: subscriptionId, page, size }));
  };

  useEffect(() => {
    fetchGridData(pageMeta.page, pageMeta.size);
  }, [pageMeta]);

  const onTablePageChange = data => {
    setPageMeta({ page: data.page, size: data.rows });
  };

  const getIconByStatus = status => {
    switch (status) {
      case 'INFO':
        return 'pi-info-circle';

      case 'FAIL':
        return 'pi-times-circle';

      default:
        return 'pi-check-circle';
    }
  };

  const getColorByStatus = status => {
    switch (status) {
      case 'INFO':
        return '#6A6A6A';

      case 'FAIL':
        return '#B45763';

      default:
        return '#2F7D7C';
    }
  };
  const nameBody = row => (
    <span style={{ color: getColorByStatus(row.status), display: 'flex', alignItems: 'center' }}>
      <i className={`pi ${getIconByStatus(row.status)}`} style={{ marginRight: 16 }} />
      <span>{formatDate(row.date, 'DD/MM/YYYY - HH:mm')}</span>
    </span>
  );

  const messageBody = row => (
    <span style={{ color: getColorByStatus(row.status) }}>
      <span>{row.message}</span>
    </span>
  );

  const bodyActionButton = rowData => (
    <SubmitButton
      className="p-mx-2"
      type="button"
      btnType="primary"
      label="Détails"
      onClick={() => {
        setActionDetailsData(rowData?.details);
      }}
      outlined
      small
      style={{
        border: 'none',
        display: rowData.status === 'FAIL' && rowData?.details ? 'initial' : 'none',
      }}
    />
  );

  return (
    <CardDisplay title="Événements" style={{ width: 'auto' }}>
      <SubscriptionEventsDetail
        data={actionDetailsData}
        isVisible={actionDetailsData !== null}
        onHide={() => setActionDetailsData(null)}
      />
      <DataTable
        id="subscription-events-table"
        value={eventsGridData?.content || []}
        loading={isLoading}
        paginator
        onPage={onTablePageChange}
        first={eventsGridData?.pageable?.offset || 0}
        numberOfElements={eventsGridData?.numberOfElements || 0}
        totalRecords={eventsGridData?.totalElements || 0}
        rows={eventsGridData?.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun évènement n'a été enregistré."
        responsive={true}
        rowHover
        autoLayout
      >
        <Column
          key="date"
          field="date"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
          body={nameBody}
        />
        <Column
          key="message"
          field="message"
          sortable={false}
          headerStyle={{ border: '0', width: '20%' }}
          body={messageBody}
        />
        <Column
          key="owner"
          field="owner"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
          body={({ owner }) => `par ${owner ?? 'le système'}`}
        />
        <Column
          key=""
          header=""
          frozen
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '5%' }}
        />
      </DataTable>
    </CardDisplay>
  );
};

export default EventsTab;
