import React, { createContext, useContext, useReducer } from 'react';

export const GlobalMessageContext = createContext(null);

export const useGlobalMessageContext = () => useContext(GlobalMessageContext);

export const CREATE_MESSAGE = 'SHOW_MESSAGE';

const globalMessageReducer = (state, action) => {
  switch (action.type) {
    case CREATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    default:
      return state;
  }
};

export const GlobalMessageProvider = props => {
  const initialState = {
    globalMessage: {},
  };
  const [state, dispatch] = useReducer(globalMessageReducer, initialState);

  const showGlobalMessage = message => {
    dispatch({
      type: CREATE_MESSAGE,
      payload: message,
    });
  };

  return (
    <GlobalMessageContext.Provider
      value={{ globalMessage: state.globalMessage, showGlobalMessage }}
    >
      {props.children}
    </GlobalMessageContext.Provider>
  );
};

export default GlobalMessageProvider;
