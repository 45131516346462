import React, { useState, useEffect, useMemo } from 'react';
import { Portal } from 'react-portal';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import { routes } from 'app/routes/appRoutes';
import { ProgressSpinner } from 'olaqin-design-system';
import { OfferStatusList } from 'app/constants/offerConstants';
import {
  createOffer,
  saveAnOfferAsDraft,
  getOfferDetails,
  selectIsOfferDetailsLoading,
  selectOfferDetailsData,
  updateOffer,
} from 'app/redux/slices/offres';
import {
  mapCreateOfferData,
  generateInitialValuesForOfferForm,
} from 'components/offers/CreateOfferForm/mapCreateOfferData';

import { useDispatch, useSelector } from 'react-redux';
import CreateOfferConfirmDialog from 'components/offers/CreateOfferForm/CreateOfferConfirmDialog';
import { toast } from 'react-toastify';
import DeleteOfferDialog from '../Dialogs/DeleteOfferDialog';
import CardComposition from './cards/CardComposition/CardComposition';
import CardConditions from './cards/CardConditions/CardConditions';
import CardGeneral from './cards/CardGeneral/CardGeneral';
import CardConnections from './cards/CardConnections/CardConnections';
import CreateOfferFormHeader from './CreateOfferFormHeader';
import customStyle from '../../souscriptions/createSubscription/createSubscription.module.scss';
import { formCreateOfferValidation } from './formCreateOfferValidation';

const CreateOfferForm = ({ match: { params } }) => {
  const offerOnEdit = params?.offerId;
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDeleteOfferDialogVisible, handleShowDeleteDialog] = useState(false);

  const isOfferDataLoading = useSelector(selectIsOfferDetailsLoading);
  const offerData = useSelector(selectOfferDetailsData);
  const formInitValues = useMemo(() => generateInitialValuesForOfferForm(offerOnEdit, offerData), [
    offerData,
  ]);
  useDocumentTitle(
    !offerOnEdit
      ? `${TITLE_PREFIX} Nouvelle offre`
      : `${TITLE_PREFIX} Création de l’offre ${offerData.nom}`,
  );

  const [isCreateOfferConfirmDialog, handleShowConfirmDialog] = useState(false);
  const [formValidValues, setFormValidValues] = useState(null);

  const handleSubmit = formData => {
    const readyData = mapCreateOfferData(formData);

    setFormValidValues(readyData);
    handleShowConfirmDialog(true);
  };

  const onSuccessConfirm = async () => {
    const offerAction = () =>
      offerOnEdit
        ? updateOffer({ offreCommercialeId: offerOnEdit, offreCommercialeForm: formValidValues })
        : createOffer({ offer: formValidValues });

    await dispatch(offerAction())
      .unwrap()
      .then(data => {
        history.push(routes.OFFER_DETAILS(offerOnEdit ? data.identifiant : data.id));
      })
      .catch(() => {});
  };

  const handleClickSaveDraft = async (values, errors, blur) => {
    if (errors.generalInfo?.nom || errors.conditions?.pricingRevisionIndexSettings) {
      blur('generalInfo.nom');
      blur('conditions.pricingRevisionIndexSettings.name');
      blur('conditions.pricingRevisionIndexSettings.value');
      return;
    }

    const readyData = mapCreateOfferData(values);
    const draftOffer = { ...readyData, status: OfferStatusList.BROUILLON };

    await dispatch(
      offerOnEdit
        ? updateOffer({ offreCommercialeId: offerOnEdit, offreCommercialeForm: draftOffer })
        : saveAnOfferAsDraft({ offer: draftOffer }),
    )
      .unwrap()
      .then(() => {
        if (offerOnEdit) {
          toast('L’offre brouillon a bien été enregistrée.', {
            type: 'dark',
            style: {
              background: '#2F7D7C',
            },
          });
        }
        history.push(routes.OFFERS());
      })
      .catch(() => {});
  };

  const handleClose = () => {
    history.push(routes.OFFERS());
  };

  useEffect(() => {
    if (params?.offerId) {
      fetchOfferData();
    }
  }, [params?.offerId]);

  const fetchOfferData = async () => {
    await dispatch(
      getOfferDetails({
        offreCommercialeId: params?.offerId,
      }),
    )
      .unwrap()
      .then(responseData => {
        if (responseData.status !== OfferStatusList.BROUILLON) {
          toast.error(
            'Accès refusé : seules les offres en état de brouillon peuvent être éditées via ce formulaire.',
          );
          history.push(routes.OFFERS());
        }
      })
      .catch();
  };

  if (isOfferDataLoading && offerOnEdit) {
    return (
      <div className="p-d-flex p-justify-center p-align-center">
        <ProgressSpinner />
      </div>
    );
  }

  const actionOnSuccessDelete = () => {
    history.push(routes.OFFERS());
  };

  return (
    <Portal>
      <div className={customStyle.pageContainerForm}>
        <CreateOfferConfirmDialog
          visible={isCreateOfferConfirmDialog}
          onHide={() => handleShowConfirmDialog(false)}
          onConfirm={onSuccessConfirm}
        />
        <Form
          initialValues={formInitValues}
          onSubmit={handleSubmit}
          mutators={{ ...arrayMutators }}
          validate={formCreateOfferValidation}
          render={form => (
            <form id="create-offer-form" onSubmit={form.handleSubmit}>
              <CreateOfferFormHeader
                isEdit={!!offerOnEdit}
                offerName={offerData.nom}
                isFormDirty={form.dirty}
                isLoading={form.submitting}
                handleClose={handleClose}
                handleClickSaveDraft={() =>
                  handleClickSaveDraft(form.values, form.errors, form.form.blur)
                }
                handleShowDeleteOffer={() => handleShowDeleteDialog(true)}
              />
              {isDeleteOfferDialogVisible && offerOnEdit && (
                <DeleteOfferDialog
                  visible={isDeleteOfferDialogVisible}
                  onHide={() => handleShowDeleteDialog(false)}
                  actionOnSuccessDelete={actionOnSuccessDelete}
                  offreCommercialeId={offerOnEdit}
                  status={offerData.status}
                  customToastMessage="L’offre brouillon a été supprimée."
                  customTitle="Suppression du brouillon de l’offre"
                />
              )}
              <div className={customStyle.formBody}>
                <div className="p-col-12 p-p-0">
                  <div className="p-lg-8 p-md-12 p-lg-offset-2">
                    <CardGeneral isEdit={offerOnEdit} />
                    <CardComposition />
                    <CardConditions />
                    <CardConnections />
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </Portal>
  );
};

export default CreateOfferForm;
