import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressSpinner } from 'olaqin-design-system';
import {
  getBillingOrder,
  selectBillingDetailsData,
  selectIsBillingDataLoading,
} from 'app/redux/slices/souscriptions';
import { formatDate } from 'app/utils/dateUtils';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import CardDetails from 'components/billingOrders/cards/CardDetails';
import CardPrestationDetails from 'components/billingOrders/cards/CardPrestationDetails';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import CardEventsHistory from 'components/billingOrders/cards/CardEventsHistory';
import BillingOrderActionMenu from './BillingOrderActionMenu';
import BillingOrderTransmitZeroDialog from './BillingOrderTransmitZeroDialog';

const BillingOrders = ({ match }) => {
  const { params } = match;
  const { billingId } = params;
  const dispatch = useDispatch();
  const billingDetailsData = useSelector(selectBillingDetailsData);
  const isLoading = useSelector(selectIsBillingDataLoading);
  const [isTransmitZeroDialogOpen, setTransmitDialogState] = useState(false);

  useDocumentTitle(`${TITLE_PREFIX} Bon ${billingDetailsData.billingOrderNumber || ''} `);

  const fetchBillingOrderData = () => {
    dispatch(getBillingOrder({ billingId }));
  };
  useEffect(() => {
    fetchBillingOrderData();
  }, []);

  const fromDate = formatDate(billingDetailsData.fromDate, 'DD/MM/YYYY');
  const toDate = formatDate(billingDetailsData.toDate, 'DD/MM/YYYY');

  const isIndexed = !!billingDetailsData.indexed;

  const isDataLoaded = !isLoading && !!Object.keys(billingDetailsData).length;

  const subTitle = () => {
    switch (billingDetailsData.type) {
      case 'USAGE':
        return `Frais d’usage pour la période du ${fromDate} au ${toDate}`;
      case 'RECURRENT':
        return `Frais récurrents pour la période du ${fromDate} au ${toDate}`;
      case 'PONCTUEL':
        return `Frais ponctuels pour la période du ${fromDate} au ${toDate}`;
      default:
        return '';
    }
  };

  if (!isDataLoaded) {
    return <ProgressSpinner />;
  }

  return (
    <>
      <div className="p-d-flex p-justify-between p-align-start p-mt-5">
        <div>
          <p
            className="p-mb-0 p-mt-0"
            style={{
              fontFamily: 'RecifeDisplay',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '38px',
              lineHeight: '40px',
              color: '#19171a',
            }}
          >
            Bon de facturation {billingDetailsData.billingOrderNumber}
          </p>
          <StatusSubscription label={billingDetailsData.status} />
        </div>
        <div>
          <BillingOrderActionMenu
            transmitZeroConfig={{
              onClick: () => setTransmitDialogState(true),
              isDisabled: billingDetailsData.status !== 'NON_TRANSMIS',
              show: true,
            }}
          />
          <BillingOrderTransmitZeroDialog
            billingOrderId={billingId}
            isVisible={isTransmitZeroDialogOpen}
            onHide={() => setTransmitDialogState(false)}
            onSuccess={fetchBillingOrderData}
          />
        </div>
      </div>
      <p
        className="p-mt-1 p-mb-2"
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          color: '#000000',
        }}
      >
        {subTitle()}
      </p>
      <div className="p-grid p-mt-1">
        <div className="p-col-12">
          <CardDetails title="Détails du bon" billingDetailsData={billingDetailsData} />
          <CardPrestationDetails
            title="Prestations à facturer"
            total={billingDetailsData.totalLabel}
            billingOrderLines={billingDetailsData.billingOrderLines}
            isIndexed={isIndexed}
          />
          <CardEventsHistory billingId={billingId} />
        </div>
      </div>
    </>
  );
};

export default BillingOrders;
