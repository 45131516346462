import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

const SubscriptionEventsDetail = ({ onHide, isVisible, data }) => (
  <DialogBox
    id={`subscription_events_detail_dialog`}
    visible={isVisible}
    onHide={onHide}
    width={600}
    title={'Détails de l’événement'}
    confirmLabel={'Fermer'}
    onConfirm={onHide}
  >
    <div style={{ margin: '8px 0' }}>{data}</div>
  </DialogBox>
);

export default SubscriptionEventsDetail;
