import React from 'react';
import { getAllTypeOfContactList, getAllDepartmentList } from 'app/redux/slices/teams';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';

const ContactsFormField = ({ sageBase }) => (
  <FormFieldGroup className="p-mt-2">
    <FormFieldInputText name="firstName" label="Prénom" colSpan="6" />
    <FormFieldInputText name="lastName" label="Nom" colSpan="6" />
    <FormFieldInputText name="email" label="E-mail" />
    <FormFieldInputText name="phoneNumber" label="N° de téléphone (bureau)" />
    <FormFieldDropdown
      name="service"
      label="Service"
      placeholder="Sélectionner"
      loadOptions={{
        action: () => getAllDepartmentList({ sageBase, fromSage: false }),
        optionValue: 'id',
        optionLabel: 'value',
      }}
    />
    <FormFieldInputText wrapperClassName="autoHeight" name="function" label="Fonction" />
    <FormFieldDropdown
      name="type"
      label="Type"
      placeholder="Sélectionner"
      loadOptions={{
        action: () => getAllTypeOfContactList({ sageBase, fromSage: false }),
        optionValue: 'id',
        optionLabel: 'value',
      }}
    />
  </FormFieldGroup>
);

export default ContactsFormField;
