import React from 'react';
import { useSelector } from 'react-redux';
import {
  getClientsNewListPaginated,
  selectIsClientsDataSearchLoading,
} from 'app/redux/slices/teams';
import SearchInput from 'components/_shared/SearchInput/SearchInput';
import usePagination from 'app/hooks/usePagination';

const ClientsHeaderTable = () => {
  const isLoadingSearch = useSelector(selectIsClientsDataSearchLoading);
  const { onTermsChange, terms } = usePagination({
    dataFetchBy: getClientsNewListPaginated,
    skipInitialCall: true,
  });

  return (
    <div className="table-header">
      <SearchInput
        placeholder="Rechercher par nom du client ou n°client"
        isLoading={isLoadingSearch}
        onSearch={searchVal => onTermsChange('search', searchVal)}
        defaultValue={terms.search}
      />
    </div>
  );
};

export default ClientsHeaderTable;
