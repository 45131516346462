import React from 'react';
import { Field } from 'react-final-form';
import { CardDisplay } from 'olaqin-design-system';
import { InputSwitch as PrimeInputSwitch } from 'primereact/components/inputswitch/InputSwitch';
import { Divider } from 'primereact/divider';
import logo from './stellairLogo-32px.svg';

const CardConnections = () => (
  <CardDisplay title="Connexions" style={{ width: '100%' }}>
    <div className="p-field p-fluid p-p-0 p-mt-3">
      <p style={{ fontWeight: 700 }} className="p-mb-1">
        Services
      </p>
      <Divider />
      <div className="p-d-flex p-align-center">
        <Field
          name="enableStellairConnection"
          render={({ input }) => (
            <PrimeInputSwitch checked={input.value} onChange={input.onChange} />
          )}
        />
        <div className="p-d-flex">
          <img className="p-pl-3" alt="stellair" src={logo} />
          <div className="p-pl-2">
            <p style={{ fontWeight: 700 }} className="p-m-0">
              Stellair
            </p>
            <p style={{ fontWeight: 400, color: '#6A6A6A' }} className="p-m-0">
              Créer des organisations, gérer les souscriptions Stellair et mesurer l’usage des
              consommations depuis Niqola
            </p>
          </div>
        </div>
      </div>
      <Divider style={{ marginTop: '0.8rem' }} />
    </div>
  </CardDisplay>
);

export default CardConnections;
