import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CardDisplay, Button } from 'olaqin-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { chargerSouscription, selectSouscription } from 'app/redux/slices/souscriptions';
import ClientCreateDialogForm from 'components/clientsNew/dialogs/ClientCreateDialogForm';
import { getClientDetails } from 'app/redux/slices/teams';
import { subscriptionStatuses } from 'app/constants/subscription.constants';
import WarningLabel from 'components/_shared/WarningLabel/WarningLabel';
import { billingSystemTypeServer, sageBaseTypes } from 'app/constants/client.constants';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import {
  listerCategoriesComptablesBillingSystem,
  selectListeCategoriesComptablesBillingSystem,
} from 'app/redux/slices/utilisateurs';
import customStyle from './CardDetails.module.scss';

const CardDetails = ({ title, sageBase, style }) => {
  const dispatch = useDispatch();
  const souscription = useSelector(selectSouscription);
  const categoriesComptables = useSelector(selectListeCategoriesComptablesBillingSystem);
  const { souscripteur, identifiant, statut, warnings, thirdPartyPayer } = souscription;
  const warningFields = warnings?.fields || [];
  const {
    adresseFacturation,
    bankDetails,
    emailFacturation,
    paymentTermTemplate,
    accountingRef,
  } = souscripteur;

  const findCategoriesComptables = categoriesComptables?.find(
    ({ code }) => code === accountingRef?.categorieComptable,
  );

  const [isEditDialogOpen, openEditDialog] = useState(false);

  const actionOnSuccessEdit = () => {
    dispatch(chargerSouscription({ souscriptionId: identifiant }));
  };

  const handleClickEdit = () => {
    dispatch(getClientDetails({ id: souscripteur.identifiant }));
    openEditDialog(true);
  };

  useEffect(() => {
    if (sageBase) {
      dispatch(
        listerCategoriesComptablesBillingSystem({
          fromSage: false,
          sageBase: sageBaseTypes[sageBase],
        }),
      );
    }
  }, [sageBase]);

  const checkPresented = field => (field ? `${field} ` : '');

  const combinedAddress = address => {
    const { adresse, complement, codePostal, ville, region, pays } = address;
    const addressArray = [adresse, complement, codePostal, ville, region, pays];
    return addressArray.reduce((acc, curr) => {
      const checkedAddress = checkPresented(curr);
      return acc + checkedAddress;
    }, '');
  };

  const firstAddress = adresseFacturation ? combinedAddress(adresseFacturation) : '';

  const isFieldInWarnings = checkField => warningFields.some(fields => fields.value === checkField);
  const isFieldInWarningsStartWith = checkField =>
    warningFields.some(fields => fields.value.startsWith(checkField));

  return (
    <CardDisplay title={title} style={style}>
      <div className={`p-grid ${customStyle.listItemWrapper}`} style={{ marginTop: '16px' }}>
        <span className="p-md-3">Client :</span>
        <span className="p-md-9">{souscripteur.nom}</span>
        <span className="p-md-3" />
        <span className="p-md-9" style={{ color: 'grey', padding: '0 0.5rem 0.5rem 0.5rem' }}>
          {souscripteur.identifiant}
          <CopyToClipboard
            text={souscripteur.identifiant}
            style={{ margin: '0 0 0 8px', cursor: 'pointer' }}
          >
            <i className="pi pi-copy" />
          </CopyToClipboard>
        </span>
        <span className="p-md-3">Numéro client :</span>
        <span className="p-md-9">
          {souscripteur.numeroClient ? (
            <>
              {souscripteur.numeroClient}
              <CopyToClipboard
                text={souscripteur.numeroClient}
                style={{ margin: '0 0 0 8px', cursor: 'pointer' }}
              >
                <i className="pi pi-copy" />
              </CopyToClipboard>
            </>
          ) : (
            '–'
          )}
        </span>
        <span className="p-md-3">E-mail principal :</span>
        <span className="p-md-9">
          {isFieldInWarnings('email') ? <WarningLabel /> : souscripteur.email}
        </span>
        <span className="p-md-3">Adresse de facturation :</span>
        <span className="p-md-9">
          {firstAddress || '–'}
          {isFieldInWarningsStartWith('adresseFacturation.') && <WarningLabel />}
        </span>
        <span className="p-md-3">Email facture démat. :</span>
        <span className="p-md-9">
          {isFieldInWarnings('emailFacturation') ? <WarningLabel /> : emailFacturation || '–'}
        </span>
        {!thirdPartyPayer && (
          <>
            <span className="p-md-3">Conditions de paiement :</span>
            <span className="p-md-9">
              {isFieldInWarnings('paymentTermTemplate') ? (
                <WarningLabel />
              ) : (
                paymentTermTemplate?.label || '–'
              )}
            </span>
            <span className="p-md-3">Coord. bancaires :</span>
            {isFieldInWarnings('bankDetails.bankName') ||
            isFieldInWarnings('bankDetails.iban') ||
            isFieldInWarnings('bankDetails.bic') ? (
              <>
                <span className="p-md-9">
                  <WarningLabel />
                </span>
              </>
            ) : (
              <span className="p-md-9">
                <div>{dashOnEmpty(bankDetails?.bankName)}</div>
                <div>{dashOnEmpty(bankDetails?.iban)}</div>
                <div>{dashOnEmpty(bankDetails?.bic)}</div>
              </span>
            )}
          </>
        )}

        <span className="p-md-3">Système de facturation :</span>
        <span className="p-md-9">
          {sageBase === billingSystemTypeServer.OLAQIN_HARDWARE && 'SAGE HARDWARE'}
          {sageBase === billingSystemTypeServer.OLAQIN_SERVICE && 'SAGE SERVICE'}
          {sageBase === billingSystemTypeServer.AFFID && 'SAGE AFFID'}
        </span>
        <span className="p-md-3">Compte collectif :</span>
        <span className="p-md-9">
          {isFieldInWarnings('accountingRef.compteCollectif') ? (
            <WarningLabel />
          ) : (
            dashOnEmpty(accountingRef?.compteCollectif)
          )}
        </span>
        <span className="p-md-3">Catégorie comptable :</span>
        <span className="p-md-9">
          {isFieldInWarnings('accountingRef.categorieComptable') ? (
            <WarningLabel />
          ) : (
            dashOnEmpty(findCategoriesComptables?.designation || accountingRef?.categorieComptable)
          )}
        </span>
      </div>
      {statut !== subscriptionStatuses.CANCEL && (
        <div className="p-ml-2 p-mt-3">
          <Button
            icon="pi pi-pencil"
            type="secondary"
            btnType="secondary"
            onClick={handleClickEdit}
            className={customStyle.customLinkButton}
            label="Mettre à jour les informations du client"
          />
        </div>
      )}
      {isEditDialogOpen && (
        <ClientCreateDialogForm
          isEdit
          visible={isEditDialogOpen}
          onHide={() => openEditDialog(false)}
          actionOnSuccessEdit={actionOnSuccessEdit}
        />
      )}
    </CardDisplay>
  );
};

export default CardDetails;
