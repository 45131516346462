export const subscriptionStatusesServer = {
  INCOMPLETE: 'INCOMPLETE',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  ANOMALIE: 'ANOMALIE',
  TERMINATED: 'TERMINATED',
  // FE Stellair
  PENDING: 'PENDING',
};

export const renewalOfferValueServer = {
  AUCUNE: 'AUCUNE',
};

export const subscriptionStatuses = {
  [subscriptionStatusesServer.INCOMPLETE]: 'Incomplète',
  [subscriptionStatusesServer.ACTIVE]: 'Active',
  [subscriptionStatusesServer.CLOSED]: 'Résiliée',
  [subscriptionStatusesServer.ANOMALIE]: 'Anomalie',
};

export const subscriptionStatusesDropDownItems = () =>
  Object.entries(subscriptionStatuses).map(i => ({
    value: i[0],
    label: i[1],
  }));

export const SubscriptionStatusLabelModel = {
  TRANSMIS: 'Transmis',
  STELLAIR_TRANSMIS: 'Clôturé',
  A_VENIR: 'À venir',
  ANOMALIE: 'Anomalie',
  SUSPENDU: 'Suspendu',
  NON_TRANSMIS: 'Non Transmis',
  ANNULE: 'Annulé',
  CLOSED: 'Résiliation le',

  // FE Stellair
  PENDING: 'Opération en cours',
};

export const SubscriptionStatusColorModel = {
  Incomplete: '#DB9774',
  Active: '#7092DB',
  Anomalie: '#D77F83',
  Résiliée: '#6A6A6A',
  CLOSED: '#D8D8D8',

  // biliing
  TRANSMIS: '#6DBAAA',
  A_VENIR: '#7092DB',
  ANOMALIE: '#D77F83',
  SUSPENDU: '#D77F83',
  NON_TRANSMIS: '#6A6A6A',
  ANNULE: '#6A6A6A',

  // FE Stellair
  PENDING: '#7092DB',
  [SubscriptionStatusLabelModel.STELLAIR_TRANSMIS]: '#6DBAAA',
};

export const StellairStatusFormat = status =>
  status === 'TRANSMIS' ? SubscriptionStatusLabelModel.STELLAIR_TRANSMIS : status;
