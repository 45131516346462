import React from 'react';
import { billingSystemTypeServer, qualityOptions } from 'app/constants/client.constants';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import FormFieldChipsInput from 'components/_shared/form/FormFieldChipsInput';
import { checkIfEmailIsInvalidInTheList } from 'app/utils/validationUtils';

import './clientCreateForm.scss';

const ContactDetails = ({ billingSystemValue }) => (
  <FormFieldGroup title="Coordonnées">
    <FormFieldInputText
      name="adresseFacturation.adresse"
      label="Adresse de facturation"
      placeholder="Ligne adresse 1"
    />
    <FormFieldInputText name="adresseFacturation.complement" placeholder="Ligne adresse 2" />
    <FormFieldInputText
      name="adresseFacturation.codePostal"
      placeholder="Code postal"
      colSpan="4"
    />
    <FormFieldInputText name="adresseFacturation.ville" placeholder="Ville" colSpan="8" />
    <FormFieldInputText name="adresseFacturation.pays" placeholder="Pays" />
    <FormFieldChipsInput
      name="emailFacturation"
      label="E-mail factures démat"
      customInputTemplate={(inputValue, item) => {
        const isItemInvalid = checkIfEmailIsInvalidInTheList(inputValue, item);
        return (
          <div className={isItemInvalid ? 'p-invalid' : ''}>
            <span>{item}</span>
          </div>
        );
      }}
    />
    <FormFieldInputText name="interlocuteur" label="Interlocuteur" />
    {billingSystemValue === billingSystemTypeServer.AFFID && (
      <FormFieldDropdown
        name="quality"
        label="Qualité"
        placeholder="Sélectionner"
        filter
        options={qualityOptions}
        showClearButton
      />
    )}
    <FormFieldInputText name="phoneNumber" label="N° de téléphone (bureau)" />
    {billingSystemValue === billingSystemTypeServer.AFFID && (
      <FormFieldInputText name="faxNumber" label="N° de téléphone (portable)" />
    )}
  </FormFieldGroup>
);

export default ContactDetails;
