import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

import { toast } from 'react-toastify';
import { ExportReportsOptions } from 'app/constants/reports.contants';
import { useDispatch } from 'react-redux';

const GenerateExportConfirmModal = ({ visible, onHide, onSucess, reportSource, downloadKey }) => {
  const dispatch = useDispatch();
  const onSubmit = async () => {
    onHide();
    await dispatch(
      ExportReportsOptions.find(item => item.value === reportSource).action(downloadKey),
    )
      .unwrap()
      .then(() => {
        toast(
          'La génération de votre export est en cours. Veuillez consulter cette page ultérieurement pour accéder au téléchargement.',
          {
            type: 'dark',
            style: {
              background: '#6A6A6A',
            },
          },
        );
        if (onSucess) onSucess();
      })
      .catch(() => {
        toast.error(
          'Impossible de générer l’export : un export similaire est déjà en cours de génération.',
        );
      });
  };
  return (
    <DialogBox
      id="ReloadConfirmationModal"
      visible={visible}
      onHide={onHide}
      width={500}
      title={`Génération de l’export des ${ExportReportsOptions.find(
        item => item.value === reportSource,
      )?.label ?? ''}`}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={onSubmit}
      confirmLabel="Démarrer l’export"
      noSubmitTypeOnButton
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 8 }}>
        L'exportation d’un grand nombre de données peut prendre plusieurs minutes. Vous pouvez
        démarrer le processus maintenant et revenir ultérieurement pour télécharger l’export
        lorsqu'il sera prêt. Souhaitez-vous démarrer l’export?
      </div>
    </DialogBox>
  );
};

export default GenerateExportConfirmModal;
