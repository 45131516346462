import React, { forwardRef } from 'react';
import { InputText as PrimeInputText } from 'primereact/inputtext';
import { useDebouncedChange } from 'app/hooks/useDebouncedChange';

const SearchInput = forwardRef(
  ({ isLoading, onSearch, placeholder = '', hideSearchIcon = false, ...rest }, ref) => {
    const { handleKeyPress, handleOnChange } = useDebouncedChange(onSearch, isLoading);
    return (
      <span
        className={`${!hideSearchIcon ? 'p-input-icon-left' : ''} p-fluid ${
          isLoading ? 'p-input-icon-right' : ''
        }`}
      >
        {!hideSearchIcon && <i className="pi pi-search" />}

        <PrimeInputText
          ref={e => {
            // eslint-disable-next-line no-param-reassign
            if (ref) ref.current = e;
          }}
          style={{ width: '420px' }}
          type="text"
          placeholder={placeholder}
          maxLength={100}
          onKeyPress={handleKeyPress}
          onInput={handleOnChange}
          {...rest}
        />
        {isLoading && <i className="pi pi-spin pi-spinner" />}
      </span>
    );
  },
);

export default SearchInput;
