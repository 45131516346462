import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewSyntecIndex,
  selectIsSyntecIndexLoading,
  toggleIndexDialog,
  selectIsSyntecIndexModalOpen,
  updateSyntecIndex,
} from 'app/redux/slices/syntecIndex';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import MonthlyIndexFields from '../forms/MonthlyIndexFields';
import YearlyIndexFields from '../forms/YearlyIndexFields';
import { formYearlyIndexValidations, formMontlyIndexValidations } from '../syntecIndexValidations';
import { changeCommaWithDot, changeDotWithComma } from '../helpers';

const CreateEditSyntcIndexDialog = ({ status, statusLabel, onSuccess, editData, clearEdit }) => {
  const dispatch = useDispatch();
  const isEditing = editData !== null;
  const isCreationVisible = useSelector(selectIsSyntecIndexModalOpen);
  const isVisible = isCreationVisible || isEditing;
  const isLoading = useSelector(selectIsSyntecIndexLoading);

  const onHide = formReset => {
    dispatch(toggleIndexDialog(false));
    clearEdit();
    if (formReset) formReset();
  };

  const handleSubmit = async (values, form) => {
    const dataValue = {
      ...values,
      value: changeCommaWithDot(values.value),
      type: statusLabel,
    };

    const targetRequestAction = isEditing
      ? updateSyntecIndex({ indexId: dataValue.id, syntecIndexForm: dataValue })
      : createNewSyntecIndex(dataValue);

    await dispatch(targetRequestAction)
      .unwrap()
      .then(() => {
        if (isEditing) {
          toast('Les modifications ont bien été enregistrées.', {
            type: 'dark',
            style: {
              background: '#2F7D7C',
            },
          });
        } else {
          toast.success('L’indice a bien été ajouté.');
        }
        onHide(form.reset);
        onSuccess();
      })
      .catch(() => {});
  };

  const dialogId = `create${statusLabel}Index`;
  const formId = `${dialogId}_form`;
  const dialogTitle = isEditing
    ? 'Modification de l’indice'
    : `Création d’un nouvel indice Syntec ${status === 1 ? 'annuel' : 'mensuel'}`;

  const confirmButtonText = isEditing ? 'Entregistrer les modifications' : 'Ajouter l’indice';
  const initialValues = editData ? { ...editData, value: changeDotWithComma(editData.value) } : {};

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={status === 0 ? formMontlyIndexValidations : formYearlyIndexValidations}
      render={form => (
        <FormEl id={formId} onSubmit={form.handleSubmit} isVisible={isVisible}>
          <DialogBox
            id={`${dialogId}_Dialog`}
            visible={isVisible}
            onHide={() => onHide(form.form.reset)}
            width={600}
            title={dialogTitle}
            isLoading={isLoading}
            onCancel={() => onHide(form.form.reset)}
            cancelLabel="Annuler"
            confirmLabel={confirmButtonText}
          >
            {status === 0 ? <MonthlyIndexFields /> : <YearlyIndexFields />}
          </DialogBox>
        </FormEl>
      )}
    />
  );
};

export default CreateEditSyntcIndexDialog;

const FormEl = ({ isVisible, children, ...rest }) => {
  if (isVisible) return <form {...rest}>{children}</form>;

  return <div>{children}</div>;
};
