import React from 'react';
import { Button } from 'olaqin-design-system';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { Divider } from 'primereact/divider';
import { useSelector } from 'react-redux';
import { selectSaveOfferAsDraftIsLoading } from 'app/redux/slices/offres';
import customStyle from '../../souscriptions/createSubscription/createSubscription.module.scss';

const CreateOfferFormHeader = ({
  isEdit = false,
  isSubmitDisabled,
  isLoading,
  handleClose,
  handleClickSaveDraft,
  offerName = '',
  handleShowDeleteOffer,
}) => {
  const isLoadingSaveOfferAsDraft = useSelector(selectSaveOfferAsDraftIsLoading);
  const textData = isEdit
    ? {
        title: `Création de l’offre ${offerName}`,
        buttonSubmitTitle: "Créer l'offre et soumettre à validation",
        buttonSaveQuitTitle: 'Enregistrer en brouillon et quitter',
      }
    : {
        title: 'Nouvelle offre',
        buttonSubmitTitle: "Créer l'offre et soumettre à validation",
        buttonSaveQuitTitle: 'Enregistrer en brouillon et quitter',
      };

  return (
    <div className={customStyle.formHeader}>
      <div className="p-d-flex p-align-center">
        <Button
          style={{
            border: 'none',
            width: '16px',
            height: '16px',
            padding: '16px',
          }}
          className="p-mx-1"
          btnType="secondary"
          type="button"
          label=""
          icon="pi pi-times"
          onClick={handleClose}
        />
        <Divider className="p-py-1" layout="vertical" />
        <p
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '24px',
            color: '#000000',
          }}
        >
          {textData.title}
        </p>
      </div>
      <div>
        {isEdit && (
          <SubmitButton
            type="button"
            btnType="secondary"
            onClick={handleShowDeleteOffer}
            outlined
            small
            icon="pi pi-trash"
          />
        )}
        <SubmitButton
          className="p-mx-2"
          type="button"
          btnType="secondary"
          label={textData.buttonSaveQuitTitle}
          onClick={handleClickSaveDraft}
          isLoading={isLoadingSaveOfferAsDraft}
          outlined
          small
        />
        <SubmitButton
          btnType="primary"
          type="submit"
          label={textData.buttonSubmitTitle}
          isLoading={isLoading}
          disabled={isSubmitDisabled}
          small
        />
      </div>
    </div>
  );
};

export default CreateOfferFormHeader;
