import axios from 'axios';
import msalInstance from 'app/sso';
import { loginRequest } from '../../msal';
import { setUserName } from '../localStorage';

export const Axios = axios.create({
  baseURL: '/',
  timeout: 29_000,
});

Axios.interceptors.request.use(async axiosConfig => {
  const config = { ...axiosConfig };

  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.',
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });

  config.headers.Authorization = `Bearer ${response.accessToken}`;

  config.params = { username: response.account.username };
  setUserName(response.account.name);
  return config;
});

export default Axios;
