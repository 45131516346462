import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CardDisplay, Button } from 'olaqin-design-system';
import { useSelector, useDispatch } from 'react-redux';
import { selectSouscription, chargerSouscription } from 'app/redux/slices/souscriptions';
import { sageBaseTypes } from 'app/constants/client.constants';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import WarningLabel from 'components/_shared/WarningLabel/WarningLabel';
import { selectListeCategoriesComptablesBillingSystem } from 'app/redux/slices/utilisateurs';
import { subscriptionStatuses } from 'app/constants/subscription.constants';
import { getClientDetails } from 'app/redux/slices/teams';
import ClientCreateDialogForm from 'components/clientsNew/dialogs/ClientCreateDialogForm';
import styles from './CardDetails.module.scss';

const CardDetailsOfThirdPartyPayer = ({ title }) => {
  const dispatch = useDispatch();
  const [isEditDialogOpen, openEditDialog] = useState(false);

  const souscription = useSelector(selectSouscription);
  const { statut, warnings, thirdPartyPayerClient, identifiant } = souscription;
  const warningFields = warnings?.fields || [];
  const categoriesComptables = useSelector(selectListeCategoriesComptablesBillingSystem);

  const isFieldInWarnings = checkField =>
    warningFields.some(fields => fields.value === `thirdPartyPayer.${checkField}`);
  const isFieldInWarningsStartWith = checkField =>
    warningFields.some(fields => fields.value.startsWith(`thirdPartyPayer.${checkField}`));

  const findCategoriesComptables = categoriesComptables?.find(
    ({ code }) => code === thirdPartyPayerClient.accountingRef?.categorieComptable,
  );

  const handleClickEdit = () => {
    dispatch(getClientDetails({ id: thirdPartyPayerClient.identifiant }));
    openEditDialog(true);
  };

  const actionOnSuccessEdit = () => {
    dispatch(chargerSouscription({ souscriptionId: identifiant }));
  };

  const isEditButtonVisible =
    identifiant !== thirdPartyPayerClient.identifiant && statut !== subscriptionStatuses.CANCEL;

  return (
    <CardDisplay title={title} style={{ width: 'auto' }}>
      <div className={`p-grid ${styles.listItemWrapper}`} style={{ marginTop: '16px' }}>
        <span className="p-md-3">Tiers :</span>
        <span className="p-md-9">
          <span style={{ color: '#3688FF' }}>{thirdPartyPayerClient.nom}</span>
          <br />
          <span style={{ color: 'grey' }}>
            {thirdPartyPayerClient.identifiant}
            <CopyToClipboard
              text={thirdPartyPayerClient.identifiant}
              style={{ margin: '0 0 0 8px', cursor: 'pointer' }}
            >
              <i className="pi pi-copy" />
            </CopyToClipboard>
          </span>
        </span>
        <span className="p-md-3">Numéro client :</span>
        <span className="p-md-9">
          {thirdPartyPayerClient.numeroClient}
          <CopyToClipboard
            text={thirdPartyPayerClient.numeroClient}
            style={{ margin: '0 0 0 8px', cursor: 'pointer' }}
          >
            <i className="pi pi-copy" />
          </CopyToClipboard>
        </span>
        <span className="p-md-3">E-mail principal :</span>
        <span className="p-md-9">
          {isFieldInWarnings('email') ? <WarningLabel /> : dashOnEmpty(thirdPartyPayerClient.email)}
        </span>
        <span className="p-md-3">Adresse de facturation :</span>
        <span className="p-md-9">
          {Object.values(thirdPartyPayerClient.adresseFacturation ?? '-').join(' ')}
          {isFieldInWarningsStartWith('adresseFacturation.') && <WarningLabel />}
        </span>
        <span className="p-md-3">Email factures demat. :</span>
        <span className="p-md-9">
          {isFieldInWarnings('emailFacturation') ? (
            <WarningLabel />
          ) : (
            dashOnEmpty(thirdPartyPayerClient.emailFacturation)
          )}
        </span>
        <span className="p-md-3">Coordonnées bancaires :</span>
        <span className="p-md-9">
          {isFieldInWarningsStartWith('bankDetails.') ? (
            <WarningLabel />
          ) : (
            <>
              <div>{dashOnEmpty(thirdPartyPayerClient.bankDetails?.bankName)}</div>
              <div>{dashOnEmpty(thirdPartyPayerClient.bankDetails?.iban)}</div>
              <div>{dashOnEmpty(thirdPartyPayerClient.bankDetails?.bic)}</div>
            </>
          )}
        </span>

        <span className="p-md-3">Conditions de paiement :</span>
        <span className="p-md-9">
          {isFieldInWarnings('paymentTermTemplate') ? (
            <WarningLabel />
          ) : (
            dashOnEmpty(thirdPartyPayerClient.paymentTermTemplate?.label)
          )}
        </span>
        <span className="p-md-3">Système de facturation :</span>
        <span className="p-md-9">SAGE {sageBaseTypes[thirdPartyPayerClient.sageBase]}</span>
        <span className="p-md-3">Compte collectif :</span>
        <span className="p-md-9">
          {isFieldInWarnings('accountingRef.compteCollectif') ? (
            <WarningLabel />
          ) : (
            dashOnEmpty(thirdPartyPayerClient.accountingRef?.compteCollectif)
          )}
        </span>
        <span className="p-md-3">Catégorie comptable :</span>
        <span className="p-md-9">
          {isFieldInWarnings('accountingRef.categorieComptable') ? (
            <WarningLabel />
          ) : (
            dashOnEmpty(
              findCategoriesComptables?.designation ||
                thirdPartyPayerClient.accountingRef?.categorieComptable,
            )
          )}
        </span>
      </div>
      {isEditButtonVisible && (
        <div className="p-ml-2 p-mt-3">
          <Button
            icon="pi pi-pencil"
            type="secondary"
            btnType="secondary"
            onClick={handleClickEdit}
            className={styles.customLinkButton}
            label="Mettre à jour les informations du client"
          />
        </div>
      )}
      {isEditDialogOpen && (
        <ClientCreateDialogForm
          isEdit
          visible={isEditDialogOpen}
          onHide={() => openEditDialog(false)}
          actionOnSuccessEdit={actionOnSuccessEdit}
        />
      )}
    </CardDisplay>
  );
};

export default CardDetailsOfThirdPartyPayer;
