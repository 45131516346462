import { Validators } from 'olaqin-design-system';
import { maxLengthValidator } from 'app/utils/formFieldUtils';

const formDeliveryAddressSchema = {
  name: [
    Validators.required('Veuillez saisir l’intitulé de l’adresse.'),
    maxLengthValidator(69, 'L’intitulé de l’adresse ne peut excéder 69 caractères'),
  ],
  adresse: [
    Validators.required('Veuillez saisir une adresse.'),
    maxLengthValidator(35, 'L’adresse ne peut excéder 35 caractères.'),
  ],
  complement: [maxLengthValidator(35, 'Le complément d’adresse ne peut excéder 35 caractères.')],
  code_postal: [
    Validators.required('Veuillez saisir un code postal.'),
    value => Validators.integer(value) && 'Le code postal est invalide.',
    maxLengthValidator(9, 'Le code postal est invalide.'),
  ],
  ville: [
    Validators.required('Veuillez saisir un nom de ville.'),
    maxLengthValidator(35, 'Le nom de la ville ne peut excéder 35 caractères.'),
  ],
  code_region: [maxLengthValidator(25, 'Le nom de la région ne peut excéder 25 caractères.')],
  pays: [
    Validators.required('Veuillez sélectionner un pays.'),
    maxLengthValidator(35, 'Le nom du pays ne peut excéder 35 caractères.'),
  ],
  expedition: [Validators.required('Veuillez sélectionner l’expédition.')],
  condition: [Validators.required('Veuillez sélectionner une condition.')],
  contact: [maxLengthValidator(35, 'Le nom du contact ne peut excéder 35 caractères.')],
  telephone: [
    maxLengthValidator(21, 'Le numéro de téléphone ne peut excéder 21 chiffres.'),
    value => Validators.integerNotRequired(value) && 'Le numéro de téléphone est invalide.',
  ],
  email: [
    maxLengthValidator(69, 'L’adresse e-mail ne peut excéder 69 caractères.'),
    value => Validators.email(value) && 'L’adresse e-mail est invalide.',
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const formDeliveryAddressValidation = values =>
  Validators.createValidator(formDeliveryAddressSchema)(values);
