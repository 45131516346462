import React from 'react';
import { useDispatch } from 'react-redux';
import { configureAnOfferStellair } from 'app/redux/slices/offres';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';
import { Portal } from 'react-portal';
import { Form } from 'react-final-form';
import { formStellairOfferValidation } from './formStellairOfferValidation';
import StellairOfferDialogFields from './StellairOfferDialogFields';

const StellairOfferDialog = ({
  visible,
  onHide,
  offreCommercialeId,
  actionOnSuccessConnect,
  connectData,
}) => {
  const dispatch = useDispatch();

  const isEdit = !!connectData;

  const handleClickConfirm = async formData => {
    await dispatch(configureAnOfferStellair({ offreCommercialeId, formData }))
      .unwrap()
      .then(() => {
        onHide();
        toast('La configuration a bien été enregistrée.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });

        actionOnSuccessConnect();
      })
      .catch(() => {});
  };

  return (
    <Portal>
      <Form
        initialValues={isEdit ? connectData : {}}
        validate={formStellairOfferValidation}
        onSubmit={handleClickConfirm}
        render={({ handleSubmit, submitting }) => (
          <form
            className="p-fluid p-formgrid p-grid"
            id="contacts-create-dialog"
            onSubmit={handleSubmit}
          >
            <DialogBox
              id="connectStellaiDialog"
              spacingField="8px"
              spacingLabel="0"
              visible={visible}
              onHide={onHide}
              isLoading={submitting}
              title="Configuration de la connexion avec une offre Stellair"
              style={{
                maxWidth: '630px',
                overflow: 'auto',
              }}
              className="p-col-12 p-sm-12 p-md-12 p-lg-8"
              confirmLabel="Enregistrer les paramètres"
              onConfirm={handleSubmit}
              cancelLabel="Annuler"
              onCancel={onHide}
            >
              <StellairOfferDialogFields />
            </DialogBox>
          </form>
        )}
      />
    </Portal>
  );
};

export default StellairOfferDialog;
