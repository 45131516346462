import { Validators } from 'olaqin-design-system';
import { thirdPartyPayerOptions } from 'app/constants/client.constants';
import { maxLengthValidator, alphaNumeriqueWithSpace } from 'app/utils/formFieldUtils';
import { validateBic, validateIban, validateMultipleEmailsString } from 'app/utils/validationUtils';

const addressScheme = {
  adresse: [
    Validators.required('Veuillez renseigner l’adresse du client.'),
    maxLengthValidator(35, 'L’adresse ne peut excéder 35 caractères.'),
  ],
  complement: [maxLengthValidator(35, 'L’adresse ne peut excéder 35 caractères.')],
  codePostal: [
    Validators.required('Le code postal est requis.'),
    maxLengthValidator(9, 'Le code postal est invalide.'),
  ],
  ville: [
    Validators.required('Le nom de la ville est requis.'),
    maxLengthValidator(35, 'Le nom de la ville ne peut excéder 35 caractères.'),
  ],
  pays: [maxLengthValidator(35, 'Le nom du pays ne peut excéder 35 caractères.')],
};

const addressSchemeSage = {
  adresse: [
    Validators.required('L’adresse postale de facturation est incomplète.'),
    maxLengthValidator(35, 'L’adresse ne peut excéder 35 caractères.'),
  ],
  complement: [maxLengthValidator(35, 'L’adresse ne peut excéder 35 caractères.')],
  codePostal: [
    Validators.required('Incomplète.'),
    maxLengthValidator(9, 'Le code postal est invalide.'),
  ],
  ville: [
    Validators.required('L’adresse postale de facturation est incomplète.'),
    maxLengthValidator(35, 'Le nom de la ville ne peut excéder 35 caractères.'),
  ],
  pays: [
    Validators.required('L’adresse postale de facturation est incomplète.'),
    maxLengthValidator(35, 'Le nom du pays ne peut excéder 35 caractères.'),
  ],
};

const accountingRefScheme = {
  compteCollectif: [Validators.required('Veuillez sélectionner le compte collectif.')],
};

const accountingRefSchemeSage = {
  compteCollectif: [Validators.required('Veuillez sélectionner le compte collectif.')],
  categorieComptable: [Validators.required('Veuillez sélectionner la catégorie comptable.')],
};

const bankDetailsScheme = {
  bankName: [maxLengthValidator(35, 'Le nom de la banque ne peut excéder 35 caractères.')],
  iban: [validateIban(true)],
  bic: [validateBic(true)],
};

const bankDetailsSchemeSage = {
  bankName: [
    Validators.required('Veuillez renseigner le pays de la banque.'),
    maxLengthValidator(35, 'Le nom de la banque ne peut excéder 35 caractères.'),
  ],
  iban: [validateIban(false)],
  bic: [validateBic(false)],
};

const validationSchemaForBillingSystem = {
  sageBase: [Validators.required('Veuillez sélectionner le système de facturation.')],
  nom: [
    Validators.required('Veuillez renseigner l’intitulé du client.'),
    maxLengthValidator(69, 'L’intitulé du client ne peut excéder 69 caractères.'),
    value =>
      alphaNumeriqueWithSpace(value, 'Client Title must only contain alphanumeric characters.'),
  ],
  email: [
    Validators.required('Veuillez renseigner l’adresse e-mail principale.'),
    value => Validators.email(value) && 'L’adresse e-mail principale est invalide.',
    maxLengthValidator(69, 'L’adresse e-mail principale ne peut pas dépasser 69 caractères.'),
  ],
  emailFacturation: [
    maxLengthValidator(
      69,
      'L’e-mail de partage des factures dématérialisées ne peut excéder 69 caractères.',
    ),
    validateMultipleEmailsString,
  ],
  interlocuteur: [
    Validators.required('Veuillez renseigner l’identité de l’interlocuteur principal.'),
    maxLengthValidator(35, 'Le nom de l’interlocuteur ne peut excéder 35 caractères.'),
  ],
  phoneNumber: [
    value => Validators.integerNotRequired(value) && 'Le numéro de téléphone est invalide.',
    maxLengthValidator(21, 'Le numéro de téléphone ne peut excéder 21 caractères.'),
  ],
  faxNumber: [
    value => Validators.integerNotRequired(value) && 'Le numéro de téléphone est invalide.',
    maxLengthValidator(21, 'Le numéro de téléphone ne peut excéder 21 caractères.'),
  ],
  siret: [
    Validators.maxLength(14),
    value => Validators.integerNotRequired(value) && 'Le n° de SIRET est invalide.',
  ],
  finess: [maxLengthValidator(9, 'Le n° FINESS est invalide.')],
  adeli: [
    maxLengthValidator(9, 'Le n° ADELI est invalide.'),
    value => Validators.integerNotRequired(value) && 'Le n° ADELI est invalide.',
  ],
  ape: [maxLengthValidator(7, 'Le code APE ne peut excéder 7 caractères.')],
  rpps: [
    maxLengthValidator(11, 'Le n° RRPS est invalide.'),
    value => Validators.integerNotRequired(value) && 'Le n° RRPS est invalide. ',
  ],

  secondReseller: [maxLengthValidator(69, 'Le nom de du revendeur ne peut excéder 69 caractères.')],
  patientRecord: [
    maxLengthValidator(69, 'Le nom du dossier patient ne peut excéder 69 caractères.'),
  ],
  targetCompletement: [maxLengthValidator(69, 'Le complément ne peut excéder 69 caractères.')],

  adresseFacturation: Validators.createValidator(addressScheme),
  accountingRef: Validators.createValidator(accountingRefScheme),
  bankDetails: Validators.createValidator(bankDetailsScheme),
};

const validationSchemaForBillingSystemSage = {
  sageBase: [Validators.required('Veuillez sélectionner le système de facturation.')],
  nom: [
    Validators.required('Veuillez renseigner l’intitulé du client.'),
    maxLengthValidator(69, 'L’intitulé du client ne peut excéder 69 caractères.'),
    value =>
      alphaNumeriqueWithSpace(value, 'Client Title must only contain alphanumeric characters.'),
  ],
  email: [
    Validators.required('Veuillez renseigner l’adresse e-mail principale.'),
    value => Validators.email(value) && 'L’adresse e-mail principale est invalide.',
    maxLengthValidator(69, 'L’adresse e-mail principale ne peut pas dépasser 69 caractères.'),
  ],
  emailFacturation: [
    Validators.required(
      'Veuillez renseigner l’adresse e-mail pour le partage des factures dématérialisées.',
    ),
    maxLengthValidator(
      69,
      'L’e-mail de partage des factures dématérialisées ne peut excéder 69 caractères.',
    ),
    validateMultipleEmailsString,
  ],
  interlocuteur: [
    Validators.required('Veuillez renseigner l’identité de l’interlocuteur principal.'),
    maxLengthValidator(35, 'Le nom de l’interlocuteur ne peut excéder 35 caractères.'),
  ],
  phoneNumber: [
    value => Validators.integerNotRequired(value) && 'Le numéro de téléphone est invalide.',
    maxLengthValidator(21, 'Le numéro de téléphone ne peut excéder 21 caractères.'),
  ],
  faxNumber: [
    value => Validators.integerNotRequired(value) && 'Le numéro de téléphone est invalide.',
    maxLengthValidator(21, 'Le numéro de téléphone ne peut excéder 21 caractères.'),
  ],
  siret: [
    Validators.maxLength(14),
    value => Validators.integerNotRequired(value) && 'Le n° de SIRET est invalide.',
  ],
  finess: [maxLengthValidator(9, 'Le n° FINESS est invalide.')],
  adeli: [
    maxLengthValidator(9, 'Le n° ADELI est invalide.'),
    value => Validators.integerNotRequired(value) && 'Le n° ADELI est invalide.',
  ],
  ape: [maxLengthValidator(7, 'Le code APE ne peut excéder 7 caractères.')],
  rpps: [
    maxLengthValidator(11, 'Le n° RRPS est invalide.'),
    value => Validators.integerNotRequired(value) && 'Le n° RRPS est invalide. ',
  ],

  secondReseller: [maxLengthValidator(69, 'Le nom de du revendeur ne peut excéder 69 caractères.')],
  patientRecord: [
    maxLengthValidator(69, 'Le nom du dossier patient ne peut excéder 69 caractères.'),
  ],
  targetCompletement: [maxLengthValidator(69, 'Le complément ne peut excéder 69 caractères.')],

  paymentTermTemplateId: [Validators.required('Veuillez sélectionner les conditions de paiement.')],

  adresseFacturation: Validators.createValidator(addressSchemeSage),

  bankDetails: Validators.createValidator(bankDetailsScheme),
  accountingRef: Validators.createValidator(accountingRefSchemeSage),
};

// eslint-disable-next-line import/prefer-default-export
export const formClientCreateValidation = (values, isBankDetailsRequired) => {
  // todo validation based on sageBase if it is HARDWARE, SERVICE or AFFID
  if (values.thirdPartyPayerOption === thirdPartyPayerOptions[1].value) {
    validationSchemaForBillingSystem.thirdPartyPayer = [
      Validators.required('Veuillez sélectionner le tiers payeur.'),
    ];
  } else {
    delete validationSchemaForBillingSystem.thirdPartyPayer;
  }

  // for edit client and with hasActiveSubscriptions
  if (values.identifiant && values.hasActiveSubscriptions) {
    // check and set bank rules
    if (isBankDetailsRequired) {
      validationSchemaForBillingSystemSage.bankDetails = Validators.createValidator(
        bankDetailsSchemeSage,
      );
      return Validators.createValidator(validationSchemaForBillingSystemSage)(values);
    }
    // set default bank validation
    validationSchemaForBillingSystemSage.bankDetails = Validators.createValidator(
      bankDetailsScheme,
    );
    return Validators.createValidator(validationSchemaForBillingSystemSage)(values);
  }
  return Validators.createValidator(validationSchemaForBillingSystem)(values);
};
