import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field, useField, useForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Button, Dialog } from 'olaqin-design-system';
import { FieldArray } from 'react-final-form-arrays';
import { SelectButton as PrimeSelectButton } from 'primereact/selectbutton';
import { Checkbox as PrimeCheckbox } from 'primereact/checkbox';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import CreateNewButton from 'components/_shared/CreateNewButton/CreateNewButton';

import {
  countingConditionsDateOperatorsOptions,
  countingConditionsTypeOptions,
  countingConditionsDateValuesOptions,
  prestationTypeOptions,
  quantityTypeOptions,
  quantityTypePonctOptions,
  tariffModelsOptions,
  tarificationsProgressive,
} from 'app/constants/offerConstants';
import {
  getListPrestations,
  selectIsLoadingListPrestations,
  selectListPrestations,
} from 'app/redux/slices/prestations';
import { getOfferUsageTypes, selectOfferUsageTypes } from 'app/redux/slices/offres';
import PrestationCreateDialog from 'components/prestationsNew/dialogs/PrestationCreateDialog';
import { Dropdown as PrimeDropdown } from 'primereact/components/dropdown/Dropdown';
import customCardStyle from 'components/souscriptions/subscriptionDetails/cards/CardDetails.module.scss';
import { InputNumber as PrimeInputNumber } from 'primereact/components/inputnumber/InputNumber';
import { toast } from 'react-toastify';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import AddDependentComposition from './AddDependentComposition';
import { formAddCompositionDialogValidation } from './formAddCompositionDialogValidation';
import CountingConditionsRow from './CountingConditionsRow';
import PaliersTarifRow from './PaliersTarifRow';
import customStyle from './CompositionDialog.module.scss';

const CompositionDialog = ({ visible, onHide, selectedCompositionItemIndex }) => {
  const dispatch = useDispatch();
  const listPrestations = useSelector(selectListPrestations);
  const isLoadingList = useSelector(selectIsLoadingListPrestations);
  const usageTypeData = useSelector(selectOfferUsageTypes);

  const offerForm = useForm();
  const { input: formOfferCompositionFieldInput } = useField('composition');

  const isSomeCompositionsAdded = !!formOfferCompositionFieldInput.value.length;
  const addedCompositions = formOfferCompositionFieldInput.value;
  const isEdit = selectedCompositionItemIndex !== null;

  useEffect(() => {
    dispatch(getListPrestations());
    dispatch(getOfferUsageTypes());
  }, []);

  const [isCreatePrestationDialogVisible, handleCreatePrestationDialog] = useState(false);

  const customHandleTypeChange = (e, handleChangeValue, quantityTypeValue, form) => {
    const isWillBePonctuelle = e.target?.value === prestationTypeOptions[0].value;

    const isUsageBasedOptionSelected = quantityTypeValue === quantityTypeOptions[1].value;

    // change option usageBased to default if selected PONCTUELLE
    if (isWillBePonctuelle && isUsageBasedOptionSelected) {
      form.change('composition.quantityType', quantityTypeOptions[0].value);
    }

    handleChangeValue(e);
  };

  const handleClickAddCountingConditions = fields => {
    fields.push({
      type: countingConditionsTypeOptions[0].value,
      condition: countingConditionsDateOperatorsOptions[0].value,
      value: countingConditionsDateValuesOptions[0].value,
    });
  };

  const handleClickAddPaliersTarifRow = fields => {
    const lastOneIndex = fields.length - 1 || 0;
    const lastOneTarifRow = fields.value[lastOneIndex];
    const preLastOneIndex = fields.length - 2 || 0;
    const preLastOneTarifRow = fields.value[preLastOneIndex];
    const { upToUnits: preUpToUnits } = preLastOneTarifRow;

    // insert doesn't work https://github.com/final-form/react-final-form-arrays/issues/138
    // so used push + update
    fields.push({
      units: (preUpToUnits || 0) + 3,
      upToUnits: '∞',
      tarif: lastOneTarifRow.tarif,
      fixedTarif: lastOneTarifRow.fixedTarif,
    });

    fields.update(lastOneIndex, {
      units: (preUpToUnits || 0) + 1,
      upToUnits: (preUpToUnits || 0) + 2,
      tarif: 0,
      fixedTarif: 0,
    });
  };

  const getProgressiveError = errors => {
    const findErrorMessage = errors.find(error => !!error?.upToUnits) || {};
    return findErrorMessage?.upToUnits || '';
  };

  const getInitialValue = useMemo(
    () => ({
      composition:
        selectedCompositionItemIndex !== null
          ? addedCompositions[selectedCompositionItemIndex]
          : {
              prestation: '',
              isOptional: false,
              type: prestationTypeOptions[0].value,
              quantityType: quantityTypeOptions[0].value,
              usageType: '',

              // array form for counting condition
              countingConditions: [],

              tarification: tariffModelsOptions[0].value,
              tariffProgressive: tarificationsProgressive,
              tariff: 0,

              isMultiplier: false,
              dependOnAnotherPrestation: '',
            },
    }),
    [selectedCompositionItemIndex, addedCompositions],
  );

  const onFormSubmit = values => {
    // add values to the main Offer form
    const formOfferValue = formOfferCompositionFieldInput.value;

    if (isEdit) {
      offerForm.mutators.update('composition', selectedCompositionItemIndex, values.composition);
      toast('La prestation a bien été modifiée', {
        type: 'dark',
        style: {
          background: '#2F7D7C',
        },
      });
    } else {
      formOfferCompositionFieldInput.onChange([...formOfferValue, values.composition]);
      toast.success('La prestation a bien été ajoutée');
    }

    onHide();
  };

  const isSubmitBtnDisabled = useCallback(isFormDirty => isEdit && !isFormDirty, [isEdit]);

  return (
    <Form
      initialValues={getInitialValue}
      onSubmit={onFormSubmit}
      validate={formAddCompositionDialogValidation}
      mutators={{ ...arrayMutators }}
      render={({ values: { composition }, handleSubmit, form, dirty }) => (
        <form id="add-composition-dialog-form" onSubmit={handleSubmit}>
          <Dialog
            id="compositionDialog"
            visible={visible}
            onHide={onHide}
            className="p-fluid p-formgrid p-grid p-col-12 p-sm-12 p-md-5 p-lg-5"
            style={{ width: '750px' }}
            contentStyle={{ maxHeight: 'calc(100vh - 25rem)' }}
            header={
              <p style={{ marginTop: '-16px' }}>
                {isEdit ? 'Modification de la prestation' : 'Ajout d’une prestation à l’offre'}
              </p>
            }
            footer={
              <div className="p-d-flex p-justify-end">
                <SubmitButton
                  type="button"
                  btnType="primary"
                  disabled={isSubmitBtnDisabled(dirty)}
                  label={isEdit ? 'Enregistrer les modifications' : 'Ajouter la prestation'}
                  onClick={handleSubmit}
                />
              </div>
            }
          >
            <div className="p-formgrid p-grid p-align-stretch vertical-container">
              <div className="p-field p-col-9 p-md-12">
                <h4 className="p-mt-0">Prestation</h4>
                <FormFieldAutoComplete
                  id="Prestation-id"
                  name="composition.prestation"
                  noWrapper
                  filter
                  filterBy="designation"
                  placeholder="Sélectionner"
                  noOptionsMessage={() => 'Aucun résultat.'}
                  options={listPrestations}
                  isDisabled={isLoadingList}
                  getOptionLabel={e => e.designation}
                  getOptionValue={e => e.identifiant}
                  footerPanel={
                    <CreateNewButton
                      style={{ textAlign: 'left' }}
                      label="Création d’une nouvelle prestation"
                      type="button"
                      onClick={() => {
                        handleCreatePrestationDialog(true);
                      }}
                    />
                  }
                />
                <div className="p-mt-3 p-d-flex p-align-center">
                  <Field
                    name="composition.isOptional"
                    type="checkbox"
                    render={({ input }) => (
                      <PrimeCheckbox checked={input.checked} onChange={input.onChange} />
                    )}
                  />
                  <p className="p-ml-2">Cette prestation est optionnelle</p>
                </div>
              </div>
              {isCreatePrestationDialogVisible && (
                <PrestationCreateDialog
                  visible={isCreatePrestationDialogVisible}
                  onHide={() => handleCreatePrestationDialog(false)}
                  prestationsNewList={listPrestations}
                />
              )}
              <div className="p-mb-5 p-field p-col-9 p-md-12">
                <h4 className="p-mb-1">Type</h4>
                <Field
                  name="composition.type"
                  render={({ input }) => (
                    <PrimeSelectButton
                      style={{ maxWidth: '16rem' }}
                      value={input.value}
                      options={prestationTypeOptions}
                      onChange={e =>
                        customHandleTypeChange(e, input.onChange, composition.quantityType, form)
                      }
                    />
                  )}
                />
              </div>
              <div className="p-mb-3 p-field p-col-9 p-md-12">
                <h4>Quantité</h4>
                <p style={{ fontWeight: 700 }} className="p-mb-1">
                  Modèle de quantité:
                </p>
                <Field
                  name="composition.quantityType"
                  render={({ input }) => (
                    <PrimeDropdown
                      value={input.value}
                      options={
                        composition.type === prestationTypeOptions[0].value
                          ? quantityTypePonctOptions
                          : quantityTypeOptions
                      }
                      onChange={input.onChange}
                      autoResize
                    />
                  )}
                />
              </div>
              {composition.quantityType === quantityTypeOptions[1].value && (
                <>
                  <div className="p-mb-3 p-field p-col-9 p-md-12">
                    <p style={{ fontWeight: 700 }} className="p-mb-1">
                      Données à mesurer
                    </p>
                    <FormFieldDropdown
                      noWrapper
                      name="composition.usageType"
                      placeholder="Sélectionner un type de donnée"
                      options={usageTypeData}
                      optionLabel="nom"
                      optionValue="identifiant"
                      autoResize
                    />
                  </div>
                  <div className="p-mb-3 p-field p-col-9 p-md-12">
                    <FieldArray
                      name="composition.countingConditions"
                      render={({ fields }) => (
                        <>
                          <p style={{ fontWeight: 700 }} className="p-mb-3">
                            Condition de facturation de l’usage
                          </p>
                          {fields.map((name, rowIndex) => (
                            <CountingConditionsRow
                              key={rowIndex}
                              name={name}
                              index={rowIndex}
                              handleClickRemove={fields.remove}
                            />
                          ))}
                          {fields.length < 10 && (
                            <Button
                              icon="pi pi-plus"
                              type="button"
                              btnType="secondary"
                              onClick={() => handleClickAddCountingConditions(fields)}
                              className={customCardStyle.customLinkButton}
                              label="Ajouter une condition"
                            />
                          )}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
              <div className="p-mb-3 p-field p-col-9 p-md-12">
                <h4>Tarification</h4>
                <p style={{ fontWeight: 700 }} className="p-mb-1">
                  Modèle tarifaire:
                </p>
                <Field
                  name="composition.tarification"
                  render={({ input }) => (
                    <PrimeDropdown
                      value={input.value}
                      options={tariffModelsOptions}
                      onChange={input.onChange}
                      appendTo={document.body}
                      autoResize
                    />
                  )}
                />
                {composition.tarification !== tariffModelsOptions[0].value && (
                  <>
                    <p style={{ fontWeight: 700 }} className="p-mb-1">
                      Paliers tarifaires
                    </p>
                    <div className={customStyle.progressiveTableHeader}>
                      <div className={customStyle.progressiveTableHeaderCell}>
                        <p style={{ fontWeight: 700 }}>Première unité</p>
                      </div>
                      <div className={customStyle.progressiveTableHeaderCell}>
                        <p style={{ fontWeight: 700 }}>Dernière unité</p>
                      </div>
                      <div className={customStyle.progressiveTableHeaderCell}>
                        <p style={{ fontWeight: 700 }}>Tarif / unité</p>
                      </div>
                      <div
                        style={{ borderRight: '1px solid #D8D8D8' }}
                        className={customStyle.progressiveTableHeaderCell}
                      >
                        <p style={{ fontWeight: 700 }}>Tarif fixe</p>
                      </div>
                      <div className="p-pr-6" />
                    </div>
                    <FieldArray
                      name="composition.tariffProgressive"
                      render={({ fields, meta: { error } }) => (
                        <>
                          {fields.map((name, rowIndex) => (
                            <PaliersTarifRow
                              key={rowIndex}
                              name={name}
                              rowIndex={rowIndex}
                              handleClickRemove={fields.remove}
                              handleUpdate={fields.update}
                            />
                          ))}
                          {!!error && !!getProgressiveError(error) && (
                            <small className="p-error p-d-block">
                              {getProgressiveError(error)}
                            </small>
                          )}
                          <div className="p-mt-2">
                            <Button
                              icon="pi pi-plus"
                              type="button"
                              btnType="secondary"
                              onClick={() => handleClickAddPaliersTarifRow(fields)}
                              className={customCardStyle.customLinkButton}
                              label="Ajouter un palier"
                            />
                          </div>
                        </>
                      )}
                    />
                    <AddDependentComposition
                      isDisabled={!isSomeCompositionsAdded}
                      options={addedCompositions}
                    />
                  </>
                )}
                {composition.tarification === tariffModelsOptions[0].value && (
                  <>
                    <p style={{ fontWeight: 700 }} className="p-mb-1">
                      Tarif unitaire HT
                    </p>
                    <Field
                      name="composition.tariff"
                      render={({ input }) => (
                        <PrimeInputNumber
                          style={{ color: '#495057' }}
                          mode="currency"
                          currency="EUR"
                          locale="fr-FR"
                          maxFractionDigits={3}
                          value={input.value}
                          onValueChange={input.onChange}
                          onBlur={input.onBlur}
                        />
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </Dialog>
        </form>
      )}
    />
  );
};

export default CompositionDialog;
