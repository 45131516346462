import React from 'react';
import { Field, useField } from 'react-final-form';
import { InputSwitch as PrimeInputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown as PrimeDropdown } from 'primereact/components/dropdown/Dropdown';

const AddDependentComposition = ({ isDisabled, options }) => {
  const { input: formOfferIsMultiplierFieldInput } = useField('composition.isMultiplier');

  const isEnabled = !!formOfferIsMultiplierFieldInput.value;

  return (
    <>
      <div className="p-mt-3 p-d-flex p-align-center">
        <Field
          name="composition.isMultiplier"
          render={({ input }) =>
            !isDisabled ? (
              <PrimeInputSwitch checked={input.value} onChange={input.onChange} />
            ) : (
              <>
                {/* todo bug https://github.com/primefaces/primereact/issues/1811 */}
                <Tooltip target=".disabled-button" position="bottom" />
                <span
                  className="disabled-button"
                  data-pr-tooltip={
                    "Cette option est indisponible car aucune autre prestation n’a été ajoutée à l'offre"
                  }
                  data-pr-position="right"
                >
                  <PrimeInputSwitch
                    disabled={isDisabled}
                    checked={input.value}
                    onChange={input.onChange}
                  />
                </span>
              </>
            )
          }
        />
        <p className="p-ml-2">
          Multiplier les paliers par la quantité d’une autre prestation de l'offre
        </p>
      </div>
      {isEnabled && (
        <div>
          <p style={{ fontWeight: 700 }} className="p-mb-1">
            Prestation référante
          </p>
          <Field
            name="composition.dependOnAnotherPrestation"
            render={({ input, meta: { valid, error, touched } }) => (
              <>
                <PrimeDropdown
                  className={!valid && touched ? 'p-invalid' : ''}
                  placeholder="Sélectionner une prestation"
                  value={input.value}
                  options={options}
                  optionLabel="prestation.designation"
                  optionValue="prestation.identifiant"
                  onChange={input.onChange}
                  appendTo={document.body}
                  autoResize
                />
                {!!error && touched && (
                  <small style={{ height: 0 }} className="p-error p-d-block">
                    {error}
                  </small>
                )}
              </>
            )}
          />
        </div>
      )}
    </>
  );
};

export default AddDependentComposition;
