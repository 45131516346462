import { useCallback, useMemo } from 'react';
import { debounce } from 'app/utils/functions';
import { useDispatch } from 'react-redux';

/**
 * Handle delayed function on return handlers for 'onKeyPress' and 'onChange'
 * on press Enter will immediately call dispatch 'delayedFunction'
 * @param delayedFunction {function}
 * @param isLoading if true used for avoid immediateCall when provided loading is in progress
 * @param wait {number} delay time
 * @return {{handleKeyPress: ((function(*): (*|string))|*), handleOnChange: (function(*): void)}}
 */
// eslint-disable-next-line import/prefer-default-export
export const useDebouncedChange = (
  delayedFunction,
  isLoading = false,
  withDispatch = false,
  wait = 700,
) => {
  const dispatch = useDispatch();

  const request = useCallback(
    searchText =>
      withDispatch ? dispatch(delayedFunction(searchText)) : delayedFunction(searchText),
    [delayedFunction],
  );
  const debouncedHandler = useMemo(() => debounce(request, wait), [request]);

  const handleKeyPress = e => {
    const textForSearch = e.target.value;
    if (e.key === 'Enter' && !!textForSearch && !isLoading) {
      return debouncedHandler.immediateCall(textForSearch);
    }
    return '';
  };

  const handleOnChange = e => {
    const textForSearch = e.target.value;
    return debouncedHandler(textForSearch);
  };

  return {
    handleKeyPress,
    handleOnChange,
  };
};
