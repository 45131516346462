import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { throwError } from 'app/utils/functions';
import { getUserName } from 'app/localStorage';

export const ExportReportsTypes = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  CLIENT: 'CLIENT_ALL',
  BILLING_ORDER: 'BILLING_ORDER',
};

const reducerName = 'reports';

const initialState = {
  stellairUsage: {
    isLoading: false,
    downloadIds: [],
    data: {},
  },
  exportSaved: {
    isLoading: false,
    isGenerating: false,
    data: {},
  },
};

export const fetchStellairUsageGridData = createAsyncThunk(
  `${reducerName}/fetchStellairUsageGridData`,
  async ({ page, size }, { rejectWithValue }) => {
    try {
      const { data } = await API.Reports.getStellairClientsUsageReport(page, size);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const downloadStellairReport = createAsyncThunk(
  `${reducerName}/downloadStellairReport`,
  async (id, { rejectWithValue, dispatch }) => {
    dispatch(setStellairUsageDownloadId(id));
    try {
      const { data } = await API.Reports.downloadStellairClientUsageReport(id);
      return { data, id };
    } catch (error) {
      throwError(error);
      return rejectWithValue({ error, id });
    }
  },
);
export const reloadStellairReport = createAsyncThunk(
  `${reducerName}/reloadStellairReport`,
  async (stellairReportId, { rejectWithValue, dispatch }) => {
    dispatch(changeRowStatusOnReloadStellair(stellairReportId));
    try {
      const { data } = await API.Anomalie.resolveStellairAnomalie(stellairReportId);
      return { data, id: stellairReportId };
    } catch (error) {
      throwError(error);
      return rejectWithValue({ error, id: stellairReportId });
    }
  },
);

export const fetchSavedReportsGridData = createAsyncThunk(
  `${reducerName}/fetchSavedReportsGridData`,
  async ({ page, size }, { rejectWithValue }) => {
    try {
      const { data } = await API.Reports.savedReportsPaginated(page, size, 'dateCreation', 'DESC');
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const generateSubscriptionReport = createAsyncThunk(
  `${reducerName}/generateSubscriptionReport`,
  async (key, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.exportSouscriptions('activationDate', 'DESC', key);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const genereateClientReport = createAsyncThunk(
  `${reducerName}/genereateClientReport`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.Reports.requestAllCustomersReport();
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const genereateBillingOrderReport = createAsyncThunk(
  `${reducerName}/genereateBillingOrderReport`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.Reports.requestAllBillingOrdersReport();
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

const reportsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setStellairUsageDownloadId(state, { payload }) {
      state.stellairUsage.downloadIds = [...state.stellairUsage.downloadIds, payload];
    },
    changeRowStatusOnReloadStellair(state, { payload }) {
      const gridData = state.stellairUsage.data.content;
      const targetItem = gridData.find(item => item.identifiant === payload);
      if (targetItem) targetItem.status = 'PENDING';
      state.stellairUsage.data.content = gridData;
    },
  },
  extraReducers: builder => {
    // Lister stellair usage
    builder.addCase(fetchStellairUsageGridData.pending, state => {
      state.stellairUsage.isLoading = true;
    });
    builder.addCase(fetchStellairUsageGridData.fulfilled, (state, action) => {
      state.stellairUsage.data = action.payload;
      state.stellairUsage.isLoading = false;
    });
    builder.addCase(fetchStellairUsageGridData.rejected, state => {
      state.stellairUsage.isLoading = false;
      state.stellairUsage.data = [];
    });

    // download stellair usage
    builder.addCase(downloadStellairReport.fulfilled, (state, action) => {
      state.stellairUsage.downloadIds = state.stellairUsage.downloadIds.filter(
        item => item !== action.payload.id,
      );
    });
    builder.addCase(downloadStellairReport.rejected, (state, action) => {
      state.stellairUsage.downloadIds = state.stellairUsage.downloadIds.filter(
        item => item !== action.error.id,
      );
    });

    // Lister saved reports
    builder.addCase(fetchSavedReportsGridData.pending, state => {
      state.exportSaved.isLoading = true;
    });
    builder.addCase(fetchSavedReportsGridData.fulfilled, (state, action) => {
      state.exportSaved.data = action.payload;
      state.exportSaved.isLoading = false;
    });
    builder.addCase(fetchSavedReportsGridData.rejected, state => {
      state.exportSaved.isLoading = false;
      state.exportSaved.data = [];
    });

    // request generating new reports
    builder.addCase(generateSubscriptionReport.pending, state => {
      state.exportSaved.isGenerating = true;
    });
    builder.addCase(generateSubscriptionReport.fulfilled, state => {
      state.exportSaved.isGenerating = false;
      state.exportSaved.data.content = [
        {
          id: '',
          name: '',
          dateCreation: 'Génération en cours…',
          type: ExportReportsTypes.SUBSCRIPTION,
          expiration: '',
          createdBy: getUserName(),
          generating: true,
        },
        ...state.exportSaved.data.content,
      ];
    });
    builder.addCase(generateSubscriptionReport.rejected, state => {
      state.exportSaved.isGenerating = false;
    });

    builder.addCase(genereateClientReport.pending, state => {
      state.exportSaved.isGenerating = true;
    });
    builder.addCase(genereateClientReport.fulfilled, state => {
      state.exportSaved.isGenerating = false;
      state.exportSaved.data.content = [
        {
          id: '',
          name: '',
          dateCreation: 'Génération en cours…',
          type: ExportReportsTypes.CLIENT,
          expiration: '',
          createdBy: getUserName(),
          generating: true,
        },
        ...state.exportSaved.data.content,
      ];
    });
    builder.addCase(genereateClientReport.rejected, state => {
      state.exportSaved.isGenerating = false;
    });

    builder.addCase(genereateBillingOrderReport.pending, state => {
      state.exportSaved.isGenerating = true;
    });
    builder.addCase(genereateBillingOrderReport.fulfilled, state => {
      state.exportSaved.isGenerating = false;
      state.exportSaved.data.content = [
        {
          id: '',
          name: '',
          dateCreation: 'Génération en cours…',
          type: ExportReportsTypes.BILLING_ORDER,
          expiration: '',
          createdBy: getUserName(),
          generating: true,
        },
        ...state.exportSaved.data.content,
      ];
    });
    builder.addCase(genereateBillingOrderReport.rejected, state => {
      state.exportSaved.isGenerating = false;
    });
  },
});

const { setStellairUsageDownloadId, changeRowStatusOnReloadStellair } = reportsSlice.actions;

const managerStateSelector = state => state[reducerName];

export const selectStellairUsageData = createSelector(
  managerStateSelector,
  state => state.stellairUsage.data,
);
export const selectIsStellairUsageLoading = createSelector(
  managerStateSelector,
  state => state.stellairUsage.isLoading,
);

export const selectRequestedStellairReportID = createSelector(
  managerStateSelector,
  state => state.stellairUsage.downloadIds,
);

export const selectSavedReportsGridData = createSelector(
  managerStateSelector,
  state => state.exportSaved.data,
);
export const selectIsSavedReportsLoading = createSelector(
  managerStateSelector,
  state => state.exportSaved.isLoading,
);

export const selectIsReportsGenerating = createSelector(
  managerStateSelector,
  state => state.exportSaved.isGenerating,
);

export default reportsSlice.reducer;
