import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getClientContactsDataTable } from 'app/redux/slices/teams';
import CreateNewButton from 'components/_shared/CreateNewButton/CreateNewButton';
import ContactsDialogForm from './ContactsDialogForm';

const ContactsDialogWrapper = ({ clientId, sageBase }) => {
  const dispatch = useDispatch();
  const [isContactsDialogVisible, handleShowDialog] = useState(false);

  const actionOnSuccessCreate = () => {
    dispatch(getClientContactsDataTable({ clientId }));
  };

  return (
    <>
      {isContactsDialogVisible && (
        <ContactsDialogForm
          visible={isContactsDialogVisible}
          onHide={() => handleShowDialog(false)}
          actionOnSuccessCreate={actionOnSuccessCreate}
          clientId={clientId}
          sageBase={sageBase}
        />
      )}
      <CreateNewButton
        label="Ajout d’un nouveau contact"
        className="p-mt-3 p-ml-3"
        onClick={() => handleShowDialog(true)}
      />
    </>
  );
};

export default ContactsDialogWrapper;
