import React, { useMemo } from 'react';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import './AutoCompleteStyles.scss';

const VirtualizedMenuList = props => {
  const { children, maxHeight, focusedOption, selectProps } = props;
  const options = Array.isArray(children) ? children : [];
  const scrollToIndex = options.findIndex(
    optItem =>
      optItem.props.data[selectProps.valuePropName] === focusedOption?.[selectProps.valuePropName],
  );

  const optionsToDisplay = 7;
  const optionRowHeight = 35;

  const rowMaxHeight = useMemo(() => {
    if (!children.length) return optionRowHeight;
    if (children.length > optionsToDisplay) return maxHeight;
    return children.length * optionRowHeight;
  }, [maxHeight, children]);

  const cache = new CellMeasurerCache({
    defaultHeight: optionRowHeight,
    fixedWidth: true,
  });

  if (!children.length) return children;

  return (
    <div style={{ flex: '1 1 auto', height: rowMaxHeight }}>
      <AutoSizer>
        {({ width }) => (
          <>
            <List
              {...props}
              height={rowMaxHeight}
              rowCount={children.length}
              rowHeight={cache.rowHeight}
              width={width}
              rowRenderer={({ index, key, style, parent }) => (
                <CellMeasurer
                  cache={cache}
                  key={key}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ measure }) => (
                    <div style={style} onLoad={measure}>
                      {children[index]}
                    </div>
                  )}
                </CellMeasurer>
              )}
              scrollToIndex={scrollToIndex}
            />
          </>
        )}
      </AutoSizer>
    </div>
  );
};

export default VirtualizedMenuList;
