import React from 'react';
import './FormFieldGroup.scss';

const FormFieldGroup = ({ title, className, children }) => (
  <div
    className={`p-formgrid p-grid p-align-stretch vertical-container FormFieldGroup ${className}`}
  >
    {title && (
      <div className="p-field p-col-9 p-md-12 p-mt-3">
        <span className="title">{title}</span>
      </div>
    )}
    {children}
  </div>
);

export default FormFieldGroup;
