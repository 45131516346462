import React, { useState } from 'react';
import { Message } from 'primereact/message';
import { subscriptionStatusesServer } from 'app/constants/subscription.constants';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import RadioBox from 'components/_shared/RadioBox/RadioBox';
import { formatDate } from 'app/utils/dateUtils';
import './TerminateSouscriptionDialog.scss';

const TerminateSouscriptionDialog = ({
  visible,
  onHide,
  onCancel,
  onConfirm,
  showLoadingOnConfirm,
  souscripteurName,
  offreCommercialeName,
  dateFin,
  cancelImmediately = false,
}) => {
  const [souscriptionsStatus, setSouscriptionsStatus] = useState();
  const endDate = formatDate(dateFin);

  const isConfirmActive = (souscriptionsStatus && !showLoadingOnConfirm) || cancelImmediately;
  const terminateDateMessage = `${souscripteurName} est engagé sur l’offre ${offreCommercialeName} jusqu’au ${endDate}`;

  return (
    <DialogBox
      id="terminateSouscriptionPopUpId"
      visible={visible}
      onHide={onHide}
      className="TerminateSouscriptionDialog"
      width={500}
      title="Résilier la souscription"
      isLoading={showLoadingOnConfirm}
      onCancel={onCancel}
      cancelLabel="Annuler"
      onConfirm={() => {
        onConfirm(cancelImmediately ? subscriptionStatusesServer.TERMINATED : souscriptionsStatus);
      }}
      confirmLabel="Confirmer la résiliation"
      isConfirmActive={isConfirmActive}
      isConfirmButtonDangerColor
    >
      {cancelImmediately ? (
        <div className="p-d-flex p-flex-column">
          <div className="content-top p-text-left p-text-normal ">
            Souhaitez-vous réellement résilier la souscription immédiatement ?
          </div>
          <ul className="p-mt-3 p-pl-5 p-text-left p-text-normal">
            <li>
              les bons de facturation à venir pour les prestations récurrentes de la souscription
              seront directement envoyés à SAGE.
            </li>
            <li>
              les bons de facturation à venir basés sur les prestations facturées selon l'usage,
              seront annulés si présents dans la souscription.
            </li>
            <li>
              il sera impossible de rétablir la souscription après avoir confirmé sa résiliation.
            </li>
          </ul>
        </div>
      ) : (
        <div className="p-d-flex p-flex-column">
          {souscripteurName && offreCommercialeName && dateFin && (
            <Message
              className="p-col-12"
              content={
                <div className="p-d-flex p-align-center p-col-12 p-py-2">
                  <span style={{ fontSize: '14px' }}>{terminateDateMessage}</span>
                </div>
              }
            />
          )}
          <h1 className="p-mt-3 content-top p-text-bold">Résilier</h1>
          <div className="p-col-12 p-px-0">
            <div className="p-d-flex p-mt-3 p-dir-col ">
              <RadioBox
                inputId="CLOSED"
                value={subscriptionStatusesServer.CLOSED}
                onChange={() => setSouscriptionsStatus(subscriptionStatusesServer.CLOSED)}
                checked={souscriptionsStatus === subscriptionStatusesServer.CLOSED}
                label="À la fin de la période d’engagement"
              >
                {souscriptionsStatus === subscriptionStatusesServer.CLOSED && (
                  <ul style={{ fontSize: '14px' }}>
                    <li>la résiliation sera effective le {endDate}</li>
                    <li>
                      il sera impossible de rétablir le renouvellement automatique de la
                      souscription après avoir confirmé sa résiliation.
                    </li>
                  </ul>
                )}
              </RadioBox>
            </div>
            <div className="p-mt-1 p-dir-col p-mb-1">
              <RadioBox
                value={subscriptionStatusesServer.TERMINATED}
                inputId="TERMINATED"
                onChange={() => setSouscriptionsStatus(subscriptionStatusesServer.TERMINATED)}
                checked={souscriptionsStatus === subscriptionStatusesServer.TERMINATED}
                label="Immédiatement"
              >
                {souscriptionsStatus === subscriptionStatusesServer.TERMINATED && (
                  <ul style={{ fontSize: '14px' }}>
                    <li>
                      les bons de facturation à venir pour les prestations récurrentes de la
                      souscription seront directement envoyés à SAGE.
                    </li>
                    <li>
                      les bons de facturation à venir basés sur les prestations facturées selon
                      l'usage, seront annulés si présents dans la souscription.
                    </li>
                    <li>
                      il sera impossible de rétablir la souscription après avoir confirmé sa
                      résiliation.
                    </li>
                  </ul>
                )}
              </RadioBox>
            </div>
          </div>
        </div>
      )}
    </DialogBox>
  );
};

export default TerminateSouscriptionDialog;
