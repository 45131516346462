import React from 'react';
import { TwoLineData } from 'olaqin-design-system';
import { capitalize } from 'app/utils/functions';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import './SubscriptionsNew.scss';
import { formatDate } from 'app/utils/dateUtils';
import { GridFilterTypes } from 'components/_shared/GridDynamicFilters/RegisterGridFiltering';

const NumeroContractColumn = {
  key: 'numeroContrat',
  field: 'numeroContrat',
  filterType: GridFilterTypes.plain,
  filterPlaceHolder: 'Tous',
  header: 'N° de contrat',
  headerStyle: { border: '0', maxWidth: '110px' },
  filterHeaderStyle: { maxWidth: '110px' },
};

const bodyNameSubscription = row => (
  <TwoLineData
    topText={`${capitalize(row.souscripteur.nom)}`}
    downText={row.souscripteur.numeroClient}
  />
);

const ClientNameColumn = {
  key: 'nom',
  field: 'client',
  header: 'Client',
  filterType: GridFilterTypes.plain,
  filterPlaceHolder: 'Tous clients',
  body: bodyNameSubscription,
  headerStyle: { border: '0', minWidth: '135px' },
  bodyStyle: {
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    maxWidth: '0',
    textOverflow: 'ellipsis',
  },
};

const bodyThirdPartyPayerSubscription = row => {
  if (!row.thirdPartyPayer?.name) {
    return '';
  }

  return (
    <TwoLineData
      topText={`${capitalize(row.thirdPartyPayer.name)}`}
      downText={row.thirdPartyPayer.number || ''}
    />
  );
};

const ThirdPartyPayerColumn = {
  key: 'thirdPartyPayer',
  header: 'Tiers-payeur',
  field: 'thirdPartyPayer',
  filterType: GridFilterTypes.plain,
  filterPlaceHolder: 'Tous tiers-payeurs',
  body: bodyThirdPartyPayerSubscription,
  headerStyle: { border: '0' },
  bodyStyle: {
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    maxWidth: '0',
    textOverflow: 'ellipsis',
  },
};

const OfferNameColumn = {
  key: 'Offre',
  filterType: GridFilterTypes.offersList,
  filterPlaceHolder: 'Toutes les offres',
  field: 'offer',
  body: row => row.offreCommerciale.nom,
  header: 'Offre',
  headerStyle: { border: '0' },
};

const bodyStatusSubscription = row => {
  const { statut, hasAnomaly, terminateDate } = row;
  return <StatusSubscription label={statut} isAnomaly={hasAnomaly} terminated={terminateDate} />;
};

const SubStatusColumn = {
  key: 'statut',
  field: 'status',
  filterType: GridFilterTypes.subscriptionStatus,
  filterPlaceHolder: 'Tous état',
  header: 'État',
  body: bodyStatusSubscription,
  headerStyle: { border: '0' },
};

const bodyCreationDate = date => {
  if (!date) {
    return '';
  }
  const formattedDate = formatDate(date, 'DD/MM/YYYY');

  return `${formattedDate}`;
};

const DateCreationColumn = {
  key: 'creation',
  field: 'startCreationDate-endCreationDate',
  filterType: GridFilterTypes.periodDate,
  filterPlaceHolder: 'Toutes',
  header: 'Création',
  body: row => bodyCreationDate(row.dateCreation),
  headerStyle: { border: '0', minWidth: '200px' },
};

const DateActivationColumn = {
  key: 'activation',
  field: 'startActivationDate-endActivationDate',
  filterType: GridFilterTypes.periodDate,
  filterPlaceHolder: 'Toutes',
  header: 'Activation',
  body: row => bodyCreationDate(row.activationDate),
  headerStyle: { border: '0', minWidth: '200px' },
};

const DateStartColumn = {
  key: 'debut',
  field: 'startDebutDate-endDebutDate',
  filterType: GridFilterTypes.periodDate,
  filterPlaceHolder: 'Toutes',
  header: 'Début fact.',
  body: row => bodyCreationDate(row.dateStart),
  headerStyle: { border: '0', minWidth: '200px' },
};

const DateEndColumn = {
  key: 'dateFin',
  field: 'startFinishDate-endFinishDate',
  filterType: GridFilterTypes.periodDate,
  filterPlaceHolder: 'Toutes',
  header: 'Fin engag.',
  body: row => bodyCreationDate(row.dateFin),
  headerStyle: { border: '0', minWidth: '200px' },
};

const DateRenewColumn = {
  key: 'renov',
  field: 'startRenewalDate-endRenewalDate',
  filterType: GridFilterTypes.periodDate,
  filterPlaceHolder: 'Toutes',
  header: 'Renouv.',
  body: row => bodyCreationDate(row.dateRenew),
  headerStyle: { border: '0', minWidth: '200px' },
};

const DateCancelColumn = {
  key: 'cancel',
  field: 'startCancellationDate-endCancellationDate',
  filterType: GridFilterTypes.periodDate,
  filterPlaceHolder: 'Toutes',
  header: 'Résiliation',
  body: row => bodyCreationDate(row.terminateDate),
  headerStyle: { border: '0', minWidth: '200px' },
};

export {
  NumeroContractColumn,
  ClientNameColumn,
  ThirdPartyPayerColumn,
  OfferNameColumn,
  SubStatusColumn,
  DateCreationColumn,
  DateActivationColumn,
  DateStartColumn,
  DateEndColumn,
  DateRenewColumn,
  DateCancelColumn,
};
