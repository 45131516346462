import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { debounce } from 'app/utils/functions';

export const getSearchParams = searchString => {
  const params = {};

  const queryString = searchString.substring(1);
  const queryParts = queryString.split('&');
  queryParts.forEach(part => {
    const [key, value] = part.split('=');
    if (key && value) params[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return params;
};
const usePagination = props => {
  const {
    initPage = 1,
    initSize = 15,
    dataFetchBy,
    withoutDispatch,
    skipInitialCall,
    debounced = false,
  } = props;
  const { search } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryPage = getSearchParams(search);
  const currentPage = queryPage.page || initPage;
  const currentSize = queryPage.size || initSize;

  const terms = useMemo(
    () =>
      Object.entries(queryPage).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        { page: currentPage, size: currentSize },
      ),
    [queryPage],
  );

  const fetchTableData = useCallback(
    debounce(
      data => {
        const queryParams = {
          ...data,
          page: +data.page - 1,
        };
        if (withoutDispatch) {
          dataFetchBy(queryParams);
        } else {
          dispatch(dataFetchBy(queryParams));
        }
      },
      debounced ? 700 : 0,
    ),
    [dataFetchBy, withoutDispatch, dispatch, debounced],
  );

  const navigateTo = (tpage, tsize, updatedTerms = {}) => {
    let query = `?page=${tpage}&size=${tsize}`;
    const { page, size, ...restTerms } = updatedTerms;
    let targetTerms = {};
    Object.entries(restTerms).forEach(key => {
      if (key[1]) {
        query += `&${key[0]}=${encodeURIComponent(key[1])}`;
        targetTerms = { ...targetTerms, [key[0]]: key[1] };
      }
    });
    history.push(query);
    fetchTableData({ page: tpage, size: tsize, ...targetTerms });
  };

  const onTablePageChange = data => {
    navigateTo(data.page + 1, data.rows, terms);
  };

  const onTermsChange = (termName, termValue, keepSearchTerm = true, skipOtherTerms) => {
    let initTerms = {};
    if (keepSearchTerm && terms.search) {
      initTerms.search = terms.search;
    }
    if (!skipOtherTerms) {
      initTerms = { ...initTerms, ...terms };
    }
    if (termName.includes('-')) {
      const multipleTerms = termName.split('-');
      initTerms = { ...initTerms, [multipleTerms[0]]: termValue[0] ?? null };
      initTerms = { ...initTerms, [multipleTerms[1]]: termValue[1] ?? null };
    } else {
      initTerms[termName] = termValue;
    }

    navigateTo(1, currentSize, initTerms);
  };

  const resetTable = () => {
    navigateTo(initPage, initSize);
  };

  const reloadPage = () => {
    fetchTableData(terms);
  };

  useEffect(() => {
    if (!skipInitialCall) {
      fetchTableData(terms);
    }
  }, [currentPage, currentSize]);

  return {
    onTablePageChange,
    onTermsChange,
    resetTable,
    reloadPage,
    terms,
  };
};

export default usePagination;
