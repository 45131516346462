import React from 'react';
import { DataTable } from 'primereact/datatable';
import { TwoLineData, Button } from 'olaqin-design-system';
import { Column } from 'primereact/column';
import { formatDate } from 'app/utils/dateUtils';
import { useSelector } from 'react-redux';
import {
  getReportsFse,
  selectIsReportsFseDataLoading,
  selectReportsFseData,
} from 'app/redux/slices/souscriptions';
import { capitalize } from 'app/utils/functions';
import customStyle from 'components/reports/Reports.module.scss';
import { generateLinkToFileFse } from 'components/reports/reportsUtils';
import usePagination from 'app/hooks/usePagination';
import ReportsFseHeaderTable from './ReportsFseHeaderTable';

const ReportsFseDataTable = () => {
  const { onTablePageChange } = usePagination({
    dataFetchBy: getReportsFse,
  });
  const isLoadingReportsData = useSelector(selectIsReportsFseDataLoading);
  const reportsFseData = useSelector(selectReportsFseData);

  const bodyPeriod = row => {
    const fromDate = formatDate(row.fromDate, 'DD/MM/YYYY');
    const toDate = formatDate(row.toDate, 'DD/MM/YYYY');

    return `${fromDate} – ${toDate}`;
  };

  const bodyClient = row => (
    <TwoLineData topText={`${capitalize(row.client)}`} downText={row.numeroClient} />
  );

  const bodyActionButton = ({ downloadToken, billingOrderNumber }) => {
    const isReadyForDownload = !!downloadToken && !!billingOrderNumber;

    const linkToFile = generateLinkToFileFse(downloadToken, billingOrderNumber);
    const fileNameForDownload = `Consommation_FSE_${billingOrderNumber}`;

    return isReadyForDownload ? (
      <a href={linkToFile} download={fileNameForDownload}>
        <Button
          icon="pi pi-download"
          type="secondary"
          btnType="secondary"
          disabled={!isReadyForDownload}
          className={`${customStyle.customButtonDownload} p-m-0`}
        />
      </a>
    ) : (
      <Button
        icon="pi pi-download"
        type="secondary"
        btnType="secondary"
        disabled={!isReadyForDownload}
        className={`${customStyle.customButtonDownload} p-m-0`}
      />
    );
  };

  return (
    <DataTable
      id="reportsFSE-table"
      header={<ReportsFseHeaderTable />}
      value={reportsFseData.content || []}
      loading={isLoadingReportsData}
      paginator={reportsFseData.totalElements > 12}
      onPage={onTablePageChange}
      first={reportsFseData.pageable?.offset || 0}
      numberOfElements={reportsFseData.numberOfElements || 0}
      totalRecords={reportsFseData.totalElements || 0}
      rows={reportsFseData.size || 0}
      paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
      currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
      lazy
      emptyMessage="Aucun rapport de consommation disponible"
      responsive
      rowHover
      autoLayout
    >
      <Column
        field="client"
        header="Client"
        headerStyle={{ border: '0', width: '18%' }}
        bodyStyle={{
          overflow: 'hidden',
          whiteSpace: 'noWrap',
          maxWidth: '0',
          textOverflow: 'ellipsis',
        }}
        body={bodyClient}
      />
      <Column field="offer" header="Offre" headerStyle={{ border: '0', width: '20%' }} />
      <Column
        field="numeroContract"
        header="N° de contrat"
        headerStyle={{ border: '0', width: '15%' }}
      />
      <Column
        field="Période"
        header="Période"
        body={bodyPeriod}
        headerStyle={{ border: '0', width: '20%' }}
      />
      <Column
        field="billingOrderNumber"
        header="Bon de facturation associé"
        headerStyle={{ border: '0', width: '20%' }}
      />
      <Column
        key="supp"
        header=""
        body={bodyActionButton}
        className="action"
        bodyStyle={{ textAlign: 'center' }}
        headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
      />
    </DataTable>
  );
};

export default ReportsFseDataTable;
