import React, { useState } from 'react';
import { Button } from 'olaqin-design-system';
import QuitterSouscription from 'components/souscriptions/createSubscription/dialogs/quitterSouscription';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import customStyle from './createSubscription.module.scss';

const CreateSubscriptionFormHeader = ({
  isEdit = false,
  isSubmitDisabled,
  handleClose,
  isFormDirty,
}) => {
  const textData = isEdit
    ? {
        title: 'Modification de la souscription',
        buttonSubmitTitle: 'Modification de la souscription',
        header: 'Modifications non enregistrées',
        message: 'Voulez-vous ignorer les modifications apportées ou continuer la modification ?',
        acceptLabel: 'Quitter sans enregister',
        rejectLabel: 'Annuler',
      }
    : {
        title: 'Nouvelle souscription',
        buttonSubmitTitle: 'Créer la souscription',
        header: 'Abandonner la souscription',
        message:
          'Voulez-vous vraiment quitter la création de la souscription ? Toutes les modifications apportées seront perdues',
        acceptLabel: 'Confirmer',
        rejectLabel: 'Annuler',
      };

  const [isQuitFormPopupVisible, setQuitFormPopupVisible] = useState(false);

  const handleClickConfirm = isLeaveConfirmed =>
    isLeaveConfirmed ? handleClose() : setQuitFormPopupVisible(false);

  const handleClickClose = () => (isFormDirty ? setQuitFormPopupVisible(true) : handleClose());

  return (
    <div className={customStyle.formHeader}>
      <div className="p-d-flex p-align-center">
        {isQuitFormPopupVisible && (
          <QuitterSouscription
            header={textData.header}
            message={textData.message}
            acceptLabel={textData.acceptLabel}
            rejectLabel={textData.rejectLabel}
            visible={isQuitFormPopupVisible}
            onHide={() => setQuitFormPopupVisible(false)}
            quitterSouscription={handleClickConfirm}
          />
        )}
        <Button
          style={{
            border: 'none',
            width: '16px',
            height: '16px',
            padding: '16px',
            marginRight: '8px',
          }}
          btnType="secondary"
          type="button"
          label=""
          icon="pi pi-times"
          onClick={handleClickClose}
        />
        <p
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '24px',
            color: '#000000',
          }}
        >
          {textData.title}
        </p>
      </div>
      <SubmitButton label={textData.buttonSubmitTitle} disabled={isSubmitDisabled} />
    </div>
  );
};

export default CreateSubscriptionFormHeader;
