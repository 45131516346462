import {
  quantityTypeOptions,
  tariffModelsOptions,
  durationOptions,
  periodicityOptions,
  renewalOptions,
  pricingRevisionIndexSettingsOptions,
} from 'app/constants/offerConstants';

/**
 * Map tiers to server view. From this view:
 * [{ units: 1, upToUnits: 2, tarif: 3, fixedTarif: 4 }]
 * to this:
 * [{minimum: 1, maximum: 2, illimite: false, amount: 3, flatFee: 4}]
 * @param tariffProgressive
 * @returns {Array}
 */
const mapTiers = tariffProgressive =>
  tariffProgressive.map((row, i) => {
    const isLast = tariffProgressive.length - 1 === i;

    if (isLast) {
      return {
        minimum: row.units,
        illimite: true,
        amount: row.tarif,
        flatFee: row.fixedTarif,
      };
    }

    return {
      minimum: row.units,
      maximum: row.upToUnits,
      illimite: false,
      amount: row.tarif,
      flatFee: row.fixedTarif,
    };
  });

/**
 * Map tariff to standard and graduated + graduated volume
 * @param tarification {string}
 * @param tariff {number}
 * @param tariffProgressive {Array}
 * @param dependOnAnotherPrestation {string}
 * @param isMultiplier {boolean}
 * @returns {{amount, type}|{tierType: string, tiers: Array, type: string}}
 */
const mapTarif = ({
  tarification,
  tariff,
  tariffProgressive,
  dependOnAnotherPrestation,
  isMultiplier,
}) => {
  // tarification standard
  if (tarification === tariffModelsOptions[0].value) {
    return {
      type: tarification,
      amount: tariff,
    };
  }

  const tarifGraduatedOrGraduatedVolumeMap = {
    // always tarifGraduated
    type: 'tarifGraduated',
    // VOLUME_PRICING – only when tarifGraduatedVolume
    tierType:
      tarification === tariffModelsOptions[1].value ? 'GRADUATED_PRICING' : 'VOLUME_PRICING',
    tiers: mapTiers(tariffProgressive),
  };

  if (dependOnAnotherPrestation && isMultiplier) {
    const dependPrestation = { identifiant: dependOnAnotherPrestation };
    tarifGraduatedOrGraduatedVolumeMap.tiersMultiplierPrestation = dependPrestation;
  }

  return tarifGraduatedOrGraduatedVolumeMap;
};

const mapQuantite = ({ quantityType, usageType, countingConditions }, tarif) => {
  if (quantityType !== quantityTypeOptions[1].value) {
    return {
      type: quantityType,
      tarif,
    };
  }

  return {
    type: quantityType,
    // ? usageDataType:
    usageDataType: {
      identifiant: usageType,
    },
    countingConditions,
    tarif,
  };
};

const mapComposition = composition =>
  composition.map(serviceItem => {
    const { isOptional, prestation, type } = serviceItem;

    const tarif = mapTarif(serviceItem);

    const quantite = mapQuantite(serviceItem, tarif);

    return {
      optional: isOptional,
      prestation: { identifiant: prestation.identifiant },
      serviceItemRepetition: type,
      // ??? PRIMAIRE OBLIGATOIRE OPTIONNELLE
      // type: 'PRIMAIRE',
      quantite,
    };
  });

// eslint-disable-next-line import/prefer-default-export
export const mapCreateOfferData = formData => {
  const prestations = mapComposition(formData.composition);

  const isCustomNameAndValueIndex =
    formData.conditions.pricingRevisionIndexSettings.type ===
    pricingRevisionIndexSettingsOptions[2].value;

  const indexSpecificValue = formData.conditions.pricingRevisionIndexSettings?.value;

  const convertedToFloat = indexSpecificValue
    ? parseFloat(indexSpecificValue.replace(',', '.'))
    : undefined;

  return {
    // tmp status set
    status: 'VALIDATION_ADV',
    nom: formData.generalInfo.nom,
    periodiciteFacturation: formData.conditions.pereodicity,
    engagement: formData.conditions.dure,
    reconductionRenouvellement: formData.conditions.reconduction,
    prestations,
    pricingRevisionIndexSettings: {
      type: formData.conditions.pricingRevisionIndexSettings.type,
      name: isCustomNameAndValueIndex
        ? formData.conditions.pricingRevisionIndexSettings.name
        : undefined,
      value: isCustomNameAndValueIndex ? convertedToFloat : undefined,
    },
    enableStellairConnection: formData.enableStellairConnection || false,
  };
};

export const generateInitialValuesForOfferForm = (isOnEditMode, offerData) => {
  if (!isOnEditMode) {
    return {
      generalInfo: {
        nom: '',
      },
      composition: [],
      conditions: {
        pereodicity: periodicityOptions[0].value,
        dure: durationOptions[0].value,
        reconduction: renewalOptions[0].value,
        pricingRevisionIndexSettings: {
          type: pricingRevisionIndexSettingsOptions[0].value,
        },
      },
      enableStellairConnection: false,
    };
  }
  return {
    generalInfo: {
      nom: offerData.nom,
    },
    composition: mapServerOfferCompositionDataToFormStructure(offerData.prestations ?? []),
    conditions: {
      pereodicity: offerData.periodiciteFacturation,
      dure: offerData.engagement,
      reconduction: offerData.reconductionRenouvellement,
      pricingRevisionIndexSettings: {
        type: offerData.pricingRevisionIndexSettings?.type || '',
        name: offerData.pricingRevisionIndexSettings?.name || '',
        value: `${offerData.pricingRevisionIndexSettings?.value || ''}`.replace('.', ','),
      },
    },
    enableStellairConnection: offerData.enableStellairConnection || false,
  };
};

const mapServerOfferCompositionDataToFormStructure = prestations =>
  prestations.map(prItem => ({
    prestation: prItem.prestation,
    isOptional: prItem.optional,
    type: prItem.serviceItemRepetition,
    quantityType: prItem.quantite.type,
    usageType: prItem.quantite.usageDataType?.identifiant || '',
    countingConditions: prItem.quantite.countingConditions ?? [],
    tarification: prItem.quantite.tarif.type,
    tariff: prItem.quantite.tarif.amount ?? 0,
    tariffProgressive: (prItem.quantite.tarif.tiers ?? []).map(tpItem => ({
      units: tpItem.minimum,
      upToUnits: tpItem.illimite ? '∞' : tpItem.maximum,
      tarif: tpItem.amount,
      fixedTarif: tpItem.flatFee,
    })),
    isMultiplier: !!prItem.quantite.tarif.tiersMultiplierPrestation,
    dependOnAnotherPrestation: prItem.quantite.tarif.tiersMultiplierPrestation?.identifiant ?? '',
  }));
