import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import SearchInput from 'components/_shared/SearchInput/SearchInput';
import usePagination from 'app/hooks/usePagination';
import {
  getSubscriptionNewListPaginated,
  selectIsSouscriptionDataLoading,
} from 'app/redux/slices/souscriptions';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';

const SubscriptionsHeaderTable = ({
  SortingComponent,
  isFilteringPanelDisplayed,
  setFilteringPanelState,
  onClearFilters,
  isClearButtonVisible,
}) => {
  const searchInputRef = useRef();
  const isLoadingSearch = useSelector(selectIsSouscriptionDataLoading);
  const { onTermsChange, terms } = usePagination({
    dataFetchBy: getSubscriptionNewListPaginated,
    skipInitialCall: true,
  });

  const clearAllFilters = () => {
    onClearFilters();
    searchInputRef.current.value = '';
  };

  return (
    <div className="table-header p-d-flex p-justify-between">
      <SearchInput
        ref={searchInputRef}
        placeholder="Rechercher par nom du client ou n°client"
        isLoading={isLoadingSearch}
        onSearch={searchVal => onTermsChange('search', searchVal)}
        defaultValue={terms.search ?? ''}
      />
      <div>
        {isClearButtonVisible && (
          <SubmitButton
            onClick={clearAllFilters}
            btnType="primary"
            label={'Effacer les filtres'}
            className="no-border"
            outlined
            small
          />
        )}

        <SubmitButton
          onClick={() => setFilteringPanelState(!isFilteringPanelDisplayed)}
          btnType="secondary"
          label={isFilteringPanelDisplayed ? 'Masquer les filtres' : 'Filtres'}
          outlined
          small
        />
        {SortingComponent()}
      </div>

      {/* <SubscriptionStatusDropwDown value={statusTerm} onChange={onStatusChange} /> */}
    </div>
  );
};

export default SubscriptionsHeaderTable;
