import React from 'react';
import { Button } from 'olaqin-design-system';
import { useSelector } from 'react-redux';
import { Message } from 'primereact/message';
import customStyle from './CardAnomalies.module.scss';

const CardAnomalies = ({ id, anomalies = [], onClickResolve, loadingsSelector }) => {
  const anomaliesLoadings = useSelector(loadingsSelector);

  const handleResolveAnomaly = anomalieId => {
    onClickResolve({ id, anomalieId });
  };

  const isAnomalyInLoading = anomalieId => anomaliesLoadings[anomalieId] === true;

  return (
    <>
      {anomalies.map(anomaly => (
        <div key={anomaly.identifiant} className="p-mt-3">
          <Message
            style={{ padding: '0', width: '100%' }}
            severity="error"
            content={
              <div className={customStyle.anomalyContainer}>
                <span className={customStyle.wordBreak}>{anomaly.message}</span>
                {!!anomaly.retryable && (
                  <Button
                    icon={isAnomalyInLoading(anomaly.identifiant) ? 'pi pi-spin pi-spinner' : ''}
                    type="button"
                    btnType="secondary"
                    className={customStyle.customButtonResolve}
                    label="Réessayer"
                    disabled={isAnomalyInLoading(anomaly.identifiant)}
                    onClick={() => handleResolveAnomaly(anomaly.identifiant)}
                  />
                )}
              </div>
            }
          />
        </div>
      ))}
    </>
  );
};

export default CardAnomalies;
