import React from 'react';
import { useSelector } from 'react-redux';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { selectOffresLoading } from 'app/redux/slices/offres';

const CreateOfferConfirmDialog = ({ visible, onHide, onConfirm }) => {
  const isLoadingCreateOffer = useSelector(selectOffresLoading);

  return (
    <DialogBox
      id="createOfferConfirmDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title="Création de l’offre"
      isLoading={isLoadingCreateOffer}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={onConfirm}
      confirmLabel="Confirmer la création"
      noTopSeparator
      noSubmitTypeOnButton
      dismissableMask
    >
      <div className="p-pb-3">
        Une fois créée, l’offre sera soumise à l’équipe d’administration des ventes afin d’être
        complétée par des paramètres de facturation et ne pourra plus être modifiée. Souhaitez-vous
        confirmer la création de l’offre ?
      </div>
    </DialogBox>
  );
};

export default CreateOfferConfirmDialog;
