import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteSouscription,
  selectIsStatusLoading,
  chargerSouscription,
  activateSouscription,
  selectDocumentsData,
  selectIsSubscriptionDeletingLoading,
} from 'app/redux/slices/souscriptions';
import { routes } from 'app/routes/appRoutes';
import ActionsControls from 'components/souscriptions/subscriptionDetails/sideManagerOfSubscription/ActionsControls';
import DeleteSouscriptionDialog from '../dialogs/DeleteSouscriptionDialog';
import ActivateSouscriptionPopUp from '../dialogs/ActivateSouscriptionPopUp';

const SideManagerOfSubscription = ({ souscription }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isStatusLoading = useSelector(selectIsStatusLoading);
  const isDeleting = useSelector(selectIsSubscriptionDeletingLoading);
  const documentsData = useSelector(selectDocumentsData);
  const [isDeleteOpen, showDeleteSubscriptionPopUp] = useState(false);
  const [isOpenActivate, showActivateSubscriptionPopUp] = useState(false);

  const showWarning = !documentsData.length;

  const closeDeletePopUp = () => showDeleteSubscriptionPopUp(false);
  const closeActivatePopUp = () => showActivateSubscriptionPopUp(false);

  const onActiverLaSouscription = () => showActivateSubscriptionPopUp(true);

  const onModifierLaSouscription = () =>
    history.push(routes.SUBSCRIPTION_EDIT(souscription.identifiant));

  const onDeleteSubscriptionRequested = () => showDeleteSubscriptionPopUp(true);

  const confirmDeleteSubscription = () => {
    dispatch(deleteSouscription(souscription.identifiant))
      .unwrap()
      .then(() => {
        closeDeletePopUp();
        history.push(routes.SUBSCRIPTIONS());
      });
  };

  const confirmActivateSubscription = activateDate => {
    dispatch(activateSouscription({ souscriptionId: souscription.identifiant, activateDate }))
      .unwrap()
      .then(() => {
        closeActivatePopUp();
        dispatch(chargerSouscription({ souscriptionId: souscription.identifiant }));
      })
      .catch(() => {});
  };

  return (
    <div className="p-d-flex">
      <ActionsControls
        canBeActivated={souscription.canBeActivated}
        onActiver={onActiverLaSouscription}
        onModifier={onModifierLaSouscription}
        onDelete={onDeleteSubscriptionRequested}
      />
      {isOpenActivate && (
        <ActivateSouscriptionPopUp
          visible={isOpenActivate}
          onHide={closeActivatePopUp}
          onCancel={closeActivatePopUp}
          onConfirm={confirmActivateSubscription}
          showLoadingOnConfirm={isStatusLoading}
          showWarning={showWarning}
        />
      )}
      {isDeleteOpen && (
        <DeleteSouscriptionDialog
          visible={isDeleteOpen}
          onHide={closeDeletePopUp}
          onConfirm={confirmDeleteSubscription}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default SideManagerOfSubscription;
