import React from 'react';
import {
  billingSystemTypeServer,
  targetCibleOptions,
  customerNatureOptions,
  categoryOptions,
  sponsorshipOptions,
  specialityOptions,
} from 'app/constants/client.constants';
import {
  getClientsGroupList,
  selectIsClientsGroupLoading,
  selectIsNewGroupItemCreated,
  createClientGroup,
} from 'app/redux/slices/teams';
import { useField } from 'react-final-form';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import CreateNewButton from 'components/_shared/CreateNewButton/CreateNewButton';

import './clientCreateForm.scss';
import { useDispatch, useSelector } from 'react-redux';

const ComplementaryInfo = ({ billingSystemValue }) => {
  const dispatch = useDispatch();
  const isGroupDataLoading = useSelector(selectIsClientsGroupLoading);
  const isGroupItemCreated = useSelector(selectIsNewGroupItemCreated);

  const { input: groupFieldInput } = useField('group');

  if (billingSystemValue !== billingSystemTypeServer.AFFID) {
    return null;
  }

  const handleCreateGroupOption = newGroupItemTitle => {
    dispatch(
      createClientGroup({
        groupName: newGroupItemTitle,
        onSuccess: () => {
          groupFieldInput.onChange(newGroupItemTitle);
        },
      }),
    );
  };

  return (
    <FormFieldGroup title="Informations complémentaires">
      {billingSystemValue === billingSystemTypeServer.AFFID && (
        <FormFieldDropdown
          name="activity"
          label="Specialité"
          placeholder="Sélectionner"
          filter
          options={specialityOptions}
          showClearButton
        />
      )}
      <FormFieldDropdown
        name="customerNature"
        label="Nature client"
        placeholder="Sélectionner"
        options={customerNatureOptions}
        showClearButton
      />
      <FormFieldDropdown
        name="category"
        label="Catégorie"
        placeholder="Sélectionner"
        options={categoryOptions}
        showClearButton
      />
      <FormFieldInputText name="secondReseller" label="Revendeur bis" />
      <FormFieldAutoComplete
        name="group"
        label="Groupe"
        placeholder="Créer ou sélectionner un groupe"
        filter
        showClearButton
        storeSingleValueOnForm
        optionsCreatable
        onCreateOption={handleCreateGroupOption}
        isLoading={isGroupDataLoading}
        formatCreateLabel={insertedValue => (
          <CreateNewButton
            style={{ textAlign: 'left' }}
            label={`Ajouter "${insertedValue}"`}
            type="button"
          />
        )}
        loadOptions={{
          action: getClientsGroupList,
          flatOptions: true,
          optionValue: 'value',
          reload: isGroupItemCreated,
        }}
      />
      <FormFieldInputText name="patientRecord" label="Dossier patient" placeholder="" />
      <FormFieldDropdown
        name="targetCible"
        label="Cible"
        placeholder="Sélectionner"
        filter
        options={targetCibleOptions}
        showClearButton
      />
      <FormFieldInputText name="targetCompletement" placeholder="Complément cible" />
      <FormFieldDropdown
        name="sponsorship"
        label="Parrainage"
        placeholder="Sélectionner"
        options={sponsorshipOptions}
        showClearButton
      />
    </FormFieldGroup>
  );
};

export default ComplementaryInfo;
