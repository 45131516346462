import React from 'react';
import { Field } from 'react-final-form';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import FormFieldWrapper from './FormFieldWrapper';
import useFormFieldDropdown from './useFormFieldDropdown';
import './FromFieldDropdown.scss';

const TooltipWrapper = ({ label, display, children }) =>
  display && label ? (
    <>
      <Tooltip target=".disabled-button" position="right" />
      <span className="disabled-button" data-pr-tooltip={label} data-pr-position="right">
        {children}
      </span>
    </>
  ) : (
    children
  );

const FormFieldDropdown = ({
  name,
  label,
  showClearButton = false,
  noWrapper = false,
  showValidationIcon = false,
  colSpan = { col: 9, md: 12 },
  loadOptions = {},
  options = [],
  onChange,
  tooltip,
  endingOption,
  ...otherProps
}) => {
  const { action, optionValue, optionLabel, reload, disabledOption } = loadOptions;
  const { list: optionFormAction, dataLoading } = useFormFieldDropdown({
    action,
    optionValue,
    optionLabel,
    reload,
    disabledOption,
  });
  return (
    <FormFieldWrapper noWrapper={noWrapper} colSpan={colSpan}>
      <TooltipWrapper {...tooltip}>
        <Field
          name={name}
          render={({ input, meta: { valid, error, touched } }) => (
            <span
              className={`FormFieldDropDownWrapper p-input-icon-right ${
                endingOption ? 'p-d-flex' : ''
              }`}
            >
              {label && (
                <label htmlFor={`${name}-id`} className="label">
                  {label}
                </label>
              )}
              <PrimeDropdown
                id={`${name}-id`}
                className={!valid && touched ? 'p-invalid' : ''}
                value={tooltip?.hideInputValue ? '' : input.value}
                onChange={
                  onChange
                    ? event => {
                        onChange(event);
                        input.onChange(event);
                      }
                    : input.onChange
                }
                onBlur={input.onBlur}
                showClear={showClearButton && !!input.value}
                appendTo={document.body}
                options={action ? optionFormAction : options}
                disabled={action ? dataLoading : tooltip?.disableInput || false}
                {...otherProps}
              />
              {endingOption}

              {!!error && touched && <small className="p-error p-d-block">{error}</small>}
              {showValidationIcon && valid && touched && (
                <i className={`pi pi-check ${label ? 'p-mt-1' : ''}`} />
              )}
            </span>
          )}
        />
      </TooltipWrapper>
    </FormFieldWrapper>
  );
};

export default FormFieldDropdown;
