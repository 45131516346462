import React, { useMemo } from 'react';
import Select, { components, createFilter } from 'react-select';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AsyncPaginate } from 'react-select-async-paginate';
import CreatableSelect from 'react-select/creatable';
import VirtualizedMenuList from './VirtualizedMenuList';

import './AutoCompleteStyles.scss';

const AutoComplete = ({
  id,
  footerPanel,
  renderers,
  value,
  options,
  valuePropName,
  filter,
  filterBy,
  hideDropDownButton,
  withPagination,
  optionsCreatable,
  ...rest
}) => {
  const ComponentCore = useMemo(() => {
    if (withPagination) {
      return AsyncPaginate;
    }
    if (optionsCreatable) {
      return CreatableSelect;
    }
    return Select;
  }, [withPagination, optionsCreatable]);

  const LoadVirutalizedList = () => (withPagination ? {} : { MenuList: VirtualizedMenuList });

  const Menu = ({ children, ...menuProps }) => (
    <components.Menu {...menuProps}>
      {children} {footerPanel}
    </components.Menu>
  );

  const valueExtractor = useMemo(() => {
    if (typeof value !== 'object' && value !== null && Array.isArray(options)) {
      return options.find(item => item[valuePropName ?? 'value'] === value) ?? null;
    }
    return value;
  }, [options, value]);

  const filterOptions = useMemo(() => {
    if (filter && filterBy) {
      const filterParams = filterBy.split(',');
      return {
        filterOption: createFilter({
          stringify: option => filterParams.map(i => option.data[i]).join(' '),
        }),
      };
    }
    return {};
  }, [filter, filterBy]);

  return (
    <ComponentCore
      inputId={id}
      classNames={{
        valueContainer: () => 'r-select-value',
        control: () => 'r-select-control',
      }}
      isOptionDisabled={option => option.disabled}
      isSearchable={filter ?? false}
      options={options}
      valuePropName={valuePropName}
      {...rest}
      {...filterOptions}
      value={valueExtractor}
      components={{
        Menu,
        ...LoadVirutalizedList(),
        DropdownIndicator: () =>
          hideDropDownButton ? null : (
            <div className="p-dropdown-trigger cursor-pointer">
              <span className="p-dropdown-trigger-icon pi pi-chevron-down" />
            </div>
          ),
        ClearIndicator: e => (
          <div
            className="p-dropdown-trigger cursor-pointer"
            onClick={() => {
              e.clearValue();
            }}
            {...e.innerProps}
          >
            <span className="p-dropdown-clear-icon pi pi-times" />
          </div>
        ),
        IndicatorsContainer: indProps => (
          <div className={`r-indicator-Container${hideDropDownButton ? ' singleBtn' : ''}`}>
            {indProps.children}
          </div>
        ),
        LoadingIndicator: () => (
          <ProgressSpinner style={{ width: 16, height: 16 }} strokeWidth={4} />
        ),
        IndicatorSeparator: null,
        renderers,
      }}
    />
  );
};

export default AutoComplete;
