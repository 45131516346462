import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  getClientContactsDataTable,
  getAllTypeOfContactList,
  getAllDepartmentList,
  selectClientContactsDataTable,
  selectClientContactDataTableIsLoading,
  selectAllTypeOfContactList,
  selectAllTypeOfContactIsLoading,
  selectAllDepartmentList,
  selectAllDepartmentIsLoading,
} from 'app/redux/slices/teams';
import ActionButton from 'components/_shared/ActionButton/ActionButton';
import ContactsDialogForm from '../CreateDialog/ContactsDialogForm';
import './ContactsDataTable.scss';
import DeleteContactDialog from '../DeleteDialog/DeleteContactDialog';

const ContactsDataTable = ({ clientId, sageBase }) => {
  const dispatch = useDispatch();
  const clientContactsDataTable = useSelector(selectClientContactsDataTable);
  const isLoading = useSelector(selectClientContactDataTableIsLoading);
  const allTypeOfContactList = useSelector(selectAllTypeOfContactList);
  const isLoadingAllTypeOfContactList = useSelector(selectAllTypeOfContactIsLoading);
  const allDepartmentList = useSelector(selectAllDepartmentList);
  const isLoadingAllDepartmentList = useSelector(selectAllDepartmentIsLoading);

  const [isEditContactsDialogVisible, handleShowEditDialog] = useState(false);
  const [isDeleteContactDialogVisible, handleShowDeleteDialog] = useState(false);
  const [contactRowData, setContactRowData] = useState({});

  const handleEditRow = rowData => {
    setContactRowData(rowData);
    handleShowEditDialog(true);
  };

  const handleDeleteRow = rowData => {
    setContactRowData(rowData);
    handleShowDeleteDialog(true);
  };

  const actionOnSuccessEdit = () => {
    dispatch(getClientContactsDataTable({ clientId }));
  };

  const actionOnSuccessDelete = () => {
    dispatch(getClientContactsDataTable({ clientId }));
  };

  useEffect(() => {
    dispatch(getClientContactsDataTable({ clientId }));
    dispatch(getAllTypeOfContactList({ sageBase, fromSage: false }));
    dispatch(getAllDepartmentList({ sageBase, fromSage: false }));
  }, []);

  const serviceBody = ({ service }) => {
    const findDepartmentService = allDepartmentList.find(item => item.id === service);
    return <div>{findDepartmentService?.value || service}</div>;
  };

  const typeBody = ({ type }) => {
    const findTypeOfContact = allTypeOfContactList.find(item => item.id === type);
    return <div>{findTypeOfContact?.value || type}</div>;
  };

  const bodyActionButton = rowData => {
    const actionsModel = [
      {
        label: 'Modifier',
        command: () => handleEditRow(rowData),
      },
      {
        label: <span className="supprimer-danger-button">Supprimer</span>,
        command: () => handleDeleteRow(rowData),
      },
    ];
    return <ActionButton model={actionsModel} />;
  };

  return (
    <>
      <DataTable
        id="delivery-address-table"
        style={{ marginTop: '16px' }}
        value={clientContactsDataTable || []}
        loading={isLoading || isLoadingAllTypeOfContactList || isLoadingAllDepartmentList}
        lazy
        emptyMessage="Aucun contact ajouté"
        responsive={true}
        autoLayout
        className="contact-table p-ml-3 p-mt-0"
      >
        <Column
          key="firstName"
          field="firstName"
          header="Prénom"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          className="field"
        />
        <Column
          key="lastName"
          field="lastName"
          header="Nom"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          className="field"
        />
        <Column
          key="email"
          field="email"
          header="E-mail"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          className="field"
        />
        <Column
          key="service"
          header="Service"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          body={serviceBody}
          className="field"
        />
        <Column
          key="function"
          field="function"
          header="Fonction"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          className="field"
        />
        <Column
          key="type"
          header="Type"
          sortable={false}
          headerStyle={{ border: '0', width: '15%' }}
          body={typeBody}
          className="field"
        />
        <Column
          key="modifier"
          header=""
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
        />
      </DataTable>
      {isEditContactsDialogVisible && (
        <ContactsDialogForm
          visible={isEditContactsDialogVisible}
          onHide={() => handleShowEditDialog(false)}
          actionOnSuccessEdit={actionOnSuccessEdit}
          clientId={clientId}
          sageBase={sageBase}
          isEdit
          contactId={contactRowData.identifiant}
        />
      )}
      {isDeleteContactDialogVisible && (
        <DeleteContactDialog
          visible={isDeleteContactDialogVisible}
          onHide={() => handleShowDeleteDialog(false)}
          actionOnSuccessDelete={actionOnSuccessDelete}
          clientId={clientId}
          contactId={contactRowData.identifiant}
        />
      )}
    </>
  );
};

export default ContactsDataTable;
