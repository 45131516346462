import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { toast } from 'react-toastify';
import { throwError } from 'app/utils/functions';

/**
 * CONSTANTS
 * */

// NB : il ya un seul appel qui liste toutes les prestations et on fait la sépration coté front (si il y a date traitement ou pas)
const managerName = 'prestations';

const initialState = {
  listeApplicationsMetiers: [],
  pagePrestations: null,
  prestations: [],
  listeArticles: [],
  listeTypesPrestations: [],
  demandeTraiteeDetail: {},
  demandeTraiteeDetailVisible: false,
  loading: false,
  isArticlesLoading: false,
  pendingNotifier: false,
  demandeLiaisonAEditer: null,
  demandeLiaisonTraiteeAEditer: null,
  error: null,
  success: null,
  prestationsNew: {
    data: {},
    isLoading: false,
    isLoadingDelete: false,
    list: [],
  },
};

/**
 * ASYNC TREATEMENT
 * */
export const afficherPagePrestations = createAsyncThunk(
  `${managerName}/afficherPagePrestations`,
  async criteres => {
    const { data } = await API.Prestations.afficherPagePrestations(
      criteres.page,
      criteres.size,
      criteres.sortField,
      criteres.sortOrder === -1 ? 'DESC' : 'ASC',
      '', // (cas hors maquettes)
      '', // (cas hors maquettes)
      '', // (cas hors maquettes)
      criteres.multiColonnes === null ? '' : criteres.multiColonnes,
      criteres.types === null ? [] : criteres.types,
      criteres.etats === null ? [] : criteres.etats,
    );

    return data;
  },
);

export const getPrestationsNewListPaginated = createAsyncThunk(
  `${managerName}/getPrestationsNewListPaginated`,
  async ({ page, size }) => {
    try {
      const { data } = await API.Prestations.getPagePrestations(page, size, 'designation', 'ASC');

      return data;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const getListPrestations = createAsyncThunk(
  `${managerName}/getListPrestations`,
  async () => {
    try {
      const { data } = await API.Prestations.listerPrestations();

      return data;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const listerPrestations = createAsyncThunk(`${managerName}/listerPrestations`, async () => {
  try {
    return (await API.Prestations.listerPrestations()).data;
  } catch (error) {
    return throwError(error);
  }
});

export const listerTypesPrestations = createAsyncThunk(
  `${managerName}/listerTypesPrestations`,
  async () => API.TypesPrestations.listerTypesPrestations(),
);

export const creerTypePrestation = createAsyncThunk(
  `${managerName}/creerTypePrestation`,
  async typeApplication => API.TypesPrestations.creerTypePrestation(typeApplication),
);

export const supprimerPrestation = createAsyncThunk(
  `${managerName}/supprimerPrestation`,
  async prestation => API.Prestations.supprimerPrestation(prestation.identifiant),
);

export const creerPrestation = createAsyncThunk(
  `${managerName}/creerPrestation`,
  async prestation => {
    try {
      return await API.Prestations.creerPrestation(prestation);
    } catch (error) {
      return throwError(error);
    }
  },
);

export const createPrestation = createAsyncThunk(
  `${managerName}/createPrestation`,
  async (prestation, { rejectWithValue }) => {
    try {
      await API.Prestations.createPrestation(prestation);

      return prestation;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updatePrestation = createAsyncThunk(
  `${managerName}/updatePrestation`,
  async ({ prestationId, formData }, { rejectWithValue }) => {
    try {
      await API.Prestations.updatePrestation(prestationId, formData);

      return formData;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const deletePrestation = createAsyncThunk(
  `${managerName}/deletePrestation`,
  async ({ prestationId }, { rejectWithValue }) => {
    try {
      const { data } = await API.Prestations.deletePrestation(prestationId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const modifierPrestation = createAsyncThunk(
  `${managerName}/modifierPrestation`,
  async prestation =>
    API.Prestations.modifierPrestation(prestation.identifiant, prestation.prestation),
);

export const notifierPrestation = createAsyncThunk(
  `${managerName}/notifierPrestation`,
  async prestationId => API.Prestations.notifierPrestation(prestationId),
);

export const listerArticles = createAsyncThunk(
  `${managerName}/listerArticles`,
  async ({ fromSage, sageBase }, { rejectWithValue }) => {
    try {
      const { data } = await API.Articles.listerArticles(fromSage, sageBase);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const listerApplicationsMetiersPrestations = createAsyncThunk(
  `${managerName}/listerApplicationsMetiersPrestations`,
  async () => {
    const {
      data,
    } = await API.ApplicationsMetiersPrestations.listerApplicationsMetiersPrestations();
    return data;
  },
);

/**
 * SLICE
 * */
const creationPrestationSlice = createSlice({
  name: managerName,
  initialState,
  reducers: {
    loadDemandeAEditer: (state, action) => {
      state.demandeLiaisonAEditer = action.payload;
    },
    loadDemandeTraiteeAEditer: (state, action) => {
      state.demandeLiaisonTraiteeAEditer = action.payload;
    },
    setArticles: (state, action) => {
      state.listeArticles = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPrestationsNewListPaginated.pending, state => {
      state.prestationsNew.isLoading = true;
    });
    builder.addCase(getPrestationsNewListPaginated.fulfilled, (state, action) => {
      state.prestationsNew.isLoading = false;
      state.prestationsNew.data = action.payload;
    });
    builder.addCase(getPrestationsNewListPaginated.rejected, state => {
      state.prestationsNew.isLoading = false;
    });

    builder.addCase(getListPrestations.pending, state => {
      state.prestationsNew.isLoading = true;
    });
    builder.addCase(getListPrestations.fulfilled, (state, action) => {
      state.prestationsNew.isLoading = false;
      state.prestationsNew.list = action.payload;
    });
    builder.addCase(getListPrestations.rejected, state => {
      state.prestationsNew.isLoading = false;
    });

    builder.addCase(createPrestation.pending, state => {
      state.prestationsNew.isLoading = true;
    });
    builder.addCase(createPrestation.fulfilled, (state, action) => {
      const { designation } = action.payload;
      toast.success(`La prestation "${designation}" a bien été ajoutée`);
      state.prestationsNew.isLoading = false;
    });
    builder.addCase(createPrestation.rejected, state => {
      state.prestationsNew.isLoading = false;
    });

    builder.addCase(updatePrestation.pending, state => {
      state.prestationsNew.isLoadingDelete = true;
    });
    builder.addCase(updatePrestation.fulfilled, state => {
      toast.info(`La prestation a bien été modifiée.`);
      state.prestationsNew.isLoadingDelete = false;
    });
    builder.addCase(updatePrestation.rejected, state => {
      state.prestationsNew.isLoadingDelete = false;
    });

    builder.addCase(deletePrestation.pending, state => {
      state.prestationsNew.isLoadingDelete = true;
    });
    builder.addCase(deletePrestation.fulfilled, state => {
      toast.info(`Le prestation a été supprimé.`);
      state.prestationsNew.isLoadingDelete = false;
    });
    builder.addCase(deletePrestation.rejected, state => {
      state.prestationsNew.isLoadingDelete = false;
    });

    // listeApplicationsMetiersPrestations
    builder.addCase(listerApplicationsMetiersPrestations.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerApplicationsMetiersPrestations.fulfilled, (state, action) => {
      state.listeApplicationsMetiers = action.payload;
      state.loading = false;
    });
    builder.addCase(listerApplicationsMetiersPrestations.rejected, state => {
      state.loading = false;
    });

    // fetchPagePrestations
    builder.addCase(afficherPagePrestations.pending, state => {
      state.loading = true;
    });
    builder.addCase(afficherPagePrestations.fulfilled, (state, action) => {
      state.pagePrestations = action.payload;
      state.loading = false;
    });
    builder.addCase(afficherPagePrestations.rejected, state => {
      state.loading = false;
    });

    // removeDemandeLiaisonById
    builder.addCase(supprimerPrestation.pending, state => {
      state.loading = true;
    });
    builder.addCase(supprimerPrestation.fulfilled, (state, action) => {
      const { identifiant } = action.meta.arg;
      state.pagePrestations.content = state.pagePrestations.content.filter(
        demande => identifiant !== demande.identifiant,
      );
      // eslint-disable-next-line operator-assignment
      state.pagePrestations.totalElements = state.pagePrestations.totalElements - 1;
      state.loading = false;
    });
    builder.addCase(supprimerPrestation.rejected, state => {
      state.loading = false;
    });

    builder.addCase(listerPrestations.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerPrestations.fulfilled, (state, action) => {
      state.prestations = action.payload;
      state.loading = false;
    });
    builder.addCase(listerPrestations.rejected, state => {
      state.loading = false;
      const errorMessage = 'Une erreur est produite durant la récupération des prestations';
      state.error = {
        messageMetier: errorMessage,
      };
      toast.error(errorMessage);
    });

    // typePrestation
    builder.addCase(listerTypesPrestations.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerTypesPrestations.fulfilled, (state, action) => {
      state.listeTypesPrestations = action.payload.data;
      state.loading = false;
    });
    builder.addCase(listerTypesPrestations.rejected, state => {
      state.loading = false;
    });
    // creerPrestation
    builder.addCase(creerPrestation.pending, state => {
      state.loading = true;
    });
    builder.addCase(creerPrestation.fulfilled, state => {
      state.loading = false;
      const successMessage = 'Prestation créée avec succès !';
      state.success = {
        messageMetier: successMessage,
      };
      toast.success(successMessage);
    });
    builder.addCase(creerPrestation.rejected, state => {
      state.loading = false;
      const errorMessage = 'Erreur dans la création de la prestation';
      state.error = {
        messageMetier: errorMessage,
      };
    });
    // modifierPrestation
    builder.addCase(modifierPrestation.pending, state => {
      state.loading = true;
    });
    builder.addCase(modifierPrestation.fulfilled, (state, action) => {
      // NB: On a ne reçoit pas l'id coté back on utilise l'id envoyé dans meta
      // TODO: UTILISER FIND
      state.demandeLiaisonAEditer = null;
      state.loading = false;
      state.pagePrestations.content = state.pagePrestations.content.map(demande => {
        if (demande.identifiant === action.meta.arg.identifiant) {
          return {
            ...demande,
            ...action.meta.arg.prestation,
            type: { identifiant: action.meta.arg.prestation.identifiantTypePrestation },
          };
        }
        return demande;
      });
    });
    builder.addCase(modifierPrestation.rejected, state => {
      state.loading = false;
    });
    // creerTypePrestation
    builder.addCase(creerTypePrestation.pending, state => {
      state.loading = true;
    });
    builder.addCase(creerTypePrestation.fulfilled, (state, action) => {
      state.loading = false;
      state.listeTypesPrestations = [
        ...state.listeTypesPrestations,
        { ...action.meta.arg, identifiant: action.payload.data.id },
      ];
    });
    builder.addCase(creerTypePrestation.rejected, state => {
      state.loading = false;
    });

    builder.addCase(notifierPrestation.pending, state => {
      state.pendingNotifier = true;
    });
    builder.addCase(notifierPrestation.fulfilled, state => {
      state.pendingNotifier = false;
      const successMessage = 'Relancé avec succès !';
      state.success = { messageMetier: successMessage };
      toast.success(successMessage);
    });
    builder.addCase(notifierPrestation.rejected, state => {
      state.pendingNotifier = false;
      const erreurMessage = 'Relancé avec succès !';
      state.error = { messageMetier: erreurMessage };
      toast.error(erreurMessage);
    });

    builder.addCase(listerArticles.pending, state => {
      state.isArticlesLoading = true;
    });
    builder.addCase(listerArticles.fulfilled, (state, action) => {
      state.listeArticles = action.payload;
      state.isArticlesLoading = false;
    });
    builder.addCase(listerArticles.rejected, (state, action) => {
      state.isArticlesLoading = false;
      toast.error(action.error.message);
    });
  },
});

const managerStateSelector = state => state[managerName];

/**
 * SELECTORS
 * */
export const selectPagePrestations = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.pagePrestations,
);

export const selectListeDemandesLiaisonTraitees = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.listeDemandesLiaisonTraitees,
);

export const selectPrestations = createSelector(managerStateSelector, state => state.prestations);

export const selectListeTypesPrestations = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.listeTypesPrestations,
);

export const selectDemandeLiaisonAEditer = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.demandeLiaisonAEditer,
);

export const selectDemandeLiaisonTraiteeAEditer = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.demandeLiaisonTraiteeAEditer,
);

export const selectError = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.error,
);

export const selectSuccess = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.success,
);

export const selectIsListeArticlesLoading = createSelector(
  managerStateSelector,
  lierPrestationState => lierPrestationState.isArticlesLoading,
);

export const selectListeArticles = createSelector(
  managerStateSelector,
  lierPrestationState => lierPrestationState.listeArticles,
);

export const selectPendingNotifier = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.selectPendingNotifier,
);

export const selectListeApplicationsMetiers = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.listeApplicationsMetiers,
);

export const selectListPrestationsPaginated = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.prestationsNew.data,
);

export const selectListPrestations = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.prestationsNew.list,
);

export const selectIsLoadingListPrestations = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.prestationsNew.isLoading,
);

export const selectIsLoadingDeletePrestation = createSelector(
  managerStateSelector,
  creationPrestation => creationPrestation.prestationsNew.isLoadingDelete,
);

/**
 * EXPORTS
 * */

export default creationPrestationSlice.reducer;
export const { actions } = creationPrestationSlice;
export const {
  ajoutPrestation,
  loadListPrestation,
  showDemandeTraiteeDetail,
  hideDemandeTraiteeDetail,
  loadDemandeAEditer,
  loadDemandeTraiteeAEditer,
  setArticles,
} = actions;
