import React, { useRef } from 'react';
import { Button } from 'olaqin-design-system';
import { Menu } from 'primereact/menu';

const ActionMenu = ({ onClick, onClickDuplicate }) => {
  const menu = useRef(null);

  const subActionsModel = [
    {
      label: 'Mettre à jour les informations du client',
      command: onClick,
      icon: 'pi pi-pencil',
      style: { fontWeight: '400' },
    },
    {
      label: 'Dupliquer',
      command: onClickDuplicate,
      icon: 'pi pi-copy',
      style: { fontWeight: '400' },
    },
  ];

  return (
    <>
      <Menu
        style={{ width: '22rem' }}
        model={subActionsModel}
        popup
        ref={menu}
        id="popup_menu_black"
        appendTo={document.body}
      />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        iconPos="right"
        btnType="primary"
        onClick={event => menu.current.toggle(event)}
        aria-controls="popup_menu_black"
        aria-haspopup
        className="p-mr-3 p-py-2 p-px-3"
        style={{
          height: '34px',
          background: '#FFFFFF',
          border: '1px solid rgba(106, 106, 106, 0.24)',
          color: 'black',
          fontSize: '14px',
          fontFamily: 'Arial',
        }}
      />
    </>
  );
};

export default ActionMenu;
