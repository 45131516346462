import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  getDeliveryAddressesAll,
  selectDeliveryAddressDataTable,
  selectDeliveryAddressDataTableIsLoading,
  getDeliveryAddress,
  getCompanyDeliveryList,
  getCompanyDeliveryRequirementList,
  selectCompanyDeliveryList,
  selectCompanyDeliveryRequirementList,
  selectIsLoadingCompanyDeliveryRequirementList,
  selectIsLoadingCompanyDeliveryList,
  selectClientDetailsDataClient,
} from 'app/redux/slices/teams';
import ActionButton from 'components/_shared/ActionButton/ActionButton';
import DeliveryAddressDialogForm from '../Dialog/DeliveryAddressDialogForm';
import './DeliveryAddressDataTable.scss';

const DeliveryAddressDataTable = ({ clientId, sageBase }) => {
  const dispatch = useDispatch();
  const deliveryAddressData = useSelector(selectDeliveryAddressDataTable);
  const companyDeliveryList = useSelector(selectCompanyDeliveryList);
  const companyDeliveryRequirementList = useSelector(selectCompanyDeliveryRequirementList);
  const isLoadingRequirementList = useSelector(selectIsLoadingCompanyDeliveryRequirementList);
  const isLoadingDeliveryList = useSelector(selectIsLoadingCompanyDeliveryList);
  const isLoading = useSelector(selectDeliveryAddressDataTableIsLoading);
  const clientData = useSelector(selectClientDetailsDataClient);

  const [isDeliveryAddressDialogVisible, handleShowDialog] = useState(false);

  const handleClickRow = ({ identifiant }) => {
    dispatch(getDeliveryAddress({ clientId, deliveryAddressId: identifiant }));
    handleShowDialog(true);
  };

  const actionOnSuccessEdit = () => {
    dispatch(getDeliveryAddressesAll({ id: clientId }));
  };

  useEffect(() => {
    dispatch(getDeliveryAddressesAll({ id: clientId }));
    // labels for Expeditions column
    dispatch(getCompanyDeliveryList(sageBase));
    dispatch(getCompanyDeliveryRequirementList(sageBase));
  }, []);

  const addressBody = ({
    adresse,
    complement,
    code_postal: postalCode,
    code_region: regionalCode,
    ville,
    pays,
  }) => (
    <div className="column-body">
      <div>{adresse}</div>
      {complement && <div>{complement}</div>}
      <div>
        {postalCode}, {regionalCode}
      </div>
      <div>
        {ville}, {pays}
      </div>
    </div>
  );

  const contactBody = ({ contact, telephone, email }) => (
    <div>
      <div>{contact}</div>
      <div>{telephone}</div>
      <div>{email}</div>
    </div>
  );

  const expeditionBody = ({ expedition, condition }) => {
    const findExpedition = companyDeliveryList.find(delivery => delivery.id === expedition);
    const findCondition = companyDeliveryRequirementList.find(
      deliveryReq => deliveryReq.id === condition,
    );
    return (
      <div>
        <div>{findExpedition?.intitule || expedition}</div>
        <div>{findCondition?.intitule || condition}</div>
      </div>
    );
  };

  const bodyActionButton = ({ identifiant }) => {
    const actionsModel = [
      {
        label: 'Modifier',
        command: () => handleClickRow({ identifiant }),
      },
    ];
    return <ActionButton model={actionsModel} />;
  };

  return (
    <>
      <DataTable
        id="delivery-address-table"
        style={{ marginTop: '16px' }}
        value={deliveryAddressData || []}
        loading={isLoading || isLoadingRequirementList || isLoadingDeliveryList}
        lazy
        emptyMessage="Aucune adresse ajoutée"
        responsive={true}
        autoLayout
        className="delivery-address-table p-mb-3"
      >
        <Column
          key="name"
          field="name"
          header="Intitulé"
          sortable={false}
          headerStyle={{ border: '0', width: '16%' }}
          className="field"
        />
        <Column
          key="Adresse"
          header="Adresse"
          sortable={false}
          headerStyle={{ border: '0', width: '26%' }}
          body={addressBody}
          className="field"
        />
        <Column
          key="Contact"
          header="Contact"
          sortable={false}
          headerStyle={{ border: '0', width: '26%' }}
          body={contactBody}
          className="field"
        />
        <Column
          key="expedition"
          header="Expédition"
          sortable={false}
          headerStyle={{ border: '0', width: '26%' }}
          body={expeditionBody}
          className="field"
        />
        <Column
          key="modifier"
          header=""
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '6%' }}
        />
      </DataTable>
      {isDeliveryAddressDialogVisible && (
        <DeliveryAddressDialogForm
          visible={isDeliveryAddressDialogVisible}
          onHide={() => handleShowDialog(false)}
          actionOnSuccessEdit={actionOnSuccessEdit}
          clientId={clientId}
          clientData={clientData}
          sageBase={sageBase}
          isEdit
        />
      )}
    </>
  );
};

export default DeliveryAddressDataTable;
