import React from 'react';
import ConnectionsTableCard from 'components/clientsDetail/Tabs/Connections/ConnectionsTableCard';
import { useDispatch } from 'react-redux';
import { chargerSouscription } from 'app/redux/slices/souscriptions';
import CardDetails from '../../cards/CardDetails';
import CardPrestations from '../../cards/CardPrestations';
import CardDocument from '../../cards/CardDocument';
import CardDetailsOfSubscription from '../../cards/CardDetailsOfSubscription';
import CardDetailsOfThirdPartyPayer from '../../cards/CardDetailsOfTPP';
import CardStellair from '../../cards/CardStellair';

const GeneralTab = ({ souscription }) => {
  const dispatch = useDispatch();
  const { offreCommerciale, thirdPartyPayer } = souscription;
  const sageBaseType = offreCommerciale?.sageBase;
  const refetchData = () => {
    dispatch(chargerSouscription({ souscriptionId: souscription.identifiant }));
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <CardDetails title="Détails du client" sageBase={sageBaseType} style={{ width: 'auto' }} />
        <ConnectionsTableCard clientDetails={souscription.souscripteur} refetchData={refetchData} />
        {thirdPartyPayer && (
          <>
            <CardDetailsOfThirdPartyPayer title="Détails du tiers-payeur" />
            <ConnectionsTableCard
              clientDetails={souscription.thirdPartyPayerClient}
              cardTitle={'Comptes de gestion internes du tiers-payeur'}
              refetchData={refetchData}
            />
          </>
        )}
        <CardDetailsOfSubscription title="Détails de la souscription" />
        {offreCommerciale?.enableStellairConnection && (
          <CardStellair
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src="/icons/StellairLogo-32.svg"
                  alt="Stellair"
                  style={{ marginRight: 4, width: 24, height: 24 }}
                />
                Stellair
              </div>
            }
          />
        )}

        <CardPrestations title="Offre souscrite" />
        <CardDocument title="Document" souscription={souscription} />
      </div>
    </div>
  );
};

export default GeneralTab;
