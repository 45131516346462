import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { pricingRevisionIndexSettingsOptions } from 'app/constants/offerConstants';

const OfferConditions = ({ offerData }) => {
  const {
    periodiciteFacturation,
    engagement,
    reconductionRenouvellement,
    pricingRevisionIndexSettings,
  } = offerData;

  const renderTarifS0 = () => {
    if (pricingRevisionIndexSettings?.type === pricingRevisionIndexSettingsOptions[2].value) {
      return `Indice spécifique (${pricingRevisionIndexSettings.value}) ${pricingRevisionIndexSettings.name}`;
    }
    return (
      pricingRevisionIndexSettingsOptions.find(
        item => item.value === pricingRevisionIndexSettings?.type,
      )?.label ?? ''
    );
  };
  return (
    <CardDisplay style={{ width: 'auto' }} title="Conditions contractuelles">
      <div className="p-grid item p-mt-2">
        <span className="p-md-3">Périodicité de facturation:</span>
        <span className="p-md-9">{periodiciteFacturation}</span>
      </div>
      <div className="p-grid item">
        <span className="p-md-3">Durée d’engagement :</span>
        <span className="p-md-9">{engagement} mois</span>
      </div>
      <div className="p-grid item">
        <span className="p-md-3">Reconduction :</span>
        <span className="p-md-9">{reconductionRenouvellement}</span>
      </div>
      {pricingRevisionIndexSettings && (
        <div className="p-grid item">
          <span className="p-md-3">Réf. révision tarifaire (S0) :</span>
          <span className="p-md-9">{renderTarifS0()}</span>
        </div>
      )}
    </CardDisplay>
  );
};

export default OfferConditions;
