import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'olaqin-design-system';
import { Column } from 'primereact/column';
import { formatDate } from 'app/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import usePagination from 'app/hooks/usePagination';
import {
  fetchSavedReportsGridData,
  selectSavedReportsGridData,
  selectIsSavedReportsLoading,
  selectIsReportsGenerating,
  ExportReportsTypes,
} from 'app/redux/slices/reports';
import { getKeyForDownloadLink, selectDownloadKeys } from 'app/redux/slices/souscriptions';
import { generateSavedReportsLinkToFile } from 'components/reports/reportsUtils';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import customStyle from 'components/reports/Reports.module.scss';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { ExportReportsOptions } from 'app/constants/reports.contants';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import GenerateExportConfirmModal from './GenerateExportConfirmModal';

const ReportsExportDataTable = () => {
  const dispatch = useDispatch();
  const [isExportModalShow, setExportModalShow] = useState(false);

  const [selectedReportsType, setSelectedReportsType] = useState(ExportReportsTypes.SUBSCRIPTION);
  const { onTablePageChange } = usePagination({
    dataFetchBy: fetchSavedReportsGridData,
  });
  const isReportGenerating = useSelector(selectIsReportsGenerating);
  const { reports: downloadKey } = useSelector(selectDownloadKeys);
  const isLoadingReportsData = useSelector(selectIsSavedReportsLoading);
  const reportsData = useSelector(selectSavedReportsGridData);

  const dateTimeFormat = (row, fieldName) => (
    <span style={{ color: row.generating ? '#6A6A6A' : 'inherit' }}>
      {row.generating
        ? row[fieldName]
        : dashOnEmpty(formatDate(row[fieldName], 'DD/MM/YYYY - HH:mm'))}
    </span>
  );

  const bodyActionButton = ({ id, name, generating }) => {
    const isReadyForDownload = !!downloadKey && !!id;

    const linkToFile = generateSavedReportsLinkToFile(downloadKey, id);

    if (generating) return null;

    return isReadyForDownload ? (
      <a href={linkToFile} download={name}>
        <Button
          icon="pi pi-download"
          type="secondary"
          btnType="secondary"
          className={`${customStyle.customButtonDownload} p-m-0`}
          disabled={!isReadyForDownload}
        />
      </a>
    ) : (
      <Button
        icon="pi pi-download"
        type="secondary"
        btnType="secondary"
        disabled={isReadyForDownload}
        className={`${customStyle.customButtonDownload} p-m-0`}
      />
    );
  };

  const bodyReportType = ({ type }) =>
    ExportReportsOptions.find(item => item.value === type)?.label ?? '';

  const handleGenerateReports = async () => {
    setExportModalShow(true);
  };

  useEffect(() => {
    dispatch(getKeyForDownloadLink());
  }, []);

  return (
    <>
      <GenerateExportConfirmModal
        visible={isExportModalShow}
        onHide={() => setExportModalShow(null)}
        reportSource={selectedReportsType}
        downloadKey={downloadKey}
      />
      <div className="p-mb-3 p-d-flex">
        <PrimeDropdown
          value={selectedReportsType}
          onChange={e => setSelectedReportsType(e.target.value)}
          options={ExportReportsOptions}
          style={{ borderRadius: 6 }}
        />
        <div className="p-ml-1">
          <SubmitButton
            btnType="primary"
            label="Générer l’export"
            onClick={handleGenerateReports}
            style={{ height: 36 }}
            type="button"
            disabled={!downloadKey || isReportGenerating}
          />
        </div>
      </div>
      <DataTable
        id="reportsExport-table"
        value={reportsData.content || []}
        loading={isLoadingReportsData}
        paginator
        onPage={onTablePageChange}
        first={reportsData.pageable?.offset || 0}
        numberOfElements={reportsData.numberOfElements || 0}
        totalRecords={reportsData.totalElements || 0}
        rows={reportsData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun export disponible"
        responsive
        rowHover
        autoLayout
      >
        <Column
          field="type"
          header="Type de données"
          headerStyle={{ border: '0', width: '20%' }}
          body={bodyReportType}
        />
        <Column
          field="dateCreation"
          header="Date de génération"
          headerStyle={{ border: '0', width: '15%' }}
          body={data => dateTimeFormat(data, 'dateCreation')}
        />
        <Column field="createdBy" header="Généré par" headerStyle={{ border: '0', width: '20%' }} />
        <Column
          field="expiration"
          header="Expire le"
          headerStyle={{ border: '0', width: '20%' }}
          body={data => dateTimeFormat(data, 'expiration')}
        />
        <Column
          key="supp"
          header=""
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
        />
      </DataTable>
    </>
  );
};

export default ReportsExportDataTable;
