import React from 'react';
import { getCompanyDeliveryList, getCompanyDeliveryRequirementList } from 'app/redux/slices/teams';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';

const DeliveryAddressFormField = ({ sageBase }) => (
  <FormFieldGroup className="p-mt-2">
    <FormFieldInputText name="name" label="Intitulé de l’adresse" placeholder="" />
    <FormFieldInputText name="adresse" label="Adresse" placeholder="Ligne adresse 1" />
    <FormFieldInputText name="complement" placeholder="Ligne adresse 2" />
    <FormFieldInputText name="code_postal" placeholder="Code postal" colSpan="4" />
    <FormFieldInputText name="ville" placeholder="Ville" colSpan="8" />
    <FormFieldInputText name="code_region" placeholder="Région" />
    <FormFieldInputText name="pays" placeholder="Pays" />
    <FormFieldDropdown
      name="expedition"
      label="Expédition"
      placeholder="Sélectionner"
      loadOptions={{
        action: () => getCompanyDeliveryList(sageBase),
        optionLabel: 'intitule',
      }}
    />
    <FormFieldDropdown
      name="condition"
      placeholder="Sélectionner"
      loadOptions={{
        action: () => getCompanyDeliveryRequirementList(sageBase),
        optionLabel: 'intitule',
      }}
    />
    <FormFieldInputText name="contact" label="Contact" placeholder="Nom complet" />
    <FormFieldInputText name="telephone" placeholder="Téléphone" />
    <FormFieldInputText name="email" placeholder="E-mail" />
  </FormFieldGroup>
);

export default DeliveryAddressFormField;
