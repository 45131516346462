import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'app/routes/appRoutes';
import { Divider } from 'primereact/divider';
import { useFormState } from 'react-final-form';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { selectOffresLoading, updateOffer } from 'app/redux/slices/offres';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import customStyle from '../../souscriptions/createSubscription/createSubscription.module.scss';
import OfferUpdateLeaveDialog from '../Dialogs/OfferUpdateLeaveDialog';

const OfferParametersEditHeader = ({ offerId, offerName }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dirty, values: formValues } = useFormState();

  const [isLeavingModalOpen, setLeavingModalOpen] = useState(false);
  const isOfferLoading = useSelector(selectOffresLoading);

  const handleSubmit = () => {
    if (!dirty) {
      redirectToDetailsView();
    } else {
      setLeavingModalOpen(true);
    }
  };

  const redirectToDetailsView = () => {
    history.push(routes.OFFER_DETAILS(offerId));
  };

  const handleClickSaveParameters = async () => {
    await dispatch(
      updateOffer({
        offreCommercialeId: offerId,
        offreCommercialeForm: formValues,
      }),
    )
      .unwrap()
      .then(() => {
        toast('Les modifications ont bien été enregistrées.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
        history.push(routes.OFFER_DETAILS(offerId));
      })
      .catch(() => {});
  };

  return (
    <div className={customStyle.formHeader}>
      <div className="p-d-flex p-align-center">
        <OfferUpdateLeaveDialog
          visible={isLeavingModalOpen}
          onHide={() => setLeavingModalOpen(false)}
          actionOnSuccess={redirectToDetailsView}
        />
        <SubmitButton
          style={{
            border: 'none',
            width: '16px',
            height: '16px',
            padding: '16px',
          }}
          className="p-mx-1"
          btnType="secondary"
          outlined
          icon="pi pi-times"
          type="button"
          onClick={handleSubmit}
        />
        <Divider className="p-py-1" layout="vertical" />
        <p
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '20px',
            fontWeight: '700',
            lineHeight: '24px',
            color: '#000000',
          }}
        >
          Paramètres de facturation de l’offre {offerName}
        </p>
      </div>
      <div>
        <SubmitButton
          className="p-mx-2"
          type="button"
          btnType="secondary"
          label={'Enregistrer et quitter'}
          onClick={handleClickSaveParameters}
          isLoading={isOfferLoading}
          outlined
          small
        />
      </div>
    </div>
  );
};

export default OfferParametersEditHeader;
