import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

const DeleteSouscriptionDialog = ({ visible, onHide, onConfirm, isLoading }) => (
  <DialogBox
    id="deleteSouscriptionDialog"
    visible={visible}
    onHide={onHide}
    width={500}
    title={'Suppression de la souscription'}
    isLoading={isLoading}
    onCancel={onHide}
    cancelLabel="Annuler"
    onConfirm={onConfirm}
    confirmLabel="Confirmer la suppression"
    isConfirmButtonDangerColor
    noTopSeparator
    dismissableMask
  >
    <div style={{ marginBottom: 16 }}>
      Toutes les informations apportées seront définitivement perdues. Souhaitez-vous réellement
      supprimer la souscription ?
    </div>
  </DialogBox>
);

export default DeleteSouscriptionDialog;
