import React from 'react';

const CustomErrorBoundaryFallbackComponent = () => (
  <div className="p-fluid">
    <div className="p-grid">
      <div className="p-col-12">
        <div
          className="p-messages p-component p-messages-error"
          style={{ margin: '0 0 1em 0', display: 'block' }}
        >
          <div className="p-messages-wrapper">
            <span className="p-messages-icon pi pi-fw pi-2x pi-check" />
            <ul>
              <li>
                <span id="msgErreurTechniqueId" className="p-messages-detail">
                  Une erreur technique s'est produite, veuillez réessayer ultérieurement.
                </span>
              </li>
              <li>
                <span id="msgErreurTechniqueContactId" className="p-messages-detail">
                  Si le problème persiste après le rechargement de la page, contactez Olaqin.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CustomErrorBoundaryFallbackComponent;
