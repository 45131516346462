import React from 'react';

const HeaderPage = ({ title, children }) => (
  <div className="p-d-flex p-justify-between p-align-center">
    <p
      style={{
        fontFamily: 'RecifeDisplay',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '38px',
        lineHeight: '40px',
        color: '#19171a',
      }}
    >
      {title}
    </p>
    {children}
  </div>
);

export default HeaderPage;
