import React from 'react';
import { RadioButton } from 'olaqin-design-system';
import './RadioBox.scss';

const RadioBox = ({
  children,
  inputId,
  className: radioClassName = '',
  type = 'checkbox',
  wrapperClassName = '',
  isWrapperDisplayContents = false,
  label,
  labelProps: { style: labelStyle, ...otherLabelProps } = {},
  ...radioProps
}) => {
  const id = inputId || Math.random().toString();

  return (
    <div
      style={isWrapperDisplayContents ? { display: 'contents' } : {}}
      className={`radioBox ${wrapperClassName}`}
    >
      <RadioButton className={radioClassName} inputId={id} type={type} {...radioProps} />
      {label && (
        <label
          htmlFor={id}
          style={{
            fontSize: '14px',
            paddingLeft: '16px',
            userSelect: 'none',
            marginLeft: '-8px',
            ...labelStyle,
          }}
          {...otherLabelProps}
        >
          {label}
        </label>
      )}
      {children && <div className="childrenWrapper">{children}</div>}
    </div>
  );
};

export default RadioBox;
