import React from 'react';
import { Button } from 'olaqin-design-system';

const ClientInfoPreview = ({ client, footerAction }) => {
  const {
    adresseFacturation,
    adresseLivraison,
    memeAdresseFacturation,
    email,
    numeroClient,
  } = client;

  const checkPresented = field => (field ? `${field} ` : '');

  const combinedAddress = address => {
    const { adresse, complement, codePostal, ville, region, pays } = address;
    const addressArray = [adresse, complement, codePostal, ville, region, pays];
    return addressArray.reduce((acc, curr) => {
      const checkedAddress = checkPresented(curr);
      return acc + checkedAddress;
    }, '');
  };

  const firstAddress = adresseFacturation ? combinedAddress(adresseFacturation) : '';
  const secondAddress =
    memeAdresseFacturation || !adresseLivraison ? firstAddress : combinedAddress(adresseLivraison);

  const defaultStyle = {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 'normal',
    color: 'black',
  };

  return (
    <>
      <div className="p-grid p-m-0">
        <span className="p-md-3 p-pl-0 p-text-bold" style={defaultStyle}>
          Numéro client :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {numeroClient}
        </span>
        <span className="p-md-3 p-pl-0 p-text-bold" style={defaultStyle}>
          Adresse e-mail :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {email}
        </span>
        <span className="p-md-3 p-pl-0 p-text-bold" style={defaultStyle}>
          Adresse de facturation :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {firstAddress || '–'}
        </span>
        <span className="p-md-3 p-pl-0 p-text-bold" style={defaultStyle}>
          Adresse de livraison :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {secondAddress || '–'}
        </span>
      </div>
      <Button
        type="button"
        style={{ textAlign: 'left' }}
        btnType="ghost-action"
        onClick={footerAction}
        label="Mettre à jour les informations du client"
      />
    </>
  );
};

export default ClientInfoPreview;
