import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardDisplay, Column, Datatable, SplitButton } from 'olaqin-design-system';
import ellipsis from 'olaqin-design-system/src/assets/icons/ellipsis.svg';
import { FileUpload as PrimeFileUpload } from 'primereact/fileupload';
import { toast } from 'react-toastify';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  deleteContractSouscription,
  getAllContractsSouscription,
  resetDocuments,
  selectDocumentsData,
  selectDocumentsKeys,
  selectIsDocumentsLoading,
  selectIsDocumentsLoadingDelete,
  uploadContractSouscription,
} from 'app/redux/slices/souscriptions';
import { formatDate } from 'app/utils/dateUtils';
import { subscriptionStatuses } from 'app/constants/subscription.constants';
import { generateLinkDocumentById } from './cardDocumentUtils';
import DeleteDocumentSubscriptionDialog from '../dialogs/DeleteDocumentSubscriptionDialog';

// ffs primereact https://github.com/primefaces/primereact/issues/2026
// eslint-disable-next-line func-names
PrimeFileUpload.prototype.clearInputElement = function() {
  if (this.fileInput) {
    this.fileInput.value = '';
  }
};

const CardDocument = ({ title, souscription }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsDocumentsLoading);
  const isLoadingDelete = useSelector(selectIsDocumentsLoadingDelete);
  const documentsData = useSelector(selectDocumentsData);
  const documentsKeys = useSelector(selectDocumentsKeys);
  const souscriptionId = souscription.identifiant;

  const uploadFileRef = useRef(null);

  const currentStatus = souscription.statut;

  const [isDeleteDocumentOpen, openDeleteDocument] = useState({ isOpen: false, id: '' });

  const closeDeleteDocumentDialog = () => openDeleteDocument({ isOpen: false, id: '' });

  useEffect(() => {
    dispatch(getAllContractsSouscription(souscriptionId));
    return () => {
      dispatch(resetDocuments());
    };
  }, []);

  const confirmDeleteDocument = () => {
    dispatch(
      deleteContractSouscription({ souscriptionId, contractFileLinkId: isDeleteDocumentOpen.id }),
    )
      .unwrap()
      .then(() => {
        closeDeleteDocumentDialog();
        dispatch(getAllContractsSouscription(souscriptionId));
      });
  };

  const clearCurrentFile = () => {
    if (uploadFileRef.current) {
      uploadFileRef.current.clear();
    }
  };

  const uploadHandler = ({ files }) => {
    const [file] = files;

    if (file.type !== 'application/pdf') {
      toast.error('Seul les fichiers au format PDF sont prit en charge');
      clearCurrentFile();
    } else {
      dispatch(
        uploadContractSouscription({ souscriptionId, name: file.name, contract: file }),
      ).then(() => {
        dispatch(getAllContractsSouscription(souscriptionId));
        clearCurrentFile();
      });
    }
  };

  const onValidationFail = () => {
    toast.error('Le fichier doit être inférieure à 10 mo');
  };

  const filenameLink = rowData => {
    const linkToDoc = generateLinkDocumentById({
      fileId: rowData.identifiant,
      documentsKeys,
      souscriptionId,
    });
    return (
      <a
        href={linkToDoc}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'flex',
          fontSize: '14px',
          fontFamily: 'OpenSans',
          color: '#3688FF',
          cursor: 'pointer',
        }}
      >
        <i
          style={{
            marginRight: '8px',
          }}
          className="pi pi-file-pdf"
        />
        <span>{rowData.fileName}</span>
      </a>
    );
  };

  const renderDate = rowData => {
    const formattedDate = formatDate(rowData.dateCreation, 'DD/MM/YYYY');
    return <span>{formattedDate}</span>;
  };

  const actionButton = ({ identifiant: fileId }) => {
    const actionsModel = [
      {
        label: 'Supprimer',
        command: () => openDeleteDocument({ isOpen: true, id: fileId }),
      },
    ];

    return (
      <>
        <span className="p-column-title">Actions</span>
        {canDeleteDocument && (
          <SplitButton
            id="splitButtonDocumentsId"
            appendTo={document.body}
            chevronDown={ellipsis}
            model={actionsModel}
            deg="180"
          />
        )}
      </>
    );
  };

  const noDocumentsButCanAdd =
    (!documentsData.length || documentsData.length < 10) &&
    (currentStatus === subscriptionStatuses.INCOMPLETE ||
      currentStatus === subscriptionStatuses.ACTIVE);

  const noDocumentsAndCanNotAdd =
    !documentsData.length &&
    !(
      currentStatus === subscriptionStatuses.INCOMPLETE ||
      currentStatus === subscriptionStatuses.ACTIVE
    );

  const SIZE_10MB = 10485760;

  const canDeleteDocument =
    currentStatus === subscriptionStatuses.INCOMPLETE ||
    currentStatus === subscriptionStatuses.ACTIVE;

  return (
    <CardDisplay title={title} style={{ width: 'auto' }}>
      {isLoading ? (
        <ProgressSpinner style={{ width: '52px', marginTop: '16px' }} />
      ) : (
        <>
          {noDocumentsAndCanNotAdd && (
            <span className="p-col-12 p-mt-3" style={{ fontSize: '14px' }}>
              Aucun document
            </span>
          )}
          {!!documentsData.length && (
            <Datatable
              id="uploadContractTable"
              style={{ marginTop: '16px' }}
              value={documentsData}
              lazy
              emptyMessage="Aucun élément"
              responsive={true}
              autoLayout
            >
              <Column
                key="fileName"
                field="fileName"
                header="Référence"
                body={filenameLink}
                sortable={false}
                headerStyle={{ border: '0', width: '46%' }}
              />
              <Column
                key="dateCreation"
                field="dateCreation"
                header="Date d’ajout"
                headerStyle={{ border: '0', width: '46%' }}
                body={renderDate}
                sortable={false}
              />
              <Column
                key="supp"
                header="Actions"
                body={actionButton}
                className="action"
                bodyStyle={{ textOverflow: 'initial' }}
                headerStyle={{ border: '0', width: '8%' }}
              />
            </Datatable>
          )}
          {noDocumentsButCanAdd && (
            <div style={{ marginTop: '16px' }}>
              <PrimeFileUpload
                ref={uploadFileRef}
                chooseLabel="Ajouter un document"
                customUpload
                auto
                maxFileSize={SIZE_10MB}
                id="documentUploadSub"
                mode="basic"
                uploadHandler={uploadHandler}
                onValidationFail={onValidationFail}
                accept="application/pdf"
              />
            </div>
          )}
          {isDeleteDocumentOpen.isOpen && (
            <DeleteDocumentSubscriptionDialog
              visible={isDeleteDocumentOpen.isOpen}
              onHide={closeDeleteDocumentDialog}
              onCancel={closeDeleteDocumentDialog}
              onConfirm={confirmDeleteDocument}
              showLoadingOnConfirm={isLoadingDelete}
            />
          )}
        </>
      )}
    </CardDisplay>
  );
};

export default CardDocument;
