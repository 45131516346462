import React from 'react';
import { InputTextarea as PrimeInputTextArea } from 'primereact/inputtextarea';

const CustomTextArea = props => {
  const handleKeyDown = e => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };
  return <PrimeInputTextArea onKeyDown={handleKeyDown} {...props} />;
};

export default CustomTextArea;
