import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  billingOrderTransmitZeroToSage,
  selectIsTransmittingZeroToSage,
} from 'app/redux/slices/souscriptions';
import { toast } from 'react-toastify';

const BillingOrderTransmitZeroDialog = ({ billingOrderId, isVisible, onHide, onSuccess }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsTransmittingZeroToSage);

  const confirmTransmission = async () => {
    await dispatch(billingOrderTransmitZeroToSage(billingOrderId))
      .unwrap()
      .then(() => {
        onHide();
        onSuccess();
        toast('Le bon de facturation a bien été transmis à SAGE.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
      })
      .catch(() => {});
  };

  return (
    <>
      <DialogBox
        id={`Transmit_zero_to_sage_Dialog`}
        visible={isVisible}
        onHide={onHide}
        title={'Transmission du bon de facturation'}
        isLoading={isLoading}
        onCancel={onHide}
        onConfirm={confirmTransmission}
        cancelLabel="Annuler"
        confirmLabel={'Confirmer la transmission'}
      >
        <div className="p-mb-2">
          Ce bon facturation a été initialement annulé conformément à la configuration de l’offre
          souscrite, car le montant total est nul. Souhaitez-vous réellement transmettre ce bon de
          facturation à SAGE ?
        </div>
      </DialogBox>
    </>
  );
};

export default BillingOrderTransmitZeroDialog;
