import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProgressSpinner } from 'olaqin-design-system';
import { routes } from 'app/routes/appRoutes';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import {
  ArchivableOffersStatus,
  DeletableOffersStatus,
  OfferStatusList,
} from 'app/constants/offerConstants';
import { toast } from 'react-toastify';

import {
  selectIsOfferDetailsLoading,
  selectIsOfferDuplicating,
  getOfferDetails,
  selectOfferDetailsData,
  getOfferUsageTypes,
  duplicateAnOffer,
} from 'app/redux/slices/offres';
import OfferCompositionTable from './Sections/OfferCompositionTable';
import OfferPararmeters from './Sections/OfferPararmeters';
import OfferConditions from './Sections/OfferConditions';
import OfferEvents from './Sections/OfferEvents';
import OfferStellairConnection from './Sections/OfferStellairConnection';
import StatusOffer from '../OfferStatus/OfferStatus';
import DeleteOfferDialog from '../Dialogs/DeleteOfferDialog';
import PublishOfferDialog from '../Dialogs/PublishOfferDialog';
import ArchiveOfferDialog from '../Dialogs/ArchiveOfferDialog';
import StellairOfferDialog from '../Dialogs/StellairOfferDialog';
import ValidateOfferDialog from '../Dialogs/ValidateOfferDialog';
import OfferRenameDialog from '../Dialogs/OfferRenameDialog';
import OffersActionMenu from './OffersActionMenu';
import OfferWarnings from './OfferWarnings';

import './OffersDetailsPage.scss';

const OffersDetailsPage = ({ match }) => {
  useDocumentTitle(`${TITLE_PREFIX} Offer Details`);
  const { params } = match;
  const { offerId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const isOfferDataLoading = useSelector(selectIsOfferDetailsLoading);
  const isOfferDuplicating = useSelector(selectIsOfferDuplicating);
  const offerData = useSelector(selectOfferDetailsData);
  const [isDeleteOfferDialogVisible, handleShowDeleteDialog] = useState(false);
  const [isPublishOfferDialogVisible, handleShowPublishDialog] = useState(false);
  const [isArchiveOfferDialogVisible, handleShowArchiveDialog] = useState(false);
  const [isValidateOfferDialogVisible, handleShowValidateDialog] = useState(false);
  const [isStellairOfferDialogVisible, handleShowStellairDialog] = useState(false);
  const [isRenameDialogVisible, handleShowRenameDialog] = useState(false);
  const {
    nom,
    prestations,
    warnings,
    status,
    published,
    sageBase,
    stellairConnectionDetails,
    enableStellairConnection,
  } = offerData;
  const offerWillNotBeDeleted = published && status === OfferStatusList.ARCHIVEE;
  const displayConfigureParamsBtn = !published && status !== OfferStatusList.ARCHIVEE;
  const isOfferDeletable = DeletableOffersStatus.includes(status) && !offerWillNotBeDeleted;
  const isOfferCanBeArchived = ArchivableOffersStatus.includes(status);
  const isOfferReadyForPublish = status === OfferStatusList.PRETE_A_ETRE_PUBLIEE;
  const isOfferValidationVisible = status === OfferStatusList.VALIDATION_ADV;

  const isDefacturationNeeded = useMemo(() => {
    const isAnyArticlesMissing = prestations?.some(prItem => !prItem.article);
    const hasStellairWarnings = enableStellairConnection && !stellairConnectionDetails;

    if (
      ((!sageBase ||
        isAnyArticlesMissing ||
        !('billOneTimeFeesSeparately' in offerData) ||
        !('transmissZeroInvoices' in offerData) ||
        !('dateFacturation' in offerData)) &&
        status === OfferStatusList.VALIDATION_ADV) ||
      hasStellairWarnings
    ) {
      return true;
    }
    return false;
  }, [sageBase, prestations, status, stellairConnectionDetails]);

  useEffect(() => {
    dispatch(getOfferUsageTypes());
  }, []);

  useEffect(() => {
    fetchOfferDetails();
  }, [offerId]);

  if (isOfferDataLoading) {
    return <ProgressSpinner />;
  }

  const actionOnSuccessDelete = () => {
    history.push(routes.OFFERS());
  };
  const actionOnReqParametersEdit = () => {
    history.push(routes.OFFER_EDIT_PARAMETERS(offerId));
  };

  const fetchOfferDetails = () => {
    dispatch(getOfferDetails({ offreCommercialeId: offerId }));
  };

  const actionDuplicateOffer = async () => {
    await dispatch(
      duplicateAnOffer({
        offreCommercialeId: offerId,
      }),
    )
      .unwrap()
      .then(responseData => {
        toast('L’offre a bien été créée.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
        history.push(routes.OFFER_EDIT(responseData.identifiant));
      })
      .catch(() => {});
  };

  return (
    <div className="OffersDetailsPage">
      <div className="p-d-flex p-justify-between p-align-center">
        <div>
          <h1 className="header">{nom}</h1>
        </div>
        <div>
          <OffersActionMenu
            deleteConf={{
              onClick: () => handleShowDeleteDialog(true),
              isDisabled: !isOfferDeletable,
              showTooltip: offerWillNotBeDeleted,
              show: isOfferDeletable,
            }}
            deFacturationConf={{
              onClick: actionOnReqParametersEdit,
              show: status === OfferStatusList.VALIDATION_ADV,
            }}
            archiveConf={{
              onClick: () => handleShowArchiveDialog(true),
              isDisabled: !isOfferCanBeArchived,
            }}
            duplicateConf={{
              onClick: actionDuplicateOffer,
              isDisabled: isOfferDuplicating,
            }}
            renameConf={{
              onClick: () => handleShowRenameDialog(true),
              isDisabled: false,
            }}
          />
          {isOfferReadyForPublish && (
            <SubmitButton
              className="p-mx-2"
              type="button"
              label="Publier l’offre"
              onClick={() => handleShowPublishDialog(true)}
              disabled={false}
              small
            />
          )}
          {isOfferValidationVisible && (
            <SubmitButton
              className="p-mx-2"
              type="button"
              label="Valider l’offre"
              onClick={() => handleShowValidateDialog(true)}
              disabled={isDefacturationNeeded}
              small
            />
          )}
        </div>
      </div>
      <StatusOffer label={status} />

      <OfferWarnings isDefacturationNeeded={isDefacturationNeeded} warnings={warnings} />

      <div className="p-grid">
        <div className="p-col-12">
          <OfferCompositionTable
            prestations={prestations}
            actionOnReqParametersEdit={actionOnReqParametersEdit}
            displayConfigureBtn={displayConfigureParamsBtn}
          />
          <OfferPararmeters
            offerData={offerData}
            actionOnReqParametersEdit={actionOnReqParametersEdit}
            displayConfigureBtn={displayConfigureParamsBtn}
          />
          {!!enableStellairConnection && (
            <OfferStellairConnection
              stellairConnectionDetails={stellairConnectionDetails}
              actionOnReqParametersEdit={() => handleShowStellairDialog(true)}
              displayConfigureBtn={
                displayConfigureParamsBtn && status !== OfferStatusList.PRETE_A_ETRE_PUBLIEE
              }
            />
          )}
          <OfferConditions offerData={offerData} />
          <OfferEvents offerId={offerId} />
        </div>
      </div>
      {isDeleteOfferDialogVisible && (
        <DeleteOfferDialog
          visible={isDeleteOfferDialogVisible}
          onHide={() => handleShowDeleteDialog(false)}
          actionOnSuccessDelete={actionOnSuccessDelete}
          offreCommercialeId={offerId}
          status={status}
        />
      )}
      {isArchiveOfferDialogVisible && (
        <ArchiveOfferDialog
          visible={isArchiveOfferDialogVisible}
          onHide={() => handleShowArchiveDialog(false)}
          status={status}
          offreCommercialeId={offerId}
          actionOnSuccessArchive={fetchOfferDetails}
        />
      )}
      {isPublishOfferDialogVisible && (
        <PublishOfferDialog
          visible={isPublishOfferDialogVisible}
          onHide={() => {
            handleShowPublishDialog(false);
          }}
          offreCommercialeId={offerId}
          actionOnSuccess={fetchOfferDetails}
        />
      )}
      {isStellairOfferDialogVisible && (
        <StellairOfferDialog
          connectData={offerData.stellairConnectionDetails}
          visible={isStellairOfferDialogVisible}
          onHide={() => {
            handleShowStellairDialog(false);
          }}
          offreCommercialeId={offerId}
          actionOnSuccessConnect={fetchOfferDetails}
        />
      )}
      {isOfferValidationVisible && (
        <ValidateOfferDialog
          visible={isValidateOfferDialogVisible}
          onHide={() => {
            handleShowValidateDialog(false);
          }}
          offreCommercialeId={offerId}
          actionOnSuccessValidate={fetchOfferDetails}
        />
      )}
      {isRenameDialogVisible && (
        <OfferRenameDialog
          visible={isRenameDialogVisible}
          onHide={() => {
            handleShowRenameDialog(false);
          }}
          offreCommercialeId={offerId}
          actionOnSuccess={fetchOfferDetails}
          offerName={nom}
        />
      )}
    </div>
  );
};

export default OffersDetailsPage;
