import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import ReportsStellairDataTable from './ReportsStellairDataTable';

const ReportsStellair = () => {
  useDocumentTitle(`${TITLE_PREFIX} Usage Stellair`);

  return (
    <div>
      <p
        style={{
          fontFamily: 'RecifeDisplay',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '38px',
          lineHeight: '40px',
          color: '#19171a',
        }}
      >
        Usage Stellair
      </p>
      <CardDisplay style={{ width: 'auto' }}>
        <ReportsStellairDataTable />
      </CardDisplay>
    </div>
  );
};

export default ReportsStellair;
