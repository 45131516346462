import React from 'react';
import { useSelector } from 'react-redux';
import SearchInput from 'components/_shared/SearchInput/SearchInput';
import usePagination from 'app/hooks/usePagination';
import { getOffersCommercial, selectIsOffersSearching } from 'app/redux/slices/offres';

const OffersHeaderTable = () => {
  const isLoadingSearch = useSelector(selectIsOffersSearching);
  const { onTermsChange, terms } = usePagination({
    dataFetchBy: getOffersCommercial,
    skipInitialCall: true,
  });

  return (
    <div className="offers-table-header">
      <SearchInput
        placeholder="Rechercher par désignation"
        isLoading={isLoadingSearch}
        onSearch={searchVal => onTermsChange('search', searchVal)}
        defaultValue={terms.search}
      />
    </div>
  );
};

export default OffersHeaderTable;
