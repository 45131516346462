import React from 'react';
import { useField } from 'react-final-form';
import { billingSystemTypeOptions } from 'app/constants/client.constants';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import './clientCreateForm.scss';

const BillingSystem = () => {
  const { input: thirdPartyPayerField } = useField('thirdPartyPayer');

  const handleBillingSystemChange = () => {
    thirdPartyPayerField.onChange('');
  };
  return (
    <FormFieldGroup title="Système de facturation" className="p-mt-4">
      <FormFieldDropdown
        name="sageBase"
        placeholder="Sélectionner"
        options={billingSystemTypeOptions}
        showClearButton
        onChange={handleBillingSystemChange}
      />
    </FormFieldGroup>
  );
};

export default BillingSystem;
