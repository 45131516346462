import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TwoLineData, SplitButton } from 'olaqin-design-system';
import ellipsis from 'olaqin-design-system/src/assets/icons/ellipsis.svg';
import {
  selectIsLoadingListPrestations,
  selectListPrestationsPaginated,
  getPrestationsNewListPaginated,
  getListPrestations,
  deletePrestation,
  selectIsLoadingDeletePrestation,
  selectListPrestations,
} from 'app/redux/slices/prestations';
import { capitalize } from 'app/utils/functions';
import { formatDate } from 'app/utils/dateUtils';
import PrestationDeleteDialog from 'components/prestationsNew/dialogs/PrestationDeleteDialog';
import PrestationCreateDialog from 'components/prestationsNew/dialogs/PrestationCreateDialog';
import './PrestationsNew.scss';

const PrestationsNewBody = () => {
  const dispatch = useDispatch();
  const prestationsNewData = useSelector(selectListPrestationsPaginated);
  const prestationsNewList = useSelector(selectListPrestations);
  const isLoading = useSelector(selectIsLoadingListPrestations);
  const isLoadingDelete = useSelector(selectIsLoadingDeletePrestation);

  useEffect(() => {
    dispatch(getPrestationsNewListPaginated({ page: 0, size: 15 }));
    dispatch(getListPrestations());
  }, []);

  const [dataDialogPrestationDelete, openDialogPrestationDelete] = useState({
    isOpen: false,
    id: '',
  });

  const [dataDialogPrestationUpdate, openDialogPrestationUpdate] = useState({
    isOpen: false,
    id: '',
    initialValues: {},
  });

  const onCloseDialogPrestationDelete = () => openDialogPrestationDelete({ isOpen: false, id: '' });
  const onCloseDialogPrestationUpdate = () =>
    openDialogPrestationUpdate({ isOpen: false, id: '', initialValues: {} });

  const onConfirmDeletePrestation = () => {
    dispatch(deletePrestation({ prestationId: dataDialogPrestationDelete.id }))
      .unwrap()
      .then(() => {
        onCloseDialogPrestationDelete();
        dispatch(getPrestationsNewListPaginated({ page: 0, size: 15 }));
        dispatch(getListPrestations());
      });
  };

  const handleChangePage = data => {
    dispatch(getPrestationsNewListPaginated({ page: data.page, size: data.rows }));
  };

  const bodyCreatedBy = row => (
    <TwoLineData
      topText={`${capitalize(row.creePar.prenom)} ${capitalize(row.creePar.nom)}`}
      downText={`Le ${formatDate(row.dateCreation, 'DD/MM/YYYY')}`}
    />
  );

  const bodyNumberOfOffers = row =>
    row.offresLieesNum || <span style={{ color: '#6A6A6A' }}>Aucune</span>;

  const bodyActionButton = ({ identifiant, designation, editable }) => {
    const actionsModel = [
      {
        label: 'Modifier',
        command: () =>
          openDialogPrestationUpdate({
            isOpen: true,
            id: identifiant,
            initialValues: { designation },
          }),
      },
      {
        label: 'Supprimer',
        command: () => openDialogPrestationDelete({ isOpen: true, id: identifiant }),
      },
    ];

    const canHaveActions = !!editable;

    return (
      <>
        {canHaveActions && (
          <SplitButton
            id="splitButtonPrestationsId"
            appendTo={document.body}
            chevronDown={ellipsis}
            model={actionsModel}
            deg="180"
          />
        )}
      </>
    );
  };

  return (
    <>
      <DataTable
        id="prestation-table"
        style={{ marginTop: '16px' }}
        value={prestationsNewData.content || []}
        loading={isLoading}
        paginator
        onPage={handleChangePage}
        first={prestationsNewData.pageable?.offset || 0}
        numberOfElements={prestationsNewData.numberOfElements || 0}
        totalRecords={prestationsNewData.totalElements || 0}
        rows={prestationsNewData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun élément"
        responsive={true}
        selectionMode="single"
        // onRowSelect={handleClickRow}
        rowHover
        autoLayout
      >
        <Column
          key="designation"
          field="designation"
          header="Désignation"
          sortable={false}
          headerStyle={{ border: '0', width: '28%' }}
          bodyStyle={{
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            maxWidth: '0',
            textOverflow: 'ellipsis',
          }}
        />
        <Column
          key="createdAt"
          field="createdAt"
          header="Créée par"
          body={bodyCreatedBy}
          sortable={false}
          headerStyle={{ border: '0', width: '25%' }}
        />
        <Column
          key="offresLieesNum"
          field="offresLieesNum"
          header="Offres liées"
          body={bodyNumberOfOffers}
          sortable={false}
          headerStyle={{ border: '0', width: '22%' }}
        />
        <Column
          key="offre"
          field="offre"
          header=""
          sortable={false}
          headerStyle={{ border: '0', width: '18%' }}
        />
        <Column
          key="supp"
          header=""
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
        />
      </DataTable>
      {dataDialogPrestationUpdate.isOpen && (
        <PrestationCreateDialog
          prestationId={dataDialogPrestationUpdate.id}
          initialValues={dataDialogPrestationUpdate.initialValues}
          visible={dataDialogPrestationUpdate.isOpen}
          onHide={onCloseDialogPrestationUpdate}
          prestationsNewList={prestationsNewList}
        />
      )}
      {dataDialogPrestationDelete.isOpen && (
        <PrestationDeleteDialog
          visible={dataDialogPrestationDelete.isOpen}
          onHide={onCloseDialogPrestationDelete}
          onCancel={onCloseDialogPrestationDelete}
          onConfirm={onConfirmDeletePrestation}
          showLoadingOnConfirm={isLoadingDelete}
        />
      )}
    </>
  );
};

export default PrestationsNewBody;
