import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { routes } from 'app/routes/appRoutes';
import { OfferStatusList } from 'app/constants/offerConstants';
import {
  getOffersCommercial,
  selectOffersData,
  selectIsLoadingOffers,
} from 'app/redux/slices/offres';
import usePagination from 'app/hooks/usePagination';
import OfferStatus from 'components/offers/OfferStatus/OfferStatus';
import { formatDateTime } from 'app/utils/dateUtils';
import { TabPanel, TabView } from 'primereact/tabview';
import OffersHeaderTable from './OffersHeaderTable';

const TabsData = [
  { label: 'Toutes' },
  { label: 'Brouillons', status: OfferStatusList.BROUILLON },
  { label: 'Validation ADV', status: OfferStatusList.VALIDATION_ADV },
  { label: 'Prêtes', status: OfferStatusList.PRETE_A_ETRE_PUBLIEE },
  { label: 'Publiées', status: OfferStatusList.PUBLIEE },
  { label: 'Archivées', status: OfferStatusList.ARCHIVEE },
];

const OffersBody = () => {
  const history = useHistory();

  const offersData = useSelector(selectOffersData);
  const isLoading = useSelector(selectIsLoadingOffers);

  const { onTablePageChange, onTermsChange, terms } = usePagination({
    dataFetchBy: getOffersCommercial,
  });

  const [currentActiveTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (terms.status) {
      const activeTabOnLoadIndex = TabsData.findIndex(tab => tab.status === terms.status);
      setActiveTab(activeTabOnLoadIndex);
    }
  }, []);

  const handleClickRow = ({ data }) => {
    if (data.status === OfferStatusList.BROUILLON) {
      history.push(routes.OFFER_EDIT(data.identifiant));
    } else {
      history.push(routes.OFFER_DETAILS(data.identifiant));
    }
  };

  const bodyStatusOffer = ({ status }) => <OfferStatus label={status} />;

  const bodyLastModifyDate = ({ dateDerniereModification }) =>
    formatDateTime(dateDerniereModification, 'DD/MM/YYYY – HH:mm');

  const bodyCreator = ({ derniereModificationPar }) =>
    `${derniereModificationPar?.nom || ''} ${derniereModificationPar?.prenom || ''}`;

  const handleTabChange = ({ index }) => {
    setActiveTab(index);
    onTermsChange('status', TabsData[index].status);
  };

  return (
    <>
      <TabView
        id="subscriptionBilling"
        activeIndex={currentActiveTab}
        onTabChange={handleTabChange}
      >
        {TabsData.map(tab => (
          <TabPanel key={tab.label} header={tab.label} />
        ))}
      </TabView>
      <DataTable
        id="offers-table"
        header={<OffersHeaderTable />}
        value={offersData.content || []}
        loading={isLoading}
        paginator={offersData.totalElements > 14}
        onPage={onTablePageChange}
        first={offersData.pageable?.offset || 0}
        numberOfElements={offersData.numberOfElements || 0}
        totalRecords={offersData.totalElements || 0}
        rows={offersData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun élément"
        responsive
        rowHover
        autoLayout
        selectionMode="single"
        onRowSelect={handleClickRow}
      >
        <Column
          header="Désignation"
          field="nom"
          sortable={false}
          headerStyle={{ border: '0', width: '50%' }}
          bodyStyle={{
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            maxWidth: '0',
            textOverflow: 'ellipsis',
          }}
        />
        <Column
          key="status"
          field="status"
          header="État"
          sortable={false}
          body={bodyStatusOffer}
          headerStyle={{ border: '0', width: '16%' }}
          bodyStyle={{ padding: '2px 16px' }}
        />
        <Column
          key="date"
          field="date"
          header="Dernière mise à jour"
          sortable={false}
          body={bodyLastModifyDate}
          headerStyle={{ border: '0', width: '16%' }}
        />
        <Column
          key="creePar"
          field="creePar"
          header="par"
          sortable={false}
          body={bodyCreator}
          headerStyle={{ border: '0', width: '16%' }}
          bodyStyle={{
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            maxWidth: '0',
            textOverflow: 'ellipsis',
          }}
        />
      </DataTable>
    </>
  );
};

export default OffersBody;
