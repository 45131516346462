import { Validators } from 'olaqin-design-system';
import { maxLengthValidator } from 'app/utils/formFieldUtils';

const isUUIDFormat = str => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

const formStellairOfferSchema = {
  name: [
    Validators.required('Veuillez saisir le nom de l’offre Stellair.'),
    maxLengthValidator(100, 'Le nom de l’offre ne peut excéder 100 caractères.'),
  ],

  technicalIdentifier: [
    Validators.required('Veuillez la valeur de l’indice.'),
    value => !isUUIDFormat(value) && 'Veuillez saisir un identifiant valide.',
  ],

  revendeur: [
    Validators.required('Veuillez saisir le nom de du revendeur de l’offre Stellai.'),
    maxLengthValidator(100, 'Le nom du revendeur de l’offre ne peut excéder 100 caractères.'),
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const formStellairOfferValidation = values =>
  Validators.createValidator(formStellairOfferSchema)(values);
