import React from 'react';
import { useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { selectOffres } from 'app/redux/slices/offres';
import { Divider } from 'primereact/divider';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import OfferPreview from './OfferPreview';
import { getOptionsFromOfferWithPresetValues } from '../../formUtils';

const CardOffer = ({ formBatch }) => {
  const allOffres = useSelector(selectOffres);
  const { input: formOfferFieldInput } = useField('offer', { allowNull: true });
  const { input: formOptionsFieldInput } = useField('options');

  const customChange = (value, onChange) => {
    const newOffer = value || null;

    // update form only once
    formBatch(() => {
      if (newOffer) {
        // set options for selected offer
        const initialOptions = getOptionsFromOfferWithPresetValues(newOffer.prestations);
        formOptionsFieldInput.onChange(initialOptions);
      }
      onChange(newOffer);
    });
  };

  return (
    <>
      <div className="p-field p-fluid p-col-12 p-lg-5 p-p-0 p-mt-3">
        <FormFieldAutoComplete
          id="offer-value"
          name="offer"
          noWrapper
          filter
          filterBy="nom,identifiant"
          placeholder="Sélectionner une offre"
          noOptionsMessage={() => 'Aucune offre trouvée'}
          options={allOffres || []}
          getOptionLabel={e => e.nom}
          getOptionValue={e => e.identifiant}
          onChange={(value, input) => customChange(value, input.onChange)}
          preventInputChange
          showClearButton
          fieldProps={{
            autoResize: true,
            allowNull: true,
          }}
        />
      </div>
      {formOfferFieldInput.value && (
        <>
          <Divider />
          <OfferPreview
            baseOffre={formOfferFieldInput.value}
            options={formOptionsFieldInput.value}
          />
        </>
      )}
    </>
  );
};

export default CardOffer;
