import React, { useState } from 'react';
import { useField } from 'react-final-form';
import { DropdownSearch } from 'olaqin-design-system';
import { useDispatch } from 'react-redux';
import { getClientDetails, getClientsAutoCompleteData } from 'app/redux/slices/teams';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import ClientInfoPreview from './ClientInfoPreview';
import ClientCreateDialogForm from '../../../../clientsNew/dialogs/ClientCreateDialogForm';

const CardClient = ({ actionOnSuccessEdit }) => {
  const dispatch = useDispatch();
  const { input: formFieldInput } = useField('client', { allowNull: true });
  const { input: formDeliveryAddressInput } = useField('deliveryAddress.identifiant');
  const [isCreateDialogVisible, handleCreateDialogVisible] = useState(false);

  const customChange = e => {
    const newClient = e || null;
    if (!newClient) {
      // clear selected delivery
      formDeliveryAddressInput.onChange('');
    }
  };

  const handleClickEditClient = () => {
    handleCreateDialogVisible(true);
    dispatch(getClientDetails({ id: formFieldInput.value?.identifiant }));
  };

  const itemTemplate = (item, option) => {
    if (option.context === 'value') return item.nom;
    const downText =
      item.adresseFacturation?.adresse && item.adresseFacturation?.ville ? (
        `${item.adresseFacturation.adresse}, ${item.adresseFacturation.ville}`
      ) : (
        <br />
      );

    return <DropdownSearch.TwoLineListItem topText={item.nom} downText={downText} />;
  };

  return (
    <>
      <div className="p-field p-fluid p-col-12 p-lg-5 p-p-0 p-mt-3">
        <FormFieldAutoComplete
          id="client-value"
          name="client"
          noWrapper
          fieldProps={{
            autoResize: true,
            allowNull: true,
          }}
          getOptionLabel={e => e.nom}
          getOptionValue={e => e.identifiant}
          placeholder="Rechercher un client"
          isClearable
          filter
          formatOptionLabel={itemTemplate}
          noOptionsMessage={() => 'Aucun client trouvé'}
          hideDropDownButton
          onChange={e => customChange(e)}
          loadOptions={{
            action: (page, size, search) =>
              getClientsAutoCompleteData({ page, size, searchText: search }),
            paginated: true,
          }}
          debounceTimeout={1000}
          cacheUniqs={[]}
        />
      </div>

      {formFieldInput.value && (
        <ClientInfoPreview client={formFieldInput.value} footerAction={handleClickEditClient} />
      )}
      {isCreateDialogVisible && (
        <ClientCreateDialogForm
          isEdit
          actionOnSuccessEdit={actionOnSuccessEdit}
          visible={isCreateDialogVisible}
          onHide={() => handleCreateDialogVisible(false)}
        />
      )}
    </>
  );
};

export default CardClient;
