import React from 'react';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import HeaderPage from 'components/_shared/HeaderPage/HeaderPage';
import ClientCreateDialogWrap from 'components/clientsNew/dialogs/ClientCreateDialogWrap';
import ClientsNewBody from './ClientsNewBody';

const ClientsNew = () => {
  useDocumentTitle(`${TITLE_PREFIX} Clients`);

  return (
    <>
      <HeaderPage title="Clients">
        <ClientCreateDialogWrap />
      </HeaderPage>
      <ClientsNewBody />
    </>
  );
};

export default ClientsNew;
