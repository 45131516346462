import React from 'react';
import { Button as PrimeButton } from 'primereact/button';
import './CreateNewButton.scss';

const CreateNewButton = ({ className = '', ...otherProps }) => (
  <PrimeButton
    icon="pi pi-plus"
    className={`p-button-text create-new-button ${className}`}
    {...otherProps}
  />
);

export default CreateNewButton;
