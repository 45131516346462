import React, { useRef } from 'react';
import { Button } from 'olaqin-design-system';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { Menu } from 'primereact/menu';

const ActionsControls = ({ onModifier, onActiver, onDelete, canBeActivated }) => {
  const menu = useRef(null);
  const isSubmitDisabled = !canBeActivated;

  const subActionsModel = [
    {
      label: 'Modification de la souscription',
      icon: 'pi pi-pencil',
      command: onModifier,
    },
    {
      label: 'Supprimer la souscription',
      icon: 'pi pi-times-circle',
      style: { color: 'red' },
      command: onDelete,
    },
  ];

  return (
    <>
      <Menu
        style={{ width: '16rem' }}
        model={subActionsModel}
        popup
        ref={menu}
        id="popup_menu"
        appendTo={document.body}
      />
      <Button
        className="p-mr-3"
        label="Actions"
        icon="pi pi-chevron-down"
        iconPos="right"
        btnType="primary"
        onClick={event => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
        style={{
          height: '34px',
          padding: '9px 12px',
          background: '#FFFFFF',
          border: '1px solid rgba(106, 106, 106, 0.24)',
          color: 'black',
          fontSize: '14px',
          fontFamily: 'Arial',
        }}
      />
      <SubmitButton
        style={{
          height: '34px',
        }}
        onClick={onActiver}
        label="Activer la facturation"
        disabled={isSubmitDisabled}
      />
    </>
  );
};

export default ActionsControls;
