import React from 'react';
import { Field } from 'react-final-form';
import { InputText as PrimeInputText } from 'primereact/inputtext';
import CustomTextArea from '../CustomTextArea/CustomTextArea';
import FormFieldWrapper from './FormFieldWrapper';

const FormFieldInputText = ({
  name,
  label,
  noWrapper = false,
  showValidationIcon = false,
  colSpan = { col: 9, md: 12 },
  validate,
  wrapperClassName,
  labelClassName,
  inputType,
  ...otherProps
}) => {
  const Component = inputType === 'textarea' ? CustomTextArea : PrimeInputText;
  return (
    <FormFieldWrapper noWrapper={noWrapper} colSpan={colSpan} className={wrapperClassName}>
      <Field
        name={name}
        validate={validate}
        render={({ input, meta: { valid, error, touched } }) => (
          <span className="p-input-icon-right">
            {label && (
              <label
                htmlFor={`${name}-id`}
                className={`label${labelClassName ? ` ${labelClassName}` : ''}`}
              >
                {label}
              </label>
            )}
            <Component
              id={`${name}-id`}
              name={name}
              className={!valid && touched ? 'p-invalid p-d-block' : ''}
              value={input.value}
              onChange={input.onChange}
              onBlur={input.onBlur}
              {...otherProps}
            />
            {!!error && touched && <small className="p-error p-d-block">{error}</small>}
            {showValidationIcon && valid && touched && (
              <i className={`pi pi-check ${label ? 'p-mt-1' : ''}`} />
            )}
          </span>
        )}
      />
    </FormFieldWrapper>
  );
};

export default FormFieldInputText;
