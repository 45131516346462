import React from 'react';
import calcNumber from 'currency.js';
import { Divider } from 'primereact/divider';
import { periodOptions, serviceItemRepetition } from 'app/constants/quantityConstants';
import { EURO_FRENCH } from 'app/utils/currencyUtils';
import { calculateTotalFacture } from 'components/souscriptions/createSubscription/calculationOfferOptions';
import customStyle from 'components/souscriptions/createSubscription/resume.module.scss';
import { filterOnlyRequiredAndEnabledOptions } from 'components/souscriptions/createSubscription/formUtils';

const OfferPreviewTotalPrices = ({
  billOneTimeFeesSeparately = false,
  periodiciteFacturation = '',
  options,
}) => {
  const filteredOptions = filterOnlyRequiredAndEnabledOptions(options);
  // separately get and calculate RECURRING and ONE_TIME
  const onlyRecurringPayment = filteredOptions.filter(
    prestation => prestation.serviceItemRepetition === serviceItemRepetition.RECURRING,
  );
  const onlyOneTimePayment = filteredOptions.filter(
    prestation => prestation.serviceItemRepetition === serviceItemRepetition.ONE_TIME,
  );
  const onlyRecurringPaymentPrices = calculateTotalFacture(onlyRecurringPayment, filteredOptions);
  const onlyOneTimePaymentPrices = calculateTotalFacture(onlyOneTimePayment, filteredOptions);

  const formattedRecurringTotal = EURO_FRENCH(onlyRecurringPaymentPrices.sum);
  const formattedOneTimeTotal = EURO_FRENCH(onlyOneTimePaymentPrices.sum);
  // for formattedFirstInvoiceTotal we need a sum
  const sumRecurringAndOnetimeTotal = calcNumber(onlyRecurringPaymentPrices.sum).add(
    onlyOneTimePaymentPrices.sum,
  );
  const formattedFirstInvoiceTotal = EURO_FRENCH(sumRecurringAndOnetimeTotal);

  const showTotalOneTimeLabel = !!onlyOneTimePaymentPrices.sum;
  const totalOneTimeLabel = `${formattedOneTimeTotal} €`;

  const { hasVariablePrice } = onlyRecurringPaymentPrices;
  const showTotalRecurringLabel = !!onlyRecurringPaymentPrices.sum || hasVariablePrice;
  const labelRecurring = onlyRecurringPaymentPrices.sum
    ? `${formattedRecurringTotal} € / ${periodOptions[periodiciteFacturation] || '–'}`
    : '';
  const showPlusForBothPlaced = labelRecurring && hasVariablePrice ? '+' : '';
  const totalRecurringLabel = `${labelRecurring} ${showPlusForBothPlaced} ${
    hasVariablePrice ? 'Variable / mois' : ''
  }`;

  // do not show First Invoice if fees bill separately or is only usage base services
  const showTotalFirstInvoiceLabel =
    !billOneTimeFeesSeparately && !!sumRecurringAndOnetimeTotal.value;
  const totalFirstInvoiceLabel = `${formattedFirstInvoiceTotal} €`;

  const totalsSectionsToShow = [
    {
      isShow: showTotalOneTimeLabel,
      label: 'Total HT services ponctuels',
      total: totalOneTimeLabel,
    },
    {
      isShow: showTotalRecurringLabel,
      label: 'Total HT services récurrents',
      total: totalRecurringLabel,
    },
    {
      isShow: showTotalFirstInvoiceLabel,
      label: 'Total HT première facturation',
      total: totalFirstInvoiceLabel,
    },
  ].filter(({ isShow }) => isShow);

  return (
    <div className="p-d-flex p-justify-end">
      {totalsSectionsToShow.map(({ label, total }, index) => (
        <React.Fragment key={label}>
          <div className="p-col-2" style={{ width: 'auto', flexGrow: '0' }}>
            <span className={`${customStyle.ligneTotalTitle} p-d-block p-text-right p-mb-1`}>
              {label}
            </span>
            <span className={`${customStyle.ligneTotal} p-d-block p-text-right`}>{total}</span>
          </div>
          {index !== totalsSectionsToShow.length - 1 && <Divider layout="vertical" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default OfferPreviewTotalPrices;
