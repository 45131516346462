import React from 'react';
import { Field } from 'react-final-form';
import { InputMask as PrimeInputMask } from 'primereact/inputmask';
import FormFieldWrapper from './FormFieldWrapper';

const FormFieldInputMask = ({
  name,
  label,
  noWrapper = false,
  showValidationIcon = false,
  colSpan = { col: 9, md: 12 },
  ...otherProps
}) => (
  <FormFieldWrapper noWrapper={noWrapper} colSpan={colSpan}>
    <Field
      name={name}
      render={({ input, meta: { valid, error, touched } }) => (
        <span className="p-input-icon-right">
          {label && (
            <label htmlFor={`${name}-id`} className="label">
              {label}
            </label>
          )}
          <PrimeInputMask
            id={`${name}-id`}
            className={!valid && touched ? 'p-invalid p-d-block' : ''}
            value={input.value}
            onChange={input.onChange}
            onBlur={input.onBlur}
            {...otherProps}
            unmask
          />
          {!!error && touched && <small className="p-error p-d-block">{error}</small>}
          {showValidationIcon && valid && touched && (
            <i className={`pi pi-check ${label ? 'p-mt-1' : ''}`} />
          )}
        </span>
      )}
    />
  </FormFieldWrapper>
);

export default FormFieldInputMask;
