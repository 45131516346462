import React from 'react';
import { Message } from 'primereact/message';
import CardWarnings from 'components/souscriptions/subscriptionDetails/cards/CardWarnings';

import warningsClass from 'components/souscriptions/subscriptionDetails/cards/CardAnomalies.module.scss';

const OfferWarnings = ({ isDefacturationNeeded, warnings }) => (
  <>
    <CardWarnings className="p-mt-3" warnings={warnings} />

    {isDefacturationNeeded && (
      <Message
        style={{ padding: '0', width: '100%' }}
        severity="warn"
        className="p-mt-3"
        content={
          <div className={warningsClass.warningContainer}>
            <span className={warningsClass.wordBreak}>
              Des paramètres doivent être configurés pour valider l’offre.
            </span>
          </div>
        }
      />
    )}
  </>
);

export default OfferWarnings;
