import React, { Suspense } from 'react';

import { useMsal, AuthenticatedTemplate } from '@azure/msal-react';

import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'olaqin-design-system/src/assets/odsicons/odsicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
// fonts
import 'fontsource-montserrat';
import 'fontsource-montserrat/300.css';
import 'fontsource-montserrat/400.css';
import 'fontsource-montserrat/500.css';
import 'fontsource-montserrat/600.css';
import 'fontsource-montserrat/700.css';
import 'fontsource-montserrat/800.css';
import 'fontsource-montserrat/900.css';
import 'fontsource-open-sans';
import 'fontsource-open-sans/400.css';
import 'fontsource-open-sans/600.css';
import 'fontsource-open-sans/700.css';

import 'layout/layout.scss';
import './App.scss';
import Navigation from 'components/navbar';
import { ProgressSpinner } from 'olaqin-design-system';
import RootToast from 'components/_shared/Toast';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from 'AppRoutes';
import FullScreenLoader from 'components/_shared/FullScreenLoader/FullScreenLoader';
import { useAppAuth } from 'app/hooks/useAppAuth';

const App = () => {
  const { instance, inProgress } = useMsal();
  useAppAuth(instance);

  const isCheckTokensRun =
    inProgress === 'startup' ||
    inProgress === 'handleRedirect' ||
    inProgress === 'login' ||
    inProgress === 'logout';

  return (
    <>
      {isCheckTokensRun && <FullScreenLoader />}
      <AuthenticatedTemplate>
        <Suspense fallback={<ProgressSpinner />}>
          {/* <SocketProvider> */}
          <RootToast />
          <div className={`layout-wrapper`}>
            <Navigation />
            {/* <UpNavbar /> */}
            <div className="right-layout">
              <div className="layout-main" data-testid="layout-main-test">
                <AppRoutes />
              </div>
            </div>
          </div>
          {/* </SocketProvider> */}
        </Suspense>
      </AuthenticatedTemplate>
    </>
  );
};

export default App;
