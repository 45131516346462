import React from 'react';
import ReportsFseDataTable from 'components/reportsFSE/ReportsFseDataTable';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';

const ReportsFSE = () => {
  useDocumentTitle(`${TITLE_PREFIX} Consommation FSE`);

  return (
    <div>
      <p
        style={{
          fontFamily: 'RecifeDisplay',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: '38px',
          lineHeight: '40px',
          color: '#19171a',
        }}
      >
        Consommation FSE
      </p>
      <div style={{ width: 'auto' }}>
        <ReportsFseDataTable />
      </div>
    </div>
  );
};

export default ReportsFSE;
