import { Validators } from 'olaqin-design-system';
import { maxLengthValidator } from 'app/utils/formFieldUtils';

const formContactsAddressSchema = {
  firstName: [maxLengthValidator(35, 'Le prénom du contact ne peut excéder 35 caractères.')],

  lastName: [
    Validators.required('Veuillez saisir le nom du contact.'),
    maxLengthValidator(35, 'Le nom du contact ne peut excéder 35 caractères.'),
  ],

  email: [
    maxLengthValidator(69, 'L’e-mail du contact ne peut excéder 69 caractères.'),
    value => Validators.email(value) && 'L’e-mail est invalide',
  ],

  phoneNumber: [
    value => Validators.integerNotRequired(value) && 'Le numéro de téléphone est invalide.',
  ],

  service: [Validators.required('Veuillez sélectionner un service.')],

  function: [
    maxLengthValidator(35, 'Le nom de la fonction du contact ne peut excéder 35 caractères.'),
    value =>
      `${value}`.toLocaleUpperCase() === 'DEMAT' &&
      `La valeur “DEMAT” n’est pas autorisée. Le contact de partage des factures dématérialisées est paramétrable depuis le champ "Email facture demat." dans les informations du client.`,
  ],

  type: [Validators.required('Veuillez sélectionner le type de contact.')],
};

// eslint-disable-next-line import/prefer-default-export
export const formContactsValidation = values =>
  Validators.createValidator(formContactsAddressSchema)(values);
