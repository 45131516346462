import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from 'react-final-form';
import { DropdownSearch } from 'olaqin-design-system';

import {
  getClientsAutoCompleteData,
  getClientByNumero,
  selectClientByNumero,
} from 'app/redux/slices/teams';
import { selectPaymentTermsList, getPaymentTerms } from 'app/redux/slices/utilisateurs';
import { sageBaseTypes, thirdPartyPayerOptions } from 'app/constants/client.constants';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';

const clientItemTemplate = ({ nom = '', numeroClient }, option) =>
  option.context === 'value' ? (
    nom
  ) : (
    <DropdownSearch.TwoLineListItem topText={nom} downText={numeroClient} />
  );

const SubscriptionThirdPartyPayerFields = ({ isEdit }) => {
  const dispatch = useDispatch();
  const { input: formClientInput } = useField('client', { allowNull: true });
  const { input: thirdPartyPayer } = useField('thirdPartyPayer');
  const { input: thirdPartyPayerType } = useField('thirdPartyPayerType');

  const tppData = useSelector(selectClientByNumero);
  const clientThirdPartyPayer = formClientInput.value?.thirdPartyPayer
    ? {
        nom: formClientInput.value?.thirdPartyPayerName,
        numeroClient: formClientInput.value?.thirdPartyPayer,
      }
    : '';
  const clientSageBase = formClientInput.value?.sageBase;
  const isShowTooltip = !formClientInput.value;

  const paymentTermsList = useSelector(selectPaymentTermsList);
  const [userChange, setUserChange] = useState(formClientInput.value?.identifiant);
  const tppInfo = useMemo(() => {
    if (thirdPartyPayerType.value === thirdPartyPayerOptions[0].value) {
      return formClientInput.value;
    }
    return tppData.client ?? {};
  }, [tppData, thirdPartyPayer.value, thirdPartyPayerType, formClientInput.value]);

  const isTppBankDetailsRequired = useMemo(
    () =>
      paymentTermsList.find(
        paymentTermItem =>
          paymentTermItem.identifiant === tppInfo?.paymentTermTemplate?.identifiant,
      )?.bankDetailsRequired ?? false,
    [tppInfo, paymentTermsList],
  );

  useEffect(() => {
    dispatch(getPaymentTerms());
  }, []);

  useEffect(() => {
    // fetching tpp payment info
    dispatch(getClientByNumero(thirdPartyPayer.value.numeroClient));
  }, [thirdPartyPayer.value]);

  useEffect(() => {
    // resetting the tpp value if we're in creation mode and if the user is changed
    if (!isEdit && userChange !== formClientInput.value?.identifiant) {
      thirdPartyPayer.onChange(clientThirdPartyPayer);
      const currentPayerOptionIndex = clientThirdPartyPayer.numeroClient ? 1 : 0;
      thirdPartyPayerType.onChange(thirdPartyPayerOptions[currentPayerOptionIndex].value);
      setUserChange(formClientInput.value?.identifiant ?? '');
    }
  }, [formClientInput, clientThirdPartyPayer, thirdPartyPayer, thirdPartyPayerType]);

  const isTppEnabled =
    thirdPartyPayerType.value === thirdPartyPayerOptions[1].value && !isShowTooltip;

  const changeTppFormType = tppValue => {
    // reseting form side third party payer type value to 1 if the user is clearing the form and the type is 0, needed for validation
    if (!tppValue && thirdPartyPayerType.value === thirdPartyPayerOptions[0].value) {
      thirdPartyPayerType.onChange(thirdPartyPayerOptions[1].value);
    }
  };

  const clearTppValueOnTppTypeChange = newTppTypeEvent => {
    // clear tpp value on the formside if the user manually changes the tpp type to "the client himself is payer"
    if (thirdPartyPayer.value && newTppTypeEvent.value === thirdPartyPayerOptions[0].value) {
      thirdPartyPayer.onChange('');
    }
  };

  return (
    <div className="p-fluid p-col-12 p-lg-5 p-p-0">
      <p style={{ fontWeight: 700 }}>Tiers payeur de la souscription</p>
      <FormFieldDropdown
        name="thirdPartyPayerType"
        placeholder="Sélectionner le tiers payeur"
        options={thirdPartyPayerOptions}
        noWrapper
        value={isShowTooltip ? '' : thirdPartyPayerType.value}
        onChange={clearTppValueOnTppTypeChange}
        tooltip={{
          label: 'Il est nécessaire de choisir un client avant de sélectionner le tiers payeur.',
          display: isShowTooltip,
          disableInput: isShowTooltip,
        }}
      />
      {isTppEnabled && (
        <FormFieldAutoComplete
          name="thirdPartyPayer"
          noWrapper
          showClearButton
          filter
          placeholder="Sélectionner"
          styles={{ container: () => ({ marginTop: 8 }) }}
          getOptionLabel={e => e.nom}
          getOptionValue={e => e.numeroClient}
          onChange={e => {
            changeTppFormType(e?.value);
          }}
          loadOptions={{
            action: (page, size, search) =>
              getClientsAutoCompleteData({
                page,
                size,
                searchText: search,
                sageBase: sageBaseTypes[clientSageBase],
              }),
            paginated: true,
            optionValue: 'numeroClient',
            disabledOption: formClientInput.value?.numeroClient,
          }}
          formatOptionLabel={clientItemTemplate}
          debounceTimeout={1000}
          cacheUniqs={[]}
        />
      )}
      {!isShowTooltip && tppInfo && (
        <div className="tppInfoBox p-mt-4">
          <div className="p-mb-4">
            <label className="infoLabel">Conditions de paiement du tiers payeur</label>
            <p>{tppInfo.paymentTermTemplate?.label || '-'}</p>
          </div>
          {isTppBankDetailsRequired && (
            <div className="p-mb-4">
              <label className="infoLabel">Coordonnées bancaires du tiers payeur </label>
              {tppInfo.bankDetails ? (
                <>
                  <p>{tppInfo.bankDetails.bankName}</p>
                  <p>{tppInfo.bankDetails.iban}</p>
                  <p>{tppInfo.bankDetails.bic}</p>
                </>
              ) : (
                <p>-</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionThirdPartyPayerFields;
