import { toast } from 'react-toastify';

export const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

/**
 * Simple debounce function
 * delay call of given function
 * @param func
 * @param wait {number}
 * @return {function}
 */
export const debounce = (func, wait = 500) => {
  let timeout;

  function debounced(...args) {
    const later = () => {
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  debounced.immediateCall = (...args) => {
    clearTimeout(timeout);
    func(...args);
  };

  return debounced;
};

/**
 * Calls toast with text on error or throw error without toast
 * @param error
 * @return {React.ReactText}
 */
export const throwError = error => {
  // axios timeout
  if (error.code === 'ECONNABORTED') {
    return toast.error(
      'Le délai d’attente de la validation de votre requête est dépassé. Merci de patienter une minute avant de vérifier si l’enregistrement de vos modifications a été effectué.',
    );
  }
  if (error.response) {
    const errorResp = error.response;

    if (errorResp.status === 500 || errorResp.status === 405) {
      const errMsg = errorResp.data.error || errorResp.data.message || 'Internal server Error';
      return toast.error(errMsg);
    }

    if (errorResp.data.message) {
      return toast.error(errorResp.data.message);
    }

    const { details } = error.response.data;
    if (details !== null && details.length > 0) {
      return toast.error(
        `${details
          .map(d => {
            const msg = d.message;
            const { description } = d;
            return msg === undefined ? `${d.champ}: ${description}` : `${msg}`;
          })
          .join(', ')}`,
      );
    }
    const { message } = errorResp.data;
    return toast.error(message);
  }

  throw Error(error);
};
