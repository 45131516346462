import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useFormFieldDropdown = ({
  action,
  optionValue = 'id',
  optionLabel = 'label',
  reload,
  disabledOption,
  paginated,
  paginationConfig = {
    firstPage: 0,
    size: 50,
  },
  flatOptions,
}) => {
  const dispatch = useDispatch();
  const [dataLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    const actionCall = () => {
      setIsLoading(true);
      dispatch(action())
        .unwrap()
        .then(dataArray => {
          // todo rewrite to avoid ...
          const formattedList = dataArray.map(item => ({
            ...item,
            label: flatOptions ? item : item[optionLabel],
            value: flatOptions ? item : item[optionValue],
            disabled: disabledOption === (flatOptions ? item : item[optionValue]),
          }));
          setIsLoading(false);
          setList(formattedList);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };
    if (action && !paginated) {
      actionCall();
    }
  }, [reload]);

  const loadPaginatedOptions = () => {
    const dataLoader = async (search, _loadedOptions, { page }) => {
      const { totalPages, content = [] } = await dispatch(
        action(page, paginationConfig?.size, search),
      ).unwrap();
      const isDisablingConditionsProvided = disabledOption && optionValue;
      const formattedList = content.map(item => ({
        ...item,
        disabled: isDisablingConditionsProvided ? disabledOption === item[optionValue] : false,
      }));

      return {
        options: formattedList,
        hasMore: totalPages > page + 1,
        additional: {
          page: page + 1,
        },
      };
    };
    return paginated
      ? {
          loadOptions: dataLoader,
          additional: {
            page: 0,
          },
        }
      : {};
  };

  return {
    dataLoading,
    list,
    loadPaginatedOptions,
  };
};

export default useFormFieldDropdown;
