import { combineReducers } from '@reduxjs/toolkit';
import prestationsReducer from './slices/prestations';
import offresReducer from './slices/offres';
import teamsReducer from './slices/teams';
import clientsReducer from './slices/utilisateurs';
import rolesReducer from './slices/roles';
import souscriptionsReducer from './slices/souscriptions';
import syntecIndexReducer from './slices/syntecIndex';
import reportsReducer from './slices/reports';

const rootReducer = combineReducers({
  prestations: prestationsReducer,
  offres: offresReducer,
  teams: teamsReducer,
  utilisateurs: clientsReducer,
  roles: rolesReducer,
  souscriptions: souscriptionsReducer,
  syntecIndexes: syntecIndexReducer,
  reports: reportsReducer,
});

export default rootReducer;
