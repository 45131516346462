import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'app/routes/appRoutes';
import NoMatch from 'components/_shared/NoMatch';
import ErrorBoundary from 'components/_shared/ErrorBoundary';
import { GlobalMessageProvider } from 'app/context/GlobalMessagesContext';
import ProtectedRoute from 'app/routes/protectedRoutes';
import SubscriptionDetailsPage from 'components/souscriptions/subscriptionDetails';
import SubscriptionsNew from 'components/subscriptionsNew/SubscriptionsNew';
import BillingOrders from 'components/billingOrders';
import Reports from 'components/reports/Reports';
import ReportsStellair from 'components/reportsStellair/ReportsStellair';
import ClientsNew from 'components/clientsNew/ClientsNew';
import CreateSubscriptionForm from 'components/souscriptions/createSubscription/CreateSubscriptionForm';
import PrestationsNew from 'components/prestationsNew/PrestationsNew';
import ReportsFSE from 'components/reportsFSE/ReportsFSE';
import Offers from 'components/offers/Offers';
import CreateOfferForm from 'components/offers/CreateOfferForm/CreateOfferForm';
import OffersDetailsPage from 'components/offers/Details/OffersDetailsPage';
import ClientsDetailsPage from 'components/clientsDetail/ClientsDetailPage';
import OfferParametersEdit from 'components/offers/OfferEditForms/OfferParametersEdit';
import IndexesPage from 'components/syntecIndex/IndexesPage';
import ReportsExport from 'components/reportsExport/ReportsExport';

const AppRoutes = () => (
  <ErrorBoundary>
    <GlobalMessageProvider>
      <Switch>
        <ProtectedRoute exact path={routes.DASHBOARD()}>
          <Redirect to={routes.SUBSCRIPTIONS()} />
        </ProtectedRoute>

        <ProtectedRoute exact path={routes.PRESTATIONS()} component={PrestationsNew} />

        <ProtectedRoute exact path={routes.OFFERS()} component={Offers} />
        <ProtectedRoute exact path={routes.OFFER_CREATE()} component={CreateOfferForm} />
        <ProtectedRoute exact path={routes.OFFER_EDIT()} component={CreateOfferForm} />
        <ProtectedRoute exact path={routes.OFFER_DETAILS()} component={OffersDetailsPage} />
        <ProtectedRoute
          exact
          path={routes.OFFER_EDIT_PARAMETERS()}
          component={OfferParametersEdit}
        />

        <ProtectedRoute exact path={routes.CLIENTS()} component={ClientsNew} />
        <ProtectedRoute exact path={routes.CLIENTS_DETAILS()} component={ClientsDetailsPage} />

        <ProtectedRoute exact path={routes.SUBSCRIPTIONS()} component={SubscriptionsNew} />
        <ProtectedRoute
          exact
          path={routes.SUBSCRIPTION_CREATE()}
          component={CreateSubscriptionForm}
        />
        <ProtectedRoute
          exact
          path={routes.SUBSCRIPTION_EDIT()}
          component={CreateSubscriptionForm}
        />
        <ProtectedRoute
          exact
          path={routes.SUBSCRIPTION_DETAILS()}
          component={SubscriptionDetailsPage}
        />
        <ProtectedRoute exact path={routes.BILLING_ORDER_DETAILS()} component={BillingOrders} />

        <ProtectedRoute exact path={routes.REPORTS_PRESTATIONS()} component={Reports} />
        <ProtectedRoute exact path={routes.REPORTS_PRESTATIONS_FSE()} component={ReportsFSE} />
        <ProtectedRoute exact path={routes.REPORTS_EXPORT()} component={ReportsExport} />
        <ProtectedRoute exact path={routes.REPORTS_STELLAIR_USAGE()} component={ReportsStellair} />

        <ProtectedRoute exact path={routes.INDEXES()} component={IndexesPage} />

        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </GlobalMessageProvider>
  </ErrorBoundary>
);

export default AppRoutes;
