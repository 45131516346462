import React, { useCallback, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useField } from 'react-final-form';
import ActionButton from 'components/_shared/ActionButton/ActionButton';
import { toast } from 'react-toastify';
import { selectOfferUsageTypes } from 'app/redux/slices/offres';
import { useSelector } from 'react-redux';
import DeleteCompositionConfirmation from './dialogs/DeleteCompositionConfirmation';
import {
  getPrestationRow,
  combineQuantitySummary,
  getRelatedLabel,
  combineTarifficationSummary,
} from '../../../offersGridFormatters';

import './styles.scss';

const DataTableComposition = ({ openEditModal }) => {
  const [selectedCompositionForDelete, setCompositionForDelete] = useState(null);
  const {
    input: formOfferCompositionFieldInput,
    meta: { error, touched },
  } = useField('composition');

  const offerCompositionData = formOfferCompositionFieldInput.value;
  const usageTypeDataList = useSelector(selectOfferUsageTypes);

  const errorTitle = () =>
    !!error && touched && <small className="p-error p-d-block p-pt-2">{error}</small>;

  const deleteRow = () => {
    const filteredList = offerCompositionData.filter(
      (_, key) => key !== selectedCompositionForDelete,
    );
    formOfferCompositionFieldInput.onChange([...filteredList]);
    setCompositionForDelete(null);
  };

  const handleDeleteRow = useCallback(
    (rowData, index) => {
      const isRefrenceRow =
        offerCompositionData.filter(
          i => i.dependOnAnotherPrestation === rowData.prestation.identifiant,
        )?.length > 0 || false;
      if (isRefrenceRow) {
        toast.error(
          'La prestation ne peut pas être supprimée car elle est utilisée comme référence pour une autre prestation.',
        );
        return;
      }

      setCompositionForDelete(index);
    },
    [offerCompositionData],
  );

  const bodyActionButton = (rowData, params) => {
    const actionsModel = [
      {
        label: 'Modifier',
        command: () => {
          openEditModal(params.rowIndex);
        },
      },
      {
        label: <span className="supprimer-danger-button">Supprimer</span>,
        command: () => handleDeleteRow(rowData, params.rowIndex),
      },
    ];
    return <ActionButton model={actionsModel} />;
  };

  const quantityRow = row =>
    combineQuantitySummary({
      countingConditions: row.countingConditions,
      quantityType: row.quantityType,
      usageDataType: row.usageType,
      usageTypeDataList,
    });

  const tarificationRow = (row, rowData) =>
    combineTarifficationSummary(
      {
        tarifType: row.tarification,
        selectors: {
          min: 'units',
          max: 'upToUnits',
          fixedFee: 'fixedTarif',
          amount: 'tarif',
          isInfinite: '',
        },
        amount: row.tariff,
        progressiveData: row.tariffProgressive,
        isMulti: row.isMultiplier,
        dependPrestationId: row.dependOnAnotherPrestation,
      },
      rowData,
    );

  return offerCompositionData.length ? (
    <>
      <DeleteCompositionConfirmation
        visible={selectedCompositionForDelete !== null}
        onHide={() => setCompositionForDelete(null)}
        actionOnSuccessDelete={deleteRow}
      />
      <DataTable
        id="composition-table"
        style={{ marginTop: '16px' }}
        value={offerCompositionData || []}
        responsive
        rowHover
        autoLayout
        rowClassName={() => ({
          compositionTableRow: 'compositionTableRow',
        })}
      >
        <Column
          header="Prestation"
          field="prestation.designation"
          headerStyle={{ border: '0', width: '22%' }}
          body={row =>
            getPrestationRow({ label: row.prestation.designation, isOptional: row.isOptional })
          }
          bodyStyle={{
            maxWidth: '0',
          }}
        />
        <Column
          field="type"
          header="Type"
          headerStyle={{ border: '0', width: '10%' }}
          body={row => getRelatedLabel(row, 'type')}
        />
        <Column
          field="quantity"
          header="Quantité"
          headerStyle={{ border: '0', width: '38%' }}
          body={quantityRow}
        />
        <Column
          field="tarification"
          header="Tarification HT"
          headerStyle={{ border: '0', width: '32%' }}
          body={tarificationRow}
        />
        <Column
          key="modifier"
          header=""
          frozen
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
        />
      </DataTable>
    </>
  ) : (
    errorTitle()
  );
};

export default DataTableComposition;
