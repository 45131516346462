import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import {
  createNewClientContact,
  updateClientContact,
  selectCreateClientContactIsLoading,
  selectUpdateClientContactIsLoading,
  selectClientContactDetails,
  selectClientContactDetailsIsLoading,
  getClientContactDetails,
} from 'app/redux/slices/teams';
import ContactsFormField from './ContactsFormField';
import { formContactsValidation } from './formContactsValidation';

const ContactsDialogForm = ({
  clientId,
  contactId,
  sageBase,
  actionOnSuccessCreate,
  actionOnSuccessEdit,
  visible,
  onHide,
  isEdit = false,
}) => {
  const dispatch = useDispatch();

  const currentFormData = useSelector(selectClientContactDetails);

  const isLoading = useSelector(selectClientContactDetailsIsLoading);
  const createIsLoading = useSelector(selectCreateClientContactIsLoading);
  const updateIsLoading = useSelector(selectUpdateClientContactIsLoading);

  const handleClickConfirm = async formData => {
    if (isEdit) {
      await dispatch(updateClientContact({ formData, clientId, contactId: formData.identifiant }))
        .unwrap()
        .then(() => {
          onHide();
          actionOnSuccessEdit();
        });
    } else {
      await dispatch(createNewClientContact({ formData, clientId }))
        .unwrap()
        .then(() => {
          onHide();
          actionOnSuccessCreate();
        });
    }
  };

  useEffect(() => {
    if (isEdit && clientId && contactId) {
      dispatch(getClientContactDetails({ clientId, contactId }));
    }
  }, [clientId, contactId]);

  return (
    <Portal>
      <Form
        initialValues={isEdit ? currentFormData || {} : {}}
        validate={formContactsValidation}
        onSubmit={handleClickConfirm}
        render={({ handleSubmit }) => (
          <form
            className="p-fluid p-formgrid p-grid"
            id="contacts-create-dialog"
            onSubmit={handleSubmit}
          >
            <DialogBox
              id="dialogContacts"
              spacingField="8px"
              spacingLabel="0"
              visible={visible}
              onHide={onHide}
              isLoading={isLoading || updateIsLoading || createIsLoading}
              title={isEdit ? 'Modification du contact' : 'Ajout d’un nouveau contact'}
              style={{
                maxWidth: '600px',
                overflow: 'auto',
              }}
              className="p-col-12 p-sm-12 p-md-12 p-lg-8"
              confirmLabel={isEdit ? 'Enregistrer les modications' : 'Ajouter le contact'}
              onConfirm={handleSubmit}
              cancelLabel="Annuler"
              onCancel={onHide}
            >
              <ContactsFormField sageBase={sageBase} />
            </DialogBox>
          </form>
        )}
      />
    </Portal>
  );
};

export default ContactsDialogForm;
