import React from 'react';
import { Button, Dialog } from 'olaqin-design-system';

const PrestationDeleteDialog = ({ visible, onHide, onCancel, onConfirm, showLoadingOnConfirm }) => (
  <Dialog
    id="prestationDeleteDialog"
    visible={visible}
    onHide={onHide}
    className="p-col-12 p-sm-12 p-md-8 p-lg-4"
    header={<span style={{ fontSize: '20px' }}> Confirmation </span>}
    footer={
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          style={{ fontSize: '12px' }}
          type="secondary"
          btnType="secondary"
          label="Annuler"
          onClick={onCancel}
        />
        <Button
          disabled={showLoadingOnConfirm}
          style={{ fontSize: '12px' }}
          type="submit"
          btnType="primary"
          label="Supprimer"
          onClick={onConfirm}
        />
      </div>
    }
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <i className="pi pi-exclamation-triangle" style={{ marginRight: '16px' }} />
      <span style={{ fontSize: '14px' }}>Voulez-vous vraiment supprimer la prestation ?</span>
    </div>
  </Dialog>
);

export default PrestationDeleteDialog;
