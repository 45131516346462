import React, { useState } from 'react';
import { CardDisplay, Column } from 'olaqin-design-system';
import { DataTable } from 'primereact/datatable';
import { selectSouscription, chargerSouscription } from 'app/redux/slices/souscriptions';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { formatDate } from 'app/utils/dateUtils';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import AddStellairAccountDialog from '../dialogs/AddStellairAccountDialog';
import StellairActivateDialog from '../dialogs/StellairActivateDialog';

import './CardStellair.scss';

const CardStellair = ({ title }) => {
  const dispatch = useDispatch();

  const [isModalVisible, toggleStellairModal] = useState(false);
  const [isActivateModalVisible, toggleActivateStellairModal] = useState(false);

  const { souscripteur, identifiant, offreCommerciale, stellairSouscription = {} } = useSelector(
    selectSouscription,
  );
  const bodyDate = (row, dateField) => dashOnEmpty(formatDate(row[dateField], 'DD/MM/YYYY'));

  const offerStellairBody = ({ name, technicalIdentifier }) => (
    <div className="OfferStellairCol">
      <span>{name}</span>
      <span>{technicalIdentifier}</span>
    </div>
  );

  const refetchSubscriptionData = () => {
    dispatch(chargerSouscription({ souscriptionId: identifiant }));
  };

  const isStellairActivationInEdit = Object.keys(stellairSouscription).length > 0;

  const stellairActivationGrid =
    offreCommerciale?.stellairConnectionDetails && stellairSouscription
      ? [
          {
            ...stellairSouscription,
            ...offreCommerciale?.stellairConnectionDetails,
          },
        ]
      : [];

  const isActivateBtnDisabled =
    !offreCommerciale?.stellairConnectionDetails || !souscripteur.stellairAccount;
  return (
    <CardDisplay title={title} style={{ width: 'auto' }}>
      <h3>Compte utilisateur</h3>
      <DataTable
        id="Compte_utilisateur"
        className="StellairClientGrid p-mt-2"
        value={souscripteur.stellairAccount ? [souscripteur.stellairAccount] : []}
        emptyMessage="Aucune organisation Stellair n’est associée au client"
        responsive
      >
        <Column field="email" header="E-mail" headerStyle={{ width: '20%', fontSize: 14 }} />
        <Column field="firstName" header="Prénom" headerStyle={{ width: '20%', fontSize: 14 }} />
        <Column field="lastName" header="Nom" headerStyle={{ width: '20%', fontSize: 14 }} />
        <Column
          field="id"
          header="Identifiant organisation Stellair"
          headerStyle={{ width: '20%', fontSize: 14 }}
          style={{ color: '#6A6A6A' }}
        />
        <Column
          field="creationDate"
          header="Créé le"
          headerStyle={{ width: '20%', fontSize: 14 }}
          body={data => bodyDate(data, 'creationDate')}
        />
      </DataTable>
      <AddStellairAccountDialog
        isVisible={isModalVisible}
        hideDialog={() => toggleStellairModal(false)}
        onSuccess={refetchSubscriptionData}
        clientData={souscripteur}
      />
      {!souscripteur.stellairAccount && (
        <SubmitButton
          label="Créer le compte utilisateur du client"
          btnType="secondary"
          type="button"
          outlined
          onClick={() => toggleStellairModal(true)}
          small
          className="p-mt-2"
          style={{
            background: '#FFFFFF',
            color: 'black',
            width: 'max-content',
          }}
        />
      )}
      <h3>Service souscrit</h3>
      <DataTable
        id="stellair_activation_table"
        className="StellairClientGrid p-mt-2"
        value={stellairActivationGrid}
        responsive
        style={{ opacity: isActivateBtnDisabled ? 0.6 : 1 }}
      >
        <Column
          field="name"
          header="Offre Stellair"
          headerStyle={{ width: '40%', fontSize: 14 }}
          body={offerStellairBody}
        />
        <Column field="revendeur" header="Revendeur" headerStyle={{ width: '20%', fontSize: 14 }} />
        <Column
          field="createDate"
          header="Activé le"
          headerStyle={{ fontSize: 14 }}
          body={row => bodyDate(row, 'createDate')}
        />
        <Column
          field="debutDate"
          header="Date début"
          headerStyle={{ fontSize: 14 }}
          body={row => bodyDate(row, 'debutDate')}
        />
        <Column
          field="finDate"
          header="Date fin"
          headerStyle={{ fontSize: 14 }}
          body={row => bodyDate(row, 'finDate')}
        />
      </DataTable>

      <StellairActivateDialog
        isVisible={isActivateModalVisible}
        hideDialog={() => toggleActivateStellairModal(false)}
        onSuccess={refetchSubscriptionData}
        isEdit={isStellairActivationInEdit}
        initData={stellairSouscription}
      />
      <SubmitButton
        label={
          isStellairActivationInEdit
            ? 'Modifier les dates d’activation du service'
            : 'Activer le service Stellair'
        }
        btnType="secondary"
        type="button"
        outlined
        onClick={() => toggleActivateStellairModal(true)}
        small
        className="p-mt-2"
        disabled={isActivateBtnDisabled}
        style={{
          background: '#FFFFFF',
          color: 'black',
          width: 'max-content',
        }}
      />
    </CardDisplay>
  );
};

export default CardStellair;
