import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardDisplay } from 'olaqin-design-system';
import {
  listerCategoriesComptablesBillingSystem,
  selectListeCategoriesComptablesBillingSystem,
} from 'app/redux/slices/utilisateurs';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import { sageBaseTypes, salesTypologyOptions } from 'app/constants/client.constants';
import {
  getCollaboratorsBillingSystem,
  selectCollaboratorsBillingSystem,
} from 'app/redux/slices/teams';

const SalesAdmin = ({ clientDetails }) => {
  const {
    accountingRef,
    salesTypology,
    sageBase: billingSystemValue,
    collaborator,
  } = clientDetails;
  const dispatch = useDispatch();
  const categoriesComptables = useSelector(selectListeCategoriesComptablesBillingSystem);
  const collaborators = useSelector(selectCollaboratorsBillingSystem);

  const findCategoriesComptables = categoriesComptables?.find(
    ({ code }) => code === accountingRef?.categorieComptable,
  );
  const collaboratorLabel = useMemo(() => {
    const findCollaborators = collaborators.find(i => i.id === collaborator);
    const label = `${findCollaborators?.nom ?? ''} ${findCollaborators?.prenom ?? ''}`;
    return findCollaborators ? label : null;
  }, [collaborators, collaborator]);

  const findSalesTypology = salesTypologyOptions?.find(({ value }) => value === salesTypology);

  useEffect(() => {
    if (billingSystemValue) {
      dispatch(
        listerCategoriesComptablesBillingSystem({
          fromSage: false,
          sageBase: sageBaseTypes[billingSystemValue],
        }),
      );
    }
    if (billingSystemValue && collaborator) {
      dispatch(
        getCollaboratorsBillingSystem({
          sageBase: billingSystemValue,
        }),
      );
    }
  }, []);

  return (
    <CardDisplay title="Gestion commerciale" style={{ width: 'auto' }}>
      <div className="item">
        <span>Catégorie comptable</span>
        <span>
          {dashOnEmpty(findCategoriesComptables?.designation || accountingRef?.categorieComptable)}
        </span>
      </div>
      <div className="item">
        <span>Typologie commerciale</span>
        <span>{dashOnEmpty(findSalesTypology?.label || salesTypology)}</span>
      </div>
      <div className="item">
        <span>Revendeur</span>
        <span>{dashOnEmpty(collaboratorLabel)}</span>
      </div>
    </CardDisplay>
  );
};

export default SalesAdmin;
