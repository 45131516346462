/* eslint-disable import/prefer-default-export */
import {
  generateSubscriptionReport,
  genereateClientReport,
  genereateBillingOrderReport,
  ExportReportsTypes,
} from 'app/redux/slices/reports';

export const ExportReportsOptions = [
  {
    label: 'Souscriptions',
    value: ExportReportsTypes.SUBSCRIPTION,
    action: downloadKey => generateSubscriptionReport(downloadKey),
  },
  {
    label: 'Clients',
    value: ExportReportsTypes.CLIENT,
    action: () => genereateClientReport(),
  },
  {
    label: 'Bons de facturation',
    value: ExportReportsTypes.BILLING_ORDER,
    action: () => genereateBillingOrderReport(),
  },
];
