import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Route } from 'react-router-dom';

const ProtectedRoute = ({ children, ...routeProps }) => {
  const isAuthenticated = useIsAuthenticated();
  return <Route {...routeProps}>{isAuthenticated ? children : null}</Route>;
};

export default ProtectedRoute;
