import React from 'react';
import { Button } from 'primereact/button';
import './SubmitButton.scss';

// available button type (btnType) is primary, secondary and danger
const SubmitButton = ({
  label,
  severity,
  btnType = 'primary',
  isLoading = false,
  loadingLabel = '',
  disabled = false,
  type = 'submit',
  small = false,
  large = false,
  outlined = false,
  icon = '',
  className = '',
  ...otherProps
}) => {
  const medium = !small && !large;

  const customClassName = `SubmitButton p-mx-0 p-button-${severity || btnType}${
    outlined ? '-outlined' : ''
  } ${small ? 'p-button-sm' : ''} ${medium ? 'p-button-md' : ''} ${
    large ? 'p-button-lg' : ''
  } ${className}`;

  return (
    <Button
      icon={isLoading ? 'pi pi-spin pi-spinner' : icon}
      className={customClassName}
      type={type}
      severity={severity}
      disabled={disabled || isLoading}
      label={isLoading ? `${loadingLabel || label}...` : label}
      {...otherProps}
    />
  );
};

export default SubmitButton;
