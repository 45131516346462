import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { publishAnOffer, selectPublishOfferIsLoading } from 'app/redux/slices/offres';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

const PublishOfferDialog = ({ visible, onHide, offreCommercialeId, actionOnSuccess }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectPublishOfferIsLoading);

  const handlePublishOffer = async () => {
    await dispatch(publishAnOffer({ offreCommercialeId }))
      .unwrap()
      .then(() => {
        onHide();
        actionOnSuccess();
      })
      .catch(() => {});
  };

  return (
    <DialogBox
      id="publishOfferDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title="Publication de l’offre"
      isLoading={isLoading}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={handlePublishOffer}
      confirmLabel="Confirmer la publication"
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 16 }}>
        Une fois publiée, l’offre sera disponible à la souscription. Confirmez-vous la publication
        de l’offre ?
      </div>
    </DialogBox>
  );
};

export default PublishOfferDialog;
