import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectTerminationLoading, terminateSouscription } from 'app/redux/slices/souscriptions';
import {
  renewalOfferValueServer,
  subscriptionStatuses,
} from 'app/constants/subscription.constants';

import ActionMenu from './ActionMenu';
import TerminateSouscriptionDialog from '../dialogs/TerminateSouscriptionDialog';

const TerminationActionButton = ({ souscription }) => {
  const {
    identifiant,
    terminateDate,
    offreCommerciale: { reconductionRenouvellement, nom: offreCommercialeName } = {},
    souscripteur: { nom: souscripteurName } = {},
    dateFin,
  } = souscription;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectTerminationLoading);
  const [isOpen, showTerminationPopUp] = useState(false);
  const cancelImmediately =
    !!terminateDate || reconductionRenouvellement === renewalOfferValueServer.AUCUNE;

  const closeTerminationPopUp = () => showTerminationPopUp(false);

  const terminate = state => {
    dispatch(terminateSouscription({ souscriptionId: identifiant, state }))
      .unwrap()
      .then(() => {
        closeTerminationPopUp();
      });
  };

  const onClick = () => showTerminationPopUp(true);

  if (souscription.statut !== subscriptionStatuses.ACTIVE) {
    return null;
  }
  return (
    <div className="p-d-flex">
      <ActionMenu onClick={onClick} cancelImmediately={cancelImmediately} />

      {isOpen && (
        <TerminateSouscriptionDialog
          visible={isOpen}
          onHide={closeTerminationPopUp}
          onCancel={closeTerminationPopUp}
          onConfirm={terminate}
          showLoadingOnConfirm={isLoading}
          cancelImmediately={cancelImmediately}
          souscripteurName={souscripteurName}
          offreCommercialeName={offreCommercialeName}
          dateFin={dateFin}
        />
      )}
    </div>
  );
};

export default TerminationActionButton;
