import React from 'react';
import { NumberField } from 'olaqin-design-system';
import {
  newTariffs,
  newTypeQuantities,
  periodOptions,
  serviceItemRepetition,
} from 'app/constants/quantityConstants';
import calcNumber from 'currency.js';
import { symbol } from 'app/constants/deviceConstants';

import { useField } from 'react-final-form';
import { EURO_FRENCH } from 'app/utils/currencyUtils';
import customStyle from 'components/souscriptions/createSubscription/nouvelleSouscription.module.scss';
import customStyleChip from 'components/subscriptionsNew/statusSubscription/SubscriptionStatus.module.scss';
import {
  calculateTier,
  calculatePerFormulaTier,
} from 'components/souscriptions/createSubscription/calculationOfferOptions';
import { InputSwitch as PrimeInputSwitch } from 'primereact/components/inputswitch/InputSwitch';
import { Chip } from 'primereact/chip';

const OfferPreviewRow = ({
  prestation,
  allPrestations,
  devise,
  options,
  periodiciteFacturation,
  index,
}) => {
  const { input: formCurrentOptionFieldInput } = useField(`options.${index}.valueQuantity`);
  const { input: enabledOptionInput } = useField(`options.${index}.enabledOption`);
  const { quantite, optional } = prestation;
  const currency = symbol(devise.nom);
  const isOptional = !!optional;
  const isOptionalAndDisabled = isOptional && !enabledOptionInput.value;

  const period =
    prestation.serviceItemRepetition === serviceItemRepetition.RECURRING
      ? `/ ${periodOptions[periodiciteFacturation] || '–'}`
      : '';

  const renderPrestationDesignation = p => {
    const showSubText = () => {
      if (quantite.tarif.type === newTariffs.TARIFF_STANDARD) {
        const { amount } = quantite.tarif;
        return `${EURO_FRENCH(amount)} ${currency} HT / unité ${period}`;
      }
      if (quantite.tarif.type === newTariffs.TARIFF_PACKAGE) {
        const { amount, units } = quantite.tarif;
        return `${EURO_FRENCH(amount)} ${currency} HT par groupe de ${units} unités ${period}`;
      }
      if (
        quantite.tarif.type === newTariffs.TARIFF_GRADUATED ||
        quantite.tarif.type === newTariffs.TARIFF_GRADUATED_VOLUME
      ) {
        const firstTier = quantite.tarif.tiers[0];
        const { amount, maximum } = firstTier;
        return `${EURO_FRENCH(amount)} ${currency} HT / unité ${period} jusqu’à ${maximum} unités`;
      }

      return '';
    };

    return (
      <div>
        <span className={`p-d-block ${customStyle.designationPrestation}`}>{p.designation}</span>
        <span className={`p-d-block ${customStyle.famillePrestation}`}>{showSubText()}</span>
        {isOptional && (
          <div className="p-pt-1">
            <Chip className={customStyleChip.customChipOptional} label="Option" />
          </div>
        )}
      </div>
    );
  };

  const generateTotalPerItemGraduatedPricing = currentSubOption => {
    const currentQuantity = currentSubOption.valeurQuantite || 1;
    const allTiers = quantite.tarif.tiers;

    const findTier = allTiers.find(
      tier => currentQuantity >= tier.minimum && currentQuantity <= tier.maximum,
    );
    const maxTier = quantite.tarif.tiers[quantite.tarif.tiers.length - 1];
    const selectedTier = findTier || maxTier;

    const calculatedSelectedTierPrice = calculateTier(
      selectedTier,
      currentQuantity,
      selectedTier.minimum,
    );

    const pricePassedTiers = allTiers.reduce((acc, curr) => {
      if (curr.minimum < selectedTier.minimum) {
        const fullPriceTier = calculateTier(curr, curr.maximum, curr.minimum);
        return calcNumber(acc).add(fullPriceTier).value;
      }
      return acc;
    }, 0);

    const price = calcNumber(calculatedSelectedTierPrice).add(pricePassedTiers);

    return `${EURO_FRENCH(price)} ${currency} HT ${period}`;
  };

  const generateTotalPerItemVolumePricing = currentSubOption => {
    const currentQuantity = currentSubOption.valeurQuantite || 1;
    const findTier = quantite.tarif.tiers.find(
      tier => currentQuantity >= tier.minimum && currentQuantity <= tier.maximum,
    );
    const maxTier = findTier || quantite.tarif.tiers[quantite.tarif.tiers.length - 1];
    const price = calculatePerFormulaTier(currentQuantity, maxTier.amount, maxTier.flatFee);

    return `${EURO_FRENCH(price)} ${currency} HT ${period}`;
  };

  const generateTotalPerItem = ind => {
    const currentSubOption = options.find(el => el.prestation.identifiant === ind);

    if (quantite.tarif.type === newTariffs.TARIFF_GRADUATED) {
      if (quantite.tarif.tierType === 'GRADUATED_PRICING') {
        return generateTotalPerItemGraduatedPricing(currentSubOption);
      }
      if (quantite.tarif.tierType === 'VOLUME_PRICING') {
        return generateTotalPerItemVolumePricing(currentSubOption);
      }

      return '';
    }

    // const presetedValue =
    //   quantite.limitType === 'FROM' && !currentSubOption.valeurQuantite
    //     ? quantite.limit
    //     : currentSubOption.valueQuantity;

    const price = quantite.tarif.amount * currentSubOption.valueQuantity;

    return `${EURO_FRENCH(price)} ${currency} HT ${period}`;
  };

  const generateTotal = () => {
    if (
      quantite.type === newTypeQuantities.QUANTITY_LIMITED ||
      quantite.type === newTypeQuantities.QUANTITY_UNLIMITED
    ) {
      return generateTotalPerItem(prestation.prestation.identifiant);
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
      return 'Variable';
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION) {
      return generateTotalPerItem(quantite.dependPrestation.identifiant);
    }

    return '';
  };

  const generateQuantityForUnlimited = () => {
    const currentSubOption = options.find(
      el => el.prestation.identifiant === prestation.prestation.identifiant,
    );

    return (
      <NumberField
        id="quantityUnlimitedChanged"
        value={currentSubOption.valueQuantity}
        min={1}
        onValueChange={formCurrentOptionFieldInput.onChange}
      />
    );
  };

  const generateQuantityForLimited = () => {
    const currentSubOption = options.find(
      el => el.prestation.identifiant === prestation.prestation.identifiant,
    );

    return (
      <NumberField
        id="quantityLimitedChange"
        value={currentSubOption.valueQuantity}
        onValueChange={formCurrentOptionFieldInput.onChange}
        min={quantite.limitType === 'FROM' ? quantite.limit : 1}
        max={quantite.limitType === 'UNTIL' ? quantite.limit : 1000}
      />
    );
  };

  const generateQuantityForDependedOnAnotherPrestation = () => {
    const dependOption = options.find(
      el => el.prestation.identifiant === quantite.dependPrestation.identifiant,
    );

    const dependPrestation = allPrestations.find(
      el => el.prestation.identifiant === quantite.dependPrestation.identifiant,
    );

    if (dependPrestation.quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
      return 'Selon usage';
    }

    return dependOption.valueQuantity;
  };

  const generateQuantite = () => {
    if (quantite.type === newTypeQuantities.QUANTITY_LIMITED) {
      return generateQuantityForLimited();
    }
    if (quantite.type === newTypeQuantities.QUANTITY_UNLIMITED) {
      return generateQuantityForUnlimited();
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
      return 'Selon usage';
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION) {
      return generateQuantityForDependedOnAnotherPrestation();
    }

    return '';
  };

  const handleEnableOption = e => {
    enabledOptionInput.onChange(!!e.value);
  };

  return (
    <div className="p-mb-2">
      <div className="p-grid p-mb-3" style={{ background: '#f7f7f7' }}>
        <div
          className={`p-col-fixed p-justify-end ${customStyle.prestationCardCell}`}
          style={{ width: '6%' }}
        >
          {isOptional && (
            <PrimeInputSwitch checked={!!enabledOptionInput.value} onChange={handleEnableOption} />
          )}
        </div>
        <div className={`p-col-fixed ${customStyle.prestationCardCell}`} style={{ width: '15%' }}>
          {prestation.article ?? ''}
        </div>
        <div className={`p-col-fixed ${customStyle.prestationCardCell}`} style={{ width: '36%' }}>
          {renderPrestationDesignation(prestation.prestation)}
        </div>
        <div
          className={`p-col-fixed ${customStyle.prestationCardCell}`}
          style={{
            width: '20%',
            pointerEvents: isOptionalAndDisabled ? 'none' : 'all',
            opacity: isOptionalAndDisabled ? '40%' : '100%',
          }}
        >
          <span>{generateQuantite()}</span>
        </div>
        <div
          className={`p-col-fixed p-text-right ${customStyle.prestationCardCellTotalHT}`}
          style={{ width: '22.5%', opacity: isOptionalAndDisabled ? '40%' : '100%' }}
        >
          <div>
            <span className={`p-d-block ${customStyle.designationPrestation}`}>
              {generateTotal()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPreviewRow;
