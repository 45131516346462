import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { CardDisplay, Button } from 'olaqin-design-system';
import customStyle from 'components/reports/Reports.module.scss';
import { useField } from 'react-final-form';
import DataTableComposition from './DataTableComposition';
import CompositionDialog from './dialogs/CompositionDialog';

const CardComposition = () => {
  const [isCompositionDialogVisible, handleCompositionDialog] = useState(false);
  const { input: formOfferCompositionFieldInput } = useField('composition');
  const [selectedCompositionIndex, setSelectedCompositionIndex] = useState(null);

  const offerCompositionData = formOfferCompositionFieldInput.value;

  return (
    <CardDisplay
      title={offerCompositionData?.length > 0 ? 'Composition de l’offre' : 'Composition'}
      style={{ width: '100%' }}
    >
      <DataTableComposition
        openEditModal={targetIndex => {
          setSelectedCompositionIndex(targetIndex);
          handleCompositionDialog(true);
        }}
      />
      <Button
        icon="pi pi-plus"
        type="button"
        btnType="secondary"
        className={customStyle.customButtonDownload}
        label="Ajouter une prestation"
        onClick={() => handleCompositionDialog(true)}
      />
      {isCompositionDialogVisible && (
        <Portal>
          <CompositionDialog
            visible={isCompositionDialogVisible}
            selectedCompositionItemIndex={selectedCompositionIndex}
            onHide={() => {
              handleCompositionDialog(false);
              setSelectedCompositionIndex(null);
            }}
          />
        </Portal>
      )}
    </CardDisplay>
  );
};

export default CardComposition;
