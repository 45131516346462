import calcNumber from 'currency.js';

export const USD = value => calcNumber(value);
export const JPY = value => calcNumber(value, { precision: 0, symbol: '¥' });
export const EURO = value => calcNumber(value, { symbol: '€', decimal: ',', separator: '.' });

/**
 * Get amount EURO without ",00"
 * @param value
 * @return {string}
 * @constructor
 */
export const EURO_FRENCH = value =>
  calcNumber(value, { symbol: '', decimal: ',', separator: ' ', precision: 3 })
    .format()
    .replace(/(\.|,)000$/g, '');
