import { Validators } from 'olaqin-design-system';
import { thirdPartyPayerOptions } from 'app/constants/client.constants';

const validationSchemaForSubscriptionForm = {
  thirdPartyPayer: [],
};

// eslint-disable-next-line import/prefer-default-export
const createSubscriptionValidation = values => {
  if (
    !values.thirdPartyPayer?.numeroClient &&
    values.thirdPartyPayerType !== thirdPartyPayerOptions[0].value
  ) {
    validationSchemaForSubscriptionForm.thirdPartyPayer = [
      Validators.required('Veuillez sélectionner le tiers payeur'),
    ];
  } else {
    delete validationSchemaForSubscriptionForm.thirdPartyPayer;
  }
  return Validators.createValidator(validationSchemaForSubscriptionForm)(values);
};

export default createSubscriptionValidation;
