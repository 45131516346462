import { Validators } from 'olaqin-design-system';
import { maxLengthValidator } from 'app/utils/formFieldUtils';

const validationSchema = {
  designation: [
    Validators.required('Veuillez renseigner une désignation'),
    maxLengthValidator(69, 'La désignation ne peut excéder 69 caractères'),
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const formPrestationCreateValidation = values =>
  Validators.createValidator(validationSchema)(values);
