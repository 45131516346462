import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  getSubscriptionBilling,
  resetBilling,
  selectBillingData,
  selectIsBillingDataLoading,
} from 'app/redux/slices/souscriptions';
import { formatDate } from 'app/utils/dateUtils';
import { capitalize } from 'app/utils/functions';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import { routes } from 'app/routes/appRoutes';
import { useHistory } from 'react-router-dom';

const FactureTransmisDataTable = ({ subscriptionId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isBillingDataLoading = useSelector(selectIsBillingDataLoading);
  const billingData = useSelector(selectBillingData);

  useEffect(() => {
    dispatch(getSubscriptionBilling({ subscriptionId, page: 0, size: 14 }));
    return () => {
      dispatch(resetBilling());
    };
  }, []);

  const handleChangePage = data => {
    dispatch(getSubscriptionBilling({ subscriptionId, page: data.page, size: data.rows }));
  };

  const handleRowSelect = ({ data }) => {
    history.push(routes.BILLING_ORDER_DETAILS(data.identifiant));
  };

  const bodyPeriod = row => {
    const fromDate = formatDate(row.fromDate, 'DD/MM/YYYY');
    const toDate = formatDate(row.toDate, 'DD/MM/YYYY');

    return `${fromDate} – ${toDate}`;
  };

  const bodyType = row => capitalize(row.type);

  const bodyTransmissionDate = row => formatDate(row.transmissionDate, 'DD/MM/YYYY - HH:mm');

  const bodyStatus = row => <StatusSubscription label={row.status} />;

  return (
    <DataTable
      id="billing-table-transmis"
      value={billingData.content || []}
      loading={isBillingDataLoading}
      paginator={billingData.totalElements > 12}
      onPage={handleChangePage}
      onRowSelect={handleRowSelect}
      selection
      selectionMode="single"
      first={billingData.pageable?.offset || 0}
      numberOfElements={billingData.numberOfElements || 0}
      totalRecords={billingData.totalElements || 0}
      rows={billingData.size || 0}
      paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
      currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
      lazy
      emptyMessage="Aucun bon de facturation transmis"
      responsive={true}
      rowHover
      autoLayout
    >
      <Column
        key="Période de facturation"
        header="Période de facturation"
        sortable={false}
        headerStyle={{ border: '0', width: '22%' }}
        body={bodyPeriod}
        bodyStyle={{
          overflow: 'hidden',
          whiteSpace: 'noWrap',
          maxWidth: '0',
          textOverflow: 'ellipsis',
        }}
      />
      <Column
        key="Type de frais"
        header="Type de frais"
        body={bodyType}
        sortable={false}
        headerStyle={{ border: '0', width: '14%' }}
      />
      <Column
        key="billingOrderNumber"
        field="billingOrderNumber"
        header="Référence"
        sortable={false}
        headerStyle={{ border: '0', width: '14%' }}
      />
      <Column
        key="État"
        field="État"
        header="État"
        body={bodyStatus}
        sortable={false}
        headerStyle={{ border: '0', width: '12%' }}
      />
      <Column
        key="Transmis le"
        field="Transmis le"
        header="Transmis le"
        body={bodyTransmissionDate}
        sortable={false}
        headerStyle={{ border: '0', width: '14%' }}
      />
      <Column
        key="Réf. Sage"
        field="sageDeliveryOrderId"
        header="Réf. Sage"
        sortable={false}
        headerStyle={{ border: '0', width: '12%' }}
      />
      <Column
        key="Total"
        field="totalLabel"
        header="Total"
        sortable={false}
        headerStyle={{ border: '0', textAlign: 'right', width: '12%' }}
        bodyStyle={{ textAlign: 'right' }}
      />
    </DataTable>
  );
};

export default FactureTransmisDataTable;
