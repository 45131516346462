import React, { memo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate, formatDateTime } from 'app/utils/dateUtils';
import { tableColumnsModel } from './columnsModel';

const ReportsDataTable = ({ reportsData, isLoading }) => {
  const bodyFormat = (row, columnType, field) => {
    const value = row[field];
    if (!value) {
      return '';
    }

    if (columnType === 'date') {
      return formatDate(value);
    }

    if (columnType === 'dateTime') {
      return formatDateTime(value);
    }

    return value;
  };

  return (
    <DataTable
      id="reports-table"
      value={reportsData || []}
      loading={isLoading}
      scrollable
      scrollHeight="calc(100vh - 23rem)"
      style={{ width: '100%' }}
      lazy
      emptyMessage="Aucune données disponible pour la période sélectionnée"
      responsive
      rowHover
      autoLayout
    >
      {tableColumnsModel.map(column => (
        <Column
          key={column.field}
          header={column.label}
          field={column.field}
          body={row => bodyFormat(row, column.type, column.field)}
          headerStyle={{ width: '14rem' }}
        />
      ))}
    </DataTable>
  );
};

export default memo(ReportsDataTable);
