import React from 'react';
import { Button } from 'olaqin-design-system';
import { Field, useField } from 'react-final-form';
import {
  countingConditionsDateOperatorsOptions,
  countingConditionsDateValuesOptions,
  countingConditionsRoleAndStatusOperatorsOptions,
  countingConditionsRoleValuesOptions,
  countingConditionsStatusValuesOptions,
  countingConditionsTypeOptions,
} from 'app/constants/offerConstants';
import { Dropdown as PrimeDropdown } from 'primereact/components/dropdown/Dropdown';
import customStyle from 'components/souscriptions/subscriptionDetails/cards/CardDetails.module.scss';

const CountingConditionsRow = ({ name, index, handleClickRemove }) => {
  const { input: formFieldCountingConditionsTypeInput } = useField(`${name}.type`);
  const { input: formFieldCountingConditionsInput } = useField(`${name}.condition`);
  const { input: formFieldCountingValueInput } = useField(`${name}.value`);
  const typeValueDate = countingConditionsTypeOptions[0].value;
  const typeValueRole = countingConditionsTypeOptions[1].value;
  const typeValueStatus = countingConditionsTypeOptions[2].value;

  const getOperatorOptions = () =>
    formFieldCountingConditionsTypeInput.value === typeValueDate
      ? countingConditionsDateOperatorsOptions
      : countingConditionsRoleAndStatusOperatorsOptions;

  const getValuesOptions = () => {
    if (formFieldCountingConditionsTypeInput.value === typeValueDate) {
      return countingConditionsDateValuesOptions;
    }
    if (formFieldCountingConditionsTypeInput.value === typeValueRole) {
      return countingConditionsRoleValuesOptions;
    }
    if (formFieldCountingConditionsTypeInput.value === typeValueStatus) {
      return countingConditionsStatusValuesOptions;
    }

    return [];
  };

  const assignDefaultOperandOnTypeChange = newTypeInputVal => {
    let operandTargetValue = countingConditionsDateOperatorsOptions[0].value;
    if (newTypeInputVal.value !== typeValueDate) {
      operandTargetValue = countingConditionsRoleAndStatusOperatorsOptions[0].value;
    }
    formFieldCountingConditionsInput.onChange(operandTargetValue);
  };

  const assignDefaultValueOnTypeChange = newTypeInputVal => {
    let targetValue = '';

    if (newTypeInputVal.value === typeValueDate) {
      targetValue = countingConditionsDateValuesOptions[0].value;
    }
    if (newTypeInputVal.value === typeValueRole) {
      targetValue = countingConditionsRoleValuesOptions[0].value;
    }
    if (newTypeInputVal.value === typeValueStatus) {
      targetValue = countingConditionsStatusValuesOptions[0].value;
    }
    formFieldCountingValueInput.onChange(targetValue);
  };

  return (
    <div style={{ gap: '0.5rem' }} className="p-d-flex p-align-center p-justify-end p-mb-2">
      <p className="p-m-0">{!index ? 'Si' : 'et si'}</p>
      <Field
        name={`${name}.type`}
        render={({ input }) => (
          <PrimeDropdown
            style={{ width: '12rem' }}
            placeholder="Sélectionner"
            value={input.value}
            options={countingConditionsTypeOptions}
            onChange={e => {
              input.onChange(e);
              assignDefaultOperandOnTypeChange(e);
              assignDefaultValueOnTypeChange(e);
            }}
          />
        )}
      />
      <Field
        name={`${name}.condition`}
        render={({ input }) => (
          <PrimeDropdown
            style={{ width: '12rem' }}
            placeholder="Sélectionner"
            value={input.value}
            options={getOperatorOptions()}
            onChange={input.onChange}
          />
        )}
      />
      <Field
        name={`${name}.value`}
        render={({ input }) => (
          <PrimeDropdown
            style={{ width: '12rem' }}
            placeholder="Sélectionner"
            value={input.value}
            options={getValuesOptions()}
            onChange={input.onChange}
          />
        )}
      />
      <Button
        icon="pi pi-times"
        type="button"
        btnType="secondary"
        onClick={() => handleClickRemove(index)}
        className={customStyle.customLinkButton}
      />
    </div>
  );
};

export default CountingConditionsRow;
