import React, { useRef } from 'react';
import { Button } from 'olaqin-design-system';
import { Menu } from 'primereact/menu';

const ActionMenu = ({ onClick, cancelImmediately }) => {
  const menu = useRef(null);

  const subActionsModel = [
    {
      label: cancelImmediately
        ? 'Résilier la souscription immédiatement'
        : 'Résilier la souscription',
      icon: 'pi pi-times-circle',
      style: { color: 'red' },
      command: onClick,
    },
  ];

  return (
    <>
      <Menu
        style={{ width: cancelImmediately ? '22rem' : '14rem' }}
        model={subActionsModel}
        popup
        ref={menu}
        id="popup_menu"
        appendTo={document.body}
      />
      <Button
        label="Actions"
        icon="pi pi-chevron-down"
        iconPos="right"
        btnType="primary"
        onClick={event => menu.current.toggle(event)}
        aria-controls="popup_menu"
        aria-haspopup
        className="p-mr-3 p-py-2 p-px-3"
        style={{
          height: '34px',
          background: '#FFFFFF',
          border: '1px solid rgba(106, 106, 106, 0.24)',
          color: 'black',
          fontSize: '14px',
          fontFamily: 'Arial',
        }}
      />
    </>
  );
};

export default ActionMenu;
