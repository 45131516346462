import env from '@beam-australia/react-env';

const API_VERSION = '1.0';

const API_BASE =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? `${env('API_URL')}/api/${API_VERSION}`
    : env('API_URL');

const API_UTILISATEURS = `${API_BASE}/utilisateurs`;
const API_ACTUATOR = `${env('API_URL')}/actuator/info`;
const NOTIFICATION_ENDPOINT = `${API_BASE}/notifications`;

export { API_VERSION, API_UTILISATEURS, API_ACTUATOR, NOTIFICATION_ENDPOINT, API_BASE };
