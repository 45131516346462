import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'olaqin-design-system';
import { selectListPrestations } from 'app/redux/slices/prestations';
import PrestationCreateDialog from './PrestationCreateDialog';

const PrestationCreateDialogWrap = () => {
  const prestationsNewList = useSelector(selectListPrestations);

  const [isCreateDialogVisible, handleCreateDialog] = useState(false);

  return (
    <>
      {isCreateDialogVisible && (
        <PrestationCreateDialog
          visible={isCreateDialogVisible}
          onHide={() => handleCreateDialog(false)}
          prestationsNewList={prestationsNewList}
        />
      )}
      <Button
        btnType="primary"
        label="Nouvelle prestation"
        icon="pi pi-plus"
        style={{ backgroundColor: '#9c67cd', borderColor: '#9c67cd' }}
        onClick={() => handleCreateDialog(true)}
      />
    </>
  );
};

export default PrestationCreateDialogWrap;
