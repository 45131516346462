import React from 'react';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import { Button } from 'olaqin-design-system';
import HeaderPage from 'components/_shared/HeaderPage/HeaderPage';
import { routes } from 'app/routes/appRoutes';
import { useHistory } from 'react-router-dom';
import OffersBody from './OffersBody';

const Offers = () => {
  const history = useHistory();
  useDocumentTitle(`${TITLE_PREFIX} Offres`);

  const handleClickCreateOffer = () => {
    history.push(routes.OFFER_CREATE());
  };

  return (
    <>
      <HeaderPage title="Offres">
        <Button
          btnType="primary"
          label="Nouvelle offre"
          icon="pi pi-plus"
          style={{ backgroundColor: '#9c67cd', borderColor: '#9c67cd' }}
          onClick={handleClickCreateOffer}
        />
      </HeaderPage>
      <OffersBody />
    </>
  );
};

export default Offers;
