import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import {
  targetCibleOptions,
  customerNatureOptions,
  categoryOptions,
  billingSystemTypeServer,
  specialityOptions,
} from 'app/constants/client.constants';

const ComplementaryInfo = ({ clientDetails, sageBaseType }) => {
  const {
    customerNature,
    secondReseller,
    group,
    patientRecord,
    targetCible,
    targetCompletement,
    sponsorship,
    category,
    activity,
  } = clientDetails;

  const findCustomerNature = customerNatureOptions?.find(({ value }) => value === customerNature);
  const findTargetCibleOptions = targetCibleOptions?.find(({ value }) => value === targetCible);
  const findCategoryOptions = categoryOptions?.find(({ value }) => value === category);
  const findActivity = specialityOptions?.find(({ value }) => value === activity);

  return (
    <CardDisplay title="Informations complémentaires" style={{ width: 'auto' }}>
      {sageBaseType === billingSystemTypeServer.AFFID && (
        <div className="item">
          <span>Spécialité</span>
          <span>{dashOnEmpty(findActivity?.label || activity)}</span>
        </div>
      )}
      <div className="item">
        <span>Nature client</span>
        <span>{dashOnEmpty(findCustomerNature?.label || customerNature)}</span>
      </div>
      <div className="item">
        <span>Catégorie</span>
        <span>{dashOnEmpty(findCategoryOptions?.label)}</span>
      </div>
      <div className="item">
        <span>Revendeur bis</span>
        <span>{dashOnEmpty(secondReseller)}</span>
      </div>
      <div className="item">
        <span>Groupe</span>
        <span>{dashOnEmpty(group)}</span>
      </div>
      <div className="item">
        <span>Dossier patient</span>
        <span>{dashOnEmpty(patientRecord)}</span>
      </div>
      <div className="item">
        <span>Complément cible</span>
        <span>
          <div className="detail">{dashOnEmpty(findTargetCibleOptions?.label || targetCible)}</div>
          <div className="detail">{dashOnEmpty(targetCompletement)}</div>
        </span>
      </div>
      <div className="item">
        <span>Parrainage</span>
        <span>{dashOnEmpty(sponsorship)}</span>
      </div>
    </CardDisplay>
  );
};

export default ComplementaryInfo;
