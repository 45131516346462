import React from 'react';
import { Field, useField } from 'react-final-form';
import { Button } from 'olaqin-design-system';
import { InputNumber as PrimeInputNumber } from 'primereact/components/inputnumber/InputNumber';
import customCardStyle from 'components/souscriptions/subscriptionDetails/cards/CardDetails.module.scss';
import customStyle from './CompositionDialog.module.scss';

const PaliersTarifRow = ({ name, rowIndex, handleClickRemove, handleUpdate }) => {
  const { input: inputField } = useField(`${name}`);
  const { input: formFieldTariffProgressive } = useField('composition.tariffProgressive');

  const { units, upToUnits } = inputField.value;
  const isFirstRow = rowIndex === 0;
  const isLastRow = formFieldTariffProgressive.value.length === rowIndex + 1;
  const nextRowIndex = rowIndex + 1;
  const nextRowValues = formFieldTariffProgressive.value[nextRowIndex];

  const onCustomUpToUnitsChange = (e, input) => {
    if (nextRowValues) {
      const nextUnitsValue = (e.value || 0) + 1;
      handleUpdate(nextRowIndex, { ...nextRowValues, units: nextUnitsValue });
    }
    return input.onChange(e.value || 0);
  };

  const onCustomRemove = () => {
    if (nextRowValues) {
      handleUpdate(nextRowIndex, { ...nextRowValues, units });
    }
    handleClickRemove(rowIndex);
  };

  const elementsFocusHandler = event => event.target.setSelectionRange(0, 0);

  if (isFirstRow) {
    return (
      <div className={customStyle.progressiveTableRow}>
        <div className={customStyle.progressiveTableRowCell}>{units}</div>
        <div className={customStyle.progressiveTableRowCell}>
          <Field
            name={`${name}.upToUnits`}
            render={({ input, meta: { valid, touched } }) => (
              <PrimeInputNumber
                style={{ color: '#495057' }}
                value={input.value}
                className={!valid && touched ? 'p-invalid' : ''}
                useGrouping={false}
                min={0}
                max={10000}
                onValueChange={e => onCustomUpToUnitsChange(e, input)}
                onBlur={input.onBlur}
                onFocus={elementsFocusHandler}
              />
            )}
          />
        </div>
        <div className={customStyle.progressiveTableRowCell}>
          <Field
            name={`${name}.tarif`}
            render={({ input }) => (
              <PrimeInputNumber
                style={{ color: '#495057' }}
                mode="currency"
                currency="EUR"
                locale="fr-FR"
                maxFractionDigits={3}
                value={input.value}
                onValueChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={elementsFocusHandler}
              />
            )}
          />
        </div>
        <div
          style={{ borderRight: '1px solid #D8D8D8' }}
          className={customStyle.progressiveTableRowCell}
        >
          <Field
            name={`${name}.fixedTarif`}
            render={({ input }) => (
              <PrimeInputNumber
                style={{ color: '#495057' }}
                mode="currency"
                currency="EUR"
                locale="fr-FR"
                maxFractionDigits={3}
                value={input.value}
                onValueChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={elementsFocusHandler}
              />
            )}
          />
        </div>
        <div className="p-pr-6" />
      </div>
    );
  }

  if (isLastRow) {
    return (
      <div className={customStyle.progressiveTableRow}>
        <div className={customStyle.progressiveTableRowCell}>{units}</div>
        <div className={customStyle.progressiveTableRowCell}>{upToUnits}</div>
        <div className={customStyle.progressiveTableRowCell}>
          <Field
            name={`${name}.tarif`}
            render={({ input }) => (
              <PrimeInputNumber
                style={{ color: '#495057' }}
                mode="currency"
                currency="EUR"
                locale="fr-FR"
                maxFractionDigits={3}
                value={input.value}
                onValueChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={elementsFocusHandler}
              />
            )}
          />
        </div>
        <div
          style={{ borderRight: '1px solid #D8D8D8' }}
          className={customStyle.progressiveTableRowCell}
        >
          <Field
            name={`${name}.fixedTarif`}
            render={({ input }) => (
              <PrimeInputNumber
                style={{ color: '#495057' }}
                mode="currency"
                currency="EUR"
                locale="fr-FR"
                maxFractionDigits={3}
                value={input.value}
                onValueChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={elementsFocusHandler}
              />
            )}
          />
        </div>
        <div className="p-pr-6" />
      </div>
    );
  }

  return (
    <div className={customStyle.progressiveTableRow}>
      <div className={customStyle.progressiveTableRowCell}>{units}</div>
      <div className={customStyle.progressiveTableRowCell}>
        <Field
          name={`${name}.upToUnits`}
          render={({ input, meta: { valid, touched } }) => (
            <PrimeInputNumber
              style={{ color: '#495057' }}
              value={input.value}
              className={!valid && touched ? 'p-invalid' : ''}
              useGrouping={false}
              min={0}
              max={10000}
              onValueChange={e => onCustomUpToUnitsChange(e, input)}
              onBlur={input.onBlur}
              onFocus={elementsFocusHandler}
            />
          )}
        />
      </div>
      <div className={customStyle.progressiveTableRowCell}>
        <Field
          name={`${name}.tarif`}
          render={({ input }) => (
            <PrimeInputNumber
              style={{ color: '#495057' }}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
              maxFractionDigits={3}
              value={input.value}
              onValueChange={input.onChange}
              onBlur={input.onBlur}
              onFocus={elementsFocusHandler}
            />
          )}
        />
      </div>
      <div
        style={{ borderRight: '1px solid #D8D8D8' }}
        className={customStyle.progressiveTableRowCell}
      >
        <Field
          name={`${name}.fixedTarif`}
          render={({ input }) => (
            <PrimeInputNumber
              style={{ color: '#495057' }}
              mode="currency"
              currency="EUR"
              locale="fr-FR"
              maxFractionDigits={3}
              value={input.value}
              onValueChange={input.onChange}
              onBlur={input.onBlur}
              onFocus={elementsFocusHandler}
            />
          )}
        />
      </div>
      <div className="p-m-2">
        <Button
          icon="pi pi-times"
          type="button"
          btnType="secondary"
          onClick={onCustomRemove}
          className={customCardStyle.customLinkButton}
        />
      </div>
    </div>
  );
};

export default PaliersTarifRow;
