import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import './BillingOrderActionMenuStyles.scss';

const BillingOrderActionMenu = ({ transmitZeroConfig }) => {
  const menu = useRef(null);

  const ActionsModel = [
    {
      label: 'Transmettre le bon de facturation à SAGE',
      icon: 'pi pi-arrow-up',
      command: transmitZeroConfig.onClick,
      disabled: transmitZeroConfig.isDisabled,
      className: 'tarnsmitZeroItem',
      style: {
        display: transmitZeroConfig.show ? 'initial' : 'none',
      },
    },
  ];

  return (
    <>
      <Menu
        style={{ width: 'max-content' }}
        model={ActionsModel}
        popup
        ref={menu}
        id="billing_order_action_menu"
        appendTo={document.body}
        className="BillingOrderActionMenu"
      />
      <SubmitButton
        label="Actions"
        icon="pi pi-chevron-down"
        btnType="secondary"
        type="button"
        outlined
        onClick={event => menu.current.toggle(event)}
        small
        style={{
          background: '#FFFFFF',
          color: 'black',
        }}
      />
    </>
  );
};

export default BillingOrderActionMenu;
