import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { Portal } from 'react-portal';
import {
  createNewClient,
  resolveClientAnomaly,
  selectAnomaliesLoadings,
  selectClientDetailsDataAnomalies,
  selectClientDetailsDataClient,
  // selectIsClientDetailsLoading,
  updateNewClient,
} from 'app/redux/slices/teams';
import {
  classificationGieOptions,
  salesTypologyOptions,
  thirdPartyPayerOptions,
} from 'app/constants/client.constants';
import CardAnomalies from 'components/souscriptions/subscriptionDetails/cards/CardAnomalies';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { selectPaymentTermsList } from 'app/redux/slices/utilisateurs';
import ClientCreateFormFields from './formClient/ClientCreateFormFields';
import { formClientCreateValidation } from './formClient/formClientCreateValidation';
import {
  generateInitialValuesForm,
  removeInvalidField,
} from './formClient/formGenerateInitialValues';

const ClientCreateDialogForm = ({
  isEdit = false,
  actionOnSuccessEdit,
  actionOnSuccessCreate,
  visible,
  onHide,
}) => {
  const dispatch = useDispatch();
  const clientDetails = useSelector(selectClientDetailsDataClient);
  const clientDetailsAnomalies = useSelector(selectClientDetailsDataAnomalies);
  const paymentTermsList = useSelector(selectPaymentTermsList);

  const initialData = clientDetails || {};
  const anomalies = (isEdit && clientDetailsAnomalies) || [];

  const handleClickConfirm = async formData => {
    const validFormData = removeInvalidField(formData, isEdit);
    await dispatch(
      isEdit
        ? updateNewClient({ clientId: initialData.identifiant, formData: validFormData })
        : createNewClient(validFormData),
    )
      .unwrap()
      .then(() => {
        if (isEdit) {
          onHide();
          actionOnSuccessEdit();
        } else {
          onHide();
          actionOnSuccessCreate();
        }
      })
      .catch(err => {
        if (err?.response?.data?.code === 'CODE_ERREUR_CLIENT_NOT_UPDATED_IN_SAGE') {
          if (isEdit) {
            onHide();
            actionOnSuccessEdit();
          } else {
            onHide();
            actionOnSuccessCreate();
          }
        }
      });
  };

  const baseInitialData = useMemo(
    () => ({
      hasActiveSubscriptions: false,
      adresseFacturation: {
        pays: 'France',
      },
      bankDetails: {},
      thirdPartyPayerOption: thirdPartyPayerOptions?.[0].value,
      salesTypology: salesTypologyOptions[17].value,
      classificationGie: classificationGieOptions[10].value,
    }),
    [],
  );

  const formInitialData = generateInitialValuesForm(isEdit, baseInitialData, initialData);

  const onClickResolveAnomaly = ({ id, anomalieId }) => {
    dispatch(resolveClientAnomaly({ id, anomalieId }));
  };

  const customValidation = values => {
    const isBankRequiredForSelectedPayment =
      paymentTermsList.find(payment => payment.identifiant === values.paymentTermTemplateId)
        ?.bankDetailsRequired || false;
    return formClientCreateValidation(values, isBankRequiredForSelectedPayment);
  };

  return (
    <Portal>
      <Form
        initialValues={formInitialData}
        validate={customValidation}
        onSubmit={handleClickConfirm}
        render={({ handleSubmit, submitting, values }) => (
          <form
            className="p-fluid p-formgrid p-grid"
            id={'client-create-dialog'}
            onSubmit={handleSubmit}
          >
            <DialogBox
              id="dialogCreateClient"
              spacingField="8px"
              spacingLabel="0"
              visible={visible}
              onHide={onHide}
              title={isEdit ? initialData.nom : 'Ajout d’un nouveau client'}
              noSeparator={!values?.sageBase}
              style={{
                maxWidth: '600px',
                overflow: 'auto',
              }}
              className="p-col-12 p-sm-12 p-md-12 p-lg-8"
              confirmLabel={isEdit ? 'Enregistrer les modifications' : 'Ajouter le client'}
              onConfirm={handleSubmit}
              cancelLabel="Annuler"
              onCancel={onHide}
              isConfirmActive={!submitting}
            >
              <>
                {!!anomalies && (
                  <div style={{ marginTop: '-1rem' }} className="p-mb-3">
                    <CardAnomalies
                      id={initialData.identifiant}
                      anomalies={anomalies}
                      onClickResolve={onClickResolveAnomaly}
                      loadingsSelector={selectAnomaliesLoadings}
                    />
                  </div>
                )}
                <ClientCreateFormFields
                  hasActiveSubscriptions={values?.hasActiveSubscriptions}
                  isEdit={isEdit}
                  sageBaseType={values?.sageBase}
                />
              </>
            </DialogBox>
          </form>
        )}
      />
    </Portal>
  );
};

export default ClientCreateDialogForm;
