import moment from 'moment';

/**
 * Format date by default to DD/MM/YYYY
 * @param date {Date|string}
 * @param format {string}
 * @return {string|string}
 */
export const formatDate = (date, format = 'DD/MM/YYYY') =>
  date ? moment(date).format(format) : '';
/**
 * Format date by default to DD/MM/YYYY HH:mm
 * @param date {Date|string}
 * @param format {string}
 * @return {string|string}
 */
export const formatDateTime = (date, format = 'DD/MM/YYYY HH:mm') =>
  date ? moment(date).format(format) : '';
/**
 * Format date by default to DD/MM/YYYY HH:mm
 * @param date {Date|string}
 * @param format {string}
 * @param days {number}
 * @return {string|string}
 */
export const formatDateWithDays = (date, format = 'DD/MM/YYYY HH:mm', days = 1) =>
  date
    ? moment(date)
        .add(days)
        .format(format)
    : '';

/**
 * Generate date starting from first date of the next month
 * @param amountOfMonth {number} – for skip months
 * @param format {string}
 * @return {string}
 */
export const startOfNextMonth = (amountOfMonth, format = 'YYYY-MM-DD') =>
  moment()
    .add(amountOfMonth, 'M')
    .startOf('month')
    .format(format);
