import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import WarningLabel from 'components/_shared/WarningLabel/WarningLabel';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';

const OfferStellairConnection = ({
  stellairConnectionDetails,
  actionOnReqParametersEdit,
  displayConfigureBtn,
}) => (
  <CardDisplay
    style={{ width: 'auto' }}
    title={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src="/icons/StellairLogo-32.svg" alt="Stellair" style={{ paddingRight: 8 }} />
        Offre Stellair
      </div>
    }
  >
    <div className="p-grid item p-mt-2">
      <span className="p-md-3">Nom de l’offre :</span>
      <span className="p-md-9">{stellairConnectionDetails?.name ?? <WarningLabel />}</span>
    </div>
    <div className="p-grid item">
      <span className="p-md-3">Identifiant technique :</span>
      <span className="p-md-9">
        {stellairConnectionDetails?.technicalIdentifier ?? <WarningLabel />}
      </span>
    </div>
    <div className="p-grid item">
      <span className="p-md-3">Revendeur :</span>
      <span className="p-md-9">{stellairConnectionDetails?.revendeur ?? <WarningLabel />}</span>
    </div>
    <div>
      {displayConfigureBtn && (
        <SubmitButton
          className="p-mt-2"
          type="button"
          btnType="secondary"
          label="Configurer la connexion avec une offre Stellair"
          outlined
          small
          style={{ background: 'white' }}
          icon="pi pi-cog"
          onClick={actionOnReqParametersEdit}
        />
      )}
    </div>
  </CardDisplay>
);

export default OfferStellairConnection;
