import React, { useEffect } from 'react';
import { getOfferUsageTypes, selectOfferDetailsData } from 'app/redux/slices/offres';
import { useDispatch, useSelector } from 'react-redux';
import { CardDisplay } from 'olaqin-design-system';
import {
  listerArticles,
  selectListeArticles,
  selectIsListeArticlesLoading,
} from 'app/redux/slices/prestations';
import {
  getSouchesList,
  selectSouchesList,
  selectIsSouchesListLoading,
} from 'app/redux/slices/utilisateurs';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import { billingSystemTypeOptions } from 'app/constants/client.constants';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import { useField } from 'react-final-form';
import CreateNewButton from 'components/_shared/CreateNewButton/CreateNewButton';
import {
  BillOneTimeFeesList,
  TransmissZeroInvoicesList,
  DateFacturationList,
} from 'app/constants/offerConstants';
import OfferCompositionTable from '../Details/Sections/OfferCompositionTable';
import customStyle from '../../souscriptions/createSubscription/createSubscription.module.scss';

const OfferParametersFields = () => {
  const dispatch = useDispatch();
  const isArticlesListLoading = useSelector(selectIsListeArticlesLoading);
  const articlesList = useSelector(selectListeArticles);
  const offerData = useSelector(selectOfferDetailsData);
  const isSoucheListLoading = useSelector(selectIsSouchesListLoading);
  const soucheList = useSelector(selectSouchesList);

  const { input: prestationFormField } = useField('prestations');

  const {
    input: { value: sageBase },
  } = useField('sageBase');

  useEffect(() => {
    dispatch(getOfferUsageTypes());
  }, []);

  useEffect(() => {
    if (sageBase) {
      clearSelectedArticles();
      fetchArticles();
      dispatch(getSouchesList(sageBase));
    }
  }, [sageBase]);

  const clearSelectedArticles = () => {
    prestationFormField.onChange(offerData.prestations);
  };

  const fetchArticles = () => dispatch(listerArticles({ fromSage: true, sageBase }));

  const articleRowRenderer = (_, { rowIndex }) => (
    <FormFieldAutoComplete
      name={`prestations[${rowIndex}].article`}
      noWrapper
      filter
      filterBy="code,designation"
      placeholder="Sélectionner"
      options={articlesList}
      styles={{ container: () => ({ width: '100%' }) }}
      getOptionLabel={e => e.code}
      getOptionValue={e => e.code}
      menuPortalTarget={document.body}
      isDisabled={isArticlesListLoading}
      isLoading={isArticlesListLoading}
      storeSingleValueOnForm
      loadOptions={{
        optionValue: 'code',
      }}
      wrapperInlineStyle={{
        width: '100%',
      }}
      tooltip={{
        label:
          'Il est nécessaire de choisir le système de facturation avant de sélectionner la référence article.',
        display: !sageBase,
        disableInput: !sageBase,
      }}
    />
  );

  return (
    <div className={customStyle.formBody}>
      <div className="p-col-12 p-p-0">
        <div className="p-xl-10 p-md-12 p-xl-offset-1">
          <CardDisplay title="Système de facturation" style={{ width: '100%' }}>
            <div className="p-field p-fluid p-col-12 p-lg-5 p-p-0 p-mt-3">
              <FormFieldDropdown
                name="sageBase"
                placeholder="Sélectionner"
                options={billingSystemTypeOptions}
              />
            </div>
          </CardDisplay>
          <OfferCompositionTable
            prestations={prestationFormField.value}
            articleRowRenderer={articleRowRenderer}
            displayConfigureBtn={false}
          >
            {!!sageBase && (
              <CreateNewButton
                style={{ textAlign: 'left' }}
                label="Actualiser les références articles"
                type="button"
                icon="pi pi-replay"
                onClick={fetchArticles}
                disabled={isArticlesListLoading}
              />
            )}
          </OfferCompositionTable>
          <CardDisplay title="Paramètres de facturation" style={{ width: '100%' }}>
            <div className="p-fluid p-col-12 p-lg-5 p-p-0">
              <p style={{ fontWeight: 700 }}>Souche</p>
              <FormFieldAutoComplete
                name="codeSouche"
                noWrapper
                filter
                filterBy="code,designation"
                placeholder="Sélectionner"
                options={soucheList}
                getOptionLabel={e => e.designation}
                getOptionValue={e => e.code}
                isDisabled={!sageBase}
                isLoading={isSoucheListLoading}
                storeSingleValueOnForm
                loadOptions={{
                  optionValue: 'code',
                }}
                tooltip={{
                  display: !sageBase,
                  label:
                    'Il est nécessaire de choisir le système de facturation avant de sélectionner la souche.',
                }}
              />
              <p style={{ fontWeight: 700 }}>Facturation des prestations ponctuelles</p>
              <FormFieldDropdown
                name="billOneTimeFeesSeparately"
                noWrapper
                placeholder="Sélectionner"
                options={BillOneTimeFeesList}
              />
              <p style={{ fontWeight: 700 }}>Bons de facturation à 0 €</p>
              <FormFieldDropdown
                name="transmissZeroInvoices"
                noWrapper
                placeholder="Sélectionner"
                options={TransmissZeroInvoicesList}
              />
              <p style={{ fontWeight: 700 }}>
                Jour de transmission des bons de facturation avec services récurrents
              </p>
              <FormFieldDropdown
                name="dateFacturation"
                noWrapper
                placeholder="Sélectionner"
                options={DateFacturationList}
              />
            </div>
          </CardDisplay>
        </div>
      </div>
    </div>
  );
};

export default OfferParametersFields;
