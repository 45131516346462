import { useEffect } from 'react';
import { EventType } from '@azure/msal-browser';
import { loginRequest } from 'msal';
import { toast } from 'react-toastify';

// eslint-disable-next-line import/prefer-default-export
export const useAppAuth = msalInstance => {
  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback(event => {
      // set active account after redirect
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const { account } = event.payload;
        msalInstance.setActiveAccount(account);
      }
      // clear accounts after logout
      if (event.eventType === EventType.LOGOUT_SUCCESS) {
        msalInstance.setActiveAccount(null);
      }
    });

    msalInstance
      .handleRedirectPromise()
      .then(
        /* authResult */ () => {
          const account = msalInstance.getActiveAccount();
          if (!account) {
            // eslint-disable-next-line no-unused-expressions
            window.parent !== window
              ? msalInstance.loginPopup(loginRequest)
              : msalInstance.loginRedirect({ ...loginRequest, prompt: 'login' });
          } else {
            // todo proper login refresh token logic
            msalInstance.acquireTokenSilent(loginRequest).then(
              () => {},
              () => {
                msalInstance.loginRedirect({ ...loginRequest, prompt: 'login' });
              },
            );
          }
        },
      )
      .catch(error => {
        if (error?.name === 'InteractionRequiredAuthError') {
          toast.error(
            'Échec de l’authentification. Veuillez réessayer, contactez le support si le problème persiste.',
          );
        }
      });
  }, []);
};
