import React from 'react';
import { Button } from 'olaqin-design-system';
import { Calendar } from 'primereact/calendar';
import { useDispatch } from 'react-redux';
import { getKeyForDownloadLink, getReportsByDate } from 'app/redux/slices/souscriptions';
import { formatDate } from 'app/utils/dateUtils';
import moment from 'moment';

const ReportsControls = ({ twoDates, isLoading, handleChangeCalendar }) => {
  const dispatch = useDispatch();

  const isValidDates = !!twoDates && twoDates.every(Boolean);

  const isGenerateDisabled = !isValidDates || isLoading;

  const fromYear = '2009';
  const toYear = moment()
    .add(5, 'y')
    .format('YYYY');

  const yearRangeCalculated = `${fromYear}:${toYear}`;

  const handleGenerateReports = () => {
    const fromDate = formatDate(twoDates[0], 'YYYY-MM-DD');
    const toDate = formatDate(twoDates[1], 'YYYY-MM-DD');

    dispatch(getReportsByDate({ fromDate, toDate }));

    // get token to be able show link for download
    dispatch(getKeyForDownloadLink());
  };

  return (
    <div className="p-mb-3">
      <Calendar
        id="report-dates-calender"
        style={{ width: '14rem' }}
        className="p-mr-3"
        placeholder="Sélectionner une période"
        dateFormat="dd/mm/yy"
        readOnlyInput
        selectionMode="range"
        value={twoDates}
        onChange={handleChangeCalendar}
        monthNavigator
        yearNavigator
        yearRange={yearRangeCalculated}
        showButtonBar
        disabled={isLoading}
        // locale="es"
      />
      <Button
        icon={isLoading ? 'pi pi-spin pi-spinner' : ''}
        btnType="primary"
        label={!isLoading ? 'Générer le rapport' : 'Génération…'}
        style={{
          backgroundColor: isGenerateDisabled ? '' : '#9c67cd',
          borderColor: '#9c67cd',
          height: '37px',
        }}
        disabled={isGenerateDisabled}
        onClick={handleGenerateReports}
      />
    </div>
  );
};

export default ReportsControls;
