import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { throwError } from 'app/utils/functions';
import { toast } from 'react-toastify';
import {
  subscriptionStatusesServer,
  subscriptionStatuses,
} from 'app/constants/subscription.constants';
import { getSubscriptionSortOrderByField } from 'components/subscriptionsNew/SubscriptionGridSorting';

const managerName = 'souscriptions';

const initialState = {
  loading: false,
  isLoadingSearch: false,
  isTerminationLoading: false,
  isDeleting: false,
  statusChangeLoading: false,
  stellairActivation: false,
  pageSouscriptions: null,
  listeSouscriptions: [],
  createSubscription: {
    isLoading: false,
    client: '',
    offre: {},
    // dateStart: null,
  },
  newListSubscriptions: {},
  steps: [
    {
      key: 1,
      label: 'Votre nouvelle offre',
      error: false,
      cursor: 0,
      progress: [0, 0],
      selectedAccordion: 0,
    },
  ],
  // souscription object
  souscription: {
    souscripteur: null,
    vendeur: null,
    options: [],
    offre: { prestations: [] },
    ajoutTerminaux: false,
    creationAcces: false,
    dateStart: null,
  },
  billing: {
    isLoading: false,
    isLoadingEventsHistory: false,
    billingData: {},
    billingDetailsData: {},
    billingEventsHistoryData: [],
  },
  anomaliesLoadings: {},
  documents: {
    isLoading: false,
    IsLoadingDelete: false,
    documentsData: [],
  },
  reports: {
    isLoading: false,
    isLoadingFse: false,
    isLoadingFseSearch: false,
    isLoadingDownloadKey: false,
    reportsData: null,
    reportsFseData: {},
    downloadKeys: {},
  },
  documentsKeys: {},
  offre: null,
  ajouterMoyenPaiement: false,
  activerSouscription: false,
  eventsGrid: {
    isLoading: false,
    data: {},
  },
  transmittingZeroToSage: false,
};

export const afficherPageSouscriptions = createAsyncThunk(
  `${managerName}/afficherPageSouscriptions`,
  async criteres => {
    try {
      return (
        await API.Souscriptions.afficherPageSouscriptions(
          criteres.page,
          criteres.size,
          criteres.sortField,
          criteres.sortOrder === -1 ? 'DESC' : 'ASC',
          criteres.nomClient ? criteres.nomClient : '',
          criteres.numeroClient ? criteres.numeroClient : '',
          criteres.numeroContrat ? criteres.numeroContrat : '',
          criteres.multiColonnes ? criteres.multiColonnes : '',
          criteres.etats ? criteres.etats : '',
          criteres.fields,
        )
      ).data;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const getSubscriptionNewListPaginated = createAsyncThunk(
  `${managerName}/getSubscriptionNewListPaginated`,
  async (
    {
      page,
      size,
      sortField = 'dateCreation',
      search,
      client,
      status,
      numeroContrat,
      startCreationDate,
      endCreationDate,
      startActivationDate,
      endActivationDate,
      startDebutDate,
      endDebutDate,
      startFinishDate,
      endFinishDate,
      startRenewalDate,
      endRenewalDate,
      startCancellationDate,
      endCancellationDate,
      thirdPartyPayer,
      offer,
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await API.Souscriptions.afficherPageSouscriptions(
        page,
        size,
        sortField,
        getSubscriptionSortOrderByField(sortField),
        search,
        client,
        status,
        numeroContrat,
        startCreationDate,
        endCreationDate,
        startActivationDate,
        endActivationDate,
        startDebutDate,
        endDebutDate,
        startFinishDate,
        endFinishDate,
        startRenewalDate,
        endRenewalDate,
        startCancellationDate,
        endCancellationDate,
        thirdPartyPayer,
        offer,
      );

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const listerSouscriptions = createAsyncThunk(
  `${managerName}/listerSouscriptions`,
  async () => {
    try {
      return await API.Souscriptions.listerSouscriptions();
    } catch (error) {
      return throwError(error);
    }
  },
);

export const terminateSouscription = createAsyncThunk(
  `${managerName}/terminateSouscriptions`,
  async ({ souscriptionId, state }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.changeSubscriptionStatus(souscriptionId, { state });
      return { data };
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const ajouterSouscription = createAsyncThunk(
  `${managerName}/ajouterSouscription`,
  async content => {
    try {
      return await API.OffresCommerciales.ajouterSouscription(
        content.offreCommercialeId,
        content.souscription,
      );
    } catch (error) {
      return throwError(error);
    }
  },
);

export const createNewSubscription = createAsyncThunk(
  `${managerName}/createNewSubscription`,
  async (content, { rejectWithValue }) => {
    try {
      return await API.Souscriptions.ajouterSouscription(content);
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const importSubByFile = createAsyncThunk(
  `${managerName}/importClientsByFile`,
  async ({ name, file }, { rejectWithValue }) => {
    try {
      const { data } = await API.Import.importOngoingSubscriptions(name, file);

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const chargerSouscription = createAsyncThunk(
  `${managerName}/chargerSouscription`,
  async ({ souscriptionId, withoutLoading = false }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.chargerSouscription(souscriptionId);
      return { data, withoutLoading };
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const cancelSouscription = createAsyncThunk(
  `${managerName}/cancelSouscription`,
  async (souscriptionId, { rejectWithValue }) => {
    try {
      return (
        await API.Souscriptions.changeSubscriptionStatus(souscriptionId, {
          state: subscriptionStatusesServer.CANCEL,
        })
      ).data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const activateSouscription = createAsyncThunk(
  `${managerName}/activateSouscription`,
  async ({ souscriptionId, activateDate }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.activateSubscription(souscriptionId, {
        activateDate,
      });

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const modifierSouscription = createAsyncThunk(
  `${managerName}/modifierSouscription`,
  async (content, { rejectWithValue }) => {
    try {
      return await API.Souscriptions.modifierSouscription(content.subscriptionOnEdit, content.data);
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const activerSouscription = createAsyncThunk(
  `${managerName}/activerSouscription`,
  async content => {
    try {
      return await API.Souscriptions.activerSouscription(content.souscriptionId);
    } catch (error) {
      return throwError(error);
    }
  },
);

export const resolveSouscriptionAnomaly = createAsyncThunk(
  `${managerName}/resolveSouscriptionAnomaly`,
  async (content, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.Souscriptions.resolveAnomalieForSubscription(
        content.souscriptionId,
        content.anomalieId,
      );

      // get subscription without Loading
      dispatch(
        chargerSouscription({ souscriptionId: content.souscriptionId, withoutLoading: true }),
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const uploadContractSouscription = createAsyncThunk(
  `/uploadContractSouscription`,
  async ({ souscriptionId, name, contract }) => {
    try {
      const res = await API.Souscriptions.uploadContractFileForSubscription(
        souscriptionId,
        name,
        contract,
      );
      return res;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const getAllContractsSouscription = createAsyncThunk(
  `/getAllContractsSouscription`,
  async (souscriptionId, thunkAPI) => {
    try {
      const { data } = await API.Souscriptions.allContractFileForSubscription(souscriptionId);

      if (data?.length) {
        // get keys for files
        const allFiles = data;
        allFiles.forEach(file => {
          thunkAPI.dispatch(
            getDownloadKeyContractSouscription({
              souscriptionId,
              contractFileLinkId: file.identifiant,
            }),
          );
        });
      }
      return data;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const getDownloadKeyContractSouscription = createAsyncThunk(
  `/getDownloadKeyContractSouscription`,
  async ({ souscriptionId, contractFileLinkId }) => {
    try {
      const {
        data,
      } = await API.Souscriptions.getDownloadKeyForDownloadingContractFileForSubscription(
        souscriptionId,
        contractFileLinkId,
      );
      const key = data?.key || '';
      return { key, contractFileLinkId };
    } catch (error) {
      return throwError(error);
    }
  },
);

export const deleteContractSouscription = createAsyncThunk(
  `/deleteContractSouscription`,
  async ({ souscriptionId, contractFileLinkId }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.deleteContratSouscription(
        souscriptionId,
        contractFileLinkId,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getSubscriptionBilling = createAsyncThunk(
  `${managerName}/getSubscriptionBilling`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.getSubscriptionBillingOrders(
        content.subscriptionId,
        content.page,
        content.size,
        content.type || 'TRANSMIS',
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getBillingOrder = createAsyncThunk(
  `${managerName}/getBillingOrder`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.getSubscriptionBillingOrderDetails(
        content.billingId,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getBillingOrderEventsHistory = createAsyncThunk(
  `${managerName}/getBillingOrderEventsHistory`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.getBillingOrderEvents(content.billingId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getReportsByDate = createAsyncThunk(
  `${managerName}/getReportsByDate`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.Reports.getPrestationsReport(content.fromDate, content.toDate);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getReportsFse = createAsyncThunk(
  `${managerName}/getReportsFse`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.Reports.getConsommationFseReport(
        content.page,
        content.size,
        content.search,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getKeyForDownloadLink = createAsyncThunk(
  `${managerName}/getKeyForDownloadLink`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.Download.getDownloadKey();
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getSubscriptionEventsPaginated = createAsyncThunk(
  `${managerName}/getSubscriptionEventsPaginated`,
  async ({ page, size, souscriptionId }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.getSubscriptionEvents(souscriptionId, page, size);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const deleteSouscription = createAsyncThunk(
  `${managerName}/deleteSouscription`,
  async (souscriptionId, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.deleteSubscription(souscriptionId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const activateStellairSubscription = createAsyncThunk(
  `${managerName}/activateStellairSubscription`,
  async ({ souscriptionId, stellairSubscriptionForm }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.activateStellairSubscription(
        souscriptionId,
        stellairSubscriptionForm,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateActivatedStellairSubscription = createAsyncThunk(
  `${managerName}/updateActivatedStellairSubscription`,
  async ({ souscriptionId, stellairSubscriptionForm }, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.updateStellairAccount(
        souscriptionId,
        stellairSubscriptionForm,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const billingOrderTransmitZeroToSage = createAsyncThunk(
  `${managerName}/BillingOrderTransmitZeroToSage`,
  async (billingOrderId, { rejectWithValue }) => {
    try {
      const { data } = await API.Souscriptions.pushBillingOrder(billingOrderId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

const souscriptionsSlice = createSlice({
  name: managerName,
  initialState,
  reducers: {
    reset: state => {
      state.souscription = initialState.souscription;
      state.steps = initialState.steps;
      state.step = initialState.step;
      state.offre = initialState.offre;
    },
    resetDocuments: state => {
      state.documents = initialState.documents;
    },
    setSouscription: (state, action) => {
      state.souscription = { ...state.souscription, ...action.payload };
    },
    setSouscripteur: (state, action) => {
      state.souscription.souscripteur = action.payload;
      if (action.payload) {
        toast.success('Client sélectionné');
      }
    },
    setVendeur: (state, action) => {
      state.souscription.vendeur = action.payload;
    },
    setContratSouscription: (state, action) => {
      state.souscription = { ...state.souscription, contratSouscription: action.payload };
      toast.success('Nouveau document ajouté avec succès!');
    },
    setAjoutTerminaux: (state, action) => {
      state.souscription.ajoutTerminaux = action.payload;
    },
    setCreationAcces: (state, action) => {
      state.souscription.creationAcces = action.payload;
    },
    updateSouscripteur: (state, action) => {
      state.souscription.souscripteur = { ...state.souscription.souscripteur, ...action.payload };
    },
    setOffre: (state, action) => {
      state.offre = action.payload;
    },
    setAjouterMoyenPaiement: (state, action) => {
      state.ajouterMoyenPaiement = action.payload;
    },
    setActiverSouscriptionPopUp: (state, action) => {
      state.activerSouscription = action.payload;
    },
    resetOptionSouscription: state => {
      state.souscription.options = [];
    },
    // Updating at index is not safe
    // better add prestation uiid to each option and find if its in options list
    setOptionSouscription: (state, action) => {
      const { quantite, identifiantPrestation, valeurQuantite, engagement } = action.payload;

      const foundIndex = state.souscription.options.findIndex(
        el => el.prestation === identifiantPrestation,
      );
      if (foundIndex < 0) {
        state.souscription.options.push({
          quantite,
          prestation: identifiantPrestation,
          valeurQuantite,
        });
      } else if (engagement) {
        state.souscription.options[foundIndex] = {
          ...state.souscription.options[foundIndex],
          engagement,
        };
      } else if (valeurQuantite) {
        state.souscription.options[foundIndex] = {
          ...state.souscription.options[foundIndex],
          prestation: identifiantPrestation,
          valeurQuantite,
        };
      } else {
        state.souscription.options[foundIndex] = {
          ...state.souscription.options[foundIndex],
          prestation: identifiantPrestation,
          quantite: { ...state.souscription.options[foundIndex].quantite, ...quantite },
        };
      }
    },
    deleteOptionSouscription: (state, { payload }) => {
      state.souscription.options = state.souscription.options.filter(
        el => el.prestation !== payload,
      );
    },
    resetBilling: state => {
      state.billing = initialState.billing;
    },
    resetReports: state => {
      state.reports = initialState.reports;
    },
  },
  extraReducers: builder => {
    // terminate Souscription
    builder.addCase(terminateSouscription.pending, state => {
      state.isTerminationLoading = true;
    });
    builder.addCase(terminateSouscription.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.souscription = { ...state.souscription, ...data };
      state.isTerminationLoading = false;
      if (data.statut === subscriptionStatuses[subscriptionStatusesServer.CLOSED]) {
        toast.info('La souscription est résiliée.');
      }
      // clear all anomalies loadings states
      state.anomaliesLoadings = {};
      state.souscription.anomalies = data.anomalies || [];
      state.souscription.warnings = data.warnings || {};
    });
    builder.addCase(terminateSouscription.rejected, state => {
      state.isTerminationLoading = false;
    });

    // Page de souscriptions
    builder.addCase(afficherPageSouscriptions.pending, state => {
      state.loading = true;
    });
    builder.addCase(afficherPageSouscriptions.fulfilled, (state, action) => {
      state.pageSouscriptions = action.payload;
      state.loading = false;
    });
    builder.addCase(afficherPageSouscriptions.rejected, state => {
      state.loading = false;
    });

    // Liste de souscriptions
    builder.addCase(listerSouscriptions.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerSouscriptions.fulfilled, (state, action) => {
      state.loading = false;
      state.listeSouscriptions = action.payload.data;
    });
    builder.addCase(listerSouscriptions.rejected, state => {
      state.loading = false;
    });

    builder.addCase(getSubscriptionNewListPaginated.pending, (state, action) => {
      const { page, size, ...restArgs } = action.meta?.arg;
      const isSearching = Object.keys(restArgs).length > 0;
      state.loading = !isSearching;
      state.isLoadingSearch = isSearching;
    });
    builder.addCase(getSubscriptionNewListPaginated.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoadingSearch = false;
      state.newListSubscriptions = action.payload;
    });
    builder.addCase(getSubscriptionNewListPaginated.rejected, state => {
      state.loading = false;
      state.isLoadingSearch = false;
    });

    // Charger une souscription
    builder.addCase(chargerSouscription.pending, (state, action) => {
      const { withoutLoading } = action.meta.arg;
      if (!withoutLoading) {
        state.loading = true;
      }
    });
    builder.addCase(chargerSouscription.fulfilled, (state, action) => {
      const { data, withoutLoading } = action.payload;
      state.souscription = data;
      // clear all anomalies loadings states
      state.anomaliesLoadings = {};
      state.souscription.anomalies = data.anomalies || [];
      state.souscription.warnings = data.warnings || {};
      if (!withoutLoading) {
        state.loading = false;
      }
    });
    builder.addCase(chargerSouscription.rejected, (state, action) => {
      const { withoutLoading } = action.meta.arg;
      if (!withoutLoading) {
        state.loading = false;
      }
    });

    // Anomalies
    builder.addCase(resolveSouscriptionAnomaly.pending, (state, action) => {
      const { anomalieId } = action.meta.arg;
      state.anomaliesLoadings = { ...state.anomaliesLoadings, [anomalieId]: true };
    });
    builder.addCase(resolveSouscriptionAnomaly.fulfilled, (state, action) => {
      const { anomalieId } = action.meta.arg;
      const { message } = action.payload;
      toast.success(message);
      state.anomaliesLoadings = { ...state.anomaliesLoadings, [anomalieId]: false };
    });
    builder.addCase(resolveSouscriptionAnomaly.rejected, (state, action) => {
      const { anomalieId } = action.meta.arg;
      state.anomaliesLoadings = { ...state.anomaliesLoadings, [anomalieId]: false };
    });

    // Cancel souscription
    builder.addCase(cancelSouscription.pending, state => {
      state.statusChangeLoading = true;
    });
    builder.addCase(cancelSouscription.fulfilled, state => {
      toast.info('La souscription est interrompue');
      state.statusChangeLoading = false;
    });
    builder.addCase(cancelSouscription.rejected, state => {
      state.statusChangeLoading = false;
    });

    // Activate souscription
    builder.addCase(activateSouscription.pending, state => {
      state.statusChangeLoading = true;
    });
    builder.addCase(activateSouscription.fulfilled, (state, action) => {
      const { messages } = action.payload;

      const matchMessages = {
        success: message =>
          toast(message, {
            type: 'dark',
            style: {
              background: '#2F7D7C',
            },
          }),
        error: message => toast.error(message),
        info: message => toast.info(message),
      };

      if (messages) {
        messages.forEach(messageData => {
          matchMessages[messageData.type](messageData.message);
        });
      }

      state.statusChangeLoading = false;
    });
    builder.addCase(activateSouscription.rejected, state => {
      state.statusChangeLoading = false;
    });

    // modifierSouscription
    builder.addCase(modifierSouscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(modifierSouscription.fulfilled, state => {
      toast.success('La souscription a bien été modifiée');
      state.loading = false;
    });
    builder.addCase(modifierSouscription.rejected, state => {
      state.loading = false;
    });

    // activerSouscription
    builder.addCase(activerSouscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(activerSouscription.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(activerSouscription.rejected, state => {
      state.loading = false;
    });

    builder.addCase(getAllContractsSouscription.pending, state => {
      state.documents.isLoading = true;
    });
    builder.addCase(getAllContractsSouscription.fulfilled, (state, action) => {
      state.documents.documentsData = action.payload;
      state.documents.isLoading = false;
    });
    builder.addCase(getAllContractsSouscription.rejected, state => {
      state.documents.isLoading = false;
    });

    builder.addCase(getDownloadKeyContractSouscription.pending, state => {
      state.documents.isLoading = true;
    });
    builder.addCase(getDownloadKeyContractSouscription.fulfilled, (state, action) => {
      state.documentsKeys[action.payload.contractFileLinkId] = action.payload.key;
      state.documents.isLoading = false;
    });
    builder.addCase(getDownloadKeyContractSouscription.rejected, state => {
      state.documents.isLoading = false;
    });

    builder.addCase(deleteContractSouscription.pending, state => {
      state.documents.isLoadingDelete = true;
    });
    builder.addCase(deleteContractSouscription.fulfilled, state => {
      toast.info('Le fichier a été supprimé.');
      state.documents.isLoadingDelete = false;
    });
    builder.addCase(deleteContractSouscription.rejected, state => {
      state.documents.isLoadingDelete = false;
    });

    // creer souscription
    builder.addCase(ajouterSouscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(ajouterSouscription.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(ajouterSouscription.rejected, state => {
      state.loading = false;
    });
    // create new sub
    builder.addCase(createNewSubscription.pending, state => {
      state.loading = true;
    });
    builder.addCase(createNewSubscription.fulfilled, state => {
      toast.success('La souscription a bien été créée.');
      state.loading = false;
    });
    builder.addCase(createNewSubscription.rejected, state => {
      state.loading = false;
    });
    // get billing
    builder.addCase(getSubscriptionBilling.pending, state => {
      state.billing.isLoading = true;
    });
    builder.addCase(getSubscriptionBilling.fulfilled, (state, action) => {
      state.billing.billingData = action.payload;
      state.billing.isLoading = false;
    });
    builder.addCase(getSubscriptionBilling.rejected, state => {
      state.billing.isLoading = false;
    });
    // get billing details
    builder.addCase(getBillingOrder.pending, state => {
      state.billing.isLoading = true;
    });
    builder.addCase(getBillingOrder.fulfilled, (state, action) => {
      state.billing.billingDetailsData = action.payload;
      state.billing.isLoading = false;
    });
    builder.addCase(getBillingOrder.rejected, state => {
      state.billing.isLoading = false;
    });
    // get billing events history
    builder.addCase(getBillingOrderEventsHistory.pending, state => {
      state.billing.isLoadingEventsHistory = true;
    });
    builder.addCase(getBillingOrderEventsHistory.fulfilled, (state, action) => {
      state.billing.billingEventsHistoryData = action.payload;
      state.billing.isLoadingEventsHistory = false;
    });
    builder.addCase(getBillingOrderEventsHistory.rejected, state => {
      state.billing.isLoadingEventsHistory = false;
    });
    // get reports
    builder.addCase(getReportsByDate.pending, state => {
      state.reports.isLoading = true;
    });
    builder.addCase(getReportsByDate.fulfilled, (state, action) => {
      state.reports.reportsData = action.payload;
      state.reports.isLoading = false;
    });
    builder.addCase(getReportsByDate.rejected, state => {
      state.reports.isLoading = false;
    });

    // get reportsFse
    builder.addCase(getReportsFse.pending, (state, action) => {
      const { search } = action.meta?.arg;
      const isSearching = search || search === '';
      state.reports.isLoadingFse = !isSearching;
      state.reports.isLoadingFseSearch = isSearching;
    });
    builder.addCase(getReportsFse.fulfilled, (state, action) => {
      state.reports.reportsFseData = action.payload;
      state.reports.isLoadingFse = false;
      state.reports.isLoadingFseSearch = false;
    });
    builder.addCase(getReportsFse.rejected, state => {
      state.reports.isLoadingFse = false;
      state.reports.isLoadingFseSearch = false;
    });

    // download key
    builder.addCase(getKeyForDownloadLink.pending, state => {
      state.reports.isLoadingDownloadKey = true;
    });
    builder.addCase(getKeyForDownloadLink.fulfilled, (state, action) => {
      const { key } = action.payload;
      state.reports.downloadKeys.reports = key;
      state.reports.isLoadingDownloadKey = false;
    });
    builder.addCase(getKeyForDownloadLink.rejected, state => {
      toast.error('Le rapport ne peut pas être téléchargé');
      state.reports.isLoadingDownloadKey = false;
    });

    // events grid data
    builder.addCase(getSubscriptionEventsPaginated.pending, state => {
      state.eventsGrid.isLoading = true;
    });
    builder.addCase(getSubscriptionEventsPaginated.fulfilled, (state, action) => {
      state.eventsGrid.isLoading = false;
      state.eventsGrid.data = action.payload;
    });
    builder.addCase(getSubscriptionEventsPaginated.rejected, state => {
      state.eventsGrid.isLoading = false;
    });

    // Delete souscription
    builder.addCase(deleteSouscription.pending, state => {
      state.isDeleting = true;
    });
    builder.addCase(deleteSouscription.fulfilled, state => {
      toast('La souscription a été supprimée.', {
        type: 'dark',
        style: {
          background: '#6A6A6A',
        },
      });
      state.isDeleting = false;
    });
    builder.addCase(deleteSouscription.rejected, state => {
      state.isDeleting = false;
    });

    // activate stellair souscription
    builder.addCase(activateStellairSubscription.pending, state => {
      state.stellairActivation = true;
    });
    builder.addCase(activateStellairSubscription.fulfilled, state => {
      state.stellairActivation = false;
    });
    builder.addCase(activateStellairSubscription.rejected, state => {
      state.stellairActivation = false;
    });

    // update stellair souscription
    builder.addCase(updateActivatedStellairSubscription.pending, state => {
      state.stellairActivation = true;
    });
    builder.addCase(updateActivatedStellairSubscription.fulfilled, state => {
      state.stellairActivation = false;
    });
    builder.addCase(updateActivatedStellairSubscription.rejected, state => {
      state.stellairActivation = false;
    });

    // transmit zero to sage
    builder.addCase(billingOrderTransmitZeroToSage.pending, state => {
      state.transmittingZeroToSage = true;
    });
    builder.addCase(billingOrderTransmitZeroToSage.fulfilled, state => {
      state.transmittingZeroToSage = false;
    });
    builder.addCase(billingOrderTransmitZeroToSage.rejected, state => {
      state.transmittingZeroToSage = false;
    });
  },
});

const managerStateSelector = state => state[managerName];

export const selectListeSouscriptions = createSelector(
  managerStateSelector,
  state => state.listeSouscriptions,
);
export const selectNewListSubscriptions = createSelector(
  managerStateSelector,
  state => state.newListSubscriptions,
);
export const selectPageSouscriptions = createSelector(
  managerStateSelector,
  state => state.pageSouscriptions,
);
export const selectSouscription = createSelector(managerStateSelector, state => state.souscription);
export const selectIsSouscriptionLoading = createSelector(
  managerStateSelector,
  state => state.loading,
);

export const selectIsSouscriptionDataLoading = createSelector(
  managerStateSelector,
  state => state.isLoadingSearch,
);

export const selectIsStatusLoading = createSelector(
  managerStateSelector,
  state => state.statusChangeLoading,
);

export const selectTerminationLoading = createSelector(
  managerStateSelector,
  state => state.isTerminationLoading,
);

export const selectBaseOffre = createSelector(managerStateSelector, state => state.offre);

export const selectAjouterMoyenPaiement = createSelector(
  managerStateSelector,
  state => state.ajouterMoyenPaiement,
);

export const selectActiverSouscriptionPopUp = createSelector(
  managerStateSelector,
  state => state.activerSouscription,
);

export const selectAnomaliesLoadings = createSelector(
  managerStateSelector,
  state => state.anomaliesLoadings,
);

export const selectIsDocumentsLoading = createSelector(
  managerStateSelector,
  state => state.documents.isLoading,
);

export const selectIsDocumentsLoadingDelete = createSelector(
  managerStateSelector,
  state => state.documents.isLoadingDelete,
);

export const selectDocumentsData = createSelector(
  managerStateSelector,
  state => state.documents.documentsData,
);

export const selectDocumentsKeys = createSelector(
  managerStateSelector,
  state => state.documentsKeys,
);

export const selectBillingData = createSelector(
  managerStateSelector,
  state => state.billing.billingData,
);

export const selectIsBillingDataLoading = createSelector(
  managerStateSelector,
  state => state.billing.isLoading,
);

export const selectBillingDetailsData = createSelector(
  managerStateSelector,
  state => state.billing.billingDetailsData,
);

export const selectIsBillingEventsHistoryLoading = createSelector(
  managerStateSelector,
  state => state.billing.isLoadingEventsHistory,
);

export const selectBillingEventsHistoryData = createSelector(
  managerStateSelector,
  state => state.billing.billingEventsHistoryData,
);

export const selectIsReportsDataLoading = createSelector(
  managerStateSelector,
  state => state.reports.isLoading,
);

export const selectReportsData = createSelector(
  managerStateSelector,
  state => state.reports.reportsData,
);

export const selectIsReportsFseDataLoading = createSelector(
  managerStateSelector,
  state => state.reports.isLoadingFse,
);

export const selectIsReportsFseDataSearchLoading = createSelector(
  managerStateSelector,
  state => state.reports.isLoadingFseSearch,
);

export const selectReportsFseData = createSelector(
  managerStateSelector,
  state => state.reports.reportsFseData,
);

export const selectIsDownloadKeyLoading = createSelector(
  managerStateSelector,
  state => state.reports.isLoadingDownloadKey,
);

export const selectDownloadKeys = createSelector(
  managerStateSelector,
  state => state.reports.downloadKeys,
);

export const selectSubscriptionEventGridData = createSelector(
  managerStateSelector,
  state => state.eventsGrid.data,
);

export const selectIsSubscriptionEventGridLoading = createSelector(
  managerStateSelector,
  state => state.eventsGrid.isLoading,
);

export const selectIsSubscriptionDeletingLoading = createSelector(
  managerStateSelector,
  state => state.isDeleting,
);

export const selectSteps = createSelector(managerStateSelector, state => state.steps);

export const selectIsStellairActivating = createSelector(
  managerStateSelector,
  state => state.stellairActivation,
);

export const selectIsTransmittingZeroToSage = createSelector(
  managerStateSelector,
  state => state.transmittingZeroToSage,
);

export const {
  setSouscription,
  setSouscripteur,
  setVendeur,
  setContratSouscription,
  setOffre,
  setAjouterMoyenPaiement,
  setActiverSouscriptionPopUp,
  setAjoutTerminaux,
  setCreationAcces,
  updateSouscripteur,
  setOptionSouscription,
  resetOptionSouscription,
  deleteOptionSouscription,
  reset,
  resetDocuments,
  resetBilling,
  resetReports,
} = souscriptionsSlice.actions;

export default souscriptionsSlice.reducer;
