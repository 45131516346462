import { subscriptionStatusesServer } from 'app/constants/subscription.constants';
import {
  ClientNameColumn,
  DateActivationColumn,
  DateCancelColumn,
  DateCreationColumn,
  DateEndColumn,
  DateRenewColumn,
  DateStartColumn,
  NumeroContractColumn,
  OfferNameColumn,
  SubStatusColumn,
  ThirdPartyPayerColumn,
} from 'components/subscriptionsNew/SubscriptionDataColumns';

export const TabsData = [
  { label: 'Toutes' },
  { label: 'Incomplètes', status: subscriptionStatusesServer.INCOMPLETE },
  { label: 'Actives', status: subscriptionStatusesServer.ACTIVE },
  { label: 'Résiliées', status: subscriptionStatusesServer.CLOSED },
  { label: 'Anomalies', status: subscriptionStatusesServer.ANOMALIE },
];

export const DataTableColumnsPerTab = [
  {
    columns: [
      NumeroContractColumn,
      ClientNameColumn,
      ThirdPartyPayerColumn,
      OfferNameColumn,
      DateCreationColumn,
      SubStatusColumn,
    ],
  },
  {
    columns: [
      NumeroContractColumn,
      ClientNameColumn,
      ThirdPartyPayerColumn,
      OfferNameColumn,
      DateCreationColumn,
      SubStatusColumn,
    ],
  },
  {
    columns: [
      NumeroContractColumn,
      ClientNameColumn,
      ThirdPartyPayerColumn,
      OfferNameColumn,
      DateActivationColumn,
      DateStartColumn,
      DateEndColumn,
      DateRenewColumn,
      SubStatusColumn,
    ],
  },
  {
    columns: [
      NumeroContractColumn,
      ClientNameColumn,
      ThirdPartyPayerColumn,
      OfferNameColumn,
      DateCancelColumn,
      SubStatusColumn,
    ],
  },
  {
    columns: [
      NumeroContractColumn,
      ClientNameColumn,
      ThirdPartyPayerColumn,
      OfferNameColumn,
      DateEndColumn,
      DateRenewColumn,
      SubStatusColumn,
    ],
  },
];
