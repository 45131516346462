const TypeQuantite = {
  QUANTITE_FIXE: 'quantiteFixe',
  QUANTITE_ILLIMITEE: 'quantiteIllimitee',
  QUANTITE_LIMITEE: 'quantiteLimitee',
  QUANTITE_DEPENDANT_AUTRE_PRESTATION: 'quantiteDependantAutrePrestation',
  PALIERS: 'paliers',
};

export const newTypeQuantities = {
  QUANTITY_LIMITED: 'quantityLimited',
  QUANTITY_UNLIMITED: 'quantityUnlimited',
  QUANTITY_DEPENDING_ON_VOLUME: 'quantityDependingOnVolume',
  QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION: 'quantityDependingOnAnotherPrestation',
};

export const newTariffs = {
  TARIFF_STANDARD: 'tarifStandard',
  TARIFF_PACKAGE: 'tarifPackage',
  TARIFF_GRADUATED: 'tarifGraduated',
  TARIFF_GRADUATED_VOLUME: 'tarifGraduatedVolume',
};

export const newTariffsWithTiers = {
  GRADUATED_PRICING: 'GRADUATED_PRICING',
  VOLUME_PRICING: 'VOLUME_PRICING',
};

export const serviceItemRepetition = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
};

export const periodOptions = {
  MENSUELLE: 'mois',
  TRIMESTRIELLE: 'trimestre',
  SEMESTRIELLE: 'semestre',
  ANNUELLE: 'an',
};

export const {
  QUANTITE_FIXE,
  QUANTITE_ILLIMITEE,
  QUANTITE_LIMITEE,
  QUANTITE_DEPENDANT_AUTRE_PRESTATION,
  PALIERS,
} = TypeQuantite;

export const description = value => {
  // eslint-disable-next-line default-case
  switch (value) {
    case QUANTITE_FIXE:
      return 'Fixe';
    case QUANTITE_ILLIMITEE:
      return 'Illimité';
    case QUANTITE_LIMITEE:
      return 'Limitée';
    case QUANTITE_DEPENDANT_AUTRE_PRESTATION:
      return "Quantité dépendant d'une autre prestation";
    case PALIERS:
      return 'Paliers';
    default:
      throw new Error('Le type de quantité est invalide!');
  }
};
