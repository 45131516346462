import {
  PrestationsRestApi,
  ArticlesRestApi,
  ApplicationsMetiersPrestationsRestApi,
  OffresCommercialesRestApi,
  TeamsRestApi,
  SouchesRestApi,
  ComptesCollectifsRestApi,
  CategoriesComptablesRestApi,
  SouscriptionsRestApi,
  ReportsRestApi,
  PaymentTermRestApi,
  DownloadRestApi,
  AnomaliesRestApi,
  CompanyDeliveryRestApi,
  TeamsPropertiesRestApi,
  TeamsContactsRestApi,
  SyntecIndexRestApi,
  DynamicsRestApi,
  ImportRestApi,
} from 'pfd-api';
import axiosInstance from './axios';
import { API_BASE } from './constants';

const commonParams = [{ basePath: API_BASE }, undefined, axiosInstance];

export default {
  Prestations: new PrestationsRestApi(...commonParams),
  Articles: new ArticlesRestApi(...commonParams),
  ApplicationsMetiersPrestations: new ApplicationsMetiersPrestationsRestApi(...commonParams),
  OffresCommerciales: new OffresCommercialesRestApi(...commonParams),
  Teams: new TeamsRestApi(...commonParams),
  TeamsProperties: new TeamsPropertiesRestApi(...commonParams),
  TeamsContacts: new TeamsContactsRestApi(...commonParams),
  Souches: new SouchesRestApi(...commonParams),
  ComptesCollectifs: new ComptesCollectifsRestApi(...commonParams),
  CategoriesComptables: new CategoriesComptablesRestApi(...commonParams),
  Souscriptions: new SouscriptionsRestApi(...commonParams),
  Reports: new ReportsRestApi(...commonParams),
  PaymentTerm: new PaymentTermRestApi(...commonParams),
  Download: new DownloadRestApi(...commonParams),
  Anomalie: new AnomaliesRestApi(...commonParams),
  CompanyDelivery: new CompanyDeliveryRestApi(...commonParams),
  SyntecIndex: new SyntecIndexRestApi(...commonParams),
  Dynamics: new DynamicsRestApi(...commonParams),
  Import: new ImportRestApi(...commonParams),
};
