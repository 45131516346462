import React from 'react';
import { subscriptionStatusesServer } from 'app/constants/subscription.constants';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { RadioButton } from 'olaqin-design-system';

const SubscriptionSortingModel = 'SubscriptionSortingModel';

export const SubscriptionSortList = [
  {
    label: 'Client (A-Z)',
    value: 'clientName',
    supportedStatuses: [
      'Toutes',
      subscriptionStatusesServer.INCOMPLETE,
      subscriptionStatusesServer.ACTIVE,
      subscriptionStatusesServer.CLOSED,
      subscriptionStatusesServer.ANOMALIE,
    ],
    sortOrder: 'ASC',
  },
  {
    label: 'Date de création (la plus récente)',
    value: 'dateCreation',
    supportedStatuses: ['Toutes', subscriptionStatusesServer.INCOMPLETE],
    sortOrder: 'DESC',
  },
  {
    label: 'Date d’activation (la plus récente)',
    value: 'activationDate',
    supportedStatuses: [subscriptionStatusesServer.ACTIVE, subscriptionStatusesServer.ANOMALIE],
    sortOrder: 'DESC',
  },
  {
    label: 'Date de début de facturation (la plus récente)',
    value: 'dateDebut',
    supportedStatuses: [subscriptionStatusesServer.ACTIVE],
    sortOrder: 'DESC',
  },
  {
    label: 'Date de fin d’engagement (la plus proche)',
    value: 'dateFin',
    supportedStatuses: [subscriptionStatusesServer.ACTIVE],
    sortOrder: 'ASC',
  },
  {
    label: 'Date de renouvellement (la plus proche)',
    value: 'dateRenew',
    supportedStatuses: [subscriptionStatusesServer.ACTIVE],
    sortOrder: 'ASC',
  },
  {
    label: 'Date de résiliation (la plus récente)',
    value: 'terminateDate',
    supportedStatuses: [subscriptionStatusesServer.CLOSED],
    sortOrder: 'DESC',
  },
  {
    label: 'Tiers-payeur (A-Z)',
    value: 'thirdPartyPayerName',
    supportedStatuses: [
      'Toutes',
      subscriptionStatusesServer.INCOMPLETE,
      subscriptionStatusesServer.ACTIVE,
      subscriptionStatusesServer.CLOSED,
      subscriptionStatusesServer.ANOMALIE,
    ],
    sortOrder: 'ASC',
  },
];

export const getTabDefaultSortingModel = tabName => {
  let SubscriptionSortingModelCache = localStorage.getItem(SubscriptionSortingModel);
  if (SubscriptionSortingModelCache) {
    SubscriptionSortingModelCache = JSON.parse(SubscriptionSortingModelCache);
    return SubscriptionSortingModelCache[tabName] ?? null;
  }
  return null;
};

export const getSubscriptionSortOrderByField = fieldName =>
  SubscriptionSortList.find(item => item.value === fieldName)?.sortOrder ?? 'DESC';

const dropItemTemplate = (props, currentActiveValue) => (
  <>
    <RadioButton checked={currentActiveValue === props.value} />
    <span>{props.label}</span>
  </>
);

const SubscriptionGridSorting = ({ currentTab, onSortChange, currentValue }) => {
  const filteredSort = SubscriptionSortList.filter(i => i.supportedStatuses.includes(currentTab));

  const onChange = e => {
    let SubscriptionSortingModelCache = localStorage.getItem(SubscriptionSortingModel) ?? '{}';
    if (SubscriptionSortingModelCache) {
      SubscriptionSortingModelCache = JSON.parse(SubscriptionSortingModelCache);
      SubscriptionSortingModelCache[currentTab] = e.value;
    }
    localStorage.setItem(SubscriptionSortingModel, JSON.stringify(SubscriptionSortingModelCache));
    onSortChange(e.value);
  };

  return (
    <PrimeDropdown
      value={currentValue}
      options={filteredSort}
      placeholder="Trier"
      onChange={onChange}
      itemTemplate={event => dropItemTemplate(event, currentValue)}
      className="SubscriptionGridSorting p-ml-1"
      panelClassName="SubscriptionGridSortingPanel"
      style={{ borderRadius: '6px', maxHeight: 'auto' }}
      autoResize
      appendTo={document.body}
    />
  );
};

export default SubscriptionGridSorting;
