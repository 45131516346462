import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'app/routes/appRoutes';

const ReturnToPreviousLink = () => (
  <Link
    to={routes.SUBSCRIPTIONS()}
    style={{
      cursor: 'pointer',
      color: '#3688ff',
      marginTop: '10px',
    }}
  >
    <span
      className="odsi-arrow-left-nav"
      style={{
        fontSize: '18px',
        width: '20px',
        verticalAlign: '-4px',
        marginRight: '4px',
      }}
    />
    <span
      style={{
        width: '120px',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2.2',
        letterSpacing: 'normal',
        textAlign: 'center',
        marginRight: '8px',
      }}
    >
      Retour à la liste
    </span>
  </Link>
);

export default ReturnToPreviousLink;
