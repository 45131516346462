import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CardDisplay } from 'olaqin-design-system';
import { routes } from 'app/routes/appRoutes';
import { formatDate } from 'app/utils/dateUtils';

const CardDetails = ({ title, billingDetailsData }) => {
  const { client, offreCommerciale } = billingDetailsData;

  const titleDefaultStyle = {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    color: 'black',
  };

  const formattedTrnsmissionDate = formatDate(billingDetailsData.scheduledDate);

  const formattedDateLevarsion = formatDate(billingDetailsData.dateLivraison);

  const formattedRevisionDate = `Indice courant au ${formatDate(
    billingDetailsData.dateIndexation,
  )}`;

  return (
    <CardDisplay title={title} style={{ width: 'auto' }}>
      <div className="p-grid" style={{ marginTop: '16px' }}>
        <span className="p-md-3" style={titleDefaultStyle}>
          Client :
        </span>
        <span className="p-md-9" style={titleDefaultStyle}>
          {client.nom}
        </span>

        <span className="p-md-3" />
        <span
          className="p-md-9"
          style={{ ...titleDefaultStyle, color: 'grey', padding: '0 0.5rem 0.5rem 0.5rem' }}
        >
          {client.email}
        </span>
        <span className="p-md-3" style={titleDefaultStyle}>
          Souscription :
        </span>
        <span className="p-md-9" style={titleDefaultStyle}>
          <Link
            to={routes.SUBSCRIPTION_DETAILS(billingDetailsData.subscriptionId)}
            style={{
              fontSize: '14px',
              fontFamily: 'OpenSans',
              color: '#3688FF',
              cursor: 'pointer',
            }}
          >
            {`${client.nom} sur ${offreCommerciale.nom}`}
          </Link>
        </span>
        <span className="p-md-3" style={titleDefaultStyle}>
          Document :
        </span>
        <span className="p-md-9" style={titleDefaultStyle}>
          {billingDetailsData.numeroContrat}
        </span>
        <span className="p-md-3" style={titleDefaultStyle}>
          Périodicité :
        </span>
        <span className="p-md-9" style={titleDefaultStyle}>
          {billingDetailsData.periodiciteFacturation}
        </span>
        <span className="p-md-3" style={titleDefaultStyle}>
          Transmission planifiée le :
        </span>
        <span className="p-md-9" style={titleDefaultStyle}>
          {formattedTrnsmissionDate}
        </span>
        <span className="p-md-3" style={titleDefaultStyle}>
          Date du document SAGE :
        </span>
        <span className="p-md-9" style={titleDefaultStyle}>
          {formattedDateLevarsion}
        </span>
        {!!billingDetailsData.sageDeliveryOrderId && (
          <>
            <span className="p-md-3" style={titleDefaultStyle}>
              Réf. du document SAGE :
            </span>
            <span className="p-md-9" style={titleDefaultStyle}>
              {billingDetailsData.sageDeliveryOrderId}
              <CopyToClipboard
                text={billingDetailsData.sageDeliveryOrderId}
                style={{ margin: '0 0 0 8px', color: '#101010', cursor: 'pointer' }}
              >
                <i className="pi pi-copy" />
              </CopyToClipboard>
            </span>
          </>
        )}
        {!!billingDetailsData.syntecIndexS0 && billingDetailsData.indexed && (
          <>
            <span className="p-md-3" style={titleDefaultStyle}>
              Réf. révision tarifaire (S0):
            </span>
            <span className="p-md-9" style={titleDefaultStyle}>
              {billingDetailsData.syntecIndexS0}
            </span>
          </>
        )}
        {billingDetailsData.syntecIndexS1 ? (
          <>
            <span className="p-md-3" style={titleDefaultStyle}>
              Indice de révision (S1) :
            </span>
            <span className="p-md-9" style={titleDefaultStyle}>
              {billingDetailsData.syntecIndexS1}
            </span>
          </>
        ) : (
          <>
            {billingDetailsData.indexed && (
              <>
                <span className="p-md-3" style={titleDefaultStyle}>
                  Indice de révision (S1) :
                </span>
                <span className="p-md-9" style={{ ...titleDefaultStyle, color: '#6A6A6A' }}>
                  {formattedRevisionDate}
                </span>
              </>
            )}
          </>
        )}
      </div>
    </CardDisplay>
  );
};

export default CardDetails;
