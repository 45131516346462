import React from 'react';
import { useSelector } from 'react-redux';
import SearchInput from 'components/_shared/SearchInput/SearchInput';
import usePagination from 'app/hooks/usePagination';
import { getReportsFse, selectIsReportsFseDataSearchLoading } from 'app/redux/slices/souscriptions';

const ReportsFseHeaderTable = () => {
  const isLoadingSearch = useSelector(selectIsReportsFseDataSearchLoading);
  const { onTermsChange, terms } = usePagination({
    dataFetchBy: getReportsFse,
    skipInitialCall: true,
  });

  return (
    <div className="table-header">
      <SearchInput
        placeholder="N° client, n° contrat, n° bon de facturation"
        isLoading={isLoadingSearch}
        onSearch={searchVal => onTermsChange('search', searchVal)}
        defaultValue={terms.search}
      />
    </div>
  );
};

export default ReportsFseHeaderTable;
