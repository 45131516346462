import { Validators } from 'olaqin-design-system';
import { tariffModelsOptions } from 'app/constants/offerConstants';
import { newTypeQuantities } from 'app/constants/quantityConstants';

const customValidation = () => value =>
  value.map(row => {
    if (row.upToUnits < row.units) {
      return {
        upToUnits:
          'La valeur de la dernière unité du palier doit être supérieure à la première unité.',
      };
    }
    return '';
  });

const validationSchema = {
  composition: Validators.createValidator({
    prestation: [Validators.required('Veuillez sélectionner une prestation.')],
    tariffProgressive: [customValidation()],
    usageType: [Validators.required('Veuillez sélectionner un type de donnée à mesurer.')],
    dependOnAnotherPrestation: [
      Validators.required('Veuillez sélectionner une prestation référence.'),
    ],
  }),
};

// eslint-disable-next-line import/prefer-default-export
export const formAddCompositionDialogValidation = values => {
  const foundErrors = Validators.createValidator(validationSchema)(values);

  const { composition } = values;

  // do not validate for "standard"
  if (composition.tarification === tariffModelsOptions[0].value) {
    delete foundErrors.composition.tariffProgressive;
  }

  if (!composition.isMultiplier) {
    delete foundErrors.composition.dependOnAnotherPrestation;
  }

  if (composition.quantityType !== newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
    delete foundErrors.composition.usageType;
  }

  // remove field if all row are passed
  if (composition.tarification !== tariffModelsOptions[0].value) {
    const field = foundErrors.composition.tariffProgressive;
    const isAllRowsPassed = field.every(rowError => !rowError);

    if (isAllRowsPassed) {
      delete foundErrors.composition.tariffProgressive;
    }
  }

  if (Object.keys(foundErrors.composition).length) {
    return foundErrors;
  }

  return {};
};
