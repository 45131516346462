// eslint-disable-next-line import/prefer-default-export
export const tableColumnsModel = [
  {
    label: 'Souscription',
    field: 'subscription',
  },
  {
    label: 'Intitulé client',
    field: 'client',
  },
  {
    label: 'N° contrat',
    field: 'numeroContract',
  },
  {
    label: 'N° client',
    field: 'numeroClient',
  },
  {
    label: 'Prestation',
    field: 'prestation',
  },
  {
    label: 'Début période',
    field: 'fromDate',
    type: 'date',
  },
  {
    label: 'Fin période',
    field: 'toDate',
    type: 'date',
  },
  {
    label: 'Bon associé',
    field: 'billingOrderNumber',
  },
  {
    label: 'État',
    field: 'status',
  },
  {
    label: 'Réf. doc SAGE',
    field: 'article',
  },
  {
    label: 'Transmission prévue',
    field: 'scheduledDate',
    type: 'date',
  },
  {
    label: 'Date transmission',
    field: 'transmissionDate',
    type: 'dateTime',
  },
  {
    label: 'Date doc SAGE',
    field: 'dateLivraison',
    type: 'date',
  },
  {
    label: 'Quantité',
    field: 'quantityLabel',
  },
  {
    label: 'Tarif u. HT',
    field: 'pricePerItemLabel',
  },
  {
    label: 'Total HT',
    field: 'totalLabel',
  },
];
