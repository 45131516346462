import React, { useState } from 'react';
import { Checkbox } from 'olaqin-design-system';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import CardWarnings from 'components/souscriptions/subscriptionDetails/cards/CardWarnings';
import RadioBox from 'components/_shared/RadioBox/RadioBox';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { startOfNextMonth } from 'app/utils/dateUtils';

const generateDelayDateOptions = () =>
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(month => {
    const date = startOfNextMonth(month);
    const label = startOfNextMonth(month, 'DD/MM/YYYY');
    return { label, value: date };
  });

const allOptions = generateDelayDateOptions();
const defaultDateOption = startOfNextMonth(2);

const ActivateSouscriptionPopUp = ({
  visible,
  onHide,
  onCancel,
  onConfirm,
  showWarning = false,
  showLoadingOnConfirm,
}) => {
  const [isWithoutDocsChecked, setWithoutDocsChecked] = useState(false);
  const [isNextStep, clickNext] = useState(false);
  const [selectedOption, setIsDateDelayed] = useState(false);
  const [dateActivation, setDateActivation] = useState(defaultDateOption);

  const showActivationStep = !showWarning || isNextStep;

  const sendActivation = () => {
    onConfirm(selectedOption ? dateActivation : null);
  };

  return (
    <DialogBox
      id="activerSouscriptionId"
      visible={visible}
      onHide={onHide}
      style={{ maxWidth: '500px' }}
      title="Activation de la facturation"
      confirmLabel={showActivationStep ? 'Activer la facturation' : 'Suivant'}
      onConfirm={showActivationStep ? sendActivation : clickNext}
      isConfirmActive={showActivationStep ? !showLoadingOnConfirm : isWithoutDocsChecked}
      isLoading={showActivationStep ? showLoadingOnConfirm : false}
      hideTitleLoading
      cancelLabel="Annuler"
      onCancel={onCancel}
      noTopSeparator
    >
      <>
        {/* show warning or immediately show next step */}
        {showWarning && !showActivationStep && (
          <div className="p-d-flex p-align-center p-flex-column">
            <CardWarnings
              warnings={{
                title:
                  'Attention ! Aucun document n’est rattaché à la souscription, le contrat signé est un document obligatoire à fournir.',
              }}
            />
            <div className="p-d-flex p-mt-3 p-mb-3">
              <Checkbox
                inputId="confirmWithoutDocuments"
                value={isWithoutDocsChecked}
                onChange={() => setWithoutDocsChecked(!isWithoutDocsChecked)}
                type="checkbox"
                checked={isWithoutDocsChecked}
              />
              <label
                htmlFor="confirmWithoutDocuments"
                style={{ fontSize: '14px', paddingLeft: '16px', userSelect: 'none' }}
              >
                Je confirme vouloir démarrer la souscription sans les documents obligatoires.
              </label>
            </div>
          </div>
        )}
        {showActivationStep && (
          <div className="p-d-flex p-flex-column p-mb-3">
            <RadioBox
              value={false}
              inputId="date_imm"
              onChange={() => setIsDateDelayed(false)}
              checked={selectedOption === false}
              label="Facturation immédiate"
            >
              <div className="p-pb-3 p-mb-1"></div>
            </RadioBox>
            <RadioBox
              value={true}
              inputId="date_delayed"
              onChange={() => setIsDateDelayed(true)}
              checked={selectedOption === true}
              label="Facturation décalée au…"
            >
              <div className="p-pt-4 p-pl-3">
                <PrimeDropdown
                  id="delay_date"
                  value={dateActivation}
                  onChange={({ value }) => setDateActivation(value)}
                  appendTo={document.body}
                  options={allOptions}
                  disabled={selectedOption !== true}
                />
              </div>
            </RadioBox>
          </div>
        )}
      </>
    </DialogBox>
  );
};

export default ActivateSouscriptionPopUp;
