import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { toast } from 'react-toastify';
import { throwError } from 'app/utils/functions';

const PUBLIEE = 'PUBLIEE';
const reducerName = 'offres';

const initialState = {
  loading: false,
  offres: [],
  allOffers: [],
  offers: {
    isLoading: false,
    isLoadingSearch: false,
    data: {},
  },
  details: {
    isLoading: false,
    data: {},
  },
  usageTypes: {
    isLoading: false,
    data: [],
  },
  saveAnOfferAsDraft: { isLoading: false },
  deleteOffer: { isLoading: false, data: {} },
  publishOffer: { isLoading: false },
  archiveOffer: { isLoading: false },
  validateOffer: { isLoading: false },
  eventsGrid: {
    isLoading: false,
    data: {},
  },
  isOfferDuplicating: false,
  offerSearchingByName: false,
};

export const listerOffresCommerciales = createAsyncThunk(
  `${reducerName}/listerOffresCommerciales`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.listerOffresCommercialesAll(PUBLIEE);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getOffersCommercial = createAsyncThunk(
  `${reducerName}/getOffersCommercial`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.afficherPageOffresCommercialesCommenceParNom(
        content.page,
        content.size,
        'dateDerniereModification',
        'DESC',
        content.search,
        content.status,
      );

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getListAllOffersCommercial = createAsyncThunk(
  `${reducerName}/getListAllOffersCommercial`,
  async (content, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.listerOffresCommercialesAll();

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createOffer = createAsyncThunk(
  `${reducerName}/createOffer`,
  async ({ offer }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.createOffreCommerciale(offer);

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateOffer = createAsyncThunk(
  `${reducerName}/updateOffer`,
  async ({ offreCommercialeId, offreCommercialeForm }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.updateOffreCommerciale(
        offreCommercialeId,
        offreCommercialeForm,
      );

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getOfferDetails = createAsyncThunk(
  `${reducerName}/getOfferDetails`,
  async ({ offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.chargerOffreCommerciale(offreCommercialeId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getOfferUsageTypes = createAsyncThunk(
  `${reducerName}/getOfferUsageTypes`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data,
      } = await API.ApplicationsMetiersPrestations.listerApplicationsMetiersPrestationUsageDataTypes();

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getOffersEventsPaginated = createAsyncThunk(
  `${reducerName}/getOffersEventsPaginated`,
  async ({ page, size, offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.getOfferEvents(offreCommercialeId, page, size);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const saveAnOfferAsDraft = createAsyncThunk(
  `${reducerName}/saveAnOfferAsDraft`,
  async ({ offer }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.createOffreCommerciale(offer);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const deleteAnOffer = createAsyncThunk(
  `${reducerName}/deleteOffer`,
  async ({ offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.supprimerOffreCommerciale(offreCommercialeId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const archiveAnOffer = createAsyncThunk(
  `${reducerName}/archiveAnOffer`,
  async ({ offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.archiveOffreCommerciale(offreCommercialeId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const publishAnOffer = createAsyncThunk(
  `${reducerName}/publishAnOffer`,
  async ({ offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.publishOffreCommerciale(offreCommercialeId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const duplicateAnOffer = createAsyncThunk(
  `${reducerName}/duplicateAnOffer`,
  async ({ offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.duplicateOffreCommerciale(offreCommercialeId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const configureAnOfferStellair = createAsyncThunk(
  `${reducerName}/configureAnOfferStellair`,
  async ({ offreCommercialeId, formData }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.configStellairOfferConnection(
        offreCommercialeId,
        formData,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const validateAnOffer = createAsyncThunk(
  `${reducerName}/validateAnOffer`,
  async ({ offreCommercialeId }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.validateOffreCommerciale(offreCommercialeId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const renameAnOffer = createAsyncThunk(
  `${reducerName}/renameAnOffer`,
  async ({ offreCommercialeId, offerNameForm }, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.updateOfferName(
        offreCommercialeId,
        offerNameForm,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const findOfferByName = createAsyncThunk(
  `${reducerName}/findOfferByName`,
  async (offerName, { rejectWithValue }) => {
    try {
      const { data } = await API.OffresCommerciales.afficherPageOffresCommercialesCommenceParNom(
        0,
        1,
        'dateDerniereModification',
        'DESC',
        offerName,
      );

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

const offresSlice = createSlice({
  name: reducerName,
  initialState,
  extraReducers: builder => {
    // Lister
    builder.addCase(listerOffresCommerciales.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerOffresCommerciales.fulfilled, (state, action) => {
      state.offres = action.payload;
      state.loading = false;
    });
    builder.addCase(listerOffresCommerciales.rejected, state => {
      state.loading = false;
      const errorMessage = 'Une erreur est produite durant la récupération des offres commerciales';
      state.error = {
        messageMetier: errorMessage,
      };
      toast.error(errorMessage);
    });

    // paginated list PUBLIEE offers
    builder.addCase(getOffersCommercial.pending, (state, action) => {
      const { search } = action.meta?.arg;
      const isSearching = search || search === '';
      state.offers.isLoading = !isSearching;
      state.offers.isLoadingSearch = isSearching;
    });
    builder.addCase(getOffersCommercial.fulfilled, (state, action) => {
      state.offers.data = action.payload;
      state.offers.isLoading = false;
      state.offers.isLoadingSearch = false;
    });
    builder.addCase(getOffersCommercial.rejected, state => {
      state.offers.isLoading = false;
      state.offers.isLoadingSearch = false;
      toast.error('Une erreur est produite durant la récupération des offres commerciales');
    });

    // list of all offers
    builder.addCase(getListAllOffersCommercial.pending, state => {
      state.loading = true;
    });
    builder.addCase(getListAllOffersCommercial.fulfilled, (state, action) => {
      state.allOffers = action.payload;
      state.loading = false;
    });
    builder.addCase(getListAllOffersCommercial.rejected, state => {
      state.loading = false;
      toast.error('Une erreur est produite durant la récupération des offres commerciales');
    });

    // create an offers
    builder.addCase(createOffer.pending, state => {
      state.loading = true;
    });
    builder.addCase(createOffer.fulfilled, state => {
      state.loading = false;
      toast.success('L’offre a bien été créée.');
    });
    builder.addCase(createOffer.rejected, state => {
      state.loading = false;
    });
    // update an offers
    builder.addCase(updateOffer.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateOffer.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(updateOffer.rejected, state => {
      state.loading = false;
    });

    // offer details
    builder.addCase(getOfferDetails.pending, state => {
      state.details.isLoading = true;
    });
    builder.addCase(getOfferDetails.fulfilled, (state, action) => {
      state.details.isLoading = false;
      state.details.data = action.payload;
    });
    builder.addCase(getOfferDetails.rejected, state => {
      state.details.isLoading = false;
    });

    // offer usage data types
    builder.addCase(getOfferUsageTypes.pending, state => {
      state.usageTypes.isLoading = true;
    });
    builder.addCase(getOfferUsageTypes.fulfilled, (state, action) => {
      state.usageTypes.isLoading = false;
      state.usageTypes.data = action.payload;
    });
    builder.addCase(getOfferUsageTypes.rejected, state => {
      state.usageTypes.isLoading = false;
    });

    // deleting an offer
    builder.addCase(deleteAnOffer.pending, state => {
      state.deleteOffer.isLoading = true;
    });
    builder.addCase(deleteAnOffer.fulfilled, (state, action) => {
      state.deleteOffer.data = action.payload;
      state.deleteOffer.isLoading = false;
    });
    builder.addCase(deleteAnOffer.rejected, state => {
      state.deleteOffer.isLoading = false;
    });

    // publish an offer
    builder.addCase(publishAnOffer.pending, state => {
      state.publishOffer.isLoading = true;
    });
    builder.addCase(publishAnOffer.fulfilled, (state, action) => {
      state.publishOffer.data = action.payload;
      state.publishOffer.isLoading = false;
      toast('L’offre a bien été publiée.', {
        type: 'dark',
        style: {
          background: '#2F7D7C',
        },
      });
    });
    builder.addCase(publishAnOffer.rejected, state => {
      state.publishOffer.isLoading = false;
    });

    // archive an offer
    builder.addCase(archiveAnOffer.pending, state => {
      state.archiveOffer.isLoading = true;
    });
    builder.addCase(archiveAnOffer.fulfilled, (state, action) => {
      state.archiveOffer.data = action.payload;
      state.archiveOffer.isLoading = false;
      toast('L’offre a été archivée.', {
        type: 'dark',
        style: {
          background: '#404040',
        },
      });
    });
    builder.addCase(archiveAnOffer.rejected, state => {
      state.archiveOffer.isLoading = false;
    });

    // save an offer as draft
    builder.addCase(saveAnOfferAsDraft.pending, state => {
      state.saveAnOfferAsDraft.isLoading = true;
    });
    builder.addCase(saveAnOfferAsDraft.fulfilled, state => {
      state.saveAnOfferAsDraft.isLoading = false;
      toast('L’offre brouillon a bien été enregistrée.', {
        type: 'dark',
        style: {
          background: '#2F7D7C',
        },
      });
    });
    builder.addCase(saveAnOfferAsDraft.rejected, state => {
      state.saveAnOfferAsDraft.isLoading = false;
    });

    // events grid data
    builder.addCase(getOffersEventsPaginated.pending, state => {
      state.eventsGrid.isLoading = true;
    });
    builder.addCase(getOffersEventsPaginated.fulfilled, (state, action) => {
      state.eventsGrid.isLoading = false;
      state.eventsGrid.data = action.payload;
    });
    builder.addCase(getOffersEventsPaginated.rejected, state => {
      state.eventsGrid.isLoading = false;
    });

    // duplicate an offer
    builder.addCase(duplicateAnOffer.pending, state => {
      state.isOfferDuplicating = true;
    });
    builder.addCase(duplicateAnOffer.fulfilled, state => {
      state.isOfferDuplicating = false;
    });
    builder.addCase(duplicateAnOffer.rejected, state => {
      state.isOfferDuplicating = false;
    });

    // validate an offer
    builder.addCase(validateAnOffer.pending, state => {
      state.validateOffer.isLoading = true;
    });
    builder.addCase(validateAnOffer.fulfilled, state => {
      state.validateOffer.isLoading = false;
    });
    builder.addCase(validateAnOffer.rejected, state => {
      state.validateOffer.isLoading = false;
    });

    // validate an offer
    builder.addCase(findOfferByName.pending, state => {
      state.offerSearchingByName = true;
    });
    builder.addCase(findOfferByName.fulfilled, state => {
      state.offerSearchingByName = false;
    });
    builder.addCase(findOfferByName.rejected, state => {
      state.offerSearchingByName = false;
    });
  },
});

const managerStateSelector = state => state[reducerName];

export const selectOffres = createSelector(managerStateSelector, state => state.offres);

export const selectOffresLoading = createSelector(managerStateSelector, state => state.loading);

export const selectOffersData = createSelector(managerStateSelector, state => state.offers.data);

export const selectIsLoadingOffers = createSelector(
  managerStateSelector,
  state => state.offers.isLoading,
);
export const selectIsOffersSearching = createSelector(
  managerStateSelector,
  state => state.offers.isLoadingSearch,
);

export const selectListAllOffers = createSelector(managerStateSelector, state => state.allOffers);

export const selectIsOfferDetailsLoading = createSelector(
  managerStateSelector,
  state => state.details.isLoading,
);

export const selectOfferDetailsData = createSelector(
  managerStateSelector,
  state => state.details.data,
);

export const selectOfferUsageTypes = createSelector(
  managerStateSelector,
  state => state.usageTypes.data,
);

export const selectSaveOfferAsDraftIsLoading = createSelector(
  managerStateSelector,
  state => state.saveAnOfferAsDraft.isLoading,
);

export const selectDeleteOfferIsLoading = createSelector(
  managerStateSelector,
  state => state.deleteOffer.isLoading,
);

export const selectPublishOfferIsLoading = createSelector(
  managerStateSelector,
  state => state.publishOffer.isLoading,
);

export const selectArchiveOfferIsLoading = createSelector(
  managerStateSelector,
  state => state.archiveOffer.isLoading,
);

export const selectOfferEventsGridData = createSelector(
  managerStateSelector,
  state => state.eventsGrid.data,
);

export const selectIsOfferEventsGridLoading = createSelector(
  managerStateSelector,
  state => state.eventsGrid.isLoading,
);

export const selectIsOfferDuplicating = createSelector(
  managerStateSelector,
  state => state.isOfferDuplicating,
);

export const selectIsOfferValidating = createSelector(
  managerStateSelector,
  state => state.validateOffer.isLoading,
);
export const selectIsOfferNameSearchLoading = createSelector(
  managerStateSelector,
  state => state.offerSearchingByName,
);

export default offresSlice.reducer;
