/* eslint-disable no-restricted-syntax */
import React, { useState, useRef, useMemo } from 'react';
import { Navbar as OdsNavbar, ExpandButton } from 'olaqin-design-system';
import { useHistory, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import env from '@beam-australia/react-env';
import { routes } from 'app/routes/appRoutes';
import navigationMapping from './navigationMapping';

const Navbar = () => {
  const { instance } = useMsal();
  const history = useHistory();
  const location = useLocation();
  const [isActive, setActive] = useState(true);
  const [activeItem, setActiveItem] = useState([]);
  const commandNavigate = ({ item: { pathname } }) => history.push(pathname);
  const menuItems = [
    {
      label: (
        <img
          src={`/icons/${isActive ? 'LogoType' : 'small-logo'}.svg`}
          alt="Niqola"
          className={isActive ? 'NiqolaLargeLogo' : ''}
        />
      ),
      icon: '',
    },
    {
      label: 'Souscription',
      icon: 'pi pi-fw odsi odsi-ticket',
      pathname: routes.SUBSCRIPTIONS(),
      command: commandNavigate,
    },
    {
      label: 'Clients',
      icon: 'pi pi-fw odsi odsi-users-partner',
      pathname: routes.CLIENTS(),
      command: commandNavigate,
    },
    {
      label: 'Solutions',
      icon: 'pi pi-fw odsi odsi-files-2-items',
      items: [
        {
          label: 'Prestations',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.PRESTATIONS(),
          command: commandNavigate,
        },
        {
          label: 'Offres',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.OFFERS(),
          command: commandNavigate,
        },
        {
          label: 'Révisions tarifaires',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.INDEXES(),
          command: commandNavigate,
        },
      ],
    },
    {
      label: 'Rapports',
      icon: 'pi pi-fw odsi odsi-files-single-item-2',
      items: [
        {
          label: 'Consommation FSE',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.REPORTS_PRESTATIONS_FSE(),
          command: commandNavigate,
        },
        {
          label: 'Prestations souscrites',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.REPORTS_PRESTATIONS(),
          command: commandNavigate,
        },
        {
          label: 'Export des données',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.REPORTS_EXPORT(),
          command: commandNavigate,
        },
        {
          label: 'Usage Stellair',
          icon: 'pi pi-fw navitem-no-icon',
          pathname: routes.REPORTS_STELLAIR_USAGE(),
          command: commandNavigate,
        },
      ],
    },
  ];
  const model = useMemo(() => menuItems, [isActive]);

  const initialActivatedItem = () => {
    if (activeItem.length > 0) return activeItem;
    const pathName = location.pathname.substring(1).split('/');
    const routeItem =
      navigationMapping.find(navItem =>
        navItem.pathname
          .substring(1)
          .split('/')
          .includes(pathName[pathName.length - 1]),
      ) ?? [];

    const routeModel = model.reduce((acc, modelItem) => {
      if (routeItem.levels?.includes(modelItem.label)) {
        acc.push(modelItem);
        const nextLevelIndex = routeItem.levels.indexOf(modelItem.label) + 1;
        if (nextLevelIndex < routeItem.levels.length) {
          const nextLevel = routeItem.levels[nextLevelIndex];
          const childItem = modelItem.items.find(child => child.label === nextLevel);
          if (childItem) acc.push(childItem);
        }
      }
      return acc;
    }, []);
    return routeModel;
  };

  const onItemRefChange = item => {
    setActiveItem(item);
  };

  const handleLogout = async () => {
    window.parent.postMessage('deconnecter', env('PORTAIL_WEB'));
    await instance.logoutRedirect({
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: env('POST_LOGOUT_REDIRECT_URI'),
    });
  };

  const footerModel = useRef([
    {
      label: 'Déconnexion',
      icon: 'pi pi-fw odsi odsi-off',
      command: handleLogout,
    },
  ]);

  const toggle = bool => {
    if (typeof bool === 'boolean') {
      return () => setActive(true);
    }
    return setActive(!isActive);
  };

  return (
    <>
      <ExpandButton className="expandButton" onClick={toggle(true)} />
      <OdsNavbar
        onHide={toggle(false)}
        id="menuGalaxie"
        expanded={isActive}
        model={model}
        footerModel={footerModel.current}
        toggle={toggle}
        activeItem={initialActivatedItem()}
        onItemRefChange={onItemRefChange}
      />
    </>
  );
};

export default Navbar;
