import React, { useMemo, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CardDisplay } from 'olaqin-design-system';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { selectSouscription } from 'app/redux/slices/souscriptions';
import { formatDate } from 'app/utils/dateUtils';
import WarningLabel from 'components/_shared/WarningLabel/WarningLabel';
import { combineAddressLabel } from 'app/utils/formatUtils';
import {
  selectCollaboratorsBillingSystem,
  getCollaboratorsBillingSystem,
} from 'app/redux/slices/teams';
import { sageBaseTypes } from 'app/constants/client.constants';
import { pricingRevisionIndexSettingsOptions } from 'app/constants/offerConstants';

const CardDetailsOfSubscription = ({ title }) => {
  const dispatch = useDispatch();
  const souscription = useSelector(selectSouscription);
  const {
    numeroContrat,
    dateCreation,
    offreCommerciale,
    dateFin,
    deliveryAddress,
    warnings,
    souscripteur,
    collaborator,
    pricingRevisionIndexS0,
  } = souscription;
  const { name: addressName, ...otherAddressFields } = deliveryAddress || {};
  const addressDetail = combineAddressLabel(otherAddressFields);
  const warningFields = warnings?.fields || [];
  const { engagement } = offreCommerciale;
  const collaborators = useSelector(selectCollaboratorsBillingSystem);

  const defaultStyle = {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 600,
    color: 'black',
  };

  useEffect(() => {
    if (souscripteur?.sageBase && collaborator?.identifiant) {
      dispatch(
        getCollaboratorsBillingSystem({
          sageBase: sageBaseTypes[souscripteur?.sageBase],
        }),
      );
    }
  }, [souscripteur?.sageBase]);

  const isFieldInWarnings = checkField => warningFields.some(fields => fields.value === checkField);

  const formattedDateCreation = dateCreation
    ? moment(dateCreation).format('DD/MM/YYYY, HH:mm')
    : '–';

  const formattedEngagement = engagement ? `${engagement} mois` : '–';

  const formattedDateFin = formatDate(dateFin, 'DD/MM/YYYY');

  const collaboratorLabel = useMemo(() => {
    const presentedCollaborator = collaborators.find(i => i.id === collaborator?.identifiant);

    return !presentedCollaborator
      ? false
      : `${presentedCollaborator.nom ?? ''} ${presentedCollaborator.prenom ?? ''}`;
  }, [collaborators, collaborator?.identifiant]);
  const isCollaboratorFieldDisplayed = collaboratorLabel && collaboratorLabel.length > 0;

  const formattedS0Tariff = () => {
    if (
      !pricingRevisionIndexS0 ||
      pricingRevisionIndexS0?.type === pricingRevisionIndexSettingsOptions[3].value
    ) {
      return (
        <span className="p-md-9" style={defaultStyle}>
          Aucune révision tarifaire
        </span>
      );
    }

    if (
      pricingRevisionIndexS0.type === pricingRevisionIndexSettingsOptions[0].value &&
      !pricingRevisionIndexS0.value
    ) {
      return (
        <span className="p-md-9" style={{ ...defaultStyle, color: '#6A6A6A' }}>
          L’indice Syntec mensuel courant sera appliqué au début de la facturation
        </span>
      );
    }

    if (
      pricingRevisionIndexS0.type === pricingRevisionIndexSettingsOptions[1].value &&
      !pricingRevisionIndexS0.value
    ) {
      return (
        <span className="p-md-9" style={{ ...defaultStyle, color: '#6A6A6A' }}>
          L’indice Syntec annuel courant sera appliqué au début de la facturation
        </span>
      );
    }

    return (
      <span className="p-md-9" style={defaultStyle}>
        {pricingRevisionIndexS0.value} {pricingRevisionIndexS0.name}
      </span>
    );
  };

  return (
    <CardDisplay title={title} style={{ width: 'auto' }}>
      <div className="p-grid" style={{ marginTop: '16px' }}>
        <span className="p-md-3" style={defaultStyle}>
          Numéro de contrat :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {numeroContrat}
          <CopyToClipboard text={numeroContrat} style={{ margin: '0 0 0 8px', cursor: 'pointer' }}>
            <i className="pi pi-copy" />
          </CopyToClipboard>
        </span>
        <span className="p-md-3" style={defaultStyle}>
          Adresse de livraison :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {isFieldInWarnings('adresseLivraison.adresse') ? (
            <WarningLabel icon="" />
          ) : (
            <div>
              <div style={defaultStyle}>{addressName}</div>
              <div style={defaultStyle}>{addressDetail}</div>
            </div>
          )}
        </span>
        {isCollaboratorFieldDisplayed && (
          <>
            <span className="p-md-3" style={defaultStyle}>
              Revendeur :
            </span>
            <span className="p-md-9" style={defaultStyle}>
              {collaboratorLabel}
            </span>
          </>
        )}
        <span className="p-md-3" style={defaultStyle}>
          Engagement initial :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {formattedEngagement}
        </span>
        {!!dateFin && (
          <>
            <span className="p-md-3" style={defaultStyle}>
              Fin d’engagement en cours le :
            </span>
            <span className="p-md-9" style={defaultStyle}>
              {formattedDateFin}
            </span>
          </>
        )}
        <span className="p-md-3" style={defaultStyle}>
          Souscription créée le :
        </span>
        <span className="p-md-9" style={defaultStyle}>
          {formattedDateCreation}
        </span>
        <span className="p-md-3" style={defaultStyle}>
          Réf. révision tarifaire (S0) :
        </span>
        {formattedS0Tariff()}
      </div>
    </CardDisplay>
  );
};

export default CardDetailsOfSubscription;
