import React from 'react';
import { Message } from 'primereact/message';
import customStyle from './CardAnomalies.module.scss';

const CardWarnings = ({ warnings = {}, ...props }) =>
  !!Object.keys(warnings).length && (
    <Message
      style={{ padding: '0', width: '100%' }}
      severity="warn"
      {...props}
      content={
        <div className={customStyle.warningContainer}>
          <span className={customStyle.wordBreak}>{warnings.title}</span>
        </div>
      }
    />
  );

export default CardWarnings;
