import React, { useEffect, useState } from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { subscriptionStatuses } from 'app/constants/subscription.constants';
import FactureTransmisDataTable from 'components/souscriptions/subscriptionDetails/Tabs/FactureTab/FactureTransmisDataTable';
import { TabPanel, TabView } from 'primereact/tabview';
import FacturePlanningDataTable from 'components/souscriptions/subscriptionDetails/Tabs/FactureTab/FacturePlanningDataTable';
import { getSearchParams } from 'app/hooks/usePagination';
import { useHistory, useLocation } from 'react-router-dom';
import FactureCanceledDataTable from './FactureCanceledDataTable';

const defaultText =
  'Retrouvez ici les bons de facturation qui seront transmis à SAGE après le démarrage de la souscription.';

const statusTextRelation = {
  [subscriptionStatuses.INCOMPLETE]: defaultText,
  [subscriptionStatuses.CANCEL]: 'Aucun bon de facturation n’a été créé.',
};

const TabsData = ['Planifiés', 'Transmis', 'Annulés'];

const getIndexTabBasedOnUrl = search => {
  const currentParams = getSearchParams(search);
  if (currentParams.tabBilling) {
    const activeTabOnLoadIndex = TabsData.findIndex(tab => tab === currentParams.tabBilling);
    return activeTabOnLoadIndex;
  }
  return 1;
};

const FactureTab = ({ subscriptionId, subscriptionStatus }) => {
  const { search } = useLocation();
  const history = useHistory();
  const [currentActiveTab, setActiveTab] = useState(getIndexTabBasedOnUrl(search));

  const textBasedOnStatus = statusTextRelation[subscriptionStatus] || defaultText;

  useEffect(() => {
    const nextTabIndex = getIndexTabBasedOnUrl(search);
    if (currentActiveTab !== nextTabIndex) {
      setActiveTab(nextTabIndex);
    }
  }, [search]);

  const isShowBilling =
    subscriptionStatus === subscriptionStatuses.ACTIVE ||
    subscriptionStatus === subscriptionStatuses.ANOMALIE ||
    subscriptionStatus === subscriptionStatuses.CLOSED;

  const onTabChange = e => {
    const currentParams = getSearchParams(search);
    const previousPageTab = encodeURIComponent(currentParams.tab);
    const nextTab = TabsData[e.index];
    history.push(`?tab=${previousPageTab}&tabBilling=${encodeURIComponent(nextTab)}`);
  };

  return (
    <CardDisplay title="Bons de facturation" style={{ width: 'auto' }}>
      {!isShowBilling && <p>{textBasedOnStatus}</p>}
      {isShowBilling && (
        <TabView
          id="subscriptionBillingFacture"
          className="p-mt-3"
          activeIndex={currentActiveTab}
          onTabChange={onTabChange}
        >
          <TabPanel header="Planifiés">
            <FacturePlanningDataTable subscriptionId={subscriptionId} />
          </TabPanel>
          <TabPanel header="Transmis">
            <FactureTransmisDataTable subscriptionId={subscriptionId} />
          </TabPanel>
          <TabPanel header="Annulés">
            <FactureCanceledDataTable subscriptionId={subscriptionId} />
          </TabPanel>
        </TabView>
      )}
    </CardDisplay>
  );
};

export default FactureTab;
