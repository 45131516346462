import React from 'react';
import { Chip } from 'primereact/chip';
import { OfferStatusColorModel, OfferStatusLabelModel } from 'app/constants/offerConstants';
import customStyle from './OfferStatus.module.scss';

const StatusOffer = ({ label }) => {
  const matchedColor = OfferStatusColorModel[label];

  const matchedLabel = OfferStatusLabelModel[label] || label;

  return (
    <>
      <Chip
        className={customStyle.customChip}
        style={{ backgroundColor: matchedColor }}
        label={matchedLabel}
      />
    </>
  );
};

export default StatusOffer;
