import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomBounce = cssTransition({
  enter: 'Toastify__slide-enter',
  exit: 'Toastify__slide-exit',
  appendPosition: true,
  duration: [800, 500],
});

const Toast = () => (
  <ToastContainer hideProgressBar transition={CustomBounce} position="bottom-right" />
);

export default Toast;
