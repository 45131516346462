import { Validators } from 'olaqin-design-system';
import {
  prestationTypeOptions,
  pricingRevisionIndexSettingsOptions,
  renewalOptions,
} from 'app/constants/offerConstants';
import { isValidNumber, maxLengthValidator } from 'app/utils/formFieldUtils';

const customValidation = () => value => {
  if (!value.length) {
    return 'Une prestation au minimum doit être ajoutée à l’offre.';
  }
  return '';
};

const validationConditionIndex = {
  name: [
    Validators.required('Veuillez saisir le nom de l’indice.'),
    maxLengthValidator(100, 'Le nom de l’indice ne peut excéder 100 caractères.'),
  ],
  value: [
    Validators.required('Veuillez la valeur de l’indice.'),
    value => !isValidNumber(value) && 'Veuillez saisir une valeur d’indice valide. Exemple : 305,7',
  ],
};

const validationSchema = {
  composition: [customValidation()],
};

const validationConditions = {
  reconduction: [
    () =>
      "L'offre ne peut pas être reconduite tacitement car elle n’est composée que de prestations à facturer ponctuellement.",
  ],
};
const validationSchemaWithRenewal = {
  ...validationSchema,
  conditions: [],
};

// eslint-disable-next-line import/prefer-default-export
export const formCreateOfferValidation = values => {
  const isCurrentIndexCustom =
    values.conditions.pricingRevisionIndexSettings.type ===
    pricingRevisionIndexSettingsOptions[2].value;

  if (isCurrentIndexCustom) {
    validationSchema.conditions = Validators.createValidator({
      pricingRevisionIndexSettings: Validators.createValidator(validationConditionIndex),
    });
  }

  const isRenewalTacite = values.conditions.reconduction === renewalOptions[1].value;
  const haveRecurringComposition = values.composition.some(
    ({ type }) => type === prestationTypeOptions[1].value,
  );
  if (isRenewalTacite && !haveRecurringComposition) {
    validationSchemaWithRenewal.conditions = Validators.createValidator(validationConditions);
    return Validators.createValidator(validationSchemaWithRenewal)(values);
  }

  return Validators.createValidator(validationSchema)(values);
};
