import React from 'react';
import { useSelector } from 'react-redux';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { selectIsDynamicSyncLoading } from 'app/redux/slices/teams';

const ConfirmDynamicSyncDialog = ({ visible, onHide, onConfirm, clientName }) => {
  const isDynamicSyncing = useSelector(selectIsDynamicSyncLoading);

  return (
    <DialogBox
      id="confirmDynamicSyncDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title="Synchronisation vers Dynamics 365"
      isLoading={isDynamicSyncing}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={onConfirm}
      confirmLabel="Confirmer"
      noTopSeparator
      noSubmitTypeOnButton
      dismissableMask
    >
      <div className="p-pb-3">
        Vous êtes sur le point de transmettre les informations du client {clientName}.
        Confirmez-vous synchronisation des données vers Dynamics 365?
      </div>
    </DialogBox>
  );
};

export default ConfirmDynamicSyncDialog;
