import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  renameAnOffer,
  findOfferByName,
  selectIsOfferNameSearchLoading,
} from 'app/redux/slices/offres';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';
import { Portal } from 'react-portal';
import { Form, Field } from 'react-final-form';
import SearchInput from 'components/_shared/SearchInput/SearchInput';

const OfferRenameDialog = ({ visible, onHide, offreCommercialeId, offerName, actionOnSuccess }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const isSearching = useSelector(selectIsOfferNameSearchLoading);

  const isOfferExist = async inputOfferName => {
    try {
      const res = await dispatch(findOfferByName(inputOfferName)).unwrap();
      return res;
    } catch {
      return {};
    }
  };

  const handleCustomValidation = async value => {
    if (!value) {
      return 'Veuillez renseigner une désignation';
    }
    const trimmedValue = value.trim();
    if (trimmedValue.length > 100) {
      return 'La désignation ne peut excéder 100 caractères';
    }
    const { content } = await isOfferExist(trimmedValue);
    if (content) {
      const findSameName = content.find(offer => offer.nom === trimmedValue);
      if (findSameName && findSameName.nom !== offerName) {
        return `L’offre "${trimmedValue}" existe déjà`;
      }
    }
    return '';
  };

  const handleClickConfirm = async formData => {
    setLoading(true);
    await dispatch(renameAnOffer({ offreCommercialeId, offerNameForm: formData }))
      .unwrap()
      .then(() => {
        onHide();
        toast('L’offre a bien été renommée.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
        actionOnSuccess();
        setLoading(false);
      })
      .catch();
  };

  return (
    <Portal>
      <Form
        initialValues={{
          name: offerName,
        }}
        onSubmit={handleClickConfirm}
        render={({ handleSubmit }) => (
          <form
            className="p-fluid p-formgrid p-grid"
            id="rename-offer-form"
            onSubmit={handleSubmit}
          >
            <DialogBox
              id="renameOfferNameDialog"
              spacingField="8px"
              spacingLabel="0"
              visible={visible}
              onHide={onHide}
              isLoading={isLoading}
              title={`Renommage de l’offre ${offerName}`}
              style={{
                maxWidth: '630px',
                overflow: 'auto',
              }}
              className="p-col-12 p-sm-12 p-md-12 p-lg-8"
              confirmLabel="Enregistrer les modifications"
              cancelLabel="Annuler"
              onCancel={onHide}
            >
              <div style={{ paddingBottom: 16 }}>
                <p style={{ fontWeight: 700 }} className="p-mb-1 p-mt-1">
                  Désignation de l’offre
                </p>
                <Field
                  name="name"
                  validate={handleCustomValidation}
                  render={({ input, meta: { valid, error, touched } }) => (
                    <span className="p-input-icon-right p-mb-1">
                      <SearchInput
                        isLoading={isSearching}
                        className={!valid && touched && 'p-invalid p-d-block'}
                        onSearch={input.onChange}
                        onBlur={input.onBlur}
                        defaultValue={input.value}
                        style={{}}
                        hideSearchIcon
                      />
                      {!!error && touched && (
                        <small style={{ height: 0 }} className="p-error p-d-block">
                          {error}
                        </small>
                      )}
                      {valid && touched && (
                        <i className="pi pi-check" style={{ color: '#2F7D7C' }} />
                      )}
                    </span>
                  )}
                />
              </div>
            </DialogBox>
          </form>
        )}
      />
    </Portal>
  );
};

export default OfferRenameDialog;
