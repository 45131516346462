import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProgressSpinner } from 'olaqin-design-system';
import { TabPanel, TabView } from 'primereact/tabview';
import {
  getClientDetails,
  selectClientDetailsDataAnomalies,
  selectClientDetailsDataClient,
  selectIsClientDetailsLoading,
} from 'app/redux/slices/teams';
import './ClientsDetailPage.scss';
import CardAnomalies from 'components/souscriptions/subscriptionDetails/cards/CardAnomalies';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import DeliveryAddressTab from './Tabs/DeliveryAddressTab/DeliveryAddressTab';
import ContactsTab from './Tabs/Contacts/ContactsTab';
import GeneralTab from './Tabs/GeneralTab/GeneralTab';
import UpdateClientDialogWrapper from './Tabs/UpdateClientDialogWrapper/UpdateClientDialogWrapper';
import ConnectionsTabs from './Tabs/Connections/ConnectionsTab';

const ClientsDetailsPage = ({ match }) => {
  const { params } = match;
  const { clientId } = params;

  const [currentActiveTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const isLoadingClientDetails = useSelector(selectIsClientDetailsLoading);
  const clientDetails = useSelector(selectClientDetailsDataClient);
  const clientDetailsAnomalies = useSelector(selectClientDetailsDataAnomalies);
  const { numeroClient, sageBase, nom } = clientDetails;

  const subTitle = `${numeroClient ? `# ${numeroClient} -` : ''} Sage ${sageBase}`;

  useEffect(() => {
    dispatch(getClientDetails({ id: clientId }));
  }, []);

  if (isLoadingClientDetails) {
    return <ProgressSpinner />;
  }

  return (
    <div className="clientsDetailsPage">
      <div className="p-d-flex p-justify-between">
        <div>
          <h1 className="header">{nom}</h1>
          <div className="subtitle">{subTitle}</div>
        </div>
        {currentActiveTab === 0 && (
          <div className="p-mt-4">
            <UpdateClientDialogWrapper clientId={clientId} />
          </div>
        )}
      </div>
      {!!clientDetailsAnomalies?.length && <StatusSubscription label="Anomalie" />}
      <CardAnomalies
        id={clientId}
        anomalies={clientDetailsAnomalies}
        onClickResolve={() => {}}
        loadingsSelector={() => {}}
      />
      <TabView
        id="clientDetailView"
        className="p-mt-3"
        activeIndex={currentActiveTab}
        onTabChange={e => setActiveTab(e.index)}
      >
        <TabPanel header="Général">
          <GeneralTab clientDetails={clientDetails} />
        </TabPanel>
        <TabPanel header="Adresses de livraison">
          <DeliveryAddressTab clientId={clientId} sageBase={sageBase} />
        </TabPanel>
        <TabPanel header="Contacts">
          <ContactsTab clientId={clientId} sageBase={sageBase} />
        </TabPanel>
        <TabPanel header="Connexions">
          <ConnectionsTabs clientDetails={clientDetails} />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default ClientsDetailsPage;
