import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import API from 'app/api';
import { throwError } from 'app/utils/functions';
import { toast } from 'react-toastify';

const managerName = 'teams';

const initialState = {
  loading: false,
  isLoadingSearch: false,
  pageTeams: null,
  listeTeams: [],
  teamsNewList: [],
  clientsNewData: {},
  clientAutoComplete: {
    isLoading: false,
    isSearching: false,
    data: [],
  },
  deliveryAddressData: [],
  deliveryAddressDataTableIsLoading: false,
  updateNewClientIsLoading: false,
  createNewClientIsLoading: false,
  anomaliesLoadings: {},
  teamDetails: {
    isLoading: false,
    data: {},
  },
  newClientId: '',
  deliveryAddress: {
    isLoading: false,
    expeditions: [],
    data: {},
  },
  updateDeliveryAddress: {
    isLoading: false,
    data: {},
  },
  createDeliveryAddress: {
    isLoading: false,
    data: {},
  },
  clientContactsDataTable: { isLoading: false, data: [] },
  clientContactDetails: { isLoading: false, data: {} },
  createClientContact: { isLoading: false, data: {} },
  updateClientContact: { isLoading: false, data: {} },
  deleteClientContact: { isLoading: false, data: {} },
  listAllTypeOfContact: {
    isLoading: false,
    data: [],
  },
  listAllDepartment: {
    isLoading: false,
    data: [],
  },
  listCompanyDelivery: {
    isLoading: false,
    data: [],
  },
  listCompanyDeliveryRequirement: {
    isLoading: false,
    data: [],
  },
  clientDetails: {
    isLoading: false,
    data: {},
  },
  collaboratorList: {
    isLoading: false,
    data: [],
  },
  sageServiceUsers: {
    isLoading: false,
    data: [],
  },
  sageHardwareUsers: {
    isLoading: false,
    data: [],
  },
  allClientsList: {
    isLoading: false,
    data: [],
  },
  membersInTeam: {
    isLoading: false,
    data: [],
  },
  usersInAllTeams: {
    isLoading: false,
    data: [],
  },
  team: {},
  group: {
    isLoading: false,
    isNewItemCreated: false,
    data: [],
  },
  clientByNumero: {},
  stellairAccount: {
    isLoading: false,
    data: {},
  },
  isDynamicsSync: false,
  isDuplicateClient: false,
};

export const listerTeams = createAsyncThunk(`${managerName}/listerTeams`, async sageBase => {
  try {
    const { data } = await API.Teams.allClientList('dateCreation', 'DESC', undefined, sageBase);
    return data;
  } catch (error) {
    return throwError(error);
  }
});

export const getCollaboratorsBillingSystem = createAsyncThunk(
  `${managerName}/getCollaborators`,
  async ({ sageBase }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.allSageUsers(sageBase);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getAllSageServiceUsers = createAsyncThunk(
  `${managerName}/getAllSageServiceUsers`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.allSageUsers('SERVICE');
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getAllSageHardwareUsers = createAsyncThunk(
  `${managerName}/getAllSageHardwareUsers`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.allSageUsers('HARDWARE');
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getAllClientsList = createAsyncThunk(
  `${managerName}/getAllClientsList`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.allClientList(
        'dateCreation',
        'DESC',
        undefined,
        params?.sageBase,
      );

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getClientsNewListPaginated = createAsyncThunk(
  `${managerName}/getClientsNewListPaginated`,
  async ({ page, size, search }) => {
    try {
      const { data } = await API.Teams.clientTeamsPaginated(page, size, 'nom', 'ASC', search);

      return data;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const getClientsAutoCompleteData = createAsyncThunk(
  `${managerName}/getClientsAutoCompleteData`,
  async ({ page, size, searchText, sageBase }) => {
    try {
      const { data } = await API.Teams.clientTeamsPaginated(
        page,
        size,
        'dateCreation',
        'DESC',
        searchText,
        sageBase,
      );

      return data;
    } catch (error) {
      return throwError(error);
    }
  },
);

export const getClientDetails = createAsyncThunk(
  `${managerName}/getClientDetails`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.getClientOrganization(id);

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const duplicateClient = createAsyncThunk(
  `${managerName}/duplicateClient`,
  async ({ clientId }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.duplicateClient(clientId);

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const importClientsByFile = createAsyncThunk(
  `${managerName}/importClientsByFile`,
  async ({ name, file }, { rejectWithValue }) => {
    try {
      const { data } = await API.Import.importClientsFromFile(name, file);

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const downloadClientsImportFileTemplate = createAsyncThunk(
  `${managerName}/downloadClientsImportFileTemplate`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.Import.downloadImportClientTemplateFile();

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getClientDetailsAnomalies = createAsyncThunk(
  `${managerName}/getClientDetailsAnomalies`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.getClientOrganization(id);

      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const resolveClientAnomaly = createAsyncThunk(
  `${managerName}/resolveClientAnomaly`,
  async (content, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await API.Anomalie.resolveAnomalie(content.anomalieId);

      // get client
      dispatch(getClientDetails({ id: content.id }));
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createNewClient = createAsyncThunk(
  `${managerName}/createNewClient`,
  async (formData, { rejectWithValue }) => {
    try {
      return (await API.Teams.addClientOrganization(formData)).data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateNewClient = createAsyncThunk(
  `${managerName}/updateNewClient`,
  async ({ clientId, formData }, { rejectWithValue, dispatch }) => {
    try {
      const { identifiant, ...validFormData } = formData;
      const { data } = await API.Teams.updateClientOrganization(clientId, validFormData);
      return data;
    } catch (error) {
      throwError(error);

      // get client anomalies when failed to update
      dispatch(getClientDetailsAnomalies({ id: clientId }));
      return rejectWithValue(error);
    }
  },
);

export const getDeliveryAddressesAll = createAsyncThunk(
  `${managerName}/getDeliveryAddressesAll`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.deliveryAddressesAll(id);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createNewDeliveryAddress = createAsyncThunk(
  `${managerName}/createNewDeliveryAddress`,
  async ({ formData, id }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.addDeliveryAddress(id, formData);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateDeliveryAddress = createAsyncThunk(
  `${managerName}/updateDeliveryAddress`,
  async ({ formData, clientId, deliveryAddressId }, { rejectWithValue }) => {
    try {
      const { identifiant, ...validFormData } = formData;
      const { data } = await API.Teams.updateDeliveryAddress(
        clientId,
        deliveryAddressId,
        validFormData,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getDeliveryAddress = createAsyncThunk(
  `${managerName}/getDeliveryAddress`,
  async ({ clientId, deliveryAddressId }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.getDeliveryAddress(clientId, deliveryAddressId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getCompanyDeliveryList = createAsyncThunk(
  `${managerName}/companyDelivery`,
  async (sageBase, { rejectWithValue }) => {
    try {
      const { data } = await API.CompanyDelivery.companyDelivery(sageBase);
      return data?.data || [];
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getCompanyDeliveryRequirementList = createAsyncThunk(
  `${managerName}/companyDeliveryRequirement`,
  async (sageBase, { rejectWithValue }) => {
    try {
      const { data } = await API.CompanyDelivery.companyDeliveryRequirement(sageBase);
      return data?.data || [];
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getAllTypeOfContactList = createAsyncThunk(
  `${managerName}/getAllTypeOfContactList`,
  async ({ sageBase, fromSage }, { rejectWithValue }) => {
    try {
      const { data } = await API.TeamsProperties.allTypeOfContacts(fromSage, sageBase);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getAllDepartmentList = createAsyncThunk(
  `${managerName}/getAllDepartmentList`,
  async ({ sageBase, fromSage }, { rejectWithValue }) => {
    try {
      const { data } = await API.TeamsProperties.allDepartments(fromSage, sageBase);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getClientContactsDataTable = createAsyncThunk(
  `${managerName}/getClientContactsDataTable`,
  async ({ clientId }, { rejectWithValue }) => {
    try {
      const { data } = await API.TeamsContacts.allClientContactsList(clientId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getClientContactDetails = createAsyncThunk(
  `${managerName}/getClientContactDetails`,
  async ({ clientId, contactId }, { rejectWithValue }) => {
    try {
      const { data } = await API.TeamsContacts.getClientContact(clientId, contactId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createNewClientContact = createAsyncThunk(
  `${managerName}/createNewClientContact`,
  async ({ formData, clientId }, { rejectWithValue }) => {
    try {
      const { data } = await API.TeamsContacts.addClientContact(clientId, formData);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateClientContact = createAsyncThunk(
  `${managerName}/updateClientContact`,
  async ({ formData, clientId, contactId }, { rejectWithValue }) => {
    try {
      const { identifiant, ...validFormData } = formData;
      const { data } = await API.TeamsContacts.updateClientContact(
        clientId,
        contactId,
        validFormData,
      );
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const deleteClientContact = createAsyncThunk(
  `${managerName}/deleteClientContact`,
  async ({ clientId, contactId }, { rejectWithValue }) => {
    try {
      const { data } = await API.TeamsContacts.deleteClientContact(clientId, contactId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const getClientsGroupList = createAsyncThunk(
  `${managerName}/getClientsGroupList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.allGroupList();
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createClientGroup = createAsyncThunk(
  `${managerName}/createClientGroup`,
  async ({ groupName, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.addClientGroup({ group: groupName });
      if (onSuccess) onSuccess(data);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);
export const getClientByNumero = createAsyncThunk(
  `${managerName}/getClientByNumero`,
  async (numeroClient, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.getClientOrganizationByNumeroClient(numeroClient);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createStellairClient = createAsyncThunk(
  `${managerName}/createStellairClient`,
  async ({ stellairAccountForm, clientId }, { rejectWithValue }) => {
    try {
      const { data } = await API.Teams.createStellairAccount(clientId, stellairAccountForm);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const createDynamicsClient = createAsyncThunk(
  `${managerName}/createDynamicsClient`,
  async (clientId, { rejectWithValue }) => {
    try {
      const { data } = await API.Dynamics.createClientInDynamics(clientId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

export const updateDynamicsClient = createAsyncThunk(
  `${managerName}/updateDynamicsClient`,
  async (clientId, { rejectWithValue }) => {
    try {
      const { data } = await API.Dynamics.updateClientInDynamics(clientId);
      return data;
    } catch (error) {
      throwError(error);
      return rejectWithValue(error);
    }
  },
);

const teamsSlice = createSlice({
  name: managerName,
  initialState,
  reducers: {
    clearAutoCompleteData: state => {
      state.clientAutoComplete = {
        isLoading: false,
        isSearching: false,
        data: [],
      };
    },
  },
  extraReducers: builder => {
    // Liste de teams
    builder.addCase(listerTeams.pending, state => {
      state.loading = true;
    });
    builder.addCase(listerTeams.fulfilled, (state, action) => {
      state.loading = false;
      state.listeTeams = action.payload;
    });
    builder.addCase(listerTeams.rejected, state => {
      state.loading = false;
    });

    builder.addCase(getDeliveryAddressesAll.pending, state => {
      state.deliveryAddressDataTableIsLoading = true;
    });
    builder.addCase(getDeliveryAddressesAll.fulfilled, (state, action) => {
      state.deliveryAddressDataTableIsLoading = false;
      state.deliveryAddressData = action.payload;
    });
    builder.addCase(getDeliveryAddressesAll.rejected, state => {
      state.deliveryAddressDataTableIsLoading = false;
    });

    builder.addCase(getClientDetails.pending, state => {
      state.clientDetails.isLoading = true;
    });
    builder.addCase(getClientDetails.fulfilled, (state, action) => {
      state.clientDetails.isLoading = false;
      // clear all anomalies loadings states
      state.anomaliesLoadings = {};
      state.clientDetails.data = action.payload;
    });
    builder.addCase(getClientDetails.rejected, state => {
      state.clientDetails.isLoading = false;
    });

    // only for anomalies update
    builder.addCase(getClientDetailsAnomalies.pending, () => {
      // state.clientDetails.isLoading = true;
    });
    builder.addCase(getClientDetailsAnomalies.fulfilled, (state, action) => {
      // state.clientDetails.isLoading = false;

      // clear all anomalies loadings states
      state.anomaliesLoadings = {};
      state.clientDetails.data.anomalies = action.payload.anomalies;
    });
    builder.addCase(getClientDetailsAnomalies.rejected, () => {
      // state.clientDetails.isLoading = false;
    });

    // Anomalies
    builder.addCase(resolveClientAnomaly.pending, (state, action) => {
      const { anomalieId } = action.meta.arg;
      state.anomaliesLoadings = { ...state.anomaliesLoadings, [anomalieId]: true };
    });
    builder.addCase(resolveClientAnomaly.fulfilled, (state, action) => {
      const { anomalieId } = action.meta.arg;
      const { message } = action.payload;
      toast.success(message);
      state.anomaliesLoadings = { ...state.anomaliesLoadings, [anomalieId]: false };
    });
    builder.addCase(resolveClientAnomaly.rejected, (state, action) => {
      const { anomalieId } = action.meta.arg;
      state.anomaliesLoadings = { ...state.anomaliesLoadings, [anomalieId]: false };
    });

    // Liste de teams
    builder.addCase(getClientsNewListPaginated.pending, (state, action) => {
      const { search } = action.meta?.arg;
      const isSearching = search || search === '';
      state.loading = !isSearching;
      state.isLoadingSearch = isSearching;
    });
    builder.addCase(getClientsNewListPaginated.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoadingSearch = false;
      state.clientsNewData = action.payload;
    });
    builder.addCase(getClientsNewListPaginated.rejected, state => {
      state.isLoadingSearch = false;
      state.loading = false;
    });

    // Clients Auto Complete

    builder.addCase(getClientsAutoCompleteData.pending, (state, action) => {
      const { searchText } = action.meta?.arg;
      const isSearching = searchText || searchText === '';
      state.clientAutoComplete.isLoading = !isSearching;
      state.clientAutoComplete.isSearching = isSearching;
    });
    builder.addCase(getClientsAutoCompleteData.fulfilled, (state, action) => {
      state.clientAutoComplete.isLoading = false;
      state.clientAutoComplete.isSearching = false;
      state.clientAutoComplete.data = action.payload;
    });
    builder.addCase(getClientsAutoCompleteData.rejected, state => {
      state.clientAutoComplete.isLoading = false;
      state.clientAutoComplete.isSearching = false;
    });

    builder.addCase(getCollaboratorsBillingSystem.pending, state => {
      state.collaboratorList.isLoading = true;
    });
    builder.addCase(getCollaboratorsBillingSystem.fulfilled, (state, action) => {
      state.collaboratorList.isLoading = false;
      state.collaboratorList.data = action.payload;
    });
    builder.addCase(getCollaboratorsBillingSystem.rejected, state => {
      state.collaboratorList.isLoading = false;
    });

    // get sage service users
    builder.addCase(getAllSageServiceUsers.pending, state => {
      state.sageServiceUsers.isLoading = true;
    });
    builder.addCase(getAllSageServiceUsers.fulfilled, (state, action) => {
      state.sageServiceUsers.isLoading = false;
      state.sageServiceUsers.data = action.payload;
    });
    builder.addCase(getAllSageServiceUsers.rejected, state => {
      state.sageServiceUsers.isLoading = false;
    });

    // duplicate client
    builder.addCase(duplicateClient.pending, state => {
      state.isDuplicateClient = true;
    });
    builder.addCase(duplicateClient.fulfilled, state => {
      state.isDuplicateClient = false;
    });
    builder.addCase(duplicateClient.rejected, state => {
      state.isDuplicateClient = false;
    });

    // get sage hardware users
    builder.addCase(getAllSageHardwareUsers.pending, state => {
      state.sageHardwareUsers.isLoading = true;
    });
    builder.addCase(getAllSageHardwareUsers.fulfilled, (state, action) => {
      state.sageHardwareUsers.isLoading = false;
      state.sageHardwareUsers.data = action.payload;
    });
    builder.addCase(getAllSageHardwareUsers.rejected, state => {
      state.sageHardwareUsers.isLoading = false;
    });

    // get all sage users
    builder.addCase(getAllClientsList.pending, state => {
      state.allClientsList.isLoading = true;
    });
    builder.addCase(getAllClientsList.fulfilled, (state, action) => {
      state.allClientsList.isLoading = false;
      state.allClientsList.data = action.payload;
    });
    builder.addCase(getAllClientsList.rejected, state => {
      state.allClientsList.isLoading = false;
    });

    // Liste de teams
    builder.addCase(createNewClient.pending, state => {
      state.createNewClientIsLoading = true;
    });
    builder.addCase(createNewClient.fulfilled, (state, action) => {
      toast.success('Le client a bien été ajouté');
      state.newClientId = action.payload?.id;
      state.createNewClientIsLoading = false;
    });
    builder.addCase(createNewClient.rejected, state => {
      state.createNewClientIsLoading = false;
    });

    builder.addCase(updateNewClient.pending, state => {
      state.updateNewClientIsLoading = true;
    });
    builder.addCase(updateNewClient.fulfilled, (state, action) => {
      const { hasActiveSubscriptions } = action.payload;
      const successMessage = hasActiveSubscriptions
        ? 'Les modifications ont bien été enregistrées. La mise à jour a été transmise à SAGE.'
        : 'Les modifications ont bien été enregistrées.';
      toast.success(successMessage);
      state.updateNewClientIsLoading = false;
    });
    builder.addCase(updateNewClient.rejected, state => {
      state.updateNewClientIsLoading = false;
    });

    builder.addCase(createNewDeliveryAddress.pending, state => {
      state.createDeliveryAddress.isLoading = true;
    });
    builder.addCase(createNewDeliveryAddress.fulfilled, (state, action) => {
      state.createDeliveryAddress.data = action.payload;
      state.createDeliveryAddress.isLoading = false;
      toast.success('L’adresse de livraison a bien été ajoutée.');
    });
    builder.addCase(createNewDeliveryAddress.rejected, state => {
      state.createDeliveryAddress.isLoading = false;
    });
    builder.addCase(getAllTypeOfContactList.pending, state => {
      state.listAllTypeOfContact.isLoading = true;
    });
    builder.addCase(getAllTypeOfContactList.fulfilled, (state, action) => {
      state.listAllTypeOfContact.data = action.payload;
      state.listAllTypeOfContact.isLoading = false;
    });
    builder.addCase(getAllTypeOfContactList.rejected, state => {
      state.listAllTypeOfContact.isLoading = false;
    });

    builder.addCase(getAllDepartmentList.pending, state => {
      state.listAllDepartment.isLoading = true;
    });
    builder.addCase(getAllDepartmentList.fulfilled, (state, action) => {
      state.listAllDepartment.data = action.payload;
      state.listAllDepartment.isLoading = false;
    });
    builder.addCase(getAllDepartmentList.rejected, state => {
      state.listAllDepartment.isLoading = false;
    });

    builder.addCase(getClientContactsDataTable.pending, state => {
      state.clientContactsDataTable.isLoading = true;
    });
    builder.addCase(getClientContactsDataTable.fulfilled, (state, action) => {
      state.clientContactsDataTable.data = action.payload;
      state.clientContactsDataTable.isLoading = false;
    });
    builder.addCase(getClientContactsDataTable.rejected, state => {
      state.clientContactsDataTable.isLoading = false;
    });

    builder.addCase(getClientContactDetails.pending, state => {
      state.clientContactDetails.data = {};
      state.clientContactDetails.isLoading = true;
    });
    builder.addCase(getClientContactDetails.fulfilled, (state, action) => {
      state.clientContactDetails.data = action.payload;
      state.clientContactDetails.isLoading = false;
    });
    builder.addCase(getClientContactDetails.rejected, state => {
      state.clientContactDetails.isLoading = false;
    });

    builder.addCase(createNewClientContact.pending, state => {
      state.createClientContact.isLoading = true;
    });
    builder.addCase(createNewClientContact.fulfilled, (state, action) => {
      state.createClientContact.data = action.payload;
      state.createClientContact.isLoading = false;
      toast.success('Le contact a bien été ajouté.');
    });
    builder.addCase(createNewClientContact.rejected, state => {
      state.createClientContact.isLoading = false;
    });

    builder.addCase(updateClientContact.pending, state => {
      state.updateClientContact.isLoading = true;
    });
    builder.addCase(updateClientContact.fulfilled, (state, action) => {
      state.updateClientContact.data = action.payload;
      state.updateClientContact.isLoading = false;
      toast.success('Les modifications ont bien été enregistrées.');
    });
    builder.addCase(updateClientContact.rejected, state => {
      state.updateClientContact.isLoading = false;
    });

    builder.addCase(deleteClientContact.pending, state => {
      state.deleteClientContact.isLoading = true;
    });
    builder.addCase(deleteClientContact.fulfilled, (state, action) => {
      state.deleteClientContact.data = action.payload;
      state.deleteClientContact.isLoading = false;
      toast.success('Le contact a été supprimé.');
    });
    builder.addCase(deleteClientContact.rejected, state => {
      state.deleteClientContact.isLoading = false;
    });

    builder.addCase(updateDeliveryAddress.pending, state => {
      state.updateDeliveryAddress.isLoading = true;
    });
    builder.addCase(updateDeliveryAddress.fulfilled, (state, action) => {
      state.updateDeliveryAddress.data = action.payload;
      state.updateDeliveryAddress.isLoading = false;
      toast.success('Les modifications ont bien été enregistrées.');
    });
    builder.addCase(updateDeliveryAddress.rejected, state => {
      state.updateDeliveryAddress.isLoading = false;
    });

    builder.addCase(getDeliveryAddress.pending, state => {
      state.deliveryAddress.isLoading = true;
    });
    builder.addCase(getDeliveryAddress.fulfilled, (state, action) => {
      state.deliveryAddress.data = action.payload;
      state.deliveryAddress.isLoading = false;
    });
    builder.addCase(getDeliveryAddress.rejected, state => {
      state.deliveryAddress.isLoading = false;
    });

    builder.addCase(getCompanyDeliveryList.pending, state => {
      state.listCompanyDelivery.isLoading = true;
    });
    builder.addCase(getCompanyDeliveryList.fulfilled, (state, action) => {
      state.listCompanyDelivery.data = action.payload;
      state.listCompanyDelivery.isLoading = false;
    });
    builder.addCase(getCompanyDeliveryList.rejected, state => {
      state.listCompanyDelivery.isLoading = false;
    });

    builder.addCase(getCompanyDeliveryRequirementList.pending, state => {
      state.listCompanyDeliveryRequirement.isLoading = true;
    });
    builder.addCase(getCompanyDeliveryRequirementList.fulfilled, (state, action) => {
      state.listCompanyDeliveryRequirement.data = action.payload;
      state.listCompanyDeliveryRequirement.isLoading = false;
    });
    builder.addCase(getCompanyDeliveryRequirementList.rejected, state => {
      state.listCompanyDeliveryRequirement.isLoading = false;
    });

    // client group
    builder.addCase(getClientsGroupList.pending, state => {
      state.group.isLoading = true;
      state.group.data = [];
    });
    builder.addCase(getClientsGroupList.fulfilled, (state, payload) => {
      state.group.isLoading = false;
      state.group.data = payload.data;
    });
    builder.addCase(getClientsGroupList.rejected, state => {
      state.group.isLoading = false;
      state.group.data = [];
    });
    builder.addCase(createClientGroup.pending, state => {
      state.group.isLoading = true;
      state.group.data = [];
    });
    builder.addCase(createClientGroup.fulfilled, state => {
      state.group.isLoading = false;
      state.group.isNewItemCreated = !state.group.isNewItemCreated;
    });
    builder.addCase(createClientGroup.rejected, state => {
      state.group.isLoading = false;
    });
    // client data by numero
    builder.addCase(getClientByNumero.pending, state => {
      state.clientByNumero = {};
    });
    builder.addCase(getClientByNumero.fulfilled, (state, action) => {
      state.clientByNumero = action.payload;
    });
    builder.addCase(getClientByNumero.rejected, state => {
      state.clientByNumero = {};
    });

    // stellair account creation

    builder.addCase(createStellairClient.pending, state => {
      state.stellairAccount.isLoading = true;
    });
    builder.addCase(createStellairClient.fulfilled, (state, action) => {
      state.stellairAccount.data = action.payload;
      state.stellairAccount.isLoading = false;
    });
    builder.addCase(createStellairClient.rejected, state => {
      state.stellairAccount.isLoading = false;
    });
    // createDynamicsClient

    builder.addCase(createDynamicsClient.pending, state => {
      state.isDynamicsSync = true;
    });
    builder.addCase(createDynamicsClient.fulfilled, state => {
      state.isDynamicsSync = false;
    });
    builder.addCase(createDynamicsClient.rejected, state => {
      state.isDynamicsSync = false;
    });
    // updateDynamicsClient

    builder.addCase(updateDynamicsClient.pending, state => {
      state.isDynamicsSync = true;
    });
    builder.addCase(updateDynamicsClient.fulfilled, state => {
      state.isDynamicsSync = false;
    });
    builder.addCase(updateDynamicsClient.rejected, state => {
      state.isDynamicsSync = false;
    });
  },
});

const managerStateSelector = state => state[managerName];
export const selectIsTeamsNewListLoading = createSelector(
  managerStateSelector,
  state => state.loading,
);

export const selectAnomaliesLoadings = createSelector(
  managerStateSelector,
  state => state.anomaliesLoadings,
);

export const selectClientDetailsDataClient = createSelector(
  managerStateSelector,
  state => state.clientDetails.data.client || {},
);
export const selectClientDetailsDataAnomalies = createSelector(
  managerStateSelector,
  state => state.clientDetails.data.anomalies,
);
export const selectIsClientDetailsLoading = createSelector(
  managerStateSelector,
  state => state.clientDetails.isLoading,
);

export const selectClientsNewData = createSelector(
  managerStateSelector,
  state => state.clientsNewData,
);

export const selectIsClientsDataSearchLoading = createSelector(
  managerStateSelector,
  state => state.isLoadingSearch,
);

export const selectNewClientId = createSelector(managerStateSelector, state => state.newClientId);

export const selectDeliveryAddressDataTable = createSelector(
  managerStateSelector,
  state => state.deliveryAddressData,
);

export const selectDeliveryAddressDataTableIsLoading = createSelector(
  managerStateSelector,
  state => state.deliveryAddressDataTableIsLoading,
);

export const selectDeliveryAddressIsLoading = createSelector(
  managerStateSelector,
  state => state.deliveryAddress.isLoading,
);

export const selectDeliveryAddress = createSelector(
  managerStateSelector,
  state => state.deliveryAddress.data,
);

export const selectUpdateDeliveryAddressIsLoading = createSelector(
  managerStateSelector,
  state => state.updateDeliveryAddress.isLoading,
);

export const selectCreateDeliveryAddressIsLoading = createSelector(
  managerStateSelector,
  state => state.createDeliveryAddress.isLoading,
);

export const selectAllTypeOfContactIsLoading = createSelector(
  managerStateSelector,
  state => state.listAllTypeOfContact.isLoading,
);

export const selectAllTypeOfContactList = createSelector(
  managerStateSelector,
  state => state.listAllTypeOfContact.data,
);

export const selectAllDepartmentIsLoading = createSelector(
  managerStateSelector,
  state => state.listAllDepartment.isLoading,
);

export const selectAllDepartmentList = createSelector(
  managerStateSelector,
  state => state.listAllDepartment.data,
);

export const selectClientContactDataTableIsLoading = createSelector(
  managerStateSelector,
  state => state.clientContactsDataTable.isLoading,
);

export const selectClientContactsDataTable = createSelector(
  managerStateSelector,
  state => state.clientContactsDataTable.data,
);

export const selectClientContactDetailsIsLoading = createSelector(
  managerStateSelector,
  state => state.clientContactDetails.isLoading,
);

export const selectClientContactDetails = createSelector(
  managerStateSelector,
  state => state.clientContactDetails.data,
);

export const selectCreateClientContactIsLoading = createSelector(
  managerStateSelector,
  state => state.createClientContact.isLoading,
);

export const selectUpdateClientContactIsLoading = createSelector(
  managerStateSelector,
  state => state.updateClientContact.isLoading,
);

export const selectDeleteClientContactIsLoading = createSelector(
  managerStateSelector,
  state => state.deleteClientContact.isLoading,
);

export const selectCompanyDeliveryRequirementList = createSelector(
  managerStateSelector,
  state => state.listCompanyDeliveryRequirement.data,
);
export const selectCompanyDeliveryList = createSelector(
  managerStateSelector,
  state => state.listCompanyDelivery.data,
);

export const selectIsLoadingCompanyDeliveryRequirementList = createSelector(
  managerStateSelector,
  state => state.listCompanyDeliveryRequirement.isLoading,
);

export const selectIsLoadingCompanyDeliveryList = createSelector(
  managerStateSelector,
  state => state.listCompanyDelivery.isLoading,
);

export const selectCollaboratorsBillingSystem = createSelector(
  managerStateSelector,
  state => state.collaboratorList.data,
);

export const selectIsClientsGroupLoading = createSelector(
  managerStateSelector,
  state => state.group.isLoading,
);

export const selectIsNewGroupItemCreated = createSelector(
  managerStateSelector,
  state => state.group.isNewItemCreated,
);

export const selectClientByNumero = createSelector(
  managerStateSelector,
  state => state.clientByNumero,
);

export const selectIsStellairAccountCreating = createSelector(
  managerStateSelector,
  state => state.stellairAccount.isLoading,
);

export const selectIsDynamicSyncLoading = createSelector(
  managerStateSelector,
  state => state.isDynamicsSync,
);

export const selectIsDuplicateClientLoading = createSelector(
  managerStateSelector,
  state => state.isDuplicateClient,
);

export default teamsSlice.reducer;
