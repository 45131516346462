import React, { useEffect, useState } from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { formatDateTime } from 'app/utils/dateUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBillingOrderEventsHistory,
  selectBillingEventsHistoryData,
} from 'app/redux/slices/souscriptions';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import SubscriptionEventsDetail from 'components/souscriptions/subscriptionDetails/Tabs/EventsTab/SubscriptionEventsDetail';
import customStyles from '../billingOrders.module.scss';

const CardEventsHistory = ({ billingId }) => {
  const dispatch = useDispatch();
  const [actionDetailsData, setActionDetailsData] = useState(null);

  const eventsHistoryData = useSelector(selectBillingEventsHistoryData);

  useEffect(() => {
    dispatch(getBillingOrderEventsHistory({ billingId }));
  }, []);

  const errorEventRow = ({ identifiant, date, message, status, details }) => {
    const rowByStatus = {
      SUCCESS: { icon: 'pi pi-check-circle', color: '#2F7D7C' },
      INFO: { icon: 'pi pi-info-circle' },
      FAIL: { icon: 'pi pi-exclamation-triangle', color: '#B45763' },
    };

    const currentRow = rowByStatus[status];

    return (
      <li
        key={identifiant}
        className={customStyles.customListRow}
        style={{ color: currentRow.color || '' }}
      >
        <i className={currentRow.icon} />
        {formatDateTime(date, 'DD/MM/YYYY – HH:mm:ss')}
        <span>{message}</span>
        <span style={{ marginLeft: 'auto', marginRight: 0 }}>
          <SubmitButton
            className="p-mx-2"
            type="button"
            btnType="primary"
            label="Details"
            onClick={() => {
              setActionDetailsData(details);
            }}
            outlined
            small
            style={{
              border: 'none',
              display: status === 'FAIL' && details ? 'initial' : 'none',
            }}
          />
        </span>
      </li>
    );
  };

  return (
    <CardDisplay title="Événements" style={{ width: 'auto' }}>
      <SubscriptionEventsDetail
        data={actionDetailsData}
        isVisible={actionDetailsData !== null}
        onHide={() => setActionDetailsData(null)}
      />
      <ul className={customStyles.customList}>
        {eventsHistoryData.map(eventHistory => errorEventRow(eventHistory))}
      </ul>
    </CardDisplay>
  );
};
export default CardEventsHistory;
