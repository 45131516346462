import React from 'react';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';

const StellairOfferDialogFields = () => (
  <FormFieldGroup className="p-mt-2 p-mb-2">
    <FormFieldInputText name="name" label="Nom de l’offre Stellair" placeholder="" />
    <FormFieldInputText
      name="technicalIdentifier"
      label="Identifiant technique de l’offre Stellair"
      placeholder=""
    />
    <FormFieldInputText name="revendeur" label="Revendeur de l’offre Stellair" placeholder="" />
  </FormFieldGroup>
);

export default StellairOfferDialogFields;
