import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateClient, selectIsDuplicateClientLoading } from 'app/redux/slices/teams';
import { Portal } from 'react-portal';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';

const DuplicateClientDialog = ({ clientId, visible, onHide, actionOnSuccessDuplicate }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsDuplicateClientLoading);
  const actionOnDuplicate = () => {
    dispatch(duplicateClient({ clientId }))
      .unwrap()
      .then(({ id }) => {
        toast('Le client a bien été ajouté.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
        onHide();
        actionOnSuccessDuplicate(id);
      })
      .catch(() => {});
  };
  return (
    <Portal>
      <DialogBox
        id="duplicateClientDialog"
        visible={visible}
        onHide={onHide}
        width={600}
        title="Duplication du client"
        isLoading={isLoading}
        onCancel={onHide}
        cancelLabel="Annuler"
        onConfirm={actionOnDuplicate}
        confirmLabel="Confirmer la duplication"
        noTopSeparator
        noSubmitTypeOnButton
        dismissableMask
      >
        <div className="p-pb-3">
          Souhaitez-vous réellement dupliquer le client ? Un nouveau client sera créé et transmis
          vers Sage.
        </div>
      </DialogBox>
    </Portal>
  );
};

export default DuplicateClientDialog;
