import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';
import { Form, Field, useField } from 'react-final-form';
import {
  selectSouscription,
  activateStellairSubscription,
  updateActivatedStellairSubscription,
  selectIsStellairActivating,
} from 'app/redux/slices/souscriptions';
import FormFieldWrapper from 'components/_shared/form/FormFieldWrapper';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import { StellairActivationFormValidations } from './StellairFormsValidations';

const StellairAcivateFormFields = ({ haveFinDate }) => {
  const { input: debutDateField } = useField('debutDate');
  const { input: finDateField } = useField('finDate');

  return (
    <>
      <Field
        name={'debutDate'}
        render={({ input, meta: { error, touched, valid } }) => (
          <>
            <FormFieldWrapper>
              <label htmlFor={`debutDate-id`} className="label boldLabel">
                Date de début d’activation du service
              </label>
              <Calendar
                value={input.value}
                onChange={e => input.onChange(e.value)}
                className={!valid && touched ? 'p-invalid' : ''}
                appendTo={document.body}
                maxDate={finDateField.value}
                dateFormat="dd/mm/yy"
              />
              {!!error && touched && <small className="p-error p-d-block">{error}</small>}
            </FormFieldWrapper>
          </>
        )}
      />
      {haveFinDate && (
        <Field
          name={'finDate'}
          render={({ input, meta: { error, touched, valid } }) => (
            <>
              <FormFieldWrapper>
                <label htmlFor={`finDate-id`} className="label boldLabel">
                  Date de fin d’activation du service
                </label>
                <Calendar
                  value={input.value}
                  onChange={e => input.onChange(e.value)}
                  className={!valid && touched ? 'p-invalid' : ''}
                  appendTo={document.body}
                  minDate={debutDateField.value}
                  dateFormat="dd/mm/yy"
                />
                {!!error && touched && <small className="p-error p-d-block">{error}</small>}
              </FormFieldWrapper>
            </>
          )}
        />
      )}
    </>
  );
};

const StellairActivateDialog = ({ isVisible, hideDialog, onSuccess, isEdit, initData }) => {
  const dispatch = useDispatch();
  const [haveFinDate, setFinDateState] = useState(!!initData.finDate);

  const { offreCommerciale, identifiant } = useSelector(selectSouscription);
  const isLoading = useSelector(selectIsStellairActivating);

  const onHide = formReset => {
    hideDialog();
    if (formReset) formReset();
  };

  const handleSubmit = async (values, form) => {
    const formatedValues = {
      debutDate: moment(values.debutDate).format('YYYY-MM-DD'),
    };
    if (haveFinDate && values.finDate && moment.isDate(values.finDate)) {
      formatedValues.finDate = moment(values.finDate).format('YYYY-MM-DD');
    }
    const serviceAction = isEdit
      ? updateActivatedStellairSubscription
      : activateStellairSubscription;
    await dispatch(
      serviceAction({
        souscriptionId: identifiant,
        stellairSubscriptionForm: formatedValues,
      }),
    )
      .unwrap()
      .then(() => {
        toast(
          isEdit
            ? 'Les dates d’activation du service Stellair ont bien été mise à jour.'
            : 'Le service Stellair a bien été activé.',
          {
            type: 'dark',
            style: {
              background: '#2F7D7C',
            },
          },
        );
        onHide(form.reset);
        onSuccess();
      })
      .catch(() => {});
  };

  const initialValues = useMemo(
    () => ({
      debutDate: isEdit ? moment(initData.debutDate).toDate() : moment().toDate(),
      ...(initData.finDate ? { finDate: moment(initData.finDate).toDate() } : {}),
    }),
    [initData],
  );

  const dialogTitle = isEdit
    ? "Mise à jour des dates d'activation du service Stellair"
    : 'Activation du service Stellair';

  const dialogConfirmBtn = isEdit ? 'Enregistrer les modifications' : 'Activer le service Stellair';
  const dialogHeaderText = isEdit
    ? 'Niqola transmettra immédiatement les modifications à Stellair'
    : `Niqola transmettra immédiatement à Stellair les données nécessaires pour activer
  le service souscrit.`;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={StellairActivationFormValidations}
      render={form => (
        <FormEl id="activate_stellair_form" onSubmit={form.handleSubmit} isVisible={isVisible}>
          <DialogBox
            id={`activate_stellair_dialog`}
            visible={isVisible}
            onHide={() => onHide(form.form.reset)}
            width={600}
            title={dialogTitle}
            isLoading={isLoading}
            onCancel={() => onHide(form.form.reset)}
            cancelLabel="Annuler"
            confirmLabel={dialogConfirmBtn}
          >
            <div className="StellairFormBox">
              <div className="StellairDialogInfoBox">
                <img
                  src="/icons/niqola-connect-stellair.png"
                  alt="Connect Niqola user to Stellair"
                />
                <span>{dialogHeaderText}</span>
              </div>
              <div className="StellairClientInfoFromNiqola">
                <h3>Offer</h3>
                <span>{offreCommerciale.nom}</span>
                <span>{offreCommerciale.identifiant}</span>
              </div>
              <StellairAcivateFormFields haveFinDate={haveFinDate} />

              <SubmitButton
                className="p-mt-2 p-ml-2 stellairSwitchDateBtn"
                type="button"
                btnType="primary"
                label={haveFinDate ? 'Retirer la date de fin' : 'Définir une date de fin'}
                outlined
                small
                icon={`pi ${haveFinDate ? 'pi-trash' : 'pi-calendar-plus'}`}
                onClick={() => setFinDateState(!haveFinDate)}
              />
            </div>
          </DialogBox>
        </FormEl>
      )}
    />
  );
};

export default StellairActivateDialog;

const FormEl = ({ isVisible, children, ...rest }) => {
  if (isVisible) return <form {...rest}>{children}</form>;

  return <div>{children}</div>;
};
