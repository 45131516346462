import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import ContactsDialogWrapper from './CreateDialog/ContactsDialogWrapper';
import ContactsDataTable from './ContactsDataTable/ContactsDataTable';

const ContactsTab = ({ clientId, sageBase }) => (
  <div className="p-grid">
    <div className="p-col-12">
      <CardDisplay title="Contacts" style={{ width: 'auto' }}>
        <ContactsDataTable clientId={clientId} sageBase={sageBase} />
        <ContactsDialogWrapper clientId={clientId} sageBase={sageBase} />
      </CardDisplay>
    </div>
  </div>
);

export default ContactsTab;
