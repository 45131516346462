/* eslint-disable import/prefer-default-export */
import { Validators } from 'olaqin-design-system';
import { isValidNumber, maxLengthValidator } from 'app/utils/formFieldUtils';

const yearPattern = /^\d{4}$/;
const yearAfter2020Pattern = /(202[0-9]|20[3-9][0-9]|2[1-9][0-9]{2}|[3-9][0-9]{3})$/;
const monthYearPattern = /^(0[1-9]|1[0-2])\/\d{4}$/;
const monthYearAfter2020Pattern = /^(0[1-9]|1[0-2])\/(202[0-9]|20[3-9][0-9]|2[1-9][0-9]{2}|[3-9][0-9]{3})$/;

const monthlyValidationSchema = {
  name: [
    Validators.required('Veuillez saisir le nom de l’indice.'),
    maxLengthValidator(100, 'Le nom de l’indice ne peut excéder 100 caractères.'),
  ],
  value: [
    Validators.required('Veuillez la valeur de l’indice.'),
    value => !isValidNumber(value) && 'Veuillez saisir une valeur d’indice valide. Exemple : 305,7',
  ],
  date: [
    Validators.required('Veuillez renseigner le mois d’application de l’indice.'),
    value =>
      !monthYearPattern.test(value) && 'Veuillez saisir une période valide. Exemple : 01/2024',
    value => !monthYearAfter2020Pattern.test(value) && 'Veuillez saisir un indice à partir de 2020',
  ],
};

export const formMontlyIndexValidations = values =>
  Validators.createValidator(monthlyValidationSchema)(values);

const yearlyValidationSchema = {
  name: [
    Validators.required('Veuillez saisir le nom de l’indice.'),
    maxLengthValidator(100, 'Le nom de l’indice ne peut excéder 100 caractères.'),
  ],
  value: [
    Validators.required('Veuillez la valeur de l’indice.'),
    value => !isValidNumber(value) && 'Veuillez saisir une valeur d’indice valide. Exemple : 305,7',
  ],
  date: [
    Validators.required("Veuillez renseigner l'année d'application de l'indice."),
    value => !yearPattern.test(value) && 'Veuillez saisir saisir une année valide. Exemple : 2024',
    value => !yearAfter2020Pattern.test(value) && 'Veuillez saisir un indice à partir de 2020',
  ],
};

export const formYearlyIndexValidations = values =>
  Validators.createValidator(yearlyValidationSchema)(values);
