import React, { useEffect, useState } from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectOfferEventsGridData,
  selectIsOfferEventsGridLoading,
  getOffersEventsPaginated,
} from 'app/redux/slices/offres';
import { OfferEventsGridStatusIcons } from 'app/constants/offerConstants';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { formatDate } from 'app/utils/dateUtils';

const OfferEvents = ({ offerId }) => {
  const dispatch = useDispatch();
  const [pageMeta, setPageMeta] = useState({ page: 0, size: 30 });
  const eventsGridData = useSelector(selectOfferEventsGridData);
  const isLoading = useSelector(selectIsOfferEventsGridLoading);

  const fetchGridData = (page, size) => {
    dispatch(getOffersEventsPaginated({ offreCommercialeId: offerId, page, size }));
  };

  useEffect(() => {
    fetchGridData(pageMeta.page, pageMeta.size);
  }, [pageMeta]);

  const onTablePageChange = data => {
    setPageMeta({ page: data.page, size: data.rows });
  };

  const nameBody = row => (
    <span
      style={{
        color: OfferEventsGridStatusIcons[row.status]?.color,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <i className={`pi ${OfferEventsGridStatusIcons[row.status]?.icon} p-mr-2`} />
      <span>{formatDate(row.date, 'DD/MM/YYYY - HH:mm')}</span>
    </span>
  );

  const messageBody = row => (
    <span style={{ color: OfferEventsGridStatusIcons[row.status]?.color }}>
      <span>{row.message}</span>
    </span>
  );

  return (
    <CardDisplay style={{ width: 'auto' }} title="Événements">
      <DataTable
        id="offers-events-table"
        value={eventsGridData?.content || []}
        loading={isLoading}
        paginator
        onPage={onTablePageChange}
        first={eventsGridData?.pageable?.offset || 0}
        numberOfElements={eventsGridData?.numberOfElements || 0}
        totalRecords={eventsGridData?.totalElements || 0}
        rows={eventsGridData?.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun évènement n'a été enregistré."
        responsive={true}
        rowHover
        autoLayout
      >
        <Column
          key="date"
          field="date"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
          body={nameBody}
        />
        <Column
          key="message"
          field="message"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
          body={messageBody}
        />
        <Column
          key="owner"
          field="owner"
          sortable={false}
          headerStyle={{ border: '0', width: '10%' }}
          body={({ owner }) => `par ${owner}`}
        />
      </DataTable>
    </CardDisplay>
  );
};

export default OfferEvents;
