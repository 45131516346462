import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { Portal } from 'react-portal';
import { importSubByFile } from 'app/redux/slices/souscriptions';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { FileUpload as PrimeFileUpload } from 'primereact/components/fileupload/FileUpload';
import { toast } from 'react-toastify';

// ffs primereact https://github.com/primefaces/primereact/issues/2026
// eslint-disable-next-line func-names
PrimeFileUpload.prototype.clearInputElement = function() {
  if (this.fileInput) {
    this.fileInput.value = '';
  }
};

const SubImportDialogForm = ({ visible, onHide }) => {
  const dispatch = useDispatch();

  const uploadFileRef = useRef(null);
  const [labelText, setLabelText] = useState('Sélectionner un fichier');

  const uploadHandler = (files, onChange) => {
    const [file] = files;
    setLabelText(file.name);
    onChange('file', file);
  };

  const handleClickConfirm = async formData => {
    await dispatch(importSubByFile({ name: formData.file.name, file: formData.file }))
      .unwrap()
      .then(() => {
        toast(
          'Le fichier a bien été importé. La création des nouvelles souscriptions est en cours de traitement.',
          {
            type: 'dark',
            style: {
              background: '#404040',
            },
          },
        );
        onHide();
      })
      .catch(() => {});
  };

  const baseInitialData = useMemo(
    () => ({
      file: null,
    }),
    [],
  );

  const customValidation = values => {
    if (!values.file) {
      return { file: 'Veuillez sélectionner un fichier.' };
    }

    return undefined;
  };

  return (
    <Portal>
      <Form
        initialValues={baseInitialData}
        validate={customValidation}
        onSubmit={handleClickConfirm}
        render={({ handleSubmit, submitting, errors, form, submitFailed }) => (
          <form
            className="p-fluid p-formgrid p-grid"
            id="sub-import-dialog"
            onSubmit={handleSubmit}
          >
            <DialogBox
              id="dialogImportSub"
              spacingField="8px"
              spacingLabel="0"
              visible={visible}
              onHide={onHide}
              title="Création de souscriptions à partir d’un fichier CSV"
              noSeparator={false}
              style={{
                maxWidth: '600px',
                overflow: 'auto',
              }}
              className="p-col-12 p-sm-12 p-md-12 p-lg-8"
              confirmLabel="Démarrer l’import"
              onCancel={onHide}
              cancelLabel="Annuler"
              onConfirm={handleSubmit}
              isConfirmActive={!submitting}
            >
              <div>
                Vous vous apprêter à créer de nouvelles souscriptions à partir d’un fichier CSV. Les
                souscriptions créées seront immédiatement activées, et les bons de facturations
                seront transmis à Sage. Cette opération est limité à 100 souscriptions et peut
                prendre plusieurs minutes.
              </div>
              <div>
                <div className="p-py-3">
                  <PrimeFileUpload
                    ref={uploadFileRef}
                    style={{
                      backgroundColor: '#9c67cd !important',
                      borderColor: '#9c67cd',
                    }}
                    chooseLabel={labelText}
                    customUpload
                    auto
                    id="documentUploadSub"
                    mode="basic"
                    uploadHandler={({ files }) => uploadHandler(files, form.change)}
                    accept=".xlsx, .xls, .csv"
                  />
                  <div>
                    {!!errors && submitFailed && (
                      <small className="p-error p-d-block">{errors.file}</small>
                    )}
                  </div>
                </div>
              </div>
            </DialogBox>
          </form>
        )}
      />
    </Portal>
  );
};

export default SubImportDialogForm;
