import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeleteOfferIsLoading, deleteAnOffer } from 'app/redux/slices/offres';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { OfferStatusList } from 'app/constants/offerConstants';
import { toast } from 'react-toastify';

const DeleteOfferDialog = ({
  visible,
  onHide,
  actionOnSuccessDelete,
  offreCommercialeId,
  status,
  customToastMessage,
  customTitle,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectDeleteOfferIsLoading);

  const handleDeleteOffer = async () => {
    await dispatch(deleteAnOffer({ offreCommercialeId }))
      .unwrap()
      .then(() => {
        onHide();
        toast(customToastMessage ?? 'L’offre a été supprimée.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
        actionOnSuccessDelete();
      });
  };

  const confirmationDesc =
    status === OfferStatusList.PUBLIEE
      ? 'Confirmez-vous l’archivage de l’offre ?'
      : `Toutes les modifications apportées seront définitivement perdues. Souhaitez-vous réellement supprimer l'offre ?`;

  return (
    <DialogBox
      id="deleteOfferDialog"
      visible={visible}
      onHide={onHide}
      width={500}
      title={customTitle ?? 'Suppression de l’offre'}
      isLoading={isLoading}
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={handleDeleteOffer}
      confirmLabel="Confirmer la suppression"
      isConfirmButtonDangerColor
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 16 }}>{confirmationDesc}</div>
    </DialogBox>
  );
};

export default DeleteOfferDialog;
