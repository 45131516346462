import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'olaqin-design-system';
import ClientCreateDialogForm from 'components/clientsNew/dialogs/ClientCreateDialogForm';
import {
  getClientsNewListPaginated,
  selectNewClientId,
  downloadClientsImportFileTemplate,
} from 'app/redux/slices/teams';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from 'app/routes/appRoutes';
import { Menu } from 'primereact/menu';
import ClientImportDialogForm from 'components/clientsNew/dialogs/ClientImportDialogForm';

const ClientCreateDialogWrap = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isCreateDialogVisible, handleCreateDialog] = useState(false);
  const [showNewClientForm, setShowNewClientForm] = useState(false);
  const [isImportDialogVisible, handleImportDialog] = useState(false);
  const newClientId = useSelector(selectNewClientId);

  const menu = useRef(null);

  const actionOnSuccessCreate = () => {
    dispatch(getClientsNewListPaginated({ page: 0, size: 15 }));
    setShowNewClientForm(true);
  };

  useEffect(() => {
    if (newClientId && showNewClientForm) {
      setShowNewClientForm(false);
      history.push(routes.CLIENTS_DETAILS(newClientId));
    }
  }, [newClientId, showNewClientForm]);

  const autoDownloadTemplate = responseData => {
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "modèle_d'importation_client.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleGetTemplate = async () => {
    await dispatch(downloadClientsImportFileTemplate())
      .unwrap()
      .then(file => {
        autoDownloadTemplate(file);
      })
      .catch(() => {});
  };

  const subActionsModel = [
    {
      label: 'Importer des clients à partir d’un CSV',
      icon: 'pi pi-file',
      command: () => handleImportDialog(true),
    },
    {
      label: 'Télécharger le modèle Excel',
      icon: 'pi pi-download',
      command: () => handleGetTemplate(),
    },
  ];

  return (
    <>
      {isCreateDialogVisible && (
        <ClientCreateDialogForm
          visible={isCreateDialogVisible}
          onHide={() => handleCreateDialog(false)}
          actionOnSuccessCreate={actionOnSuccessCreate}
        />
      )}
      {isImportDialogVisible && (
        <ClientImportDialogForm
          visible={isImportDialogVisible}
          onHide={() => handleImportDialog(false)}
          actionOnSuccess={actionOnSuccessCreate}
        />
      )}
      <div>
        <Menu
          style={{ width: '21.5rem' }}
          model={subActionsModel}
          popup
          ref={menu}
          id="popup_menu_client"
          appendTo={document.body}
        />
        <Button
          className="p-mr-3"
          label="Importer"
          icon="pi pi-chevron-down"
          iconPos="right"
          btnType="primary"
          onClick={event => menu.current.toggle(event)}
          aria-controls="popup_menu"
          aria-haspopup
          style={{
            height: '48px',
            padding: '9px 12px',
            background: '#FFFFFF',
            border: '1px solid rgba(106, 106, 106, 0.24)',
            color: 'black',
            fontSize: '14px',
            fontFamily: 'Arial',
          }}
        />
        <Button
          btnType="primary"
          label="Nouveau client"
          icon="pi pi-plus"
          style={{ backgroundColor: '#9c67cd', borderColor: '#9c67cd' }}
          onClick={() => handleCreateDialog(true)}
        />
      </div>
    </>
  );
};

export default ClientCreateDialogWrap;
