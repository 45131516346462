import React from 'react';
import { sageBaseTypes } from 'app/constants/client.constants';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import './clientCreateForm.scss';
import { listerComptesCollectifsBillingSystem } from 'app/redux/slices/utilisateurs';

const Identification = ({ billingSystemValue }) => (
  <FormFieldGroup title="Identification">
    <FormFieldInputText name="nom" label="Intitulé de la société" />
    <FormFieldInputText name="email" label="E-mail principal" showValidationIcon />
    <FormFieldDropdown
      name="accountingRef.compteCollectif"
      label="Compte collectif"
      placeholder="Sélectionner"
      loadOptions={{
        action: () =>
          listerComptesCollectifsBillingSystem({
            fromSage: false,
            sageBase: sageBaseTypes[billingSystemValue],
          }),
        optionValue: 'code',
        optionLabel: 'code',
        reload: billingSystemValue,
      }}
      showClearButton
    />
  </FormFieldGroup>
);

export default Identification;
