import React from 'react';
import BillingSystem from './clientCreateForm/BillingSystem';
import Identification from './clientCreateForm/Identification';
import ContactDetails from './clientCreateForm/ContactDetails';
import BusinessIdentifiers from './clientCreateForm/BusinessIdentifiers';
import PaymentInfo from './clientCreateForm/PaymentInfo';
import SalesAdmin from './clientCreateForm/SalesAdmin';
import ComplementaryInfo from './clientCreateForm/ComplementaryInfo';
import ThirdPartyPayerInfo from './clientCreateForm/ThirdPartyPayerInfo';

const ClientCreateFormFields = ({ hasActiveSubscriptions, isEdit, sageBaseType }) => (
  <>
    {!isEdit && <BillingSystem />}
    {sageBaseType && (
      <>
        <Identification billingSystemValue={sageBaseType} />
        <ContactDetails billingSystemValue={sageBaseType} />
        <BusinessIdentifiers billingSystemValue={sageBaseType} />
        <PaymentInfo billingSystemValue={sageBaseType} isEdit={isEdit} />
        <ThirdPartyPayerInfo billingSystemValue={sageBaseType} />
        <SalesAdmin
          billingSystemValue={sageBaseType}
          hasActiveSubscriptions={hasActiveSubscriptions}
          isEdit={isEdit}
        />
        <ComplementaryInfo billingSystemValue={sageBaseType} />
      </>
    )}
  </>
);

export default ClientCreateFormFields;
