import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';

const OfferUpdateLeaveDialog = ({ visible, onHide, actionOnSuccess }) => (
  <DialogBox
    id="updateOfferParamsLeaveDialog"
    visible={visible}
    onHide={onHide}
    width={500}
    title="Quitter le paramétrage"
    onCancel={onHide}
    cancelLabel="Ne pas quitter"
    onConfirm={actionOnSuccess}
    confirmLabel="Quitter sans enregistrer"
    noTopSeparator
    dismissableMask
  >
    <div style={{ marginBottom: 16 }}>
      Si vous avez effectué des modifications, celles-ci seront perdues. Souhaitez-vous réellement
      quitter la configuration des paramètres de facturation ?
    </div>
  </DialogBox>
);

export default OfferUpdateLeaveDialog;
