import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  fetchYearlyIndexGridData,
  selectYearlyIndexesData,
  selectYearlyIndexesIsLoading,
  fetchMonthlyIndexGridData,
  selectMontlyIndexesIsLoading,
  selectMontlyIndexesData,
} from 'app/redux/slices/syntecIndex';
import usePagination from 'app/hooks/usePagination';
import { formatDate } from 'app/utils/dateUtils';
import { TabPanel, TabView } from 'primereact/tabview';
import { useLocation } from 'react-router-dom';
import ActionButton from 'components/_shared/ActionButton/ActionButton';
import IndexesHeaderTable from './IndexesHeaderTable';
import CreateEditSyntcIndexDialog from './dialogs/CreateEditSyntcIndexDialog';
import DeleteSyntecIndexDialog from './dialogs/DeleteSyntecIndexDialog';
import { changeDotWithComma } from './helpers';

const TabsData = [{ label: 'Indices Syntec mensuels' }, { label: 'Indices Syntec annuels' }];

const MonthStatus = 'MONTH';
const YearStatus = 'YEAR';

const IndexesBody = () => {
  const { search } = useLocation();

  const queryPage = new URLSearchParams(search);
  const initStatus = queryPage.get('status');
  const [currentActiveTab, setActiveTab] = useState(initStatus === MonthStatus ? 0 : 1);
  const [targetIndexForDelete, setTargetIndexForDelete] = useState(null);
  const [targetIndexForEdit, setTargetIndexForEdit] = useState(null);
  const yearlyIndexData = useSelector(selectYearlyIndexesData);
  const montlyIndexData = useSelector(selectMontlyIndexesData);
  const isYearlyDataLoading = useSelector(selectYearlyIndexesIsLoading);
  const isMontlyDataLoading = useSelector(selectMontlyIndexesIsLoading);
  const isLoading = currentActiveTab === 0 ? isMontlyDataLoading : isYearlyDataLoading;

  const indexesData = currentActiveTab === 0 ? montlyIndexData : yearlyIndexData;

  const currentStatusLabel = currentActiveTab === 0 ? MonthStatus : YearStatus;
  const { onTablePageChange, onTermsChange, terms, reloadPage } = usePagination({
    dataFetchBy: currentActiveTab === 0 ? fetchMonthlyIndexGridData : fetchYearlyIndexGridData,
    initStatus: currentStatusLabel,
  });

  useEffect(() => {
    if (terms.status) {
      const activeTabOnLoadIndex = terms.status === MonthStatus ? 0 : 1;
      setActiveTab(activeTabOnLoadIndex);
    }
  }, []);

  const bodyDate = ({ dateCreation }) => formatDate(dateCreation, 'DD/MM/YYYY');

  const handleTabChange = ({ index }) => {
    setActiveTab(index);
  };

  useEffect(() => {
    const targetStatus = currentActiveTab === 0 ? MonthStatus : YearStatus;
    onTermsChange('status', targetStatus);
  }, [currentActiveTab]);

  const handleDeleteRow = rowData => {
    setTargetIndexForDelete(rowData);
  };
  const handleOpenEditDialog = rowData => {
    setTargetIndexForEdit(rowData);
  };

  const bodyActionButton = rowData => {
    const actionsModel = [
      {
        label: <span>Modifier</span>,
        command: () => handleOpenEditDialog(rowData),
      },
      {
        label: <span className="supprimer-danger-button">Supprimer</span>,
        command: () => handleDeleteRow(rowData),
      },
    ];
    return <ActionButton model={actionsModel} />;
  };

  const onHideDeleteModal = () => {
    setTargetIndexForDelete(null);
    reloadPage();
  };

  return (
    <>
      <CreateEditSyntcIndexDialog
        status={currentActiveTab}
        statusLabel={currentStatusLabel}
        onSuccess={reloadPage}
        clearEdit={() => setTargetIndexForEdit(null)}
        editData={targetIndexForEdit}
      />
      <DeleteSyntecIndexDialog onHide={onHideDeleteModal} targetIndex={targetIndexForDelete} />
      <TabView id="indexesTableTab" activeIndex={currentActiveTab} onTabChange={handleTabChange}>
        {TabsData.map(tab => (
          <TabPanel key={tab.label} header={tab.label} />
        ))}
      </TabView>
      <DataTable
        id="indexes-table"
        header={<IndexesHeaderTable activeTabIndex={currentActiveTab} />}
        value={indexesData.content || []}
        loading={isLoading}
        paginator={indexesData.totalElements > 14}
        onPage={onTablePageChange}
        first={indexesData.pageable?.offset || 0}
        numberOfElements={indexesData.numberOfElements || 0}
        totalRecords={indexesData.totalElements || 0}
        rows={indexesData.size || 0}
        paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="{first}-{last} de {totalRecords} résultats"
        lazy
        emptyMessage="Aucun élément"
        responsive
        rowHover
        autoLayout
      >
        <Column
          header="Nom de l’indice"
          field="name"
          sortable={false}
          headerStyle={{ border: '0', width: '50%' }}
          bodyStyle={{
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            maxWidth: '0',
            textOverflow: 'ellipsis',
          }}
        />
        <Column
          header="Valeur"
          key="value"
          field="value"
          sortable={false}
          headerStyle={{ border: '0', width: '16%' }}
          bodyStyle={{ padding: '2px 16px' }}
          body={row => changeDotWithComma(row.value)}
        />
        <Column
          header={currentActiveTab === 1 ? "Année d'application" : 'Mois d’application'}
          key="date"
          field="date"
          sortable={false}
          headerStyle={{ border: '0', width: '16%' }}
        />
        <Column
          header="Ajouté le"
          key="dateCreation"
          field="dateCreation"
          sortable={false}
          headerStyle={{ border: '0', width: '16%' }}
          body={bodyDate}
        />
        <Column
          key="modifier"
          header=""
          frozen
          body={bodyActionButton}
          className="action"
          bodyStyle={{ textAlign: 'center' }}
          headerStyle={{ textAlign: 'center', border: '0', width: '10%' }}
        />
      </DataTable>
    </>
  );
};

export default IndexesBody;
