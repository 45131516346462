import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';
import {
  SubscriptionStatusColorModel,
  subscriptionStatusesDropDownItems,
} from 'app/constants/subscription.constants';
import customStyle from './SubscriptionStatus.module.scss';

const SubscriptionStatusDropwDown = ({ value, onChange, placeholder }) => {
  const valueFormatter = () => {
    const formattedValue = (value ?? '').split(',');
    const isValueEmpty = formattedValue[0] === '';
    return isValueEmpty ? [] : formattedValue;
  };

  return (
    <MultiSelect
      placeholder={placeholder ?? 'État'}
      value={valueFormatter()}
      options={subscriptionStatusesDropDownItems()}
      onChange={e => {
        onChange(e.value.length > 0 ? e.value.join(',') : '');
      }}
      appendTo={document.body}
      style={{ marginLeft: 8 }}
      itemTemplate={option => (
        <Chip
          className={customStyle.customChip}
          style={{ backgroundColor: SubscriptionStatusColorModel[option.label] }}
          label={option.label}
        />
      )}
    />
  );
};

export default SubscriptionStatusDropwDown;
