import React from 'react';
import { SplitButton } from 'olaqin-design-system';
import ellipsis from 'olaqin-design-system/src/assets/icons/ellipsis.svg';
import './ActionButton.scss';

const ActionButton = ({ model, ...otherProps }) => (
  <span className="action-button">
    <SplitButton
      id="actionButton"
      appendTo={document.body}
      chevronDown={ellipsis}
      model={model}
      deg="180"
      {...otherProps}
    />
  </span>
);

export default ActionButton;
