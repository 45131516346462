import React from 'react';
import { Chip } from 'primereact/chip';
import {
  SubscriptionStatusColorModel,
  SubscriptionStatusLabelModel,
  subscriptionStatuses,
} from 'app/constants/subscription.constants';
import { formatDateWithDays } from 'app/utils/dateUtils';
import customStyle from './SubscriptionStatus.module.scss';

const StatusSubscription = ({ label, isAnomaly = false, terminated = false }) => {
  const matchedColor = SubscriptionStatusColorModel[label];

  const matchedLabel = SubscriptionStatusLabelModel[label] || label;

  return (
    <>
      <Chip
        className={customStyle.customChip}
        style={{ backgroundColor: matchedColor }}
        label={matchedLabel}
      />
      {isAnomaly && (
        <Chip
          className={customStyle.customChip}
          style={{ backgroundColor: SubscriptionStatusColorModel.ANOMALIE }}
          label={SubscriptionStatusLabelModel.ANOMALIE}
        />
      )}

      {terminated && label !== subscriptionStatuses.CLOSED && (
        <Chip
          className={customStyle.customChip}
          style={{ backgroundColor: SubscriptionStatusColorModel.CLOSED, color: '#000' }}
          label={`${SubscriptionStatusLabelModel.CLOSED} ${formatDateWithDays(
            terminated,
            'DD/MM/YYYY',
            -1,
          )}`}
        />
      )}
    </>
  );
};

export default StatusSubscription;
