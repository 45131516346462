import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { billingSystemTypeServer } from 'app/constants/client.constants';
import { dashOnEmpty } from 'app/utils/formFieldUtils';

const BusinessIdentifiers = ({ clientDetails, sageBaseType }) => {
  const { siret, ape, finess, adeli, rpps } = clientDetails;
  return (
    <CardDisplay title="Identifiants entreprises" style={{ width: 'auto' }}>
      <div className="item">
        <span>N° SIRET</span>
        <span>{dashOnEmpty(siret)}</span>
      </div>
      <div className="item">
        <span>Code APE</span>
        <span>{dashOnEmpty(ape)}</span>
      </div>
      {sageBaseType === billingSystemTypeServer.OLAQIN_HARDWARE && (
        <div className="item">
          <span>N° FINESS</span>
          <span>{dashOnEmpty(finess)}</span>
        </div>
      )}
      {sageBaseType === billingSystemTypeServer.AFFID && (
        <div className="item">
          <span>N° ADELI</span>
          <span>{dashOnEmpty(adeli)}</span>
        </div>
      )}
      {sageBaseType === billingSystemTypeServer.AFFID && (
        <div className="item">
          <span>N° RPPS</span>
          <span>{dashOnEmpty(rpps)}</span>
        </div>
      )}
    </CardDisplay>
  );
};

export default BusinessIdentifiers;
