import calcNumber from 'currency.js';
import { newTariffs, newTypeQuantities } from 'app/constants/quantityConstants';

/**
 * o1 * o2 + fee
 * @param o1
 * @param o2
 * @param fee
 * @return {number}
 */
export const calculatePerFormulaTier = (o1, o2, fee) =>
  calcNumber(o1)
    .multiply(o2)
    .add(fee).value;

export const calculateTier = (selectedTier, max, min, withFee = true) => {
  const quantityToCalc = max - min;
  const checkedQuantity = !min ? quantityToCalc : quantityToCalc + 1;
  const flatFee = withFee ? selectedTier.flatFee : 0;
  return calculatePerFormulaTier(checkedQuantity, selectedTier.amount, flatFee);
};

const calculateTotalPerItemGraduatedPricing = (valueQuantity, quantity) => {
  const allTiers = quantity.tarif.tiers;

  const findTier = allTiers.find(
    tier => valueQuantity >= tier.minimum && valueQuantity <= tier.maximum,
  );
  const maxTier = quantity.tarif.tiers[quantity.tarif.tiers.length - 1];
  const selectedTier = findTier || maxTier;

  const calculatedSelectedTierPrice = calculateTier(
    selectedTier,
    valueQuantity,
    selectedTier.minimum,
  );

  const pricePassedTiers = allTiers.reduce((acc, curr) => {
    if (curr.minimum < selectedTier.minimum) {
      const fullPriceTier = calculateTier(curr, curr.maximum, curr.minimum);
      return calcNumber(acc).add(fullPriceTier).value;
    }
    return acc;
  }, 0);

  return calcNumber(calculatedSelectedTierPrice).add(pricePassedTiers).value;
};

const calculateTotalPerItemVolumePricing = (valueQuantity, quantity) => {
  const findTier = quantity.tarif.tiers.find(
    tier => valueQuantity >= tier.minimum && valueQuantity <= tier.maximum,
  );
  const maxTier = findTier || quantity.tarif.tiers[quantity.tarif.tiers.length - 1];

  return calculatePerFormulaTier(valueQuantity, maxTier.amount, maxTier.flatFee);
};

/**
 * Calculate price based on type of tariff
 * @param {string} ind
 * @param {Array} allSubs
 * @param {Object} quantity
 * @return {string|number}
 */
const calculateTotalPerItem = (ind, allSubs, quantity) => {
  const currentSubOption = allSubs.find(
    el => el.prestation === ind || el.prestation.identifiant === ind,
  );

  // one case when option from user doesn't exist it's when optional and disabled,
  // so we skip calculation for this option
  if (currentSubOption === undefined) {
    return 0;
  }

  if (quantity.tarif.type === newTariffs.TARIFF_GRADUATED) {
    if (quantity.tarif.tierType === 'GRADUATED_PRICING') {
      return calculateTotalPerItemGraduatedPricing(currentSubOption.valueQuantity, quantity);
    }
    if (quantity.tarif.tierType === 'VOLUME_PRICING') {
      return calculateTotalPerItemVolumePricing(currentSubOption.valueQuantity, quantity);
    }

    return '';
  }

  const presetedValue =
    quantity.limitType === 'FROM' && !currentSubOption.valueQuantity
      ? quantity.limit
      : currentSubOption.valueQuantity;

  return calcNumber(quantity.tarif.amount).multiply(presetedValue).value;
};

/**
 * Calculate based on quantity type
 * @param allSubs {Array} Options controlled by user in form
 * @param quantity {Object}
 * @param prestation {Object} - from BE
 * @return {string|number}
 */
const calculatePricePerSub = (allSubs, quantity, prestation) => {
  // new types
  if (
    quantity.type === newTypeQuantities.QUANTITY_LIMITED ||
    quantity.type === newTypeQuantities.QUANTITY_UNLIMITED
  ) {
    return calculateTotalPerItem(prestation.identifiant, allSubs, quantity);
  }
  if (quantity.type === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
    return 'Variable';
  }
  if (quantity.type === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION) {
    return calculateTotalPerItem(quantity.dependPrestation.identifiant, allSubs, quantity);
  }

  return 0;
};

/**
 * Go across allPrestations and get sum and flag if we have variable price
 * @param allPrestations
 * @param options
 * @return {{hasVariablePrice: boolean, sum: number}}
 */
export const calculateTotalFacture = (allPrestations, options) => {
  const totalPrices = {
    hasVariablePrice: false,
    sum: 0,
  };
  const allTotalPrices = allPrestations.map(option =>
    calculatePricePerSub(options, option.quantite, option.prestation),
  );

  totalPrices.sum = allTotalPrices.reduce((acc, curr) => {
    if (typeof curr === 'string') {
      totalPrices.hasVariablePrice = true;
      return acc;
    }
    return calcNumber(acc).add(curr);
  }, 0);

  return totalPrices;
};
