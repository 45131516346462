import { billingSystemTypeServer, thirdPartyPayerOptions } from 'app/constants/client.constants';

export const generateInitialValuesForm = (isEdit = false, baseInitialData, initialData) => {
  if (isEdit) {
    const thirdPartyPayer = initialData.thirdPartyPayer
      ? {
          thirdPartyPayer: {
            nom: initialData.thirdPartyPayerName,
            numeroClient: initialData.thirdPartyPayer,
          },
          thirdPartyPayerOption: thirdPartyPayerOptions[1].value,
        }
      : {
          thirdPartyPayerOption: thirdPartyPayerOptions[0].value,
        };

    return {
      ...initialData,
      ...thirdPartyPayer,
    };
  }
  return baseInitialData;
};

export const removeInvalidField = (formData, isEdit) => {
  const { sageBase } = formData;
  const newFormData = { ...formData };
  if (newFormData?.accountingRef?.categorieComptable === '') {
    delete newFormData.accountingRef.categorieComptable;
  }
  if (newFormData?.accountingRef?.compteCollectif === '') {
    delete newFormData.accountingRef.compteCollectif;
  }
  if (newFormData.thirdPartyPayer?.numeroClient) {
    newFormData.thirdPartyPayer = newFormData.thirdPartyPayer?.numeroClient;
    delete newFormData.thirdPartyPayerName;
  }
  let invalidFields = [];
  if (sageBase === billingSystemTypeServer.OLAQIN_HARDWARE) {
    invalidFields = [
      'activity',
      'faxNumber',
      'quality',
      'rpps',
      'adeli',
      'targetCible',
      'targetCompletement',
      'patientRecord',
      'group',
      'secondReseller',
      'customerNature',
      'hasActiveSubscriptions',
      'thirdPartyPayerOption',
      'hasAnomaly',
    ];
  }
  if (sageBase === billingSystemTypeServer.OLAQIN_SERVICE) {
    invalidFields = [
      'activity',
      'faxNumber',
      'quality',
      'finess',
      'rpps',
      'adeli',
      'targetCible',
      'targetCompletement',
      'patientRecord',
      'group',
      'secondReseller',
      'customerNature',
      'hasActiveSubscriptions',
      'thirdPartyPayerOption',
      'hasAnomaly',
    ];
  }
  if (sageBase === billingSystemTypeServer.AFFID) {
    invalidFields = ['finess', 'hasActiveSubscriptions', 'thirdPartyPayerOption', 'hasAnomaly'];
  }
  if (isEdit) {
    invalidFields.push('sageBase');
  }
  invalidFields.forEach(item => {
    if (newFormData[item] !== undefined) {
      delete newFormData[item];
    }
  });
  return newFormData;
};
