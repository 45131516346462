/* eslint-disable linebreak-style */
import React from 'react';
import { useSelector } from 'react-redux';
import { CardDisplay, BasicTable, Column } from 'olaqin-design-system';
import calcNumber from 'currency.js';
import { selectSouscription } from 'app/redux/slices/souscriptions';
import { EURO_FRENCH } from 'app/utils/currencyUtils';
import {
  newTariffs,
  newTariffsWithTiers,
  newTypeQuantities,
  periodOptions,
  serviceItemRepetition,
} from 'app/constants/quantityConstants';
import { symbol } from 'app/constants/deviceConstants';
import customStyle from 'components/souscriptions/createSubscription/nouvelleSouscription.module.scss';
import {
  calculatePerFormulaTier,
  calculateTier,
} from 'components/souscriptions/createSubscription/calculationOfferOptions';
import { Chip } from 'primereact/chip';
import customStyleChip from 'components/subscriptionsNew/statusSubscription/SubscriptionStatus.module.scss';
import OfferPreviewTotalPrices from 'components/souscriptions/createSubscription/Cards/CardOffer/OfferPreviewTotalPrices';
import { Divider } from 'primereact/divider';

const CardPrestations = ({ title }) => {
  const souscription = useSelector(selectSouscription);
  const { offreCommerciale, options } = souscription;
  const currency = symbol(offreCommerciale?.devise?.nom);

  const getPeriod = serviceItemRepetitionOption =>
    serviceItemRepetitionOption === serviceItemRepetition.RECURRING
      ? `/ ${periodOptions[offreCommerciale.periodiciteFacturation] || '–'}`
      : '';

  const getOfferPrestation = prestationId =>
    offreCommerciale.prestations?.find(prItem => prItem.prestation?.identifiant === prestationId) ??
    {};

  const showSubText = ({ quantite, period }) => {
    if (Object.values(newTypeQuantities).includes(quantite.type)) {
      if (quantite.tarif.type === newTariffs.TARIFF_STANDARD) {
        const { amount } = quantite.tarif;
        return `${EURO_FRENCH(amount)} ${currency} / unité ${period}`;
      }
      if (quantite.tarif.type === newTariffs.TARIFF_PACKAGE) {
        const { amount, units } = quantite.tarif;
        return `${EURO_FRENCH(amount)} ${currency} par groupe de ${units} unités ${period}`;
      }
      if (
        quantite.tarif.type === newTariffs.TARIFF_GRADUATED ||
        quantite.tarif.type === newTariffs.TARIFF_GRADUATED_VOLUME
      ) {
        return '';
      }
    }

    return '';
  };

  const designationBodyTemplate = ({ prestation }) => {
    const { designation, subLabel = false, optional = false } = prestation;

    if (subLabel) {
      return (
        <span className={`p-d-block ${customStyle.designationPrestationSubLabel}`}>
          {designation}
        </span>
      );
    }

    return (
      <>
        <span className={`p-d-block ${customStyle.designationPrestation}`}>{designation}</span>
        {optional && (
          <div className="p-pt-1">
            <Chip className={customStyleChip.customChipOptional} label="Option" />
          </div>
        )}
      </>
    );
  };

  const generateTotalPerItemGraduatedPricing = (currentSubOption, quantite, period) => {
    const currentQuantity = currentSubOption.valueQuantity || 1;
    const allTiers = quantite.tarif.tiers;

    const findTier = allTiers.find(
      tier => currentQuantity >= tier.minimum && currentQuantity <= tier.maximum,
    );
    const maxTier = quantite.tarif.tiers[quantite.tarif.tiers.length - 1];
    const selectedTier = findTier || maxTier;

    const calculatedSelectedTierPriceWithoutFee = calculateTier(
      selectedTier,
      currentQuantity,
      selectedTier.minimum,
      false,
    );

    const passedTiersInfo = allTiers.reduce((acc, curr) => {
      if (curr.minimum < selectedTier.minimum) {
        const fullPriceTierWithoutFee = calculateTier(curr, curr.maximum, curr.minimum, false);
        const quantityToCalc = curr.maximum - curr.minimum;
        const checkedQuantity = !curr.minimum ? quantityToCalc : quantityToCalc + 1;
        const tierFullInfo = {
          prestation: {
            designation: templateTariffDesignationText(curr.maximum, curr.minimum),
            subLabel: true,
          },
          quantite: checkedQuantity,
          tarif: `${EURO_FRENCH(curr.amount)} ${currency} ${period}`,
          total: `${EURO_FRENCH(fullPriceTierWithoutFee)} ${currency} ${period}`,
        };
        const flatFeeInfo = {
          prestation: {
            designation: templateTariffFixedFeeText(curr.maximum, curr.minimum),
            subLabel: true,
          },
          quantite: 1,
          tarif: `${EURO_FRENCH(curr.flatFee)} ${currency} ${period}`,
          total: `${EURO_FRENCH(curr.flatFee)} ${currency} ${period}`,
        };
        acc.push(tierFullInfo, flatFeeInfo);
      }
      return acc;
    }, []);

    const quantityToCalc = currentQuantity - selectedTier.minimum;
    const checkedQuantity = !selectedTier.minimum ? quantityToCalc : quantityToCalc + 1;

    return [
      ...passedTiersInfo,
      {
        prestation: {
          designation: templateTariffDesignationText(selectedTier.maximum, selectedTier.minimum),
          subLabel: true,
        },
        quantite: checkedQuantity,
        tarif: `${EURO_FRENCH(selectedTier.amount)} ${currency} ${period}`,
        total: `${EURO_FRENCH(calculatedSelectedTierPriceWithoutFee)} ${currency} ${period}`,
      },
      {
        prestation: {
          designation: templateTariffFixedFeeText(selectedTier.maximum, selectedTier.minimum),
          subLabel: true,
        },
        quantite: 1,
        tarif: `${EURO_FRENCH(selectedTier.flatFee)} ${currency} ${period}`,
        total: `${EURO_FRENCH(selectedTier.flatFee)} ${currency} ${period}`,
      },
    ];
  };

  const generateTotalPerItemVolumePricing = (currentSubOption, quantite, period) => {
    const currentQuantity = currentSubOption.valueQuantity || 1;
    const findTier = quantite.tarif.tiers.find(
      tier => currentQuantity >= tier.minimum && currentQuantity <= tier.maximum,
    );
    const selectedTier = findTier || quantite.tarif.tiers[quantite.tarif.tiers.length - 1];
    const priceWithoutFee = calculatePerFormulaTier(currentQuantity, selectedTier.amount, 0);

    return [
      {
        prestation: {
          designation: templateTariffDesignationText(selectedTier.maximum, selectedTier.minimum),
          subLabel: true,
        },
        quantite: currentQuantity,
        tarif: `${EURO_FRENCH(selectedTier.amount)} ${currency} ${period}`,
        total: `${EURO_FRENCH(priceWithoutFee)} ${currency} ${period}`,
      },
      {
        prestation: {
          designation: templateTariffFixedFeeText(selectedTier.maximum, selectedTier.minimum),
          subLabel: true,
        },
        quantite: 1,
        tarif: `${EURO_FRENCH(selectedTier.flatFee)} ${currency} ${period}`,
        total: `${EURO_FRENCH(selectedTier.flatFee)} ${currency} ${period}`,
      },
    ];
  };

  const generateTotalPerItem = (ind, quantite, period) => {
    const currentSubOption = souscription.options.find(el => el.prestation.identifiant === ind);

    if (quantite.tarif.type === newTariffs.TARIFF_GRADUATED) {
      if (quantite.tarif.tierType === newTariffsWithTiers.GRADUATED_PRICING) {
        return '';
      }
      if (quantite.tarif.tierType === newTariffsWithTiers.VOLUME_PRICING) {
        return '';
      }

      return '';
    }

    const valueQuantity = currentSubOption.valueQuantity || 1;

    const price = calcNumber(quantite.tarif.amount).multiply(valueQuantity).value;

    return `${EURO_FRENCH(price)} ${currency} ${period}`;
  };

  const getTotalPerRow = (quantite, prestation, period) => {
    // new types
    if (
      quantite.type === newTypeQuantities.QUANTITY_LIMITED ||
      quantite.type === newTypeQuantities.QUANTITY_UNLIMITED
    ) {
      return generateTotalPerItem(prestation.identifiant, quantite, period);
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
      return quantite.tarif.type === newTariffs.TARIFF_STANDARD ? 'Variable' : '';
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION) {
      return generateTotalPerItem(quantite.dependPrestation.identifiant, quantite, period);
    }

    return '';
  };

  const getTiersTariffsInfo = (currentSubOption, quantite, period) => {
    if (quantite.tarif.type === newTariffs.TARIFF_GRADUATED) {
      if (quantite.tarif.tierType === newTariffsWithTiers.GRADUATED_PRICING) {
        return generateTotalPerItemGraduatedPricing(currentSubOption, quantite, period);
      }
      if (quantite.tarif.tierType === newTariffsWithTiers.VOLUME_PRICING) {
        return generateTotalPerItemVolumePricing(currentSubOption, quantite, period);
      }
      return [];
    }
    return [];
  };

  const templateTariffDesignationText = (max, min) =>
    !max ? `à partir de ${min} unités` : `de ${min} à ${max} unités`;

  const templateTariffFixedFeeText = (max, min) =>
    !max ? `Frais fixe à partir de ${min} unités` : `Frais fixe de ${min} à ${max} unités`;

  const getTiersTariffsInfoOnVolume = (currentSubOption, quantite, period) => {
    const allTiers = quantite.tarif.tiers;

    const tiersInfo = allTiers.reduce((acc, curr) => {
      const tierFullInfo = {
        prestation: {
          designation: templateTariffDesignationText(curr.maximum, curr.minimum),
          subLabel: true,
        },
        quantite: '',
        tarif: `${EURO_FRENCH(curr.amount)} ${currency} ${period}`,
        total: `Variable`,
      };
      const flatFeeInfo = {
        prestation: {
          designation: templateTariffFixedFeeText(curr.maximum, curr.minimum),
          subLabel: true,
        },
        quantite: '',
        tarif: `${EURO_FRENCH(curr.flatFee)} ${currency} ${period}`,
        total: `Variable`,
      };
      acc.push(tierFullInfo, flatFeeInfo);

      return acc;
    }, []);

    return tiersInfo;
  };

  const getTiersInfo = (quantite, prestationId, period) => {
    const currentSubOption = souscription.options.find(
      el => el.prestation.identifiant === prestationId,
    );

    if (
      quantite.type === newTypeQuantities.QUANTITY_LIMITED ||
      quantite.type === newTypeQuantities.QUANTITY_UNLIMITED
    ) {
      return getTiersTariffsInfo(currentSubOption, quantite, period);
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME) {
      if (quantite.tarif.type === newTariffs.TARIFF_STANDARD) {
        return [];
      }
      return getTiersTariffsInfoOnVolume(currentSubOption, quantite, period);
    }
    if (quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION) {
      return getTiersTariffsInfo(currentSubOption, quantite, period);
    }

    return [];
  };

  const infosPrestations = () => {
    const result = [];
    if (options?.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const { prestation, quantite, valueQuantity } = option;
        const offerPrestationItem = getOfferPrestation(prestation.identifiant);
        const period = getPeriod(option.serviceItemRepetition);
        const optional = offerPrestationItem?.optional;
        const article = offerPrestationItem?.article;
        const fullPrestation = { designation: prestation.designation, quantite, period, optional };
        const tjm = {
          article,
          prestation: fullPrestation,
          quantite: valueQuantity || 'Selon usage',
          tarif: showSubText(fullPrestation),
          total: getTotalPerRow(quantite, prestation, period),
        };

        const tiersInfo = getTiersInfo(quantite, prestation.identifiant, period);

        result.push(tjm, ...tiersInfo);
      }
    }
    return result;
  };

  return (
    <CardDisplay className="p-grid" title={title} style={{ width: 'auto' }}>
      <span className="p-col-12">{offreCommerciale.nom}</span>

      <div className="p-col-12 p-grid" style={{ marginTop: '16px' }}>
        <BasicTable value={infosPrestations()}>
          <Column
            field="article"
            header="Réf. article Sage"
            headerStyle={{ width: '15%', textAlign: 'left', fontSize: '12px' }}
            style={{ fontSize: '12px' }}
          />
          <Column
            field="prestation"
            header="Prestations"
            headerStyle={{ width: '22%', textAlign: 'left', fontSize: '12px' }}
            style={{ fontSize: '12px' }}
            body={designationBodyTemplate}
          />
          <Column
            field="quantite"
            header="Quantité"
            headerStyle={{ textAlign: 'left', width: '22%', fontSize: '12px' }}
            style={{ fontSize: '12px' }}
          />
          <Column
            field="tarif"
            header="Tarif unitaire"
            headerStyle={{ textAlign: 'right', width: '21%', fontSize: '12px' }}
            style={{ fontSize: '12px', textAlign: 'right' }}
          />
          <Column
            field="total"
            header="Total HT"
            headerStyle={{ width: '21%', textAlign: 'right', fontSize: '12px' }}
            style={{ textAlign: 'right', fontSize: '12px' }}
          />
        </BasicTable>
      </div>
      <Divider />
      <div className="p-col-12">
        <OfferPreviewTotalPrices
          options={options}
          billOneTimeFeesSeparately={offreCommerciale.billOneTimeFeesSeparately}
          periodiciteFacturation={offreCommerciale.periodiciteFacturation}
        />
      </div>
    </CardDisplay>
  );
};

export default CardPrestations;
