import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleIndexDialog } from 'app/redux/slices/syntecIndex';

const IndexesHeaderTable = ({ activeTabIndex }) => {
  const dispatch = useDispatch();
  return (
    <div className="indexes-table-header">
      <h1 style={{ fontWeight: 700, color: '#000' }}>
        {activeTabIndex === 1 ? 'Indices Syntec annuels' : 'Indices Syntec mensuels'}
      </h1>
      <SubmitButton
        btnType="primary"
        label="Nouvel indice"
        icon="pi pi-plus"
        onClick={() => dispatch(toggleIndexDialog(true))}
      />
    </div>
  );
};

export default IndexesHeaderTable;
