import React, { useEffect } from 'react';
import { useField } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  listerCategoriesComptablesBillingSystem,
  selectListeCategoriesComptablesBillingSystem,
} from 'app/redux/slices/utilisateurs';
import {
  getCollaboratorsBillingSystem,
  selectCollaboratorsBillingSystem,
} from 'app/redux/slices/teams';
import {
  salesTypologyOptions,
  sageBaseTypes,
  classificationGieOptions,
} from 'app/constants/client.constants';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import './clientCreateForm.scss';

const SalesAdmin = ({ billingSystemValue, isEdit = false }) => {
  const dispatch = useDispatch();
  const { input: formFieldAccountingRefInput } = useField('accountingRef');
  const { input: formFieldCollaboratorInput } = useField('collaborator');

  const categoriesComptables = useSelector(selectListeCategoriesComptablesBillingSystem);
  const collaborators = useSelector(selectCollaboratorsBillingSystem);
  const categoriesComptablesOptions = categoriesComptables.map(el => ({
    label: el.designation,
    value: el.code,
  }));
  const collaboratorOptions = collaborators.map(el => ({
    label: `${el.nom ?? ''} ${el.prenom ?? ''}`,
    value: el.id,
  }));

  useEffect(() => {
    if (!isEdit) {
      formFieldAccountingRefInput.onChange({
        compteCollectif: '',
        categorieComptable: '',
      });
      formFieldCollaboratorInput.onChange('');
    }
    dispatch(
      listerCategoriesComptablesBillingSystem({
        fromSage: false,
        sageBase: sageBaseTypes[billingSystemValue],
      }),
    );
    dispatch(
      getCollaboratorsBillingSystem({
        sageBase: sageBaseTypes[billingSystemValue],
      }),
    );
  }, [billingSystemValue]);

  return (
    <FormFieldGroup title="Gestion commerciale">
      <FormFieldDropdown
        name="accountingRef.categorieComptable"
        label="Catégorie comptable"
        placeholder="Sélectionner"
        filter
        options={categoriesComptablesOptions}
        showClearButton
      />
      <FormFieldAutoComplete
        name="salesTypology"
        label="Typologie commerciale"
        placeholder="Sélectionner"
        filter
        showClearButton
        storeSingleValueOnForm
        options={salesTypologyOptions}
        loadOptions={{
          optionValue: 'value',
        }}
      />
      <FormFieldDropdown
        name="classificationGie"
        label="Classification GIE"
        placeholder="Sélectionner"
        filter
        options={classificationGieOptions}
        showClearButton
      />
      <FormFieldDropdown
        name="collaborator"
        label="Revendeur"
        placeholder="Sélectionner"
        options={collaboratorOptions}
        showClearButton
      />
    </FormFieldGroup>
  );
};

export default SalesAdmin;
