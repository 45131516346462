import React, { useState } from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { useSelector } from 'react-redux';
import {
  selectDownloadKeys,
  selectIsDownloadKeyLoading,
  selectIsReportsDataLoading,
  selectReportsData,
} from 'app/redux/slices/souscriptions';
import ReportsDataTable from 'components/reports/ReportsDataTable/ReportsDataTable';
import ReportsControls from 'components/reports/ReportsControls';
import ReportsDownload from 'components/reports/ReportsDownload';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import { generateLinkToFile, getFormattedDatesByTemplate } from './reportsUtils';

const Reports = () => {
  useDocumentTitle(`${TITLE_PREFIX} Prestations souscrites`);
  const isLoadingReportsData = useSelector(selectIsReportsDataLoading);
  const reportsData = useSelector(selectReportsData);
  const downloadKeys = useSelector(selectDownloadKeys);
  const isLoadingDownloadKeys = useSelector(selectIsDownloadKeyLoading);

  const [twoDates, handleSetTwoDates] = useState(null);

  const handleChangeTwoDate = ({ value }) => handleSetTwoDates(value);

  const isValidDates = !!twoDates && twoDates.every(Boolean);

  const { fromDateServer, toDateServer, fromDate, toDate } = isValidDates
    ? getFormattedDatesByTemplate(twoDates)
    : {};

  const fileNameForDownload = `Prestations_souscrites_${fromDate}–${toDate}`;

  const isDownloadReady =
    reportsData?.length && !isLoadingDownloadKeys && downloadKeys.reports && !isLoadingReportsData;

  const linkToFile = generateLinkToFile(downloadKeys.reports, fromDateServer, toDateServer);

  return (
    <div>
      <div className="p-d-flex p-justify-between p-align-center">
        <p
          style={{
            fontFamily: 'RecifeDisplay',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '38px',
            lineHeight: '40px',
            color: '#19171a',
          }}
        >
          Prestations souscrites
        </p>
        <ReportsDownload
          fileNameForDownload={fileNameForDownload}
          isDisabled={!isDownloadReady}
          linkToFile={linkToFile}
        />
      </div>
      <CardDisplay title="" style={{ width: 'auto' }}>
        <ReportsControls
          isLoading={isLoadingReportsData}
          twoDates={twoDates}
          handleChangeCalendar={handleChangeTwoDate}
        />
        {!!reportsData && (
          <ReportsDataTable reportsData={reportsData} isLoading={isLoadingReportsData} />
        )}
      </CardDisplay>
    </div>
  );
};

export default Reports;
