import React from 'react';
import { CardDisplay } from 'olaqin-design-system';
import { billingSystemTypeServer, qualityOptions } from 'app/constants/client.constants';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import { combineAddressLabel } from 'app/utils/formatUtils';

const ContactDetails = ({ clientDetails, sageBaseType }) => {
  const {
    adresseFacturation,
    interlocuteur,
    quality,
    emailFacturation,
    phoneNumber,
    faxNumber,
  } = clientDetails;
  const addressLabel = adresseFacturation ? combineAddressLabel(adresseFacturation) : null;

  const findQuality = qualityOptions?.find(({ value }) => value === quality);

  return (
    <CardDisplay title="Coordonnées" style={{ width: 'auto' }}>
      {sageBaseType === billingSystemTypeServer.AFFID && (
        <div className="item">
          <span>Interlocuteur</span>
          <span>{dashOnEmpty(interlocuteur)}</span>
        </div>
      )}
      {sageBaseType === billingSystemTypeServer.AFFID && (
        <div className="item">
          <span>Qualité</span>
          <span>{dashOnEmpty(findQuality?.label || quality)}</span>
        </div>
      )}
      <div className="item">
        <span>Adresse de facturation</span>
        <span>{dashOnEmpty(addressLabel)}</span>
      </div>
      <div className="item">
        <span>E-mail factures démat.</span>
        <span>{dashOnEmpty(emailFacturation)}</span>
      </div>
      <div className="item">
        <span>N° de téléphone (bureau)</span>
        <span>{dashOnEmpty(phoneNumber)}</span>
      </div>
      {sageBaseType === billingSystemTypeServer.AFFID && (
        <div className="item">
          <span>N° de téléphone (portable)</span>
          <span>{dashOnEmpty(faxNumber)}</span>
        </div>
      )}
    </CardDisplay>
  );
};

export default ContactDetails;
