import React, { useMemo, useState } from 'react';
import { CardDisplay, Column } from 'olaqin-design-system';
import { DataTable } from 'primereact/datatable';
import { formatDate } from 'app/utils/dateUtils';
import { dashOnEmpty } from 'app/utils/formFieldUtils';
import { useDispatch } from 'react-redux';
import { createDynamicsClient, updateDynamicsClient } from 'app/redux/slices/teams';
import { toast } from 'react-toastify';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import ConfirmDynamicSyncDialog from './ConfirmDynamicSyncDialog';

const ConnectionsTableCard = ({ clientDetails = {}, refetchData, cardTitle }) => {
  const dispatch = useDispatch();
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const isEdit = clientDetails.dynamicsAccount;

  const connectionTableData = useMemo(() => {
    if (!clientDetails) return [];

    return [
      {
        logo: '/icons/sage-28.svg',
        sageBase: clientDetails.sageBase,
        system: 'Sage',
        syncTime: clientDetails.sageAccount?.creationDate ?? null,
        lastUpdate: clientDetails.sageAccount?.dateDerniereModification ?? null,
      },
      {
        logo: '/icons/Dynamics-28.svg',
        system: 'Dynamics 365',
        syncTime: clientDetails.dynamicsAccount?.dateCreation ?? null,
        lastUpdate: clientDetails.dynamicsAccount?.dateDerniereModification ?? null,
        data: clientDetails.dynamicsAccount ?? {},
      },
    ];
  }, [clientDetails]);

  const onConfirmSync = async () => {
    const action = isEdit ? updateDynamicsClient : createDynamicsClient;
    await dispatch(action(clientDetails.identifiant))
      .unwrap()
      .then(() => {
        setConfirmDialogOpen(false);
        refetchData();
        toast('Les informations du clients ont bien été synchronisées vers Dynamics 365.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
      })
      .catch();
  };

  const bodyDateTime = (row, dateField) =>
    dashOnEmpty(formatDate(row[dateField], 'DD/MM/YYYY - HH:mm'));

  const bodySystem = row => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={row.logo} alt={row.system} style={{ marginRight: 8 }} />
      <div>
        <div style={{ fontWeight: 'bold' }}>{row.system}</div>
        {row.sageBase && <div style={{ color: '#6A6A6A', marginTop: 4 }}>{row.sageBase}</div>}
      </div>
    </div>
  );

  const bodySyncButton = row => (
    <>
      {!row.sageBase && (
        <SubmitButton
          label="Synchroniser"
          btnType="secondary"
          type="button"
          outlined
          onClick={() => setConfirmDialogOpen(true)}
          small
          className="p-mt-2"
          style={{
            background: '#FFFFFF',
            color: 'black',
            width: 'max-content',
          }}
        />
      )}
    </>
  );

  return (
    <CardDisplay
      title={cardTitle ?? 'Comptes de gestion internes du client'}
      style={{ width: 'auto' }}
    >
      <ConfirmDynamicSyncDialog
        visible={isConfirmDialogOpen}
        onHide={() => setConfirmDialogOpen(false)}
        onConfirm={onConfirmSync}
        clientName={clientDetails.nom}
      />
      <DataTable
        id="client_stellair_connection"
        className="StellairClientGrid p-mt-2"
        value={connectionTableData}
        emptyMessage="no data"
        responsive
      >
        <Column
          field="system"
          header="Système"
          headerStyle={{ width: '20%', fontSize: 14 }}
          body={bodySystem}
        />
        <Column
          field="syncTime"
          header="Première synchronisation"
          headerStyle={{ width: '20%', fontSize: 14 }}
          body={data => bodyDateTime(data, 'syncTime')}
        />
        <Column
          field="lastUpdate"
          header="Dernière mise à jour"
          headerStyle={{ width: '20%', fontSize: 14 }}
          body={data => bodyDateTime(data, 'lastUpdate')}
        />
        <Column
          field=""
          header=""
          headerStyle={{ width: '20%', fontSize: 14 }}
          body={bodySyncButton}
        />
      </DataTable>
    </CardDisplay>
  );
};

export default ConnectionsTableCard;
