import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSyntecIndex, selectIsSyntecIndexDeleting } from 'app/redux/slices/syntecIndex';
import { toast } from 'react-toastify';

const DeleteSyntecIndexDialog = ({ targetIndex, onHide }) => {
  const dispatch = useDispatch();
  const isDeleting = useSelector(selectIsSyntecIndexDeleting);
  const onSubmit = async () => {
    await dispatch(deleteSyntecIndex(targetIndex.id))
      .unwrap()
      .then(() => {
        toast(`L’indice ${targetIndex.name} a été supprimé`, {
          type: 'dark',
          style: {
            background: '#6A6A6A',
          },
        });
        onHide();
      })
      .catch(() => {});
  };
  return (
    <DialogBox
      id="DeleteSyntecIndexDialog"
      visible={targetIndex !== null}
      isLoading={isDeleting}
      onHide={onHide}
      width={500}
      title="Suppression de l’indice"
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={onSubmit}
      confirmLabel="Confirmer la suppression"
      noSubmitTypeOnButton
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 8 }}>
        Toutes les informations apportées seront définitivement perdues. Souhaitez-vous réellement
        supprimer cet indice ?
      </div>
    </DialogBox>
  );
};

export default DeleteSyntecIndexDialog;
