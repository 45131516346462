import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsStellairAccountCreating, createStellairClient } from 'app/redux/slices/teams';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { toast } from 'react-toastify';
import { Form } from 'react-final-form';
import { StellairAccountFormValidations } from './StellairFormsValidations';

const AddStellairAccountDialog = ({ isVisible, hideDialog, onSuccess, clientData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsStellairAccountCreating);
  const onHide = formReset => {
    hideDialog();
    if (formReset) formReset();
  };

  const handleSubmit = async (values, form) => {
    await dispatch(
      createStellairClient({ clientId: clientData?.identifiant, stellairAccountForm: values }),
    )
      .unwrap()
      .then(() => {
        toast('Le compte utilisateur Stellair a bien été créé.', {
          type: 'dark',
          style: {
            background: '#2F7D7C',
          },
        });
        onHide(form.reset);
        onSuccess();
      })
      .catch(() => {});
  };

  const initialValues = {
    firstName: clientData?.nom,
    email: clientData?.email,
  };
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={StellairAccountFormValidations}
      render={form => (
        <FormEl
          id="create_stellair_account_form"
          onSubmit={form.handleSubmit}
          isVisible={isVisible}
        >
          <DialogBox
            id={`create_stellair_account_dialog`}
            visible={isVisible}
            onHide={() => onHide(form.form.reset)}
            width={600}
            title="Création du compte utilisateur Stellair"
            isLoading={isLoading}
            onCancel={() => onHide(form.form.reset)}
            cancelLabel="Annuler"
            confirmLabel="Créer le compte sur Stellair"
          >
            <div className="StellairFormBox">
              <div className="StellairDialogInfoBox">
                <img
                  src="/icons/niqola-connect-stellair.png"
                  alt="Connect Niqola user to Stellair"
                />
                <span>
                  Niqola transmettra immédiatement à Stellair les données nécessaires pour créer le
                  compte et l'organisation du client {clientData?.nom}.
                </span>
              </div>
              <div className="StellairClientInfoFromNiqola">
                <h3>Client</h3>
                <span>{clientData?.nom}</span>
                <span>{clientData?.identifiant}</span>
              </div>
              <FormFieldInputText
                name="firstName"
                label="Prénom de l’utilisateur"
                labelClassName="boldLabel"
              />
              <FormFieldInputText
                name="lastName"
                label="Nom de l’utilisateur"
                labelClassName="boldLabel"
              />
              <FormFieldInputText
                name="email"
                label="E-mail du compte utilisateur"
                labelClassName="boldLabel"
              />
            </div>
          </DialogBox>
        </FormEl>
      )}
    />
  );
};

export default AddStellairAccountDialog;

const FormEl = ({ isVisible, children, ...rest }) => {
  if (isVisible) return <form {...rest}>{children}</form>;

  return <div>{children}</div>;
};
