import React, { useEffect, useMemo } from 'react';
import { DropdownSearch } from 'olaqin-design-system';
import { useField } from 'react-final-form';
import { selectPaymentTermsList } from 'app/redux/slices/utilisateurs';

import {
  getClientsAutoCompleteData,
  selectClientByNumero,
  getClientByNumero,
} from 'app/redux/slices/teams';
import { sageBaseTypes, thirdPartyPayerOptions } from 'app/constants/client.constants';
import FormFieldGroup from 'components/_shared/form/FormFieldGroup';
import FormFieldDropdown from 'components/_shared/form/FormFieldDropdown';
import FormFieldAutoComplete from 'components/_shared/form/FormFieldAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import './clientCreateForm.scss';

const ThirdPartyPayerInfo = ({ billingSystemValue }) => {
  const dispatch = useDispatch();
  const paymentTermsList = useSelector(selectPaymentTermsList);
  const { client: tppInfo } = useSelector(selectClientByNumero);

  const { input: clientId } = useField('numeroClient');
  const { input: thirdPartyPayerField } = useField('thirdPartyPayer');
  const { input: thirdPartyPayerOptionField } = useField('thirdPartyPayerOption');

  const isEnabledThirdPayer = thirdPartyPayerOptionField.value === thirdPartyPayerOptions[1].value;

  const handleThirdPartyPayerOptionChange = () => {
    thirdPartyPayerField.onChange('');
  };

  const clientItemTemplate = ({ nom = '', numeroClient }, option) =>
    option.context === 'value' ? (
      nom
    ) : (
      <DropdownSearch.TwoLineListItem topText={nom} downText={numeroClient} />
    );

  const isTppBankDetailsRequired = useMemo(
    () =>
      paymentTermsList.find(
        paymentTermItem =>
          paymentTermItem.identifiant === tppInfo?.paymentTermTemplate?.identifiant,
      )?.bankDetailsRequired ?? false,
    [tppInfo, paymentTermsList],
  );

  useEffect(() => {
    dispatch(getClientByNumero(thirdPartyPayerField.value.numeroClient));
  }, [thirdPartyPayerField.value]);

  return (
    <FormFieldGroup title="Tiers payeur">
      <FormFieldDropdown
        name="thirdPartyPayerOption"
        label=""
        options={thirdPartyPayerOptions}
        onChange={handleThirdPartyPayerOptionChange}
      />
      {isEnabledThirdPayer && (
        <FormFieldAutoComplete
          name="thirdPartyPayer"
          label=""
          showClearButton
          filter
          placeholder="Sélectionner"
          loadOptions={{
            action: (page, size, search) =>
              getClientsAutoCompleteData({
                page,
                size,
                searchText: search,
                sageBase: sageBaseTypes[billingSystemValue],
              }),
            paginated: true,
            disabledOption: clientId.value,
            optionValue: 'numeroClient',
          }}
          getOptionLabel={e => e.nom}
          getOptionValue={e => e.numeroClient}
          formatOptionLabel={clientItemTemplate}
          debounceTimeout={1000}
          cacheUniqs={[]}
        />
      )}
      {tppInfo && (
        <div className="p-col-9 p-md-12 tppInfoBox">
          <div className="p-mb-4">
            <label className="infoLabel">Conditions de paiement du tiers payeur</label>
            <p>{tppInfo.paymentTermTemplate?.label || '-'}</p>
          </div>
          {isTppBankDetailsRequired && (
            <div className="p-mb-4">
              <label className="infoLabel">Coordonnées bancaires du tiers payeur </label>
              {tppInfo.bankDetails ? (
                <>
                  <p>{tppInfo.bankDetails.bankName}</p>
                  <p>{tppInfo.bankDetails.iban}</p>
                  <p>{tppInfo.bankDetails.bic}</p>
                </>
              ) : (
                <p>-</p>
              )}
            </div>
          )}
        </div>
      )}
    </FormFieldGroup>
  );
};

export default ThirdPartyPayerInfo;
