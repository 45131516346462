import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProgressSpinner } from 'olaqin-design-system';
import StatusSubscription from 'components/subscriptionsNew/statusSubscription/StatusSubscription';
import {
  selectSouscription,
  chargerSouscription,
  selectIsSouscriptionLoading,
  resolveSouscriptionAnomaly,
  selectAnomaliesLoadings,
} from 'app/redux/slices/souscriptions';
import { subscriptionStatuses } from 'app/constants/subscription.constants';
import { useDocumentTitle } from 'app/hooks';
import { TITLE_PREFIX } from 'app/constants/appConstants';
import { TabPanel, TabView } from 'primereact/tabview';
import { Chip } from 'primereact/chip';
import GeneralTab from 'components/souscriptions/subscriptionDetails/Tabs/GeneralTab/GeneralTab';
import ReturnToPreviousLink from 'components/souscriptions/subscriptionDetails/ReturnToPreviousLink';
import FactureTab from 'components/souscriptions/subscriptionDetails/Tabs/FactureTab/FactureTab';
import CardAnomalies from 'components/souscriptions/subscriptionDetails/cards/CardAnomalies';
import CardWarnings from 'components/souscriptions/subscriptionDetails/cards/CardWarnings';
import { formatDateWithDays } from 'app/utils/dateUtils';
import { useHistory, useLocation } from 'react-router-dom';
import { getSearchParams } from 'app/hooks/usePagination';
import SideManagerOfSubscription from './sideManagerOfSubscription/SideManagerOfSubscription';
import TerminationActionButton from './TerminationActionButton/TerminationActionButton';
import EventsTab from './Tabs/EventsTab/EventsTab';

import './SubscriptionDetails.scss';

const TabsData = ['Général', 'Facturation', 'Événements'];

const SubscriptionDetailsPage = ({ match }) => {
  const { params } = match;
  const { subscriptionId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const souscription = useSelector(selectSouscription);
  const isSubscriptionLoading = useSelector(selectIsSouscriptionLoading);
  const { offreCommerciale, souscripteur } = souscription;
  const [currentActiveTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(chargerSouscription({ souscriptionId: subscriptionId }));
  }, []);

  useEffect(() => {
    const currentParams = getSearchParams(search);
    if (currentParams.tab) {
      const activeTabOnLoadIndex = TabsData.findIndex(tab => tab === currentParams.tab);
      setActiveTab(activeTabOnLoadIndex);
    }
  }, [search]);

  const isSubscriptionLoaded =
    !isSubscriptionLoading &&
    !!souscripteur &&
    !!offreCommerciale &&
    souscription.identifiant === subscriptionId;

  const pageTitle = `${souscripteur?.nom || ''} sur ${offreCommerciale?.nom || ''}`;

  useDocumentTitle(`${TITLE_PREFIX} ${isSubscriptionLoaded ? pageTitle : ''}`);

  const oneDayBeforeTerminationDate = formatDateWithDays(
    souscription.terminateDate,
    'DD/MM/YYYY',
    -1,
  );

  const onTabChange = e => {
    const nextTab = TabsData[e.index];
    history.push(`?tab=${encodeURIComponent(nextTab)}`);
  };

  const handleClickResolveAnomaly = ({ id, anomalieId }) => {
    dispatch(resolveSouscriptionAnomaly({ souscriptionId: id, anomalieId }));
  };

  if (!isSubscriptionLoaded) {
    return <ProgressSpinner />;
  }

  return (
    <div>
      <ReturnToPreviousLink />
      <div className="p-d-flex p-justify-between">
        <div>
          <h1
            style={{
              margin: '16px 0 10px 0',
              fontFamily: 'Montserrat',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            {pageTitle}
          </h1>
          <StatusSubscription label={souscription.statut} isAnomaly={souscription?.hasAnomaly} />
          {souscription.statut === subscriptionStatuses.ACTIVE && souscription.terminateDate && (
            <Chip
              label={`Résiliation le ${oneDayBeforeTerminationDate}`}
              className="terminationDate"
            />
          )}
        </div>
        <TerminationActionButton souscription={souscription} />
        {souscription.statut === subscriptionStatuses.INCOMPLETE && (
          <SideManagerOfSubscription souscription={souscription} />
        )}
      </div>

      <div className="p-grid">
        <div className="p-col-12">
          <CardAnomalies
            id={souscription.identifiant}
            anomalies={souscription.anomalies}
            onClickResolve={handleClickResolveAnomaly}
            loadingsSelector={selectAnomaliesLoadings}
          />
          <CardWarnings className="p-mt-3" warnings={souscription.warnings} />
          <TabView
            id="subscriptionBilling"
            className="p-mt-3"
            activeIndex={currentActiveTab}
            onTabChange={onTabChange}
          >
            <TabPanel header="Général">
              <GeneralTab souscription={souscription} />
            </TabPanel>
            <TabPanel header="Facturation">
              <FactureTab
                subscriptionId={souscription.identifiant}
                subscriptionStatus={souscription.statut}
              />
            </TabPanel>
            <TabPanel header="Événements">
              <EventsTab subscriptionId={souscription.identifiant} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetailsPage;
