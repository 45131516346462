import React from 'react';
import { ConfirmDialog } from 'olaqin-design-system';

const QuitterSouscription = props => {
  const {
    visible,
    onHide,
    quitterSouscription,
    header = 'Confirmation',
    message = '',
    acceptLabel = 'Oui',
    rejectLabel = 'Non',
  } = props;

  return (
    <ConfirmDialog
      id="quitterSouscriptionId"
      visible={visible}
      onHide={onHide}
      header={header}
      icon="pi pi-exclamation-triangle"
      message={message}
      acceptLabel={acceptLabel}
      accept={() => quitterSouscription(true)}
      rejectLabel={rejectLabel}
      reject={() => quitterSouscription(false)}
    />
  );
};

export default QuitterSouscription;
