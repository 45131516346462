import { newTypeQuantities } from 'app/constants/quantityConstants';

/**
 * Filter options so we can calculate and send only non-optional or enabled by user
 * @param options {Array}
 * @return *[]
 */
export const filterOnlyRequiredAndEnabledOptions = options =>
  options.filter(checkForEnabledAndRequired);

/**
 * Only non-optional or enabled by user
 * @param opt
 * @return {boolean}
 */
export const checkForEnabledAndRequired = opt => !opt.optional || !!opt.enabledOption;
/**
 * Based on offer set initial options
 * @param offerPrestations {Array}
 * @return {(*&{valueQuantity: number})[]}
 */
export const getOptionsFromOfferWithPresetValues = (offerPrestations = []) =>
  offerPrestations.map((prestation, i, allPrestations) => {
    let currPrestation = prestation;
    if (prestation.quantite.type === newTypeQuantities.QUANTITY_DEPENDING_ON_ANOTHER_PRESTATION) {
      currPrestation = allPrestations.find(
        el => el.prestation.identifiant === prestation.quantite.dependPrestation.identifiant,
      );
    }

    const initialValue = currPrestation.quantite.valeur || 1;

    const presetValue =
      currPrestation.quantite.limitType === 'FROM' ? currPrestation.quantite.limit : initialValue;

    return {
      ...prestation,
      valueQuantity: presetValue,
    };

    // return dispatch(
    //   setOptionSouscription({
    //     quantite: { identifiant: p.quantite.identifiant, tarif: p.quantite.tarif },
    //     i,
    //     identifiantPrestation: p.prestation.identifiant,
    //     valueQuantity: presetValue
    //   })
    // );
  });
