import { createSelector, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const managerName = 'roles';

const initialState = {
  profile: 'marketing',
};

const profileSlice = createSlice({
  name: managerName,
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      const successMessage = `Profile défini sur ${action.payload}`;
      toast.success(successMessage);
    },
  },
});

const managerStateSelector = state => state[managerName];

export const selectProfile = createSelector(managerStateSelector, state => state.profile);

export default profileSlice.reducer;
export const { actions } = profileSlice;
export const { setProfile } = actions;
