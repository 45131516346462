import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import store from 'app/redux/store';
import msalInstance from 'app/sso';

import App from './App';

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </Provider>,
  document.getElementById('root'),
);
