import { formatDate } from 'app/utils/dateUtils';
import { API_BASE } from 'app/api/constants';

export const generateLinkToFile = (key, fromDate, toDate) => {
  const encodedKey = encodeURIComponent(key);
  return `${API_BASE}/reports/prestations/download?fromDate=${fromDate}&toDate=${toDate}&key=${encodedKey}`;
};

export const generateLinkToFileFse = (key, billingOrderId) => {
  const encodedKey = encodeURIComponent(key);
  const encodedBillingId = encodeURIComponent(billingOrderId);
  return `${API_BASE}/reports/fse/csv?billingOrderId=${encodedBillingId}&key=${encodedKey}`;
};

export const generateSavedReportsLinkToFile = (key, savedReportId) => {
  const encodedKey = encodeURIComponent(key);
  const reportId = encodeURIComponent(savedReportId);
  return `${API_BASE}/reports/saved/download?savedReportId=${reportId}&key=${encodedKey}`;
};

export const getFormattedDatesByTemplate = arrayOfTwoDates => {
  const fromDateServer = formatDate(arrayOfTwoDates[0], 'YYYY-MM-DD');
  const toDateServer = formatDate(arrayOfTwoDates[1], 'YYYY-MM-DD');

  const fromDate = formatDate(arrayOfTwoDates[0]);
  const toDate = formatDate(arrayOfTwoDates[1]);

  return { fromDateServer, toDateServer, fromDate, toDate };
};
