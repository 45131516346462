export const prestationTypeOptions = [
  { label: 'Ponctuelle', value: 'ONE_TIME' },
  { label: 'Récurrente', value: 'RECURRING' },
];

export const quantityTypeOptions = [
  { label: 'Libre saisie à la souscription', value: 'quantityUnlimited' },
  { label: 'Mesure de l’usage', value: 'quantityDependingOnVolume' },
];

export const quantityTypePonctOptions = [
  { label: 'Libre saisie à la souscription', value: 'quantityUnlimited' },
  { label: 'Mesure de l’usage', value: 'quantityDependingOnVolume', disabled: true },
];

// Connected to countingConditions constants
export const countingConditionsTypeOptions = [
  { label: 'la date de création de l’utilisateur', value: 'Date' },
  { label: 'le rôle de l’utilisateur', value: 'Role' },
  { label: 'le statut de l’utilisateur', value: 'State' },
];

export const countingConditionsDateOperatorsOptions = [
  { label: 'est passée de', value: 'Is before' },
];

export const countingConditionsDateValuesOptions = [
  { label: '1 mois', value: '1 month' },
  { label: '2 mois', value: '2 month' },
  { label: '3 mois', value: '3 month' },
];

export const countingConditionsRoleAndStatusOperatorsOptions = [
  { label: 'est', value: 'Is' },
  { label: 'n’est pas', value: 'Is not' },
  { label: 'contient', value: 'Contains' },
  { label: 'ne contient pas', value: 'Does not contain' },
];

export const countingConditionsRoleValuesOptions = [
  { label: 'utilisateur', value: 'User' },
  { label: 'superviseur', value: 'Supervisor' },
];

export const countingConditionsStatusValuesOptions = [
  { label: 'activé', value: 'Activated' },
  { label: 'créé', value: 'Created' },
];

export const tariffModelsOptions = [
  { label: 'Tarification standard', value: 'tarifStandard' },
  { label: 'Tarification progressive', value: 'tarifGraduated' },
  { label: 'Tarification au volume', value: 'tarifGraduatedVolume' },
];

export const periodicityOptions = [
  { label: 'Mensuelle', value: 'MENSUELLE' },
  { label: 'Trimestrielle', value: 'TRIMESTRIELLE' },
  { label: 'Semestrielle', value: 'SEMESTRIELLE' },
  { label: 'Annuelle', value: 'ANNUELLE' },
];

export const pricingRevisionIndexSettingsOptions = [
  { label: 'Indice Syntec mensuel courant à l’activation', value: 'MONTHLY' },
  { label: 'Indice Syntec annuel courant à l’activation', value: 'ANNUAL' },
  { label: 'Indice spécifique', value: 'CUSTOM' },
  { label: 'Aucune révision tarifaire', value: 'NO_INDEX' },
];

export const durationOptions = [
  { label: '12 mois', value: 12 },
  { label: '24 mois', value: 24 },
  { label: '36 mois', value: 36 },
];

export const renewalOptions = [
  { label: 'Aucune', value: 'AUCUNE' },
  { label: 'Tacite', value: 'TACITE' },
];

export const tarificationsProgressive = [
  { units: 1, upToUnits: 2, tarif: 0, fixedTarif: 0 },
  { units: 3, upToUnits: '∞', tarif: 0, fixedTarif: 0 },
];

export const OfferStatusList = {
  VALIDATION_ADV: 'VALIDATION_ADV',
  PUBLIEE: 'PUBLIEE',
  A_EXAMINER: 'A_EXAMINER',
  BROUILLON: 'BROUILLON',
  PRETE_A_ETRE_PUBLIEE: 'PRETE_A_ETRE_PUBLIEE',
  A_VALIDER: 'A_VALIDER',
  A_COMPLETER: 'A_COMPLETER',
  ARCHIVEE: 'ARCHIVEE',
};

export const DeletableOffersStatus = [
  OfferStatusList.VALIDATION_ADV,
  OfferStatusList.PRETE_A_ETRE_PUBLIEE,
  OfferStatusList.ARCHIVEE,
];

export const ArchivableOffersStatus = [
  OfferStatusList.VALIDATION_ADV,
  OfferStatusList.PRETE_A_ETRE_PUBLIEE,
  OfferStatusList.PUBLIEE,
];

export const OfferStatusColorModel = {
  [OfferStatusList.BROUILLON]: '#6A6A6A',
  [OfferStatusList.VALIDATION_ADV]: '#DB9774',
  [OfferStatusList.ARCHIVEE]: '#6A6A6A',
  [OfferStatusList.PUBLIEE]: '#7092DB',
  [OfferStatusList.PRETE_A_ETRE_PUBLIEE]: '#9380DB',
};

export const OfferStatusLabelModel = {
  [OfferStatusList.BROUILLON]: 'Brouillon',
  [OfferStatusList.VALIDATION_ADV]: 'Validation ADV',
  [OfferStatusList.ARCHIVEE]: 'Archivée',
  [OfferStatusList.PUBLIEE]: 'Publiée',
  [OfferStatusList.PRETE_A_ETRE_PUBLIEE]: 'Prête à être publiée',
};

export const GridEventsStatuses = {
  Info: 'INFO',
  Fail: 'FAIL',
  Success: 'SUCCESS',
};

export const OfferEventsGridStatusIcons = {
  [GridEventsStatuses.Info]: {
    color: '#6A6A6A',
    icon: 'pi-info-circle',
  },
  [GridEventsStatuses.Fail]: {
    color: '#B45763',
    icon: 'pi-times-circle',
  },
  [GridEventsStatuses.Success]: {
    color: '#2F7D7C',
    icon: 'pi-check-circle',
  },
};

export const BillOneTimeFeesList = [
  {
    label: 'Générer un bon de facturation indépendant',
    value: true,
  },
  {
    label: 'Inclure avec les prestations récurrentes',
    value: false,
  },
];
export const TransmissZeroInvoicesList = [
  {
    label: 'Ne pas transmettre à SAGE',
    value: false,
  },
  {
    label: 'Transmettre à sage',
    value: true,
  },
];
export const DateFacturationList = [
  {
    label: 'Au début de la période de facturation',
    value: 'BEGINNING_OF_THE_PERIOD',
  },
  {
    label: '1 mois avant le début de la période',
    value: 'ONE_MONTH_BEFORE_BEGINNING_OF_BILLING_PERIOD',
  },
];

export const OfferPricingRevisionIndexTypes = {
  NO_INDEX: 'NO_INDEX',
};
