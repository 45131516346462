/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Chip } from 'primereact/chip';
import { newTypeQuantities } from 'app/constants/quantityConstants';
import { EURO_FRENCH } from 'app/utils/currencyUtils';
import {
  countingConditionsDateOperatorsOptions,
  countingConditionsDateValuesOptions,
  countingConditionsRoleAndStatusOperatorsOptions,
  countingConditionsRoleValuesOptions,
  countingConditionsStatusValuesOptions,
  countingConditionsTypeOptions,
  quantityTypeOptions,
  tariffModelsOptions,
  prestationTypeOptions,
} from 'app/constants/offerConstants';

const combinedConditionsOptions = [
  ...countingConditionsTypeOptions,
  ...countingConditionsDateOperatorsOptions,
  ...countingConditionsDateValuesOptions,
  ...countingConditionsRoleAndStatusOperatorsOptions,
  ...countingConditionsRoleValuesOptions,
  ...countingConditionsStatusValuesOptions,
];

const findTextLabel = (rowValue, options) =>
  options.find(({ value }) => value === rowValue)?.label || '';

export const getRelatedLabel = (row, selector) =>
  prestationTypeOptions.find(type => type.value === row[selector])?.label;

export const getPrestationRow = ({ label, isOptional }) => (
  <>
    <p className="prestationPr" style={{ marginBottom: 8 }}>
      {label}
    </p>
    {isOptional && <Chip label="Option" className="customChipOptional" />}
  </>
);

export const combineQuantitySummary = ({
  countingConditions,
  quantityType,
  usageDataType,
  usageTypeDataList = [],
}) => {
  const quantityTypeLabel = findTextLabel(quantityType, quantityTypeOptions);
  const usageTypeLabel =
    usageTypeDataList.find(i => i.identifiant === usageDataType?.identifiant)?.nom ?? '';
  const isDependingOnValue = quantityType === newTypeQuantities.QUANTITY_DEPENDING_ON_VOLUME;
  const selectedCountingConditions = countingConditions || [];
  const countingConditionsLabels = selectedCountingConditions.map(
    ({ type, condition, value }, index) => {
      const typeLabel = findTextLabel(type, combinedConditionsOptions);
      const conditionLabel = findTextLabel(condition, combinedConditionsOptions);
      const valueLabel = findTextLabel(value, combinedConditionsOptions);

      return `${index === 0 ? 'Si' : 'Et si'} ${typeLabel} ${conditionLabel} ${valueLabel}`;
    },
  );
  return (
    <div>
      <div>{`${quantityTypeLabel} ${usageTypeLabel} `}</div>
      {isDependingOnValue &&
        countingConditionsLabels.map((labelText, index) => (
          <div key={labelText + index} className="p-pt-1">
            {labelText}
          </div>
        ))}
    </div>
  );
};

export const combineTarifficationSummary = (
  { tarifType, selectors, amount, progressiveData, isMulti, dependPrestationId },
  { value },
) => {
  const tariffModel = findTextLabel(tarifType, tariffModelsOptions);

  const getTariffBracketsLabels = brackets =>
    brackets.map((bracketsRow, index) => {
      const isLastRow = index === brackets.length - 1;
      const descriptionLabelLastRow = `à partir de ${bracketsRow[selectors.min]} unités`;
      const descriptionLabel = `de ${bracketsRow[selectors.min]} à ${
        bracketsRow[selectors.isInfinite] ? '∞' : bracketsRow[selectors.max]
      } unités`;
      const addedFixedFee = bracketsRow[selectors.fixedFee]
        ? `+ ${EURO_FRENCH(bracketsRow[selectors.fixedFee])} €`
        : '';
      const sumLabel = `${EURO_FRENCH(bracketsRow[selectors.amount])} € / unité ${addedFixedFee}`;
      return `${isLastRow ? descriptionLabelLastRow : descriptionLabel} : ${sumLabel}`;
    });

  const isStandardTariff = tarifType === tariffModelsOptions[0].value;
  const labelStandard = `${EURO_FRENCH(amount)} € / unité`;

  const tariffBracketsLabels = getTariffBracketsLabels(progressiveData ?? []);
  const isHaveDependentPrestation = !isStandardTariff && isMulti && !!dependPrestationId;
  const dependentPrestationLabel =
    value.find(({ prestation }) => prestation.identifiant === dependPrestationId)?.prestation
      ?.designation || '';

  return (
    <div>
      <div>{tariffModel}</div>
      <div>
        {isStandardTariff
          ? labelStandard
          : tariffBracketsLabels.map((labelText, index) => (
              <div key={labelText + index} className="p-pt-1">
                {labelText}
              </div>
            ))}
        {isHaveDependentPrestation && (
          <>
            <div className="p-pt-1">Paliers dépendant de la quantité de</div>
            <div>{dependentPrestationLabel}</div>
          </>
        )}
      </div>
    </div>
  );
};
