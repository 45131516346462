import React from 'react';
import { useSelector } from 'react-redux';

import { CardDisplay } from 'olaqin-design-system';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import WarningLabel from 'components/_shared/WarningLabel/WarningLabel';
import { selectOfferUsageTypes } from 'app/redux/slices/offres';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';
import {
  getPrestationRow,
  combineQuantitySummary,
  getRelatedLabel,
  combineTarifficationSummary,
} from '../../offersGridFormatters';

const OfferCompositionTable = ({
  prestations,
  articleRowRenderer,
  children,
  actionOnReqParametersEdit,
  displayConfigureBtn,
}) => {
  const usageTypeDataList = useSelector(selectOfferUsageTypes);

  const getArticleRow = row => row.article ?? <WarningLabel />;

  const quantityRow = row =>
    combineQuantitySummary({
      countingConditions: row.quantite.countingConditions,
      quantityType: row.quantite.type,
      usageDataType: row.quantite.usageDataType,
      usageTypeDataList,
    });

  const tarificationRow = (row, rowData) =>
    combineTarifficationSummary(
      {
        tarifType: row.quantite.tarif.type,
        selectors: {
          min: 'minimum',
          max: 'maximum',
          fixedFee: 'flatFee',
          amount: 'amount',
          isInfinite: 'illimite',
        },
        amount: row.quantite.tarif.amount,
        progressiveData: row.quantite.tarif?.tiers,
        isMulti: row.quantite.tarif.tiersMultiplierPrestation?.identifiant,
        dependPrestationId: row.quantite.tarif.tiersMultiplierPrestation?.identifiant,
      },
      rowData,
    );
  return (
    <CardDisplay style={{ width: 'auto' }} title="Composition de l’offre">
      <DataTable
        id="offer-composition-table"
        className="p-mt-2"
        value={prestations || []}
        responsive
        rowHover
        autoLayout
        rowClassName={() => ({
          compositionTableRow: 'compositionTableRow',
        })}
      >
        <Column
          header="Référence article"
          field="article"
          headerStyle={{ border: '0', width: '25%' }}
          body={articleRowRenderer ?? getArticleRow}
          bodyStyle={{
            maxWidth: '0',
          }}
        />
        <Column
          header="Prestation"
          field="prestation.designation"
          headerStyle={{ border: '0', width: '20%' }}
          body={row =>
            getPrestationRow({
              label: row.prestation.designation,
              isOptional: row.optional,
            })
          }
          bodyStyle={{
            maxWidth: '0',
          }}
        />
        <Column
          field="quantite.type"
          header="Type"
          headerStyle={{ border: '0', width: '10%' }}
          body={row => getRelatedLabel(row, 'serviceItemRepetition')}
        />
        <Column
          field="quantity"
          header="Quantité"
          headerStyle={{ border: '0', width: '20%' }}
          body={quantityRow}
        />

        <Column
          field="quantite.tarif"
          header="Tarification HT"
          headerStyle={{ border: '0', width: '25%' }}
          body={tarificationRow}
        />
      </DataTable>
      {children}
      {displayConfigureBtn && (
        <div className="p-pt-2">
          <SubmitButton
            className="p-mt-2"
            type="button"
            btnType="secondary"
            label="Configurer les paramètres de facturation"
            outlined
            small
            style={{ background: 'white' }}
            icon="pi pi-cog"
            onClick={actionOnReqParametersEdit}
          />
        </div>
      )}
    </CardDisplay>
  );
};

export default OfferCompositionTable;
