import React from 'react';
import { Divider } from 'primereact/divider';
import customStyle from 'components/souscriptions/createSubscription/nouvelleSouscription.module.scss';
import OfferPreviewRow from './OfferPreviewRow';
import OfferPreviewTotalPrices from './OfferPreviewTotalPrices';

const OfferPreview = ({ baseOffre, options }) => (
  <div className="p-col-12">
    <div className="p-mb-2">
      <div className="p-grid">
        <div
          className={`p-col-fixed ${customStyle.columnTitlePrestationCard}`}
          style={{ width: '6%' }}
        />
        <div
          className={`p-col-fixed ${customStyle.columnTitlePrestationCard}`}
          style={{ width: '15%' }}
        >
          Réf. article Sage
        </div>
        <div
          className={`p-col-fixed ${customStyle.columnTitlePrestationCard}`}
          style={{ width: '36%' }}
        >
          Prestations
        </div>
        <div
          className={`p-col-fixed ${customStyle.columnTitlePrestationCard}`}
          style={{ width: '20%' }}
        >
          Quantité
        </div>
        <div
          className={`p-col-fixed p-text-right ${customStyle.columnTitlePrestationCard}`}
          style={{ width: '22.5%' }}
        >
          Total HT
        </div>
      </div>
    </div>
    {baseOffre.devise &&
      baseOffre.prestations.map((el, index) => (
        <>
          {el.prestation?.identifiant && (
            <OfferPreviewRow
              key={el.prestation.identifiant}
              prestation={el}
              allPrestations={baseOffre.prestations}
              periodiciteFacturation={baseOffre.periodiciteFacturation}
              devise={baseOffre.devise}
              options={options}
              index={index}
            />
          )}
        </>
      ))}
    <Divider />
    <OfferPreviewTotalPrices
      options={options}
      billOneTimeFeesSeparately={baseOffre.billOneTimeFeesSeparately}
      periodiciteFacturation={baseOffre.periodiciteFacturation}
    />
  </div>
);

export default OfferPreview;
