import React from 'react';
import { CardDisplay, TwoLineData } from 'olaqin-design-system';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import customStyles from '../billingOrders.module.scss';

const CardPrestationDetails = ({ title, total, billingOrderLines, isIndexed }) => {
  const designationTemplate = row => {
    if (!row.note) {
      return row.designation;
    }

    return <TwoLineData topText={row.designation} downText={row.note} />;
  };

  return (
    <CardDisplay title={title} style={{ width: 'auto' }}>
      <DataTable
        id="prestations-details-table"
        className="p-mt-3"
        value={billingOrderLines || []}
        lazy
        emptyMessage="Aucun bon de facturation transmis"
        responsive
        rowHover
      >
        <Column
          field="article"
          header="Réf. article"
          sortable={false}
          headerStyle={{ border: '0', width: '14%' }}
          bodyStyle={{ color: '#6A6A6A' }}
        />
        <Column
          field="designation"
          header="Désignation"
          body={designationTemplate}
          sortable={false}
          headerStyle={{ border: '0', width: '50%' }}
          bodyStyle={{ padding: '0.5rem 1rem' }}
        />
        <Column
          field="quantityLabel"
          header="Quantité"
          sortable={false}
          headerStyle={{ border: '0', textAlign: 'right', width: '12%' }}
          bodyStyle={{ textAlign: 'right', wordSpacing: '0' }}
        />
        <Column
          field="pricePerItemLabel"
          header="Tarif initial HT"
          sortable={false}
          headerStyle={{ border: '0', textAlign: 'right', width: '12%' }}
          bodyStyle={{ textAlign: 'right', wordSpacing: '0' }}
        />
        {isIndexed && (
          <Column
            field="pricePerItemIndexedLabel"
            header="Tarif unitaire HT"
            sortable={false}
            headerStyle={{ border: '0', textAlign: 'right', width: '12%' }}
            bodyStyle={{ textAlign: 'right', wordSpacing: '0' }}
          />
        )}
        <Column
          field="totalLabel"
          header="Total"
          sortable={false}
          headerStyle={{ border: '0', textAlign: 'right', width: '12%' }}
          bodyStyle={{ textAlign: 'right', wordSpacing: '0' }}
        />
      </DataTable>
      <div className={customStyles.footerTableTotal}>
        <div className="p-d-flex p-flex-column">
          <span style={{ fontWeight: 700 }}>Total HT</span>
          <span style={{ fontSize: '1rem' }}>{total} HT</span>
        </div>
      </div>
    </CardDisplay>
  );
};

export default CardPrestationDetails;
