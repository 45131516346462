import React from 'react';
import { Field, useField } from 'react-final-form';
import { CardDisplay } from 'olaqin-design-system';
import { Dropdown as PrimeDropdown } from 'primereact/components/dropdown/Dropdown';
import {
  durationOptions,
  periodicityOptions,
  pricingRevisionIndexSettingsOptions,
  renewalOptions,
} from 'app/constants/offerConstants';
import { InputText as PrimeInputText } from 'primereact/components/inputtext/InputText';
import FormFieldInputText from 'components/_shared/form/FormFieldInputText';

const CardConditions = () => {
  const { input: currentIndexInput } = useField('conditions.pricingRevisionIndexSettings.type');

  const isCurrentIndexCustom =
    currentIndexInput.value === pricingRevisionIndexSettingsOptions[2].value;

  return (
    <CardDisplay title="Conditions contractuelles" style={{ width: '100%' }}>
      <div className="p-field p-fluid p-lg-5 p-p-0 p-mt-3">
        <p style={{ fontWeight: 700 }} className="p-mb-1">
          Périodicité de facturation
        </p>
        <Field
          name="conditions.pereodicity"
          render={({ input }) => (
            <PrimeDropdown
              className="p-mb-4"
              value={input.value}
              options={periodicityOptions}
              onChange={input.onChange}
              autoResize
            />
          )}
        />
        <p style={{ fontWeight: 700 }} className="p-mb-1">
          Durée d’engagement
        </p>
        <Field
          name="conditions.dure"
          render={({ input }) => (
            <PrimeDropdown
              className="p-mb-4"
              value={input.value}
              options={durationOptions}
              onChange={input.onChange}
              autoResize
            />
          )}
        />
        <p style={{ fontWeight: 700 }} className="p-mb-1">
          Reconduction
        </p>
        <Field
          name="conditions.reconduction"
          render={({ input, meta: { valid, error, touched } }) => (
            <>
              <PrimeDropdown
                className={!valid && touched ? 'p-invalid' : ''}
                value={input.value}
                options={renewalOptions}
                onChange={input.onChange}
                autoResize
              />
              {!!error && touched && (
                <small style={{ height: 0 }} className="p-error">
                  {error}
                </small>
              )}
            </>
          )}
        />
        <p style={{ fontWeight: 700 }} className="p-mb-1">
          Référence de révision tarifaire (S0)
        </p>
        <Field
          name="conditions.pricingRevisionIndexSettings.type"
          render={({ input }) => (
            <PrimeDropdown
              className="p-mb-2"
              value={input.value}
              options={pricingRevisionIndexSettingsOptions}
              onChange={input.onChange}
              autoResize
            />
          )}
        />
        {isCurrentIndexCustom && (
          <>
            <FormFieldInputText
              wrapperClassName="p-pr-0 p-pt-0 p-pl-0 p-pb-2"
              name="conditions.pricingRevisionIndexSettings.name"
              placeholder="Nom de l’indice"
              inputType="textarea"
              autoResize
              rows={1}
            />
            <Field
              name="conditions.pricingRevisionIndexSettings.value"
              render={({ input, meta: { valid, error, touched } }) => (
                <>
                  <PrimeInputText
                    style={{ color: '#495057' }}
                    className={!valid && touched ? 'p-invalid p-d-block' : ''}
                    placeholder="Valeur de l’indice"
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                  />
                  {!!error && touched && (
                    <small style={{ height: 0 }} className="p-error p-d-block">
                      {error}
                    </small>
                  )}
                </>
              )}
            />
          </>
        )}
      </div>
    </CardDisplay>
  );
};

export default CardConditions;
