import React from 'react';
import DialogBox from 'components/_shared/DialogBox/DialogBox';
import { useDispatch } from 'react-redux';
import { reloadStellairReport } from 'app/redux/slices/reports';
import { toast } from 'react-toastify';

const ReloadConfirmationModal = ({ targetId, onHide, onSucess }) => {
  const dispatch = useDispatch();
  const onSubmit = async () => {
    onHide();
    toast(
      'La récupération du rapport est en cours. Veuillez consulter cette page ultérieurement vérifier l’état de l’opération.',
      {
        type: 'dark',
        style: {
          background: '#6A6A6A',
        },
      },
    );
    await dispatch(reloadStellairReport(targetId))
      .unwrap()
      .then(() => {
        onSucess();
      })
      .catch(() => {});
  };
  return (
    <DialogBox
      id="ReloadConfirmationModal"
      visible={targetId !== null}
      onHide={onHide}
      width={500}
      title="Récupération du rapport d’usage Stellair"
      onCancel={onHide}
      cancelLabel="Annuler"
      onConfirm={onSubmit}
      confirmLabel="Confirmer"
      noSubmitTypeOnButton
      noTopSeparator
      dismissableMask
    >
      <div style={{ marginBottom: 8 }}>
        Si la récupération du rapport est effectuée avec succès, tous les bons de facturation
        suspendus seront calculées et transmis vers Sage. Confirmez-vous la récupération du rapport
        d’usage Stellair?
      </div>
    </DialogBox>
  );
};

export default ReloadConfirmationModal;
