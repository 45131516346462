const Devise = { EUR: 'EUR', USD: 'USD', GBP: 'GBP' };

export const { EUR, USD, GBP } = Devise;

export const symbol = nom => {
  // eslint-disable-next-line default-case
  switch (nom) {
    case EUR:
      return '€';
    case USD:
      return '$';
    case GBP:
      return '£';
    default:
      throw new Error('Le type quantité est invalide!');
  }
};

export default Devise;
