import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import SubmitButton from 'components/_shared/SubmitButton/SubmitButton';

const OffersActionMenu = ({
  deleteConf,
  deFacturationConf,
  archiveConf,
  duplicateConf,
  renameConf,
}) => {
  const menu = useRef(null);

  const ActionsModel = [
    {
      label: 'Paramètres de facturation',
      icon: 'pi pi-cog',
      command: deFacturationConf.onClick,
      disabled: deFacturationConf.isDisabled,
      style: {
        display: deFacturationConf.show ? 'initial' : 'none',
      },
    },
    {
      label: 'Renommer l’offre',
      icon: 'pi pi-pencil',
      command: renameConf.onClick,
      disabled: renameConf.isDisabled,
    },
    {
      label: 'Dupliquer l’offre',
      icon: 'pi pi-clone',
      command: duplicateConf.onClick,
      disabled: duplicateConf.isDisabled,
    },
    {
      label: 'Archiver l’offre',
      icon: 'pi pi-folder',
      command: archiveConf.onClick,
      disabled: archiveConf.isDisabled,
      template: (item, options) => {
        if (item.disabled) {
          return (
            <>
              <Tooltip target=".disabled-button-archiver" position="bottom" />
              <span
                className="disabled-button-archiver"
                data-pr-tooltip={
                  "Seules les offres ayant le statut 'Validation ADV', 'Prête à être publiée' ou 'Publiée' peuvent être archivées."
                }
                data-pr-position="bottom"
              >
                {options.element}
              </span>
            </>
          );
        }

        return options.element;
      },
    },
    {
      label: 'Supprimer l’offre',
      icon: 'pi pi-trash',
      className: 'deleteActionMenu',
      command: deleteConf.onClick,
      disabled: deleteConf.isDisabled,
      style: {
        display: deleteConf.show ? 'initial' : 'none',
      },
      template: (item, options) => {
        if (item.disabled && deleteConf.showTooltip) {
          return (
            <>
              <Tooltip target=".disabled-button" position="bottom" />
              <span
                className="disabled-button"
                data-pr-tooltip={'La suppression d’une offre ayant été publiée n’est pas admise.'}
                data-pr-position="bottom"
              >
                {options.element}
              </span>
            </>
          );
        }

        return options.element;
      },
    },
  ];

  return (
    <>
      <Menu
        style={{ width: '18rem' }}
        model={ActionsModel}
        popup
        ref={menu}
        id="offers_popup_menu"
        appendTo={document.body}
      />
      <SubmitButton
        label="Actions"
        icon="pi pi-chevron-down"
        btnType="secondary"
        type="button"
        outlined
        onClick={event => menu.current.toggle(event)}
        small
        style={{
          background: '#FFFFFF',
          color: 'black',
        }}
      />
    </>
  );
};

export default OffersActionMenu;
