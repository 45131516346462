import React from 'react';
import { billingSystemTypeServer } from 'app/constants/client.constants';
import { ProgressSpinner } from 'olaqin-design-system';
import Identification from './sections/Identification';
import ContactDetails from './sections/ContactDetails';
import BusinessIdentifiers from './sections/BusinessIdentifiers';
import PaymentInfo from './sections/PaymentInfo';
import SalesAdmin from './sections/SalesAdmin';
import ComplementaryInfo from './sections/ComplementaryInfo';

const GeneralTab = ({ clientDetails }) => {
  const { sageBase: sageBaseType } = clientDetails;

  // prevent rerender during sageBase get its value
  if (!sageBaseType) {
    return <ProgressSpinner />;
  }

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <Identification clientDetails={clientDetails} sageBaseType={sageBaseType} />
        <ContactDetails clientDetails={clientDetails} sageBaseType={sageBaseType} />
        <BusinessIdentifiers clientDetails={clientDetails} sageBaseType={sageBaseType} />
        <PaymentInfo clientDetails={clientDetails} />
        <SalesAdmin clientDetails={clientDetails} />
        {sageBaseType === billingSystemTypeServer.AFFID && (
          <ComplementaryInfo clientDetails={clientDetails} sageBaseType={sageBaseType} />
        )}
      </div>
    </div>
  );
};

export default GeneralTab;
