import React from 'react';
// import { ProgressSpinner } from 'olaqin-design-system';
import customStyles from './FullScreenLoader.module.scss';

export default function FullScreenLoader() {
  return (
    <div className={customStyles.container}>
      {/* <ProgressSpinner /> */}
      <img
        className={customStyles.imageScale}
        alt="Login Loading"
        src="./icons/favicon_144px.svg"
      />
    </div>
  );
}
